import { render, staticRenderFns } from "./CatalogSpecialList.vue?vue&type=template&id=923e9cde&scoped=true&"
import script from "./CatalogSpecialList.vue?vue&type=script&lang=js&"
export * from "./CatalogSpecialList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "923e9cde",
  null
  
)

export default component.exports