<template>
  <div class="layout-row">
    <v-tooltip bottom open-delay="750">
      <template v-slot:activator="{ on }">

        <div v-on="on" class="layout-row layout-align-center-center" style="position:relative;height:100%;">

          <div class="blue-grey lighten-4 layout-row layout-align-center-center" style="border-radius: 4px;width:47px;height:25px;" v-on:click="$emit('clickToggleBtn', value)">
            <div class="px-3" style="border-radius:2px;">
              <v-icon v-if="!valueBool" color="blue-grey" small>mdi-star-outline</v-icon>
              <v-icon v-else color="green" small>mdi-star</v-icon>
            </div>
          </div>

        </div>

      </template>
      <span v-if="!valueBool">Zur Favoritenliste hinzufügen</span>
      <span v-if="valueBool">Von der Favoritenliste entfernen</span>
    </v-tooltip>
  </div>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: [
    'value',
    'field',
    'clickToggleBtn',
  ],
  data() {
    return {
      icon: '',
      valueBool: false,
    }
  },
  created() {
    this.valueBool = (this.value && this.field && this.getValueByObjectPath(this.value, this.field))
  },
  watch: {
    value: {
      handler(newVal) {
        this.valueBool = (newVal && this.field && this.getValueByObjectPath(newVal, this.field))
      },
      deep: true
    },
  },
  methods: {
    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }
    }
  }
})
</script>
