<template>
  <v-menu
      v-model="pickerMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :dark="dark"
      transition="scale-transition"
      offset-y
      min-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="dateString"
          append-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          ref="input"
          :key="keyValue"
          :name="name"
          :label="label"
          :color="color"
          :dark="dark"
          :dense="dense"
          :clearable="clearable"
          :disabled="disabled"
          :error-messages="getErrorMsg"
          class="ma-0"
          :outlined="outlined"
          :style="{'cursor': disabled ? 'default':'pointer'}"
          @click:clear="clearField"
          @change="$emit('change')"
      ></v-text-field>
    </template>
    <v-card v-if="pickerMenu && !disabled" :dark="dark" persistent>
      <div class="layout-row px-4 py-1">

        <div class="layout-column mr-2">
          <v-date-picker
              :dark="dark"
              v-model="dateValue"
              locale="de-DE"
              no-title
              landscape
          ></v-date-picker>
        </div>

        <v-divider vertical></v-divider>

        <div class="layout-column time-column mx-2">
          <section>
            <h4 class="text-center" style="transform: translateX(-8px);">Stunde</h4>
            <div class="vue_datetime_picker">
              <ul id="vue_datetime_picker_hours" @scroll="keepFocusing">
                <template v-for="(h, hIndex) in 24">
                  <li
                      :key="hIndex"
                      :data-key="h-1"
                      :class="{'vue_datetime_picker__selected': timeValues.hour === (h-1)}"
                      v-text="(h-1).toString().padStart(2, '0')"
                      @click="select($event, 'hour', h-1)"
                  ></li>
                </template>
              </ul>
            </div>
          </section>
        </div>

        <v-divider vertical></v-divider>

        <div class="layout-column time-column ml-1">
          <section>
            <h4 class="text-center" style="transform: translateX(-8px);">Minute</h4>
            <div class="vue_datetime_picker">
              <ul id="vue_datetime_picker_minutes" @scroll="keepFocusing">
                <template v-for="(m, mIndex) in [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]">
                  <li
                      :key="mIndex"
                      :data-key="m"
                      :class="{'vue_datetime_picker__selected': timeValues.minute === (m)}"
                      v-text="(m).toString().padStart(2, '0')"
                      @click="select($event, 'minute', m)"
                  ></li>
                </template>
              </ul>
            </div>
          </section>
        </div>

      </div>
      <v-card-actions>
        <div class="flex layout-row">
          <!--                  <v-btn text color="primary" @click="close">Auswählen</v-btn>-->
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none flex"
                  @click.stop="close()"
                  icon="check"
                  tooltip="Auswahl übernehmen"
                  :label="'Auswahl übernehmen'"></lx-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>

// function toLocaleStringSupportsLocales() {
//   return (
//     typeof Intl === "object" &&
//     !!Intl &&
//     typeof Intl.DateTimeFormat === "function"
//   )
// }

const DefaultDateLocale = 'de-DE'
const DefaultDateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
}

export default {
    name: "LxDateTimePicker",
    props: {
        value: [String, Date],
        name: String,
        label: String,
        dark: Boolean,
        dense: Boolean,
        disabled: Boolean,
        clearable: Boolean,
        outlined: Boolean,
        color: {
            type: String,
            default() {
                return '#353a47'
            }
        },
        errors: {
            type: [Array, String],
            required: false
        },
        keyValue: {
            type: String
        },
        locale: {
            type: String,
            default: () => {
                return 'de-DE'
            }
        },
        options: [Object, null]
    },
    $_veeValidate: {
        name() {
            return this.name
        },
        value() {
            return this.value
        }
    },
    data() {
        return {
            pickerMenu: false,
            timeValues: {},
            dateValue: null,
            dateString: ''
        }
    },
    computed: {
        getErrorMsg: function () {
            if (!this.errors) {
                return []
            }
            return this.errors
        },
    },
    methods: {
        clearField(){
            this.$emit('input', null)
        },
        keepFocusing() {
            if (this.disabled) {
                return
            }

            window.clearTimeout(this.debounceTimer)

            if (!this.isFocusing) {
                this.isFocusing = true
            }
        },
        select(event, type, value) {
            const el = event.target
            const pel = el.parentElement

            pel.querySelectorAll('li.vue_datetime_picker__selected').forEach(
                function (element) {
                    // remove the selected class
                    element.classList.remove('vue_datetime_picker__selected')
                });

            // add the selected class to the element that was clicked
            el.classList.add('vue_datetime_picker__selected')

            this.timeValues[type] = value
        },
        close() {
            this.pickerMenu = false
            let now = new Date()
            let n = (!this.dateValue) ? now : new Date(this.dateValue)

            n.setHours(
                isNaN(this.timeValues['hour']) ? now.getHours() : this.timeValues['hour'],
                isNaN(this.timeValues['minute']) ? now.getMinutes() : this.timeValues['minute'],
                0, 0)

            this.dateString = n.toLocaleString(DefaultDateLocale, DefaultDateOptions)

            this.$emit('input', n)
        }
    },
    watch: {
        value: {
            handler(newValue) {
                if (newValue instanceof Date) {
                    return
                }

                if (typeof newValue === 'string') {
                    try {
                        let temp = new Date(newValue)

                        this.dateValue = temp.toISOString()
                        this.dateString = temp.toLocaleString(DefaultDateLocale, DefaultDateOptions)

                        this.$set(this.timeValues, 'hours', temp.getHours())
                        this.$set(this.timeValues, 'minute', temp.getMinutes())
                    } catch (e) {
                        this.dateValue = null
                        this.dateString = ''
                        this.timeValues = {}
                    }

                    return
                }

                if (!newValue) {
                    this.dateValue = null
                    this.dateString = ''
                    this.timeValues = {}
                }
            },
            immediate: true
        }
    }
}
</script>

<style scoped lang="scss">
.vue_datetime_picker {
  cursor: pointer;
  width: 6em;
  height: 16em;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #3c4b5e;
  line-height: 100%;
  font-style: normal;
  pointer-events: initial;
  transition: color .2s, opacity .2s;
  overflow-x: hidden;
  overflow-y: auto;
}

.vue_datetime_picker ul {
  list-style: none;
  margin: 0;
  padding: 0;

}

.vue_datetime_picker li {
  margin: 2px;
  padding: 2px;
  font-size: 12px;
  line-height: 14px;
  //border: 1px solid #00bfa5;
  //width: 100%;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 5px;
}

.vue_datetime_picker li:focus {
  background: #41B883;
  color: #000;
  border: 1px solid #000;
  padding: 4px;
}

.vue_datetime_picker__selected {
  background: #41B883;
  color: #fff;
  border: 1px solid #000;
  padding: 4px;
}

.vue_datetime_picker li:hover {
  color: #000;
  border: 1px solid #00bfa5;
}



.time-column{
  h4{
    height:48px;
    line-height:48px;
  }
  ::-webkit-scrollbar-corner {
    //background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-track {
    &:horizontal {
      //background: red;
      border-top: 1px solid transparent;
    }

    &:vertical {
      //background: red;
      border-left: 1px solid transparent;
    }

    background-color: transparent;
  }
}
</style>
