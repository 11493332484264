<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="UserInvitationForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    tooltip="zurück zur Übersicht"
                    :label="'Zurück'"></lx-btn>

            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!valid || pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="sendInvites(reset)"
                    icon="mdi-email"
                    tooltip="Erkannte E-Mails versenden"
                    :label="'Versenden'"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="clearForm(reset)"
                    icon="cancel"
                    tooltip="Letzte Änderungen rückgängig machen"
                    :label="'Zurücksetzen'"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-email"
                                         :main-headline="$t('PANELS.INVITATIONS.SEND.HEADLINE')"
                                         :sub-headline="$t('PANELS.INVITATIONS.SEND.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-1 pa-2">
                  <div class="flex-100 pa-2">
                    <v-alert
                      border="left"
                      prominent
                      style="font-size:15px;"
                      class="px-3 py-2"
                      v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      An dieser Stelle können Sie Einladungen an zukünftige Nutzer
                      versenden, tragen
                      Sie dazu einfach die gewünschte E-Mail in das Textfeld ein.
                      Sie können auch
                      mehrere E-Mails angeben und diese einfach mit einem
                      Semikolon getrennt
                      eingeben z.B.: <strong
                      class="light-blue lighten-5 px-2 py-1"
                      style="border-radius:4px;">a@test.de <span
                      class="pink--text text--lighten-2 font-weight-bold">;</span>
                      b@test.de</strong>. <span>Nachdem eine E-Mail erkannt wurde, können Sie im unteren Feld für den E-Mail-Nutzer schon die entsprechenden Rechte vergeben.</span>
                      Abschließend drücken Sie noch den Button "versenden" und
                      alle angegebenen Nutzer erhalten ihre Einladungs-E-Mail.
                    </v-alert>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Emails" name="Emails"
                                         rules="required|multi_email"
                                         v-slot="{ errors }">
                      <v-text-field
                        :dark="compColor.isInDarkMode"
                        label="E-Mail"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        v-model="model.email"
                        placeholder="z.B.: name@domain.de; name2@domain.de"
                        outlined
                        class="mb-0"
                        :dense="denseFields"
                        :error-messages="errors[0]"
                        @keyup="checkForEmails"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-email</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2" v-if="recognizedEmails && recognizedEmails.length > 0">
                    <fake-input-frame label="Erkannte E-Mail-Adressen"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                      <div class="flex layout-row layout-wrap px-2 pt-1">

                        <div style="border-radius:4px;"
                             class="white--text px-2 pt-1 layout-column mr-2 mb-2"
                             v-bind:class="[compColor.isInDarkMode ? compColor.expansionHeader : compColor.contentFrameFront]"
                             v-for="(mail, index) in recognizedEmails"
                             v-bind:key="index">
                          <div class="layout-row pa-1">
                            <v-icon :color="compColor.primeColor">
                              mdi-account-check
                            </v-icon>
                            <div class="flex layout-column layout-align-center-start">
                              <span class="pl-2 subtitle-1"
                                    v-bind:class="compColor.isInDarkMode ? '' : 'grey--text text--darken-2'">
                                  {{mail.name.split("@")[0]}}<span
                                  class="font-weight-black"
                                  style="margin:0 2px;font-size: 20px;"
                                  v-bind:style="{color: compColor.primeColor}">@</span>{{mail.name.split("@")[1]}}
                              </span>
                            </div>
                          </div>

                          <div class="layout-column px-1 pt-1 mt-1 elevation-2"
                               v-bind:class="compColor.contentFrameBack"
                               style="border-radius: 5px;">
                            <v-checkbox
                              :dark="compColor.isInDarkMode"
                              :color="mail.roles.length === allRoles.length ? compColor.primeColor : 'amber'"
                              :input-value="mail.roles.length > 0 && mail.roles.length === allRoles.length"
                              :indeterminate="mail.roles.length > 0 && mail.roles.length < allRoles.length"
                              @change="mainCheckChanged(allRoles, mail.roles, 'roles')"
                              hide-details
                              class="mt-0 mr-2 mb-0 pt-0"
                            >
                              <template slot="label">
                                <small
                                  v-bind:class="compColor.isInDarkMode ? '' : 'blue-grey--text'">Rollen/Gruppen
                                  ({{ mail.roles.length }}/{{ allRoles.length }})
                                </small>
                              </template>
                            </v-checkbox>
                            <v-divider class="my-0"></v-divider>
                            <div class="layout-column">
                              <div v-for="(value, index) in allRoles"
                                   :key="index"
                                   class="layout-row layout-align-start-center">
                                <v-checkbox
                                  v-model="mail.roles"
                                  :label="$t('ROLES.' + value.toLocaleUpperCase())"
                                  :dark="compColor.isInDarkMode"
                                  :color="compColor.primeColor"
                                  :value="value"
                                  hide-details
                                  @change="autoCheckUncheckUsers(mail.roles)"
                                  v-if="value !== 'users'"
                                  class="my-0 mr-2 pt-0"
                                ></v-checkbox>
                              </div>
                              <v-divider class="my-0"></v-divider>
                              <div class="layout-row layout-align-start-center mb-1">
                                <v-checkbox
                                  v-model="mail.roles"
                                  :label="$t('ROLES.USERS')"
                                  :dark="compColor.isInDarkMode"
                                  :color="compColor.primeColor"
                                  value="users"
                                  hide-details
                                  disabled
                                  class="mt-0 mr-2 mb-0 pt-0"
                                ></v-checkbox>
                                <small v-bind:style="{color: compColor.primeColor}">(automatisch gesetzt)</small>
                              </div>
                            </div>
                          </div>

                          <div class="layout-column px-1 py-2 mt-2 layout-align-center-center"
                               v-bind:class="compColor.contentFrameBack"
                               style="border-radius: 5px;" v-if="allowDepartmentSelection && allDepartments.length <= 0">
                            <span class="body-2 blue-grey--text">keine Stationen vorhanden</span>
                          </div>

                          <div class="layout-column px-1 pt-1 mt-2 mb-2 elevation-2"
                               v-bind:class="compColor.contentFrameBack"
                               style="border-radius: 5px;" v-if="allowDepartmentSelection && allDepartments.length > 0">
                            <v-checkbox
                              :dark="compColor.isInDarkMode"
                              :color="mail.departments.length === allDepartments.length ? compColor.primeColor : 'amber'"
                              :input-value="mail.departments.length > 0 && mail.departments.length === allDepartments.length"
                              :indeterminate="mail.departments.length > 0 && mail.departments.length < allDepartments.length"
                              @change="mainCheckChanged(allDepartments, mail.departments, 'departments')"
                              hide-details
                              class="mt-0 mb-0 mr-2 pt-0"
                            >
                              <template slot="label">
                                <small
                                  v-bind:class="compColor.isInDarkMode ? '' : 'blue-grey--text'">Stationen
                                  ({{ mail.departments.length }}/{{ allDepartments.length }})
                                </small>
                              </template>
                            </v-checkbox>
                            <v-divider class="my-0"></v-divider>
                            <!--                                                                        <v-divider vertical class="mx-2"></v-divider>-->
                            <div class="layout-column flex layout-wrap">
                              <div v-for="(value, index) in allDepartments"
                                   :key="index">
                                <v-checkbox
                                  v-model="mail.departments"
                                  :label="value.name"
                                  :dark="compColor.isInDarkMode"
                                  :color="compColor.primeColor"
                                  :value="value._id"
                                  hide-details
                                  class="mt-0 mb-0 mr-2 pt-0"
                                ></v-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </fake-input-frame>
                  </div>
                  <div class="flex-100 pa-2" v-if="invites && invites.length > 0">
                    <fake-input-frame label="versandte E-Mails"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">

                      <div class="pa-2">
                        <div v-for="(mail, index) in invites"
                             :key="index"
                             class="px-2 py-1"
                             :class="index < invites.length - 1 ? 'mb-1' : ''"
                             :style="{'background': mail.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                             style="border-radius: 4px;">
                          <div class="layout-row">
                            <div class="pa-2 pl-1 layout-column layout-align-center-center">
                              <v-icon v-if="mail.success" color="#080">
                                done
                              </v-icon>
                              <v-icon v-else color="#800">error</v-icon>
                            </div>
                            <v-divider vertical class="mr-2"></v-divider>
                            <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                              <span style="line-height: 16px;"><strong>{{ mail.address.name }}</strong></span>
                            </div>
                            <div class="layout-column layout-align-center-center mx-1 ml-2 mr-2" v-if="mail.unsaved">
                              <div class="py-1 px-1 font-weight-bold amber"
                                   style="font-size:9px;line-height: 14px;border-radius:3px;">
                                unsaved
                              </div>
                            </div>
                            <div class="layout-column layout-align-center-center ml-2">
                              <v-icon
                                :color="mail.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                                size="25">mdi-email
                              </v-icon>
                            </div>
                          </div>
                        </div>
                      </div>

                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>

  </div>
</template>

<script>
function SortValues(input) {
  //console.log('INPU: ', input)
  input.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return input
}


export default {
  name: 'UserInvitations',
  data() {
    return {
      hoverHeader: false,
      allowDepartmentSelection: (this.getUserLocationType() !== "pharmacy" && this.getIfHospitalLocation()),
      // isPublicPharmacy: !this.getIfHospitalLocation(),
      invites: [],
      panel: [1, 0],
      loading: false,
      model: {},
      usersRole: ['users'],
      recognizedEmails: [],
      // isPharmacy: (this.getUserLocationType() === "pharmacy"),
      allRoles: [],
      allDepartments: [],

    }
  },
  created() {
    this.$http.get('/auth/perm/roles') // get all existing roles
      .then(resultRoles => {
        if (resultRoles.data) {
          this.allRoles = resultRoles.data
        }
      })
      .catch(this.NotifyError);

    if (this.allowDepartmentSelection) {
      this.$http.get('/department')
          .then(allDepartments => {
            if (allDepartments.data && allDepartments.data.data) {
              this.allDepartments = SortValues(allDepartments.data.data)
            }
          })
          .catch(this.NotifyError)
    }

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  methods: {
    autoCheckUncheckUsers(elements) {
      let found = false;
      let reset = false;
      if (elements.length > 0) {
        elements.forEach(items => {
          if (items !== 'users') {
            found = true
          } else {
            reset = true
          }
        });
        if (found && !reset) {
          elements.push('users')
        } else if (!found && reset) {
          elements.pop()
        }
      }
    },
    mainCheckChanged(all, current, type) {
      if (all.length === current.length) {
        current.splice(0, all.length)
      } else {
        current.splice(0, all.length);
        all.forEach(item => {
          if (type === 'departments') {
            current.push(item._id)
          }
          if (type === 'roles') {
            current.push(item)
          }
        })
      }

    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    checkForEmails() {
      if (!this.model.email) return;

      const reMails = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // this.recognizedEmails = [];
      let tempEmails = [];
      this.model.email.split(";").map((a) => {
        let address = a.trim();
        if (address && address.length > 0) {
          let valid = (address.match(reMails) != null);
          if (valid) {

            let test = {
              name: address,
              roles: [],
              departments: []
            };

            tempEmails.push(test.name);

            let existing = false;

            this.recognizedEmails.forEach(entry => {
              if (entry.name === test.name) {
                existing = true
              }
            });
            if (!existing) {
              this.recognizedEmails.push(test)
            }
          }
        }
      });

      this.recognizedEmails = this.recognizedEmails.filter(function (n) {
        return tempEmails.indexOf(n.name) !== -1;
      })
    },
    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },
    sendInvites(reset) {
      this.$http.post('/users/invitations', this.recognizedEmails)
        .then((res) => {
          reset();
          this.model = {};
          this.recognizedEmails = [];
          if (this.$refs.MultiEmailSendForm) {
            this.$refs.MultiEmailSendForm.reset()
          }
          this.invites = res.data;
          this.NotifySystem('Einladung wurden erfolgreich versand.');
        })
        .catch((err) => {
          if (err?.response?.status === 406) {
            return this.NotifySystem({
              title: 'Einladung',
              text: 'Die von Ihnen angegebenen E-Mail Adressen sind bereits registriert oder können für diesen Vorgang nicht verwendet werden.',
              type: 'warning',
              duration: 12000,
            })
          }

          this.NotifyError(err)
        })
    },
    clearForm(reset) {
      reset();
      this.recognizedEmails = [];
      this.model = {}
    },
  }
}
</script>

<style scoped>

</style>
