<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.REFRESH.TOOLTIP')"
                  :label="$t('BUTTONS.REFRESH.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="isLoadingData"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="createNewHospital()"
                  icon="mdi-plus-circle"
                  :tooltip="$t('BUTTONS.HOSPITAL_ADD.TOOLTIP')"
                  :label="$t('BUTTONS.HOSPITAL_ADD.TITLE')"></lx-btn>
        </div>
        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>


      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
          :dark="isDarkMode"
          :color="compColor.primeColor"
          :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
          class="mt-2 mb-0"
          :label="$t('INPUTS.TITLES.SEARCH_TERM')"
          hide-details
          outlined
          :dense="denseFields"
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="searchString"
        ></v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="HospitalList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openHospitalDetails">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'icon'" style="padding:1px;height:35px;">
                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>
                </div>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>

  </div>
</template>
<script>
import router from '../../../router'

export default {
  name: 'HospitalList',
  data() {
    return {
      searchString: null,
      searchDelayTimer: null,
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['ordernr'],
          sortDesc: [false],
          // getClassForItem: (row) => {
          //   if (row.isExpanded) {
          //     return 'row-bold'
          //   }
          //   return ''
          // }
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            text: 'Medizinische Einrichtung',
            value: 'name',
            width: '300px',
            sortable: true
          },
          {
            text: 'E-Mail',
            value: 'email',
            width: '300px',
            sortable: true
          },
          {
            text: 'Aktiv',
            value: 'is_active',
            sortable: true,
            renderer: 'icon'
          }
        ]
      },
      items: [],

      isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      frameworkComponents: null,
      columnDefs: null,
      rowData: null,
      FilterAreFloating: false,
      allowBlurOnLoading: true,
      defaultSortOpts: {
        colId: 'name',
        sort: 'asc'
      }
    }
  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },

    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openHospitalDetails(params) {
      if (params?._id) {
        router.push({ path: `/administration/hospitals/${params._id.toString()}/details` });
      }
    },

    createNewHospital() {
      this.$http.get('/pharmacy/licenses/quota')
          .then(res => {
            const quota = res.data || {}

            if (quota.num_medicalunit >= quota.max_medicalunit) {
              this.DialogConfirm(
                  this.$t('LICENSES.QUOTA_MEDICALUNIT.TITLE'),
                  this.$t('LICENSES.QUOTA_MEDICALUNIT.TEXT'),
                  { ok: this.$t('BUTTONS.DIALOGS.CLOSE'), cancel: this.$t('BUTTONS.DIALOGS.CANCEL'), hideCancelButton: true, icon: 'mdi-cash-lock' }
              )

            } else {
              return router.push({ path: `/administration/hospitals/new` });
            }
          })
          .catch(this.NotifyError)
    },

    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
        searchString: this.searchString,
        searchFields: ['name', 'email']
      })

      this.$http.get('/hospital', params)
        .then(res => {
          if (res.data) {
            this.items = res.data.data || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    }
  },
  watch: {
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 500)
    }
  },
  computed: {}
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.view-top-toolbar {
  .btn {
    min-width: 40px;
  }

  .btn__content {
    padding: 0 5px !important;
  }
}
</style>
