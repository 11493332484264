<template>

  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.REFRESH.TOOLTIP')"
                  :label="$t('BUTTONS.REFRESH.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="isLoadingData"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="openInvitationView"
                  icon="mdi-plus"
                  :tooltip="$t('BUTTONS.INVITATION_SEND.TOOLTIP')"
                  :label="$t('BUTTONS.INVITATION_SEND.TITLE')"></lx-btn>

        </div>
        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
            :dark="isDarkMode"
            :color="compColor.primeColor"
            :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
            class="mt-2 mb-0"
            label="Suchbegriff(e) eingeben"
            hide-details
            outlined
            :dense="denseFields"
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="searchString"
        ></v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
                v-model="tableObj.selection"
                tableName="UserInvitationList"
                :items="items"
                :headers="tableObj.headers"
                :loading="tableObj.loading"
                :tableOptions="tableObj.options"
                :hideContextFilter="true"
                @changeOptions="changeTableOptions">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'state'" style="padding:1px;height:35px;line-height:35px;">
<!--                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>-->

                  <div v-if="value.i[value.f.value] === 1">
                    <v-icon color="green lighten-3" class="mr-2">schedule</v-icon>
                    <span class="green--text">Bearbeitung steht aus</span>
                  </div>
                  <div v-if="value.i[value.f.value] === 2">
                    <v-icon color="amber darken-2" class="mr-2">settings</v-icon>
                    <span>In Bearbeitung durch User</span>
                  </div>

                </div>
                <div v-else-if="value.f.renderer === 'invitation'" style="padding:1px;height:35px;line-height:35px;"
                     class="text-center">
                  <!--                <v-icon :color="compColor.prime">mdi-lock-reset</v-icon>-->
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="copyLink(value.i)"
                          icon="assignment_returned"
                          :hide-label="true"
                          :tooltip="$t('BUTTONS.REGISTRATION_LINK_CLIPBOARD.TOOLTIP')"
                          :label="$t('BUTTONS.REGISTRATION_LINK_CLIPBOARD.TITLE')"></lx-btn>
                </div>
                <div v-else-if="value.f.renderer === 'dateTime'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                    {{ value.i[value.f.value] | formatDate('dd, DD.MM.YYYY HH:mm:ss') }}
                  </div>
                </div>
                <div v-else-if="value.f.renderer === 'timeLeft'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center font-weight-bold" style="border-radius:3px;height:33px;">
                    {{ getDuration(value.i[value.f.value])}}
                  </div>
                </div>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>


<!--    <div class="flex layout-column pa-lg-2 pa-md-2">-->
<!--        <v-card class="flex layout-row">-->
<!--            <div class="flex layout-column">-->

<!--                <div class="elevation-6 pa-1 layout-row" style="z-index: 2" v-bind:class="[compColor.contentFrameBack]">-->
<!--                    <div class="flex"  v-bind:class="[compColor.contentFrameFront]">-->
<!--                        <v-btn :dark="compColor.isInDarkMode" :color="compColor.primeColor" class="white&#45;&#45;text text-none ma-1" :disabled="isLoadingData" @click="getData()">-->
<!--                            <v-icon left dark>refresh</v-icon>-->
<!--                            <span>Aktualisieren</span>-->
<!--                        </v-btn>-->
<!--                        <v-btn :dark="compColor.isInDarkMode" :color="compColor.primeColor" class="white&#45;&#45;text text-none ma-1" :disabled="isLoadingData" to="invitations/create">-->
<!--                            <v-icon left dark>add_box</v-icon>-->
<!--                            <span>Einladungen versenden</span>-->
<!--                        </v-btn>-->
<!--                    </div>-->
<!--                    <div class="pa-1"  v-bind:class="[compColor.contentFrameFront]">-->
<!--                        <lx-grid-settings-menu :reset-function="resetGridByUser" :grid-name="'userInvitationList'"></lx-grid-settings-menu>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="pa-0 flex layout-column" style="position: relative;overflow: hidden;">-->

<!--                    <grid-loader-progress :gridIsLoading="isLoadingData"></grid-loader-progress>-->

<!--                    <ag-grid-vue-->
<!--                            class="flex ag-theme-material lx-ag-grid"-->
<!--                            v-bind:class="[{'dark-grid': compColor.isInDarkMode ,'grid-is-loading': isLoadingData, 'blur-is-Loading': isLoadingData && allowBlurOnLoading}, 'lx-list-grid-' + compColor.textInput]"-->
<!--                            :gridOptions="gridOptionsGlobal"-->
<!--                            :floatingFilter="FilterAreFloating"-->
<!--                            :rowSelection="'multiple'"-->
<!--                            :suppressRowClickSelection="true"-->
<!--                            :sortingOrder="['desc', 'asc']"-->
<!--                            :frameworkComponents="frameworkComponents"-->
<!--                            @body-scroll="onBodyScrollGlobal($event, 'userInvitationList')"-->
<!--                            @columnMoved="onColumnsChangedGlobal($event, 'userInvitationList')"-->
<!--                            @filter-changed="filterChangedGlobal($event, 'userInvitationList')"-->
<!--                            @grid-ready="onGridReadyGlobal($event, 'userInvitationList')"-->
<!--                            @selection-changed="selectionChangedGlobal($event, 'userInvitationList')"-->
<!--                            @sort-changed="sortChangedGlobal($event, 'userInvitationList')"-->
<!--                            :columnDefs="columnDefs">-->
<!--                        &lt;!&ndash;            @row-clicked="openUserDetails"&ndash;&gt;-->
<!--                        &lt;!&ndash;          :localeText="gridLocalText"&ndash;&gt;-->
<!--                    </ag-grid-vue>-->
<!--                </div>-->

<!--                <div class="layout-row paging-shadow" v-bind:class="[compColor.contentFrameBack]">-->
<!--                    <lx-pagination :page-name="'userInvitationList'" :page-count="pagingOptions.count" :page-skip="pagingOptions.skip"-->
<!--                                   :page-limit="pagingOptions.limit" :page-change="getData"></lx-pagination>-->
<!--                </div>-->
<!--            </div>-->
<!--        </v-card>-->
<!--    </div>-->

  </div>
</template>

<script>
// import {AgGridVue} from 'ag-grid-vue'
import moment from 'moment'
import router from '../../../router'
import lodash from 'lodash'

import TokenIDRenderer from '../../../components/lx_ag-grid/components/cellRenderer/tokenIDLinkCopyCell'
import TokenStatusRenderer from '../../../components/lx_ag-grid/components/cellRenderer/tokenStatusCell'

// let tokenStateCellRenderer = function(params) {
  //   let myValue = (params.value === 1) ? 'Offen' : 'In Benutzung';
  //   return '<span style="color: '+params.color+'">' + myValue + '</span>';
  // };

  let tokenDateCellRenderer = function(params) {
      let myDateM = moment(params.value);
      return myDateM.format('DD.MM.YYYY, HH:MM') + ' Uhr'
  };


  export default {
    name: 'UserInvitationsList',
    data() {
      return {
        searchString: null,
        searchDelayTimer: null,
        tableObj: {
          loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
          options: {
            sortBy: ['tokentime'],
            sortDesc: [true],
            // getClassForItem: (row) => {
            //   if (row.isExpanded) {
            //     return 'row-bold'
            //   }
            //   return ''
            // }
          },
          filterValue: null,
          selection: null,
          headers: [
            {
              width: '30px',
              sortable: true,
              sticky: 'left',
              renderer: 'invitation',
              noResize: true,
              alternateText: 'Registrierungslink kopieren'
            },
            {
              text: 'Empfänger',
              value: 'receiver',
              width: '150px',
              sortable: true
            },
            {
              text: 'Organisation',
              value: 'location.name',
              width: '200px',
              sortable: true
            },
            {
              text: 'Datum',
              value: 'tokentime',
              width: '170px',
              sortable: true,
              renderer: 'dateTime'
            },
            {
              text: 'gültig für',
              value: 'tokentime',
              width: '100px',
              sortable: true,
              renderer: 'timeLeft'
            },
            {
              text: 'Status',
              value: 'tokenstate',
              sortable: true,
              renderer: 'state',
            },
          ]
        },
        items: [],

          isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
          frameworkComponents: null,
          columnDefs: null,
          rowData: null,
          FilterAreFloating: false,
          allowBlurOnLoading: true,
          defaultSortOpts: {
              colId: 'receiver',
              sort: 'asc'
          },
      }
    },
    components: {
      // DeleteDialog
    },
    created() {
      this.createColumnDefs();
    },
      beforeMount() {
          this.frameworkComponents = {
              // agColumnHeader: agGridComponentHeader,
              tokenIDRenderer: TokenIDRenderer,
              tokenStatusRenderer: TokenStatusRenderer
          }
      },
    watch: {
      searchString() {
        clearInterval(this.searchDelayTimer);

        this.searchDelayTimer = setInterval(() => {
          clearInterval(this.searchDelayTimer);
          this.getData()
        }, 500)
      }
    },
    methods: {
      getDuration(date){
        let start = moment(date)
        let end = lodash.cloneDeep(start).add(7,'days')
        let now = moment()
        var duration = moment.duration(end.diff(now))

        if(duration.asMilliseconds() > 0){
          if(duration.days() > 0){
            return duration.days() + 'd ' + duration.hours() + 'h'
          } else if(duration.hours() > 0){
            return duration.hours() + 'h ' + duration.minutes() + 'min'
          } else {
            return duration.minutes() + 'min'
          }
        } else {
          return 'abgelaufen'
        }
      },
      /**
       * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
       */
      changeTableOptions(value) {
        if (value) {
          this.tableObj.options = value
        }
        this.getData()
      },
      copyLink(item) {
        //console.log(item.tokenid)

        let path = window.location.origin + '/auth/register/' + item.tokenid

        const hiddenElement = document.createElement('input');
        // hiddenElement.style.display = 'none';
        hiddenElement.setAttribute('type', 'text');
        hiddenElement.setAttribute('value', path);
        document.body.appendChild(hiddenElement);

        hiddenElement.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.log('Oops, unable to copy');
        }

        window.getSelection().removeAllRanges();
        document.body.removeChild(hiddenElement);
      },
      openInvitationView(){
        router.push({name: 'UserInvitations'})
      },
      /**
       * hier werden wie gewohnt die Spalten des Grids definiert,
       * Besonderheit hier ist nur:
       *
       *     filterParams: {
       *       newRowsAction: 'keep'
       *    }
       *
       * dies ist Wichtig damit nach "getData" ein eventueller Eintrag im Filterinput erhalten bleibt
       * **/
      createColumnDefs() {
        this.columnDefs = [
          {
            headerName: 'Empfänger',
            field: 'receiver',
              filter: false,
            filterParams: {
              newRowsAction: 'keep'
            },
            headerComponentParams: {sortChangedAction: this.sortChanged}
          },
          {
            headerName: 'Organisation',
            field: 'location.name',
              filter: false,
            filterParams: {
              newRowsAction: 'keep'
            },
            headerComponentParams: {sortChangedAction: this.sortChanged}
          },
          {
            headerName: 'Datum',
            field: 'tokentime',
              filter: false,
            filterParams: {
              newRowsAction: 'keep'
            },
            cellRenderer: tokenDateCellRenderer
          },
          {
            headerName: 'Status',
            field: 'tokenstate',
            filter: false,
            width: 250,
            sortable: false,
            filterParams: {
              newRowsAction: 'keep'
            },
              cellRendererSelector: () => {
                  let check = {
                      component: 'tokenStatusRenderer'
                  };
                  return check
              }
          },
            {
                headerName: '',
                width:60,
                filter: false,
                headerComponentParams: {sortChangedAction: this.sortChanged},
                cellRendererSelector: () => {
                    let check = {
                        component: 'tokenIDRenderer'
                    };
                    return check
                }
            },
        ]
      },


      /**
       * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
       * Besonderheit:
       * "this.pagingOptions.count = res.data.count"
       * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
       *
       * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
       * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
       *
       * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
       * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
       * **/
      getData(options) {
        if (this.tableObj.loading) return
        this.tableObj.loading = true

        // let query = '{}';

        if (options && typeof options.skip === 'number') {
          this.pagingOptions.skip = options.skip
        }
        if (options && options.limit) {
          this.pagingOptions.limit = options.limit
        }

        const params = this.$helper.GetQueryParam({
          ...this.tableObj.options,
          skip: this.pagingOptions.skip,
          limit: this.pagingOptions.limit,
          query: {},
          count: true,
          searchString: this.searchString,
          searchFields: ['receiver']
        })

        this.$http.get('/users/getInvitations', params)
          .then(res => {
            if (res.data) {
              this.items = res.data.result || []
              this.pagingOptions.count = res.data.count || 0

              // this.gridOptionsGlobal.api.setRowData(res.data.result);
              // if (res.data.count && res.data.count > 0) {
              //   this.pagingOptions.count = res.data.count; // Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
              //   // this.$store.dispatch('setPagingOptions', this.pagingOptions) // nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
              //
              //   let scollIndex = this.$store.getters.gridScrollIndex;
              //
              //   if (typeof scollIndex === 'number' || (typeof scollIndex === 'object' && scollIndex.constructor === Number)) {
              //     this.gridOptionsGlobal.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top') // sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
              //   }
              // }
            }
            // else {
            //   this.rowData = []
            // }
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.tableObj.loading = false
          })
      },

    }
  }
</script>

<style scoped>

</style>
