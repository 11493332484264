<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="ArticleFieldsEditorForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!hasChanged"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('BUTTONS.SAVE.TOOLTIP')"
                    :label="$t('BUTTONS.SAVE.TITLE')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!hasChanged"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="clear(reset)"
                    icon="cancel"
                    :tooltip="$t('BUTTONS.REVERT.TOOLTIP')"
                    :label="$t('BUTTONS.REVERT.TITLE')"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
        <div ref="scrollContainer" class="flex pa-1 layout-column" style="overflow-y: auto;">
          <div class="ma-1 pa-3 layout-row elevation-3"
               style="border-radius: 5px;"
               :style="{'background': compColor.lxExpansion.background}">
            <div class="mx-2 layout-column" style="width:200px;">
              <fake-input-frame label="Benutzergruppe" class="flex"
                                :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                <div class="flex my-2 mr-4" style="overflow-y: auto;width: 100%;">
                  <v-list
                    flat
                    class="transparent"
                    :dark="compColor.isInDarkMode"
                    style="width:100%;"
                  >
                    <v-list-item-group :color="compColor.primeColor">
                      <v-list-item
                        v-for="role in roles"
                        :key="role"
                        @click="selectRole(role)"
                        v-bind:class="getPrimeColor(selected, role)"
                        style="min-height: 56px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start">
                              <span style="line-height: 16px;"><strong>{{ role | translate(['roles']) }}</strong></span>
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <small class="pl-2">({{ role }})</small>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon v-if="selected === role">
                          <v-icon :color="compColor.primeColor">double_arrow</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </fake-input-frame>
            </div>

            <div class="flex mx-2 layout-column">
              <fake-input-frame class="flex pa-2 layout-column" label="Tabellenspalten"
                                :input-background="isDarkMode ? 'transparent':'#f5f5f5'">

                <div class="my-2 flex" style="overflow-y: auto;">

                  <div v-show="selected">
                    <div class="px-3" v-for="field in model[selected]" :key="field.key">
                      <v-checkbox
                        v-model="field.active"
                        :label="field.key"
                        :color="compColor.primeColor"
                        @change="checkBoxChange()"
                        hide-details
                        class="mt-0 mb-1"
                      >
                        <template v-slot:label>
                          <div class="ml-3">
                            <div class="subtitle-2" style="line-height: 1.1rem">{{ field.description }}</div>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-divider></v-divider>

                    </div>

                  </div>

                </div>
              </fake-input-frame>
            </div>

          </div>

        </div>
      </div>
    </ValidationObserver>

  </div>
</template>

<script>
import lodash from 'lodash'

export default {
  name: 'TableFilter',
  data() {
    return {
      loading: false,
      isImportingData: false,
      roles: [],
      fields: [],
      groups: {},
      selected: null,
      model: {},
      oldModel: {},
      hasChanged: false
    }
  },
  created() {
    this.getData()
  },
  methods: {
    checkBoxChange() {
      this.hasChanged = !lodash.isEqual(this.oldModel, this.model)
    },
    getPrimeColor(selected, role) {
      if (selected === role) {
        return this.compColor.textInput + ' lighten-5'
      } else {
        return ''
      }
    },
    selectRole(role) {
      this.selected = role;

      if (!Array.isArray(this.groups[this.selected])) {
        this.groups[this.selected] = []
      }
    },
    getData() {
      this.$http.get('/settings/articlefields/groups')
        .then(res => {
          if (res.data) {
            this.roles = res.data.roles || []
            this.fields = res.data.fields || []
            this.groups = res.data.groups || {}

            for (let role in this.roles) {
              if (this.groups[this.roles[role]]) {
                let tmpObj = []
                let arr = this.groups[this.roles[role]]

                for (let field in this.fields) {
                  let fieldClone = lodash.cloneDeep(this.fields[field])
                  fieldClone.active = Boolean(arr.find(element => element === this.fields[field].key))

                  tmpObj.push(fieldClone)
                }

                this.model[this.roles[role]] = tmpObj

              } else {
                let tmpObj2 = []
                for (let field in this.fields) {
                  let fieldClone = lodash.cloneDeep(this.fields[field])
                  fieldClone.active = true
                  tmpObj2.push(fieldClone)
                }
                this.model[this.roles[role]] = tmpObj2
              }
            }

            this.oldModel = lodash.cloneDeep(this.model)

            if (this.roles && this.roles.length > 0) {
              this.selectRole(this.roles[0])
            }

          }
        })
        .catch(this.handleError)
    },
    save() {
      let tempGroups = {}

      for (let roles in this.model) {
        let notUsed = false
        let role = this.model[roles]

        let keys = []

        for (let item in role) {
          if (role[item].active) {
            keys.push(role[item].key)
          } else {
            notUsed = true
          }
        }

        if (notUsed) {
          tempGroups[roles] = keys
        } else if (this.groups[roles] && this.groups[roles].length > 0) {
          tempGroups[roles] = keys
        }
      }

      this.$http.put('/settings/articlefields/groups', tempGroups)
        .then(() => {
          this.NotifySystem('Änderungen gespeichert')
          this.oldModel = lodash.cloneDeep(this.model);
          this.hasChanged = false
        })
        .catch(this.NotifyError)
    },
    clear() {
      this.hasChanged = false
      this.model = {}
      setTimeout(() => {
        this.model = lodash.cloneDeep(this.oldModel)
      }, 0)
    },
    back() {
      window.history.back()
    }
  }
}
</script>

<style scoped>

</style>
