<template>
  <v-form @submit="register" id="reset_fields">
      <div class="flex layout-column" style="max-width: 700px;">
          <!-- EMail is registered -->
          <ValidationObserver ref="RegisterForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
            <div v-if="twoFA" class="flex px-10 py-6" style="overflow-y: auto;">
              <h2 class="white--text mb-0 mt-0">Zwei-Faktor-Authentisierung</h2>
              <span class="white--text mb-6 body-2">Die Zwei-Faktor-Authentisierung ist aktiviert. Bitte öffnen Sie nun Ihre Authentikator App, tragen den aktuellen Zugriffscode in die unten stehenden Felder ein und bestätigen die Eingabe.  </span>

              <div class="layout-column pt-3 mt-3" style="border:1px solid white;border-radius:5px;background:rgba(255,255,255,0.15)">
                <code-input v-model="model.passcode" ref="codeInput" :enter-func="register"
                            :error-reset-func="resetErrors" :style-version="1"></code-input>

              </div>
            </div>

              <div v-if="!twoFA" class="flex px-10 py-6" style="overflow-y: auto;">
                  <v-row no-gutters>
                      <v-col cols="12">
                          <v-alert
                                  border="left"
                                  prominent
                                  style="font-size:15px;"
                                  class="pa-3"
                                  v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                              <template slot="prepend">
                                  <v-avatar>
                                      <v-icon large>mdi-information-variant</v-icon>
                                  </v-avatar>
                              </template>
                              Ihre E-Mail-Adresse ist unserem Authentifizierungssystem bekannt.
                              Sie nutzen damit bereits andere Produkte der Litixsoft GmbH.
                              Um die Registrierung für den Medishop abzuschliessen, melden Sie sich mit Ihren persönlichen Anmeldedaten an.
                              Nach der Anmeldung werden Sie der Organisation <strong class="text-decoration-underline" v-if="company">"{{company}}"</strong>
                              zugewiesen.
                          </v-alert>
                      </v-col>
                  </v-row>
                  <v-row class="mt-0">
                      <v-col cols="12">
                          <v-text-field
                                  v-model="email"
                                  color="white"
                                  prepend-inner-icon="email"
                                  label="E-Mail"
                                  dark
                                  placeholder=" "
                                  outlined
                                  disabled
                          ></v-text-field>
                      </v-col>
                  </v-row>

<!--                  <v-row no-gutters class="mt-0">-->
<!--                      <v-col cols="12">-->
<!--                          <validation-provider ref="Benutzername" name="Benutzername" rules="required"-->
<!--                                               v-slot="{ errors }">-->
<!--                              <v-text-field-->
<!--                                      v-model="model.login"-->
<!--                                      :error-messages="errors[0]"-->
<!--                                      color="white"-->
<!--                                      prepend-inner-icon="supervised_user_circle"-->
<!--                                      label="Benutzername*"-->
<!--                                      dark-->
<!--                                      placeholder=" "-->
<!--                                      outlined-->
<!--                              ></v-text-field>-->
<!--                          </validation-provider>-->
<!--                      </v-col>-->
<!--                  </v-row>-->

                  <v-row no-gutters class="mt-0">
                      <v-col cols="12">
                          <validation-provider name="Password" rules="required" v-slot="{ errors }">
                              <v-text-field
                                      color="white"
                                      dark
                                      placeholder=""
                                      outlined
                                      :error-messages="errors[0]"
                                      label="Passwort*"
                                      prepend-inner-icon="fingerprint"
                                      v-model="model.password"
                                      :clearable="false"
                                      :append-icon="showPW ? 'visibility_off' : 'visibility'"
                                      :type="showPW ? 'text': 'password'"
                                      @focus="wrong_credentials = false"
                                      @click:append="() => (showPW = !showPW)"
                                      autocomplete="off"
                              ></v-text-field>
                          </validation-provider>
                      </v-col>
                  </v-row>
              </div>
<!--            v-if="(!twoFA && loginError && !pristine) || (twoFA && loginError)"-->
              <v-alert
                  v-if="error_text"
                  border="left"
                  prominent
                  color="red"
                  style="font-size:15px;"
                  class="px-5 py-3 mx-12 red white--text"
              >
                <template slot="prepend">
                  <v-avatar>
                    <v-icon large>mdi-information-variant</v-icon>
                  </v-avatar>
                </template>
                {{ $t('AUTH.LOGIN_ERRORS.' + error_text) }}
              </v-alert>

              <v-divider class="mx-5"></v-divider>

              <div class="pa-2 d-flex flex-row">
                  <div class="grow mr-2" v-if="twoFA">
                      <v-btn block class="green darken-2 text-none white--text" type="submit"
                             :disabled="!model || !model.passcode || model.passcode.length < 6"
                             :loading="loading">Zugriffscode bestätigen
                      </v-btn>
                  </div>
                  <div class="grow mr-2" v-else>
                      <v-btn block class="green darken-2 text-none white--text" type="submit"
                             :disabled="!valid || pristine"
                             :loading="loading">Registrierung abschließen
                      </v-btn>
                  </div>
              </div>
          </ValidationObserver>
      </div>
  </v-form>
</template>

<script>
import CodeInput from "@/components/lx_auth/components/CodeInput.vue";

export default {
  name: 'RegistrationExistsUserComponent',
  components: {
    CodeInput
  },
  props: {
    company: String,
    email: String,
    token: String,
    redirect: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },
  data () {
    return {
      loading: false,
      model: {},
      showPW: false,
      error_text: null,
      twoFA: false,
    }
  },
  methods: {
    /**
     *  Send mail to user for changing password
     *
     *  Param for backend is user object with login name and/or email address
     *
     * @param ev
     */
    register(ev) {
      if (this.loading) {
        return
      }

      ev.preventDefault(); // wichtig damit Formular mit Enter abgeschickt werden kann

      this.wrong_credentials = false

      let regForm = {
        credentials: {
          login: this.email,
          password: this.model.password,
        },
        tokenid: this.token
      }

      if (this.twoFA) {
        regForm.credentials.passcode = this.model.passcode
      }

      this.loading = true

      this.$http.post('/register', regForm)
          .then(this.redirect)
          .catch((error) => {
            // Handle 2fa captcha
            if (error.response.status === 406) {
              this.twoFA = true
              this.resetErrors()
              return
            }

            // Handle wrong credentials
            if (error.response.status === 401) {
              this.error_text = (error.response.data?.message || 'UNKNOWN').toLocaleUpperCase().replaceAll(" ", "_")
              this.twoFA = false
              return
            }

            this.HandleFormError(this.$refs.RegisterFromTokenForm, error)
                .then(() => {
                  // console.log('success')
                })
                .catch(this.NotifyError)
          })
          .finally(() => {
            this.loading = false;
          })
    },
    resetErrors() {
      this.error_text = null
    },
    clearForm(reset) {
      reset()
      this.twoFA = false
      this.resetErrors()
      this.model = {}

      this.$refs['codeInput'].resetSingleFields()
    }
  }
}
</script>

<style scoped>

</style>