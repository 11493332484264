<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="HospitalDetailForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="chevron_left"
                    :hide-label="true"
                    :tooltip="$t('BUTTONS.BACK.TOOLTIP')"
                    :label="$t('BUTTONS.BACK.TITLE')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    :disabled="isBusy || !valid || (pristine && !columnchanges)"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('BUTTONS.SAVE.TOOLTIP')"
                    :label="$t('BUTTONS.SAVE.TITLE')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    :disabled="pristine && !columnchanges"
                    width="auto"
                    classes="text-none mr-1"
                    @click="clearForm(reset)"
                    icon="cancel"
                    :tooltip="$t('BUTTONS.REVERT.TOOLTIP')"
                    :label="$t('BUTTONS.REVERT.TITLE')"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-file-import"
                                         :main-headline="$t('PANELS.IMPORT.SETTINGS.HEADLINE')"
                                         :sub-headline="$t('PANELS.IMPORT.SETTINGS.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-1 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Werttrennungszeichen"
                                         name="CSV Werttrennungszeichen"
                                         rules="required|length:1" v-slot="{ errors }">
                      <v-text-field
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :error-messages="errors[0]"
                        label="CSV Werttrennungszeichen"
                        v-model="model.comma"
                        required
                        :dark="compColor.isInDarkMode"
                        class="mb-1"
                        outlined
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-cursor-text</v-icon>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </div>
                  <div class="flex-100 px-2">
                    <validation-provider tag="div" ref="Encoding" name="Encoding"
                                         rules="required" v-slot="{ errors }">
                      <v-select
                        :dark="compColor.isInDarkMode"
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :items="encodingList"
                        :error-messages="errors[0]"
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="Datei Encoding"
                        v-model="model.encoding"
                        item-value="value"
                        item-text="text"
                        class="mb-0"
                        hide-no-data
                        required
                        outlined
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">text_rotation_none</v-icon>
                        </template>
                      </v-select>
                    </validation-provider>
                  </div>
                  <div class="flex-100 px-2 pb-4">
                    <fake-input-frame label="Kopfdaten"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                      <div class="px-2 pb-2">

                        <!--                                            <div class="flex px-2 pt-4 pb-2" style="border:1px solid #c2c2c2;border-radius:3px;position:relative;">-->
                        <!--                                                        <span class="px-1 white"-->
                        <!--                                                              style="color:rgba(0,0,0,0.87);font-size:12px;line-height:12px;position:absolute;top:-6px;left:10px;">weitere Optionen</span>-->
                        <validation-provider tag="div" ref="Kopfdaten" name="Kopfdaten"
                                             v-slot="{ errors }">
                          <v-checkbox
                            :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            :error-messages="errors[0]"
                            v-model="model.hasHeader"
                            hide-details
                            class="ma-0"
                            label="Datei besitzt Kopfdaten"
                          ></v-checkbox>
                        </validation-provider>
                      </div>
                      <!--                                            </div>-->
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-file-table-outline"
                                         :main-headline="$t('PANELS.IMPORT.FIELD_ASSIGNMENT.HEADLINE')"
                                         :sub-headline="$t('PANELS.IMPORT.FIELD_ASSIGNMENT.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-1 pa-2">
                  <div class="flex-100 pa-2">
                    <fake-input-frame label="Feldzuordnungen anlegen und verwalten"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                      <div class="pa-2">
                        <div style="border:1px solid rgba(0,0,0,0.2);border-radius: 4px;">
                          <v-simple-table :dark="compColor.isInDarkMode" fixed-header
                                          style="width:100%;border: black 2px">
                            <template>
                              <thead>
                                <tr>
                                  <th class="text-left" width="100px">Index</th>
                                  <th class="text-left">CSV-Spaltenname/-index</th>
                                  <th class="text-left">Datenbankfeld</th>
                                  <th class="text-left">Validierung</th>
                                  <th class="text-right" width="75px">Löschen</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colspan="5" class="px-1">
                                    <v-btn
                                      block
                                      :color="compColor.primeColor"
                                      @click="editField(-1)"
                                      :dark="compColor.isInDarkMode"
                                      class="white--text text-none"
                                    >
                                      <v-icon>add</v-icon>
                                      <span>neue Felder hinzufügen</span>
                                    </v-btn>
                                  </td>
                                </tr>
                                <tr v-for="(column, index) in model.columns"
                                    :key="column.abdaFieldname"
                                    @click="editField(index)" style="cursor:pointer;" title="zum bearbeiten anklicken">
                                  <td>{{ isNaN(column.csvColumnIdent) ? 'Name' : 'Index' }}
                                  </td>
                                  <td>{{ column.csvColumnIdent }}</td>
                                  <td>{{ abdaFields[column.abdaFieldname] || '*** unbekannt oder fehlerhaft ***' }}</td>
                                  <td>{{ column.csvColumnFormat }}</td>
                                  <td>
                                    <v-btn
                                      text
                                      icon
                                      small
                                      @click="removeField($event, index)"
                                    >
                                      <v-icon>delete_forever</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                                <tr v-if="!model || !model.columns || model.columns.length <= 0">
                                  <td colspan="5"></td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
            <lx-expansion-panel v-if="apiinfo">
              <lx-expansion-panel-header icon="mdi-robot"
                                         :main-headline="$t('PANELS.IMPORT.AUTOIMPORT.HEADLINE')"
                                         :sub-headline="$t('PANELS.IMPORT.AUTOIMPORT.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-1 pa-2">
                  <div class="flex-100 pa-2">
                    <v-text-field
                      :color="compColor.primeColor"
                      :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                      label="API-Schlüssel"
                      v-model="apiinfo.apikey"
                      readonly
                      :dark="compColor.isInDarkMode"
                      outlined
                      class="mb-0"
                    >
                      <template #append v-if="!isDarkMode">
                        <v-icon color="grey lighten-2">mdi-key-variant</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div class="flex-100 pa-2" v-if="!apiinfo.enabled">
                    <v-alert
                      border="left"
                      prominent
                      style="font-size:15px;"
                      class="pa-3 ma-0"
                      v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      <div class="layout-column layout-align-center-start subtitle-2 font-weight-regular">
                        Der Zugriff ist momentan deaktivert!
                      </div>
                    </v-alert>
                  </div>
                  <div class="flex-100 px-2" v-if="apiinfo.last_access">
                    <fake-input-frame label="Letzer Zugriff"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                      <div class="pt-2 pb-0 col-12">
                        <v-alert
                          border="bottom"
                          colored-border
                          type="success"
                          elevation="2"
                          :dark="compColor.isInDarkMode"
                        >{{ apiinfo.last_access | formatDate('DD MMMM YYYY HH:mm:ss') }}
                        </v-alert>
                      </div>
                    </fake-input-frame>
                  </div>
                  <div class="flex-100 pa-2">
                    <fake-input-frame label="Zielort-Sonderartikelliste für automatischen Import"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                      <div class="pt-2 pb-0 col-12">
                        <v-alert
                            border="bottom"
                            colored-border
                            type="info"
                            elevation="2"
                            :dark="compColor.isInDarkMode"
                        >
                          <v-row align="center">
                            <v-col class="grow">
                              Als Zielort wurde "<b>{{ listname }}</b>" festgelegt. Zum ändern klicken Sie auf den Button.
                            </v-col>
                            <v-col class="shrink">
                              <v-btn
                                  :color="compColor.primeColor"
                                  :dark="compColor.isInDarkMode"
                                  class="white--text text-none"
                                  @click="dialogChangeAbdaList=true">Ändern</v-btn>
                            </v-col>
                          </v-row>
                        </v-alert>
                      </div>
                    </fake-input-frame>
                  </div>
                  <div class="flex-100 pa-2">
                    <fake-input-frame label="Dateizuweisung"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">

                      <div class="pa-2">
                        <div style="border:1px solid rgba(0,0,0,0.2);border-radius: 4px;">
                          <v-simple-table
                            :dark="compColor.isInDarkMode"
                            fixed-header
                            style="width:100%;border: black 2px"
                          >
                            <template>
                              <thead>
                                <tr>
                                  <th class="text-left">Dateiname</th>
                                  <th class="text-left">Zielort</th>
                                  <th class="text-right" width="75px">Löschen</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colspan="3" class="px-1">
                                    <v-btn
                                      block
                                      :color="compColor.primeColor"
                                      @click="addAssignment($event)"
                                      :dark="compColor.isInDarkMode"
                                      class="white--text text-none"
                                      :loading="isBusy"
                                    >
                                      <v-icon>add</v-icon>
                                      <span>neuer Eintrag</span>
                                    </v-btn>
                                  </td>
                                </tr>
                                <tr v-for="(assignment, index) in model.assignments || []"
                                    :key="index">
                                  <td>
                                    <v-text-field
                                      :dark="compColor.isInDarkMode"
                                      :color="compColor.primeColor"
                                      placeholder="Dateiname"
                                      v-model="assignment.filename"
                                      hide-details
                                      class="mb-0"
                                      outlined
                                      dense
                                      @change="columnchanges = true"
                                    >
                                      <template slot="prepend-inner">
                                        <div class="mr-1 layout-column layout-align-center-center" style="height:25px;">
                                          <v-icon>mdi-file-document</v-icon>
                                        </div>
                                      </template>
                                    </v-text-field>
                                  </td>
                                  <td>
                                    <v-select
                                      :dark="compColor.isInDarkMode"
                                      :color="compColor.primeColor"
                                      placeholder="Zielort"
                                      v-model="assignment.location"
                                      :items="locationList"
                                      item-text="name"
                                      item-value="_id"
                                      hide-no-data
                                      hide-details
                                      :menu-props="{ bottom: true, offsetY: true }"
                                      class="mb-0"
                                      dense
                                      outlined
                                      @change="columnchanges = true"
                                    >
                                      <template v-slot:prepend-inner>
                                        <div class="mr-1 layout-column layout-align-center-center" style="height:25px;">
                                          <v-icon>mdi-hospital-building</v-icon>
                                        </div>
                                      </template>

                                      <template v-slot:selection="{item}">
                                        <span v-if="item._id">{{ item.name }}</span>
                                        <span v-else>{{ listname }}</span>
                                      </template>


                                      <template v-slot:item="{item,on,attr}">
                                        <v-list-item
                                            v-bind="attr"
                                            v-on="on"
                                            ripple
                                        >
                                          <v-list-item-avatar
                                              left
                                              rounded
                                          >
                                            <v-icon
                                                :class="(item._id) ? 'green lighten-1' : 'orange lighten-2'"
                                                :dark="!isDarkMode"
                                            >
                                              {{(item._id) ? 'mdi-ambulance' :'mdi-pharmacy' }}
                                            </v-icon>
                                          </v-list-item-avatar>

                                          <v-list-item-content v-if="!item._id">
                                            <v-list-item-title>{{ listname }}</v-list-item-title>
                                            <v-list-item-subtitle>Sonderartikelliste</v-list-item-subtitle>
                                          </v-list-item-content>

                                          <v-list-item-content v-else>
                                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                                            <v-list-item-subtitle>Arzneimittelliste</v-list-item-subtitle>
                                          </v-list-item-content>

                                        </v-list-item>
                                      </template>
                                    </v-select>
                                  </td>
                                  <td>
                                    <v-btn
                                      text
                                      icon
                                      small
                                      @click="removeAssignment($event, index)"
                                    >
                                      <v-icon>delete_forever</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                                <tr v-if="!model || !model.assignments || model.assignments.length === 0">
                                  <td colspan="3">
                                    {{ (model.assignments || []).length }} Dateien für den automatischen Import
                                    vorhanden
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                      </div>

                    </fake-input-frame>
                  </div>
                  <div class="flex-100 pa-2" v-if="showHTMLByRight({act: 'GET', obj: '/downloads/importer'})">
                    <fake-input-frame label="Downloads"
                                      :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                      <download-view></download-view>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>

    <lx-dialog v-model="dialog" :allow-drag="true" :overlay="true" :size="{x: '70%', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          <span>{{ dialogTitle }}</span>
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-0 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <ValidationObserver tag="div" ref="importSettingsAddFieldPopupForm" class="layout-column flex">
            <div class="pa-4 layout-row layout-wrap">

              <div class="flex-100">
                <validation-provider tag="div" ref="csvColumnIdent"
                                     name="CSV-Spaltenindex/Name" rules="required"
                                     v-slot="{ errors }">
                  <v-text-field
                    :color="compColor.primeColor"
                    label="CSV-Spaltenindex/Name"
                    prepend-inner-icon="description"
                    v-model="modal.csvColumnIdent"
                    :error-messages="errors[0]"
                    outlined
                    validate-on-blur
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="flex-100">
                <validation-provider tag="div" ref="csvValidate" name="CSV Validierung"
                                     rules="regex_validate" v-slot="{ errors }">
                  <v-text-field
                    :color="compColor.primeColor"
                    label="Validierung"
                    prepend-inner-icon="attach_money"
                    v-model="modal.csvValidate"
                    :error-messages="errors[0]"
                    outlined
                    clearable
                    validate-on-blur
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="flex-100">
                <validation-provider tag="div" ref="abdaFieldname" name="Datenbankfeld"
                                     rules="required" v-slot="{ errors }">
                  <v-select
                    :color="compColor.primeColor"
                    :items="abdaFieldsAvailable"
                    :error-messages="errors[0]"
                    item-text="text"
                    item-value="value"
                    :menu-props="{ bottom: true, offsetY: true }"
                    prepend-inner-icon="chevron_right"
                    label="Datenbankfeld"
                    v-model="modal.abdaFieldname"
                    hide-no-data
                    required
                    outlined
                  ></v-select>
                </validation-provider>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!showSaveBtn"
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click.stop="saveModal"
                    icon="save"
                    :tooltip="$t('BUTTONS.DIALOG.SAVE.TOOLTIP')"
                    :label="$t('BUTTONS.DIALOG.SAVE.TITLE')"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="dialog = false;"
                  icon="cancel"
                  :tooltip="$t('BUTTONS.DIALOG.CANCEL.TOOLTIP')"
                  :label="$t('BUTTONS.DIALOG.CANCEL.TITLE')"></lx-btn>
        </div>
      </template>
    </lx-dialog>

    <lx-dialog v-model="dialogChangeAbdaList" :allow-drag="true" :overlay="true" :size="{x: '70%', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          <span>Standard Sonderartikelliste ändern</span>
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-0 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">

          <v-select
              v-model="selectedDefaultItem"
              :items="specialarticlelists"
              :label="$t('INPUTS.TITLES.DATA_BASE')"
              :dark="compColor.isInDarkMode"
              :color="compColor.primeColor"
              :menu-props="{ bottom: true, offsetY: true }"
              class="ma-2"
              item-text="name"
              item-value="_id"
              hide-no-data
              hide-details
              outlined
          >

            <template v-slot:item="{item,on,attr}">
              <v-list-item
                  v-bind="attr"
                  v-on="on"
                  ripple
              >
                <v-list-item-avatar
                    left
                    rounded
                >
                  <v-icon
                      :class="(item._id) ? 'orange lighten-2' : 'green lighten-1'"
                      :dark="!isDarkMode"
                  >
                    {{(item._id) ? 'mdi-pharmacy' : 'mdi-ambulance' }}
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="!item._id">Sonderartikelliste</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>Arzneimittelliste</v-list-item-subtitle>
                </v-list-item-content>

              </v-list-item>
            </template>
          </v-select>

        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <div class="flex mr-1">
            <lx-btn :color="'#353a47'"
                    block
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click.stop="SaveModalAbdaList"
                    icon="save"
                    :tooltip="$t('BUTTONS.DIALOG.SAVE.TOOLTIP')"
                    :label="$t('BUTTONS.DIALOG.SAVE.TITLE')"></lx-btn>
          </div>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="dialogChangeAbdaList = false;"
                  icon="cancel"
                  :tooltip="$t('BUTTONS.DIALOG.CANCEL.TOOLTIP')"
                  :label="$t('BUTTONS.DIALOG.CANCEL.TITLE')"></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>

<script>
import _ from 'lodash'
import DownloadView from '@/views/Administration/Data/DownloadView';

export default {
  name: 'ImportSettings',
  components: {
    DownloadView
  },
  props: {
    importType: {
      type: String,
      default() {
        return 'abda'
      }
    }
  },
  data() {
    return {
      hoverHeader: false,
      dialog: false,
      dialogTitle: 'Feld hinzufügen/bearbeiten',
      dialogChangeAbdaList: false,
      panel: [0, 1, 2],
      columnchanges: false,
      abdaFieldsAvailable: {},
      abdaFields: {},
      encodingList: [],
      locationList: [],
      specialarticlelists: [],
      selectedDefaultItem: null,
      modal: {},
      model: {},
      oldModel: {},
      apiinfo: null,
      isLoading: 0
    }
  },
  computed: {
    showSaveBtn() {
      if (!this.modal || !this.modal.csvColumnIdent || !this.modal.abdaFieldname) {
        return false
      }

      let test = this.$refs['importSettingsAddFieldPopupForm']

      if (test) {
        return !test.flags.pristine && test.flags.valid
      }

      return true
    },
    isBusy() {
      return this.isLoading > 0
    },
    listname() {
      const item = this.specialarticlelists.find((x) => { return x.import_default})
      return item.name || 'no given'
    },
  },
  created() {
    this.getData()

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  methods: {
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    getData() {
      this.apiinfo = null
      this.isLoading++

      const params = {
        config: {
          opts: { sort: { name: 1 } },
        }
      }

      this.$http.get('/hospital', params)
        .then(res => {
          if (res.data && res.data.data) {
            this.locationList = res.data.data
          }

          this.locationList.push(
              { name: ' *** Eigene globale ABDA *** ', _id: null }
          )
        })
        .finally(() => {
          this.isLoading--
        })

      /*
        Optain special article list
       */
      this.isLoading++
      this.$http.get('/abda/settings/list')
        .then((result) => {
          this.specialarticlelists = (Array.isArray(result.data)) ? result.data : []
          const n = this.specialarticlelists.find((x) => { return x.import_default})
          this.selectedDefaultItem = (n ||{})._id
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.isLoading--
        })

      this.isLoading++
      this.$http.get('/imports/settings/' + this.importType)
        .then((res) => {
          let data = res.data || {}
          this.model = data.settings || {}
          this.abdaFields = data.options.fields || {}
          this.encodingList = data.options.charmap || []

          if (this.model.columns && this.model.columns.length > 0) {
            this.model.columns.sort(function (a, b) {
              return a.csvColumnIdent - b.csvColumnIdent
            });
          }

          this.oldModel = _.cloneDeep(this.model)

          if (data.auto_import === true) {
            this.isLoading++
            this.$http.get('/api/info')
              .then((res) => {
                this.apiinfo = res.data
              })
              .finally(() => {
                this.isLoading--
              })
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.isLoading--
        })
    },
    back() {
      window.history.back()
    },
    save(reset) {
      this.validateColumns()
        .then((data) => {
          this.$http.post('/imports/settings/' + this.importType, data)
            .then(() => {
              reset();
              this.columnchanges = false
              this.NotifySystem('Gespeichert')
            })
            .catch(this.NotifyError)
        })
        .catch(this.NotifyError)
    },
    editField(index) {
      if (!Array.isArray(this.model.columns)) {
        return
      }

      this.abdaFieldsAvailable = []
      const usedKeys = Array.from(this.model.columns, obj => obj.abdaFieldname)
      Object.keys(this.abdaFields).forEach((key) => {
        if (usedKeys.indexOf(key) === -1) {
          this.abdaFieldsAvailable.push({ text: this.abdaFields[key], value: key })
        }
      })

      if (index !== -1) {
        let data = this.model.columns[index]
        this.abdaFieldsAvailable.push({ text: this.abdaFields[data.abdaFieldname], value: data.abdaFieldname })

        this.dialogTitle = 'Feld bearbeiten'
        this.modal = {
          $index: index,
          index: index,
          csvColumnIdent: data.csvColumnIdent,
          csvValidate: data.csvColumnFormat,
          abdaFieldname: data.abdaFieldname
        }
      } else {
        this.dialogTitle = 'Neues Feld hinzufügen'
        this.modal = {
          index: null,
          csvColumnIdent: null,
          csvValidate: null,
          abdaFieldname: null
        }
      }

      //
      this.abdaFieldsAvailable.sort((left, right) => {
        const A = left.text.toUpperCase() // ignore upper and lowercase
        const B = right.text.toUpperCase() // ignore upper and lowercase

        if (A < B) {
          return -1
        }
        if (A > B) {
          return 1
        }

        // names must be equal
        return 0
      })

      this.dialog = true
    },
    removeField(event, index) {
      event.preventDefault(); // stop bubbling to top element
      event.stopPropagation(); //stop full bubbling
      this.model.columns.splice(index, 1);
      this.columnchanges = true;
    },
    addAssignment(event) {
      event.preventDefault(); // stop bubbling to top element
      event.stopPropagation(); //stop full bubbling

      this.isLoading++

      this.$nextTick(() => {
        if (!Array.isArray(this.model.assignments)) {
          this.model.assignments = []
        }

        this.model.assignments.push({})
        this.isLoading--
        this.dialog = false
        this.columnchanges = true
      })

    },
    removeAssignment(event, index) {
      this.isLoading++
      event.preventDefault() // stop bubbling to top element
      event.stopPropagation() //stop full bubbling
      this.model.assignments.splice(index, 1)
      this.columnchanges = true
      this.isLoading--
    },
    saveModal() {
      let listIndex = -1;

      if ({}.hasOwnProperty.call(this.modal, '$index')) {
        listIndex = this.modal['$index'];
        delete this.modal['$index']
      }

      let n = parseInt(this.modal.csvColumnIdent);

      let merge = function (target, source) {
        Object.keys(source).forEach(function (value) {
          target[value] = source[value];
        });
      };

      let data = {
        csvColumnIdent: isNaN(n) ? this.modal.csvColumnIdent : n,
        csvColumnFormat: this.modal.csvValidate,
        abdaFieldname: this.modal.abdaFieldname
      };

      if (listIndex !== -1) {
        merge(this.model.columns[listIndex], data);
      } else {
        this.model.columns.push(data);
      }

      if (this.model.columns && this.model.columns.length > 0) {
        this.model.columns.sort(function (a, b) {
          return a.csvColumnIdent - b.csvColumnIdent;
        });
      }

      this.dialog = false;
      this.columnchanges = true;
    },
    SaveModalAbdaList() {
      this.dialogChangeAbdaList = false
      this.isLoading++
      this.$http.patch('/abda/settings/list/'+this.selectedDefaultItem+'/default')
          .then(() => {})
          .catch(this.NotifyError)
          .finally(() => {
            this.isLoading--
            this.getData()
          })
    },
    validateColumns() {
      let model = _.clone(this.model);
      return new Promise(
        function (resolve, reject) {
          let error = !Array.isArray(model.columns) || model.columns.length === 0;

          if (error) {
            return reject('empty')
          }

          model.columns.forEach(function (column) {
            error = (!model.hasHeader && isNaN(column.csvColumnIdent)) || error;
          });

          if (error) {
            return reject('index required')
          }

          resolve(model);
        }
      )
    },
    clearForm(reset) {
      reset();
      this.columnchanges = false;
      this.model = _.cloneDeep(this.oldModel)
    }
  }
}
</script>

<style scoped>

</style>
