<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <div class="layout-column flex ma-2 elevation-5" style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
        <lx-expansion-panels :single-open="false" v-model="panel">
          <lx-expansion-panel>
            <lx-expansion-panel-header icon="mdi-city"
                                       :main-headline="$t('PANELS.ROOT_OVERVIEW.BACKGROUND_SERVICES.HEADLINE')"
                                       :sub-headline="$t('PANELS.ROOT_OVERVIEW.BACKGROUND_SERVICES.SUBHEADING')">
            </lx-expansion-panel-header>
            <lx-expansion-panel-content>
              <div class="layout-row layout-wrap pa-2">
                <div class="flex-100 pa-2">
                  <v-toolbar elevation="0" dense>
                    <v-btn small icon @click="refreshServices" title="Aktualisieren">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-simple-table
                      fixed-header
                      height="240px"
                  >
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Beschreibung
                        </th>
                        <th class="text-center">
                          Status
                        </th>
                        <th class="text-right">
                          Letzte Laufzeit
                        </th>
                        <th class="text-left">

                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in services"
                          :key="item.name"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td class="text-center">{{
                            item.state === 1 ? 'Running' : 'Stopped'
                          }}
                        </td>
                        <td class="text-right">{{ item.last_run | formatDate('llll') }}</td>
                        <td class="text-right">
                          <v-btn icon small :disabled="item.state === 1"
                                 @click="startService(item.name)">
                            <v-icon>mdi-play</v-icon>
                          </v-btn>
                          <v-btn icon small disabled>
                            <v-icon>mdi-stop</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </div>
              </div>
            </lx-expansion-panel-content>
          </lx-expansion-panel>

          <lx-expansion-panel>
            <lx-expansion-panel-header icon="mdi-mail"
                                       :main-headline="$t('PANELS.ROOT_OVERVIEW.SYSTEM_MESSAGES.HEADLINE')"
                                       :sub-headline="$t('PANELS.ROOT_OVERVIEW.SYSTEM_MESSAGES.SUBHEADING')">
            </lx-expansion-panel-header>
            <lx-expansion-panel-content v-bind:class="[compColor.contentFrameBack]">
              <div class="layout-row layout-wrap pa-2">
                <div class="flex-100 pa-2">
                  <v-toolbar elevation="0" dense>
                    <v-btn small icon @click="refreshMessages" title="Aktualisieren">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn small icon @click="showCreateMessageDialog = true" title="Nachricht erstellen">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-simple-table
                      fixed-header
                      height="240px"
                  >
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">

                        </th>
                        <th class="text-left">
                          Nachricht
                        </th>
                        <th class="text-right">
                          Erstellt am
                        </th>
                        <th class="text-right">
                          Läuft ab
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in messages"
                          :key="item.name"
                      >
                        <td class="text-left">
                          <v-btn icon x-small @click="deleteMessage(item._id)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                        <td class="text-left cell-nowrap">{{ item.message?.text }}</td>
                        <td class="text-right">{{ item.timestamp | formatDate('llll') }}</td>
                        <td class="text-right">{{ item.expiry | formatDate('llll') }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>

            </lx-expansion-panel-content>
          </lx-expansion-panel>
        </lx-expansion-panels>
      </div>
    </div>

    <v-dialog v-model="showCreateMessageDialog" persistent :dark="compColor.isDark" max-width="720px">
      <ValidationObserver ref="adminMessage" v-slot="{ valid, pristine }">
        <v-card>
          <v-card-title>Systemnachricht erstellen</v-card-title>
          <v-card-text>

            <div class="layout-row layout-wrap mt-3 pa-2">
              <!--                            <div class="layout-row layout-wrap mt-3 pa-2">-->
              <div class="flex-100 pa-2">
                <validation-provider tag="div" ref="text"
                                     rules="required"
                                     name="Nachricht"
                                     v-slot="{ errors }">
                  <v-textarea
                      class="mt-4 ml-4 mr-4"
                      counter
                      auto-grow
                      clearable
                      placeholder="Schreib was schönes..."
                      clear-icon="mdi-close-circle"
                      label="Nachricht"
                      v-model="messageData.text"
                      :error-messages="errors[0]"
                  ></v-textarea>
                </validation-provider>
              </div>

              <div class="flex-40 pa-2">
                <validation-provider tag="div"
                                     ref="af_from"
                                     name="af_from"
                                     rules="date_must_be_lesser_than:@af_to"
                                     v-slot="{ errors }">
                  <lx-date-time-picker
                      label="Start"
                      v-model="messageData.startDate"
                      :errors="errors"
                  ></lx-date-time-picker>
                </validation-provider>
              </div>

              <div class="flex-20 pa-2">
              </div>

              <div class="flex-40 pa-2">
                <validation-provider tag="div"
                                     ref="af_to"
                                     name="af_to"
                                     rules="date_must_in_future|date_must_be_greater_than:@af_from"
                                     v-slot="{ errors }">
                  <lx-date-time-picker
                      label="Ende"
                      v-model="messageData.endDate"
                      clearable
                      :errors="errors"
                  ></lx-date-time-picker>
                </validation-provider>
              </div>

              <div class="flex-60 pa-2">
              </div>

            </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                :disabled="!valid || pristine"
                @click="saveMessage"
            >Speichern
            </v-btn>
            <v-btn
                text
                color="secondary"
                @click="showCreateMessageDialog = false"
            >Abbrechen
            </v-btn>

          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </div>
</template>

<script>
import lxDateTimePicker from '@/components/lx_datetimepicker/LxDateTimePicker.vue';

export default {
  name: 'Overview',
  components: {
    lxDateTimePicker
  },
  data() {
    return {
      isLoading: false,
      panel: [0, 1, 2, 3],
      errors: [],
      showCreateMessageDialog: false,
      datetimePicker: {
        time: null,
        date: null
      },
      messageData: {
        text: null,
        startDate: null,
        endDate: null

      },
      services: [],
      messages: []
    }
  },
  created() {
    this.refreshServices()
    this.refreshMessages()
  },
  methods: {
    refreshServices() {
      this.$http.get('/system/services')
          .then((res) => {
            if (Array.isArray(res.data)) {
              this.services = res.data
            } else {
              this.services = []
            }
          })
          .catch(this.NotifyError)

    },
    refreshMessages() {
      this.$http.get('/messaging/admin')
          .then((res) => {
            if (Array.isArray(res.data)) {
              this.messages = res.data
            } else {
              this.messages = []
            }
          })
          .catch(this.NotifyError)
    },
    startService(name) {
      this.$http.patch('/system/services/' + name)
          .then(this.NotifySuccess)
          .catch(this.NotifyError)

    },
    saveMessage() {
      if (this.isLoading) return;

      const data = {
        message: {
          text: this.messageData.text
        },
        expiry: this.messageData.endDate,
        timestamp: this.messageData.startDate
      }

      this.isLoading = true;
      this.$http.post('/messaging/system', data)
          .then(() => {
            this.messageData = {}
            this.showCreateMessageDialog = false
            this.refreshMessages()
          })
          .finally(() => {
            this.isLoading = false;
          })

    },
    deleteMessage(messageId) {
      this.$http.delete('/messaging/admin/' + messageId)
          .then(this.NotifySuccess)
          .catch(this.NotifyError)
          .finally(this.refreshMessages)
    },
  }
}
</script>

<style scoped>
.cell-nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
}
</style>