<template>
  <div class="pa-0">
    <v-list two-line class="pa-0 lx-dash-list" color="transparent">
      <v-list-item class="ma-2 py-1 px-6 elevation-3" v-bind:class="[compColor.contentFrameBack]"
                   style="border-radius:3px;" v-if="!advertisementList || advertisementList.length <= 0">
        <v-list-item-content v-bind:class="[compColor.widget.text]">
          <div class="flex layout-column px-3"
               v-bind:style="{'border-left': '4px solid  #eceff1','border-radius': '5px'}">
            <div class="font-weight-bold subtitle-2 my-1" style="line-height: 1.0rem;">derzeit sind kein
              Produktinformationen vorhanden
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <div class="my-2 mx-2" v-for="(item, index) in advertisementList" :key="index">

      <v-list-item-content v-bind:class="[compColor.widget.text]" class="pb-0">
        <advertisement-item-widget
            :headline="item.headline"
            :subheading="item.subheading"
            :content="item.content"
            :color="item.color"
            :creation_date="item.creation_date"
            :options="item.options"
            :offer_price="item.offer_price"
            :regular_price="item.regular_price"
            :file="item.file_info"
            :dark="isDarkMode"
            :download="download"
        ></advertisement-item-widget>
      </v-list-item-content>
      </div>
    </v-list>

    <!-- PRINT DIALOG -->
    <v-dialog
        persistent
        v-model="pdfDialog"
        overlay-opacity="0.9"
        width="90%"
        content-class="pdf-popup"
        transition="dialog-scale-transition"
        :dark="compColor.isInDarkMode"
        :color="compColor.primeColor"
    >
      <div class="elevation-10 layout-column" style="border-radius: 6px;height:100%;min-height:100%;max-height:100%;"
           v-bind:style="{background: compColor.contentFrame}">

        <!-- CONTENT  -->
        <div class="ma-1 pa-1 flex layout-column lx-delivery-background" :class="{'elevation-3': !loadingPDF}"
             style="border-radius: 5px;position: relative;"
             :style="{'background': loadingPDF ? 'transparent' : compColor.lxExpansion.background}">

          <lx-pdf-viewer :value="pdfObject" :loading="loadingPDF"></lx-pdf-viewer>

          <lx-loading-animation :loading="loadingPDF">
            <span class="mb-n3 pa-0 font-weight-light" style="font-size:1em">lade</span>
            <span class="mb-n3 pa-0 font-weight-bold" style="font-size:3.5em;">Ansicht</span>
          </lx-loading-animation>
        </div>

        <!-- ACTIONS -->

        <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div class="layout-row layout-align-end-center pa-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  block
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click="pdfDialog = false"
                  icon="mdi-close"
                  tooltip="PDF Ansicht schließen"
                  :label="'Schließen'"></lx-btn>
        </div>
      </div>
    </v-dialog>

  </div>
</template>

<script>
import AdvertisementItemWidget from "@/components/advertisementItem/AdvertisementItem.vue"
import LxLoadingAnimation from "@/components/lx_loading_animation/LxLoadingAnimation.vue"
import LxPdfViewer from "@/components/lx_pdf_viewer/LxPdfViewer.vue"

function getNumberOfDays(start, end) {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  return Math.round(diffInTime / oneDay);
}

export default {
  name: 'Advertisement',
  components: {
    LxLoadingAnimation,
    LxPdfViewer,
    AdvertisementItemWidget
  },
  data() {
    return {
      advertisementList: null,
      pdfDialog: false,
      pdfObject: null,
      loadingPDF: false,

    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        config: {
          opts: { sort: { creation_date: -1 } },
        }
      }

      this.$http.get('/advertisement', params)
          .then(res => {
            if (res.data && Array.isArray(res.data.result)) {
              // appends default color
              this.advertisementList = res.data.result.map((v) => {
                let days_left;

                if (v.expiry_date) {
                  days_left = getNumberOfDays(new Date(), v.expiry_date)
                }

                return {
                  options: {
                    days_left: days_left,
                    counter: days_left && days_left > 0 && days_left < 7,
                    image: false,
                  },
                  color: this.isDarkMode ? '#4a525e' : '#E0E0E0',
                  ...v
                }
              })
            }
          })
          .catch(this.NotifyError)
    },
    download(file) {
    if (!file?.file_id) return

    const isPdfPreview = (file.file_type === 'application/pdf');

    if (isPdfPreview) {
      this.pdfDialog = true
      this.loadingPDF = true
    }

    this.$http.getAsBlob('/advertisement/file/' + encodeURIComponent(file.file_id))
        .then((res) => {
          if (!this.loadingPDF && res.headers['content-type'] === 'application/pdf') {
            this.pdfDialog = true
            this.loadingPDF = true
          }

          if (isPdfPreview && res.data instanceof Blob) {
            this.pdfObject = res.data
          } else {
            let objectURL = URL.createObjectURL(res.data)
            const hiddenElement = document.createElement('a')
            hiddenElement.style.display = 'none'
            hiddenElement.href = objectURL
            hiddenElement.setAttribute('download', file.file_name)
            document.body.appendChild(hiddenElement)
            hiddenElement.click()
            document.body.removeChild(hiddenElement)

            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              URL.revokeObjectURL(objectURL)
            }, 100);

          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loadingPDF = false
        })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
