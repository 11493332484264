<template>
  <div class="flex layout-column layout-align-center-center">
    <v-alert
        v-if="error"
        class="ma-6"
        prominent
        type="error"
    >
      <h3 class="text-h5">
        Es tut uns sehr leid!
      </h3>
      <div>Beim automatischen Anmeldeprozess ist ein Fehler aufgetreten.</div>

      <v-divider
          class="my-4 black"
          style="opacity: 0.22"
      ></v-divider>

      <v-row align="center" no-gutters>
        <v-col class="grow" v-if="errorText">
          <span class="text-wrap text-pre" v-text="errorText"></span>
        </v-col>
        <v-col class="grow" v-else>
          Bitte schließen Sie das Browsertab und öffnen Medishop erneut aus Ihrem SHS.<br>Sollte das Problem weiterhin
          bestehen, wenden Sie sich bitte an Ihren Administrator.
        </v-col>
      </v-row>
    </v-alert>

    <v-progress-circular
      v-if="!error"
      :size="Math.round(9.375 * getFontSize)"
      :width="Math.round(0.125 * getFontSize)"
      rotate="90"
      color="teal accent-3"
      indeterminate
      value="10"
    >

      <v-progress-circular
        :size="Math.round(7.5 * getFontSize)"
        :width="Math.round(0.625 * getFontSize)"
        color="teal accent-3"
        indeterminate
        value="10"
      >
        <div class="layout-column layout-align-center-center white--text">
          <span class="mb-n3 pa-0" style="font-size:0.9em;">automatischer</span>
          <h1 class="pa-0" style="font-size: 39px;">Login</h1>
        </div>
      </v-progress-circular>
    </v-progress-circular>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('AutoLoginComponent', {
  name: 'AutoLoginComponent',
  props: ['token'],
  components: {},
  data() {
    return {
      model: null,
      getFontSize: 30,
      error: false,
      errorText: null
    }
  },
  created() {
    if (this.token) {
      this.$http.post('/login/shs', { sid: this.token })
        .then(res => {
          this.error = false
          this.errorText = null
          setTimeout(() => {
            this.authStore.dispatch('login', { data: res.data, type: 'medianLogin' });
          }, 2000)
        })
        .catch((e) => {
          const { data: errorData, status: errorStatus } = e.response
          this.error = true

          /*
            Handle only those errors that do not have a status of 500. And errorData is an object with 'message' key
           */
          if (errorStatus !== 500 && typeof errorData.message === 'string') {
            const p = ['AUTH', 'SHS_ERRORS']
            p.push(errorData.message)

            this.errorText = this.$t(p.join('.'))
          }
        })
    }
  },
  watch: {},
  computed: {},
  methods: {}
})
</script>
