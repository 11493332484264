<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="ImportDataForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="isBusy || files.length === 0"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="upload"
                    icon="cloud_upload"
                    :tooltip="$t('BUTTONS.IMPORT_UPLOADED_FILES.TOOLTIP')"
                    :label="$t('BUTTONS.IMPORT_UPLOADED_FILES.TITLE')"></lx-btn>
            <lx-btn :color="'#353a47'"
              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
              :disabled="isBusy"
              small
              iconSize="20"
              width="auto"
              classes="text-none mr-1"
              @click="openHiddenFileInput"
              icon="mdi-plus-circle"
              :tooltip="$t('BUTTONS.IMPORT_ADD_FILES.TOOLTIP')"
              :label="$t('BUTTONS.IMPORT_ADD_FILES.TITLE')"></lx-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <div>
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                          small
                          iconSize="20"
                          v-on="on"
                          width="auto"
                          height="40"
                          classes="text-none mr-1"
                          isMenu
                          :hide-label="true"
                          icon="settings"
                          :tooltip="$t('BUTTONS.IMPORT_MORE_SETTINGS.TOOLTIP')"
                          :label="$t('BUTTONS.IMPORT_MORE_SETTINGS.TITLE')"></lx-btn>
                </div>
              </template>
              <v-list v-bind:class="{'blue-grey darken-3': compColor.isInDarkMode}" class="py-0">
                <v-list-item :dark="compColor.isInDarkMode"
                             @click="$router.push({path: '/administration/import/history'})">
                  <v-list-item-icon>
                    <v-icon :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                            size="18">mdi-sort-clock-ascending-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>History</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item :dark="compColor.isInDarkMode"
                             @click="$router.push({path: '/administration/import/settings/abda'})">
                  <v-list-item-icon>
                    <v-icon :color="compColor.primeColor" :dark="compColor.isInDarkMode"
                            size="18">settings
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Einstellungen</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>


        <div class="flex layout-row lx-delivery-background pa-1" style="overflow-y: hidden">

          <div class="elevation-3 flex layout-column" style="border-radius: 6px;"
               :style="{'background': compColor.lxExpansion.background}">
            <!--        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">-->

            <div
              class="layout-column flex ma-1 pa-2"
              @drop.prevent="addFilesByDragAndDrop"
              @dragenter.prevent="dropOverlayVisible = true"
              @dragleave.prevent="dropOverlayVisible = false"
              @dragover.prevent
              style="position:relative;border-radius:4px;"
            >
              <!--              || files.length === 0-->
              <v-overlay
                absolute
                :value="dropOverlayVisible || isBusy"
                :z-index="0"
                @dragover.prevent style="pointer-events: none;"
              >
                <div class="flex layout-column layout-align-center-center px-12">
                  <v-icon size="120" :color="compColor.primeColor">cloud_upload</v-icon>
                  <span class="subtitle-1 font-weight-light"
                        v-bind:class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-4': isDarkMode}"
                        style="max-width: 450px; text-align:center;">Ziehen Sie ihre Dateien aus ihrem Dateibrowser auf dieses Feld und lassen Sie sie los</span>
                </div>
              </v-overlay>

              <lx-loading-animation :loading="isBusy">
                <span class="mb-n3 pa-0 font-weight-light" style="font-size:1em">Daten</span>
                <span class="mb-n3 pa-0 font-weight-bold" style="font-size:3.5em;">werden</span>
                <span class="mt-n3 pa-0 font-weight-light" style="font-size:2.1em">hochgeladen</span>
              </lx-loading-animation>

              <fake-input-frame label="Liste aller zu importierenden Dateien" class="flex pa-2"
                                style="position: relative;">


                <div class="flex layout-column layout-align-center-center px-12"
                     v-if="!dropOverlayVisible"
                     style="z-index:1;position: absolute;opacity:0.3;top:50%;left:50%;transform: translateX(-50%) translateY(-50%)">
                  <v-icon size="120" :color="compColor.primeColor">cloud_upload</v-icon>
                  <span class="subtitle-1 font-weight-light"
                        v-bind:class="{'blue-grey--text text--darken-2': !isDarkMode, 'blue-grey--text text--lighten-4': isDarkMode}"
                        style="max-width: 450px; text-align:center;">Ziehen Sie ihre Dateien aus ihrem Dateibrowser auf dieses Feld und lassen Sie sie los</span>
                </div>

                <div class="pa-2" style="position:absolute;left:0;top:0;right:0;bottom:0;z-index:2;overflow-y: auto;">

                  <div v-for="(file, index) in files"
                       :key="index"
                       class="px-2 py-1"
                       :class="index < files.length - 1 ? 'mb-1' : ''"
                       :style="{'background': file.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                       style="border-radius: 4px;">
                    <div class="layout-row">
                      <div class="pa-1 pr-2 layout-column layout-align-center-center">
                        <v-btn text icon @click="removeFile(file)"
                               color="blue-grey darken-2"
                               title="Entfernen">
                          <v-icon>delete_forever</v-icon>
                        </v-btn>
                      </div>
                      <v-divider vertical class="ml-1 mr-3"></v-divider>
                      <div class="pa-1 pr-2 layout-column layout-align-center-center">
                        <v-btn text icon @click="previewFile(file)"
                               color="blue-grey darken-2"
                               title="Voransicht">
                          <v-icon>mdi-file-search-outline</v-icon>
                        </v-btn>
                      </div>
                      <v-divider vertical class="mr-2"></v-divider>
                      <div class="layout-row flex py-2">
                        <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start pl-3">
                          <span style="line-height: 16px;"><strong>{{ file.name }}</strong></span>
                          <small style="opacity: 0.6;line-height: 15px;">{{ Math.round(file.size / 1000) }} kb</small>
                        </div>
                        <v-divider vertical class="mx-3"></v-divider>

                        <div class="layout-row">
<!--                          <v-select-->
<!--                            dense-->
<!--                            :dark="compColor.isInDarkMode"-->
<!--                            :color="compColor.primeColor"-->
<!--                            :background-color="isDarkMode ? 'transparent':'#f5f5f5'"-->
<!--                            placeholder="KH-Abteilung"-->
<!--                            v-model="files[index].location"-->
<!--                            :items="locationList"-->
<!--                            item-text="name"-->
<!--                            item-value="_id"-->
<!--                            :menu-props="{ bottom: true, offsetY: true }"-->
<!--                            hide-no-data-->
<!--                            hide-details-->
<!--                            class="mb-0"-->
<!--                            outlined-->
<!--                            style="min-width:300px;max-width:300px;"-->
<!--                          ></v-select>-->
                          <v-select
                            dense
                            outlined
                            hide-no-data
                            hide-details
                            item-text="name"
                            item-value="_id"
                            class="mb-0"
                            style="min-width:480px;max-width:480px;"
                            placeholder="Sonder-/Arnzeimittelliste"
                            v-model="files[index].location"
                            :dark="isDarkMode"
                            :color="compColor.primeColor"
                            :background-color="isDarkMode ? 'transparent':'#f5f5f5'"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :items="myList"
                          >
                            <template v-slot:item="{item,on,attr}">
                              <v-list-item
                                  v-bind="attr"
                                  v-on="on"
                                  ripple
                              >
                                <v-list-item-avatar
                                    left
                                    rounded
                                >
                                  <v-icon
                                      :class="(item.isSpecial) ? 'orange lighten-2' : 'green lighten-1'"
                                      :dark="!isDarkMode"
                                  >
                                    {{(item.isSpecial) ? 'mdi-pharmacy' : 'mdi-ambulance' }}
                                  </v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                                  <v-list-item-subtitle v-if="item.isSpecial">Sonderartikelliste</v-list-item-subtitle>
                                  <v-list-item-subtitle v-else>Arzneimittelliste</v-list-item-subtitle>
                                </v-list-item-content>

                              </v-list-item>
                            </template>
                          </v-select>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </fake-input-frame>
            </div>

          </div>
        </div>

        <input type="file" hidden ref="hiddenFileInput" @change="addFilesByDialog"/>

        <lx-loading-animation :loading="isImportingData">
          <span class="mb-n3 pa-0 font-weight-light" style="font-size:1.8em">Daten</span>
          <span class="mb-n3 pa-0 font-weight-bold" style="font-size:2.7em;">werden</span>
          <span class="mt-n3 pa-0 font-weight-light" style="font-size:1.8em">hochgeladen</span>
        </lx-loading-animation>

      </div>
    </ValidationObserver>

    <lx-dialog v-model="showFileItem" :allow-drag="true" :overlay="true" :size="{x: '80', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Datenimport Voransicht
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <csv-viewer :file="previewFileItem" :capacity="100"></csv-viewer>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  block
                  classes="text-none mr-1 flex"
                  @click.stop="showFileItem = false"
                  icon="cancel"
                  :tooltip="$t('BUTTONS.DIALOG.CLOSE.TOOLTIP')"
                  :label="$t('BUTTONS.DIALOG.CLOSE.TITLE')"></lx-btn>

        </div>
      </template>
    </lx-dialog>
  </div>
</template>

<script>
import CsvViewer from '../../../components/lx_csv-viewer/CsvViewer'
import axios from 'axios';
import LxLoadingAnimation from '@/components/lx_loading_animation/LxLoadingAnimation'

export default {
  name: 'Import',
  components: {
    CsvViewer,
    LxLoadingAnimation
  },
  data() {
    return {
      loading: 0,
      dropOverlayVisible: false,
      isImportingData: false,
      files: [],
      files2: [],
      locationList: [],
      specialList: [],
      // useUserAbda: false,
      showFileItem: false,
      previewFileItem: null
    }
  },
  created() {
    const params = {
      config: {
        opts: { sort: { name: 1 } },
        count: false
      }
    }

    // this.loading++
    this.$http.get('/hospital', params)
      .then(res => {
        if (res.data && res.data.data) {
          this.locationList = res.data.data
        }
      })
      .finally(() => {
        // this.isLoading--
      })

    // this.loading++
    this.$http.get('/abda/settings/list', params)
        .then(res => {
          if (res.data) {
            let n = res.data || []
            n.forEach((e, i) => { n[i].isSpecial = true})
            this.specialList = n
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          // this.isLoading--
        })

  },
  computed: {
    isBusy() {
      return this.loading > 0
    },
    myList () {
      return [].concat(this.locationList, this.specialList)
    }
  },
  methods: {
    /**
     * Browser benutzen seit hundert Jahren einen Scheiss Datei Dialog aus Button und Input wenn man <input type="file"/> benutzte
     * da das hässlich ist versteckt man dieses Ding und aktiviert es durch den Click eines Buttons der per ID pder Refs darauf zugreift
     * **/
    openHiddenFileInput() {
      this.$refs.hiddenFileInput.click()
    },
    /**
     * wird bei onChange aufgerufen wenn im Dateidialog Dateien ausgewählt werden
     * diese werden an Methode addFiles übergeben wo sie zerlegt und aufbereitet werden
     * **/
    addFilesByDialog() {
      let file = this.$refs.hiddenFileInput;
      if (file && file.files) {
        this.addFiles(file.files)
      }
    },
    /**
     * wird bei drop Event aufgerufen wenn im Drag and Drop Bereich Dateien fallengelassen wurden
     * diese werden an Methode addFiles übergeben wo sie zerlegt und aufbereitet werden
     * **/
    addFilesByDragAndDrop(e) {
      this.dropOverlayVisible = false
      if (this.isImportingData) return;

      this.addFiles(e.dataTransfer.files)
    },
    /**
     * zerlegt die Files Objekte des Browsers und push sie dann in das this.files Array welches die Tabelle füllt
     * **/
    addFiles(files) {
      if (!files) return;

      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...files]).forEach(f => {
        this.files.push(f);
      });
    },
    /**
     * entfernt beim click auf den Papierkorb in der Tabelle die zugehörige Datei
     * **/
    removeFile(file) {
      this.files = this.files.filter(f => {
        return f !== file;
      });
    },
    previewFile(file) {
      this.showFileItem = false;
      this.previewFileItem = null;

      this.$nextTick(function () {
        this.showFileItem = Boolean(file);
        this.previewFileItem = file;
      })
    },
    upload() {
      // check if
      const itemsContainsLocation = this.files.every((obj) => obj.hasOwnProperty("location"))

      if (!itemsContainsLocation) {
        this.DialogConfirm(
            'Import',
            'Den Dateien muss ein Zielpunkt zugewiesen werden.',
            { hideCancelButton: true, ok: this.$t('BUTTONS.DIALOGS.CONFIRM') }
        )
          .then(() => {})

        return
      }

      this.isImportingData = true;
      let formData = new FormData()
      let importFiles = {}
      let counter = 0

      this.files.forEach((f) => {
        if (typeof f.location !== 'string') {
          return
        }

        counter++

        const name = 'file' + counter.toString()
        formData.append(name, f)
        formData.append(name + '_location', f.location)
        importFiles[name] = f
      })

      if (counter === 0) {
        return this.NotifyError('keine gültigen Elemente für den Importvorgang')
      }

      axios.post('/abda/import', formData, { headers: { 'content-type': 'multipart/form-data' } })
        .then((res) => {
          const data = res.data;
          this.files = [];

          if (Array.isArray(data)) {
            data.forEach((file) => {
              const filename = file.filename;
              const title = `Datei Import von ${filename}`;
              const nFaulty = file.faulty;
              const nTotal = file.total;

              if (file.success !== true) {
                this.NotifySystem({
                  title: title,
                  text: file.message,
                  type: 'error',
                  duration: -1
                });
              } else {
                const msgType = (nFaulty === 0) ? 'success' : 'warning';

                this.NotifySystem({
                  title: title,
                  text: `Es wurden ${nTotal - nFaulty} von ${nTotal} Datensätze(n) importiert.</br>Davon waren ${nFaulty} fehlerhaft.`,
                  type: msgType,
                  duration: (nFaulty === 0) ? 3000 : -1
                });
              }
            });

            // Remove only success files from List
            data.forEach((result) => {
              if (result.success === false && importFiles[result.name]) {
                this.files.push(importFiles[result.name]);
              }
            });
          }
        })
        .finally(() => {
          this.isImportingData = false;

          //reset file input um neue dateien hochladen zu können
          if (this.$refs['hiddenFileInput']) {
            this.$refs['hiddenFileInput'].value = ''
          }
        })
    },
    back() {
      window.history.back()
    }
  }
}
</script>

<style scoped>

</style>
