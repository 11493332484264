<template>
  <div class="layout-row px-1 pt-0 pb-1">
    <div class="layout-row layout-align-start-center">

      <lx-btn :color="'#353a47'"
              :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
              small
              iconSize="20"
              width="auto"
              classes="text-none mr-1"
              @click="modal = true"
              icon="mdi-fingerprint"
              tooltip="Passwort ändern"
              :label="$t('BUTTONS.CHANGE_PASSWORD.TITLE')"></lx-btn>

    </div>

      <lx-dialog v-model="modal" :allow-drag="true" :allow-resize="true" :overlay="true" :size="{x: '800px', y: 'auto'}">
        <template #header>
          <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center grey--text text--darken-2">
            Passwort ändern
          </div>
        </template>
        <template #default>
          <ValidationObserver ref="setPasswordPopupForm" class="layout-column flex">
            <div class="ma-1 pa-4 flex elevation-3"
               style="border-radius: 5px;position: relative;overflow-y: auto;"
               :style="{'background': compColor.lxExpansion.background}">
            <div class="flex layout-row layout-wrap pa-1">
              <div class="flex-100 px-4">
                <validation-provider tag="div" ref="passwordOld" name="Altes Passwort" vid="passwordOld"
                                     rules="min:1|required|requireDependency:passwordNew1"
                                     v-slot="{ errors }">
                  <v-text-field
                      tabindex="1"
                      :dark="compColor.isInDarkMode"
                      :append-icon="showPasswordsOld ? 'visibility_off' : 'visibility'"
                      clearable
                      @click:append="showPasswordsOld = !showPasswordsOld"
                      :color="compColor.primeColor"
                      :label="'Altes Passwort'"
                      outlined
                      height="50"
                      prepend-inner-icon="fingerprint"
                      :error-messages="errors[0]"
                      :type="showPasswordsOld ? 'text' : 'password'"
                      v-model="model.oldPassword"
                  ></v-text-field>
                </validation-provider>
              </div>


              <div class="flex-100 px-4">
                <v-divider class="mb-6"></v-divider>
              </div>

              <div class="flex-100 px-4">
                <validation-provider tag="div" ref="passwordNew1" name="Neues Passwort" vid="passwordNew1"
                                     rules="min:1|required|requireDependency:passwordOld|password:passwordNew2"
                                     v-slot="{ errors }">
                  <v-text-field
                      tabindex="2"
                      :dark="compColor.isInDarkMode"
                      :append-icon="showPasswordsNew1 ? 'visibility_off' : 'visibility'"
                      clearable
                      @click:append="showPasswordsNew1 = !showPasswordsNew1"
                      :color="compColor.primeColor"
                      :dense="denseFields"
                      :label="'Neues Passwort'"
                      outlined
                      height="50"
                      prepend-inner-icon="fingerprint"
                      :error-messages="errors[0]"
                      :type="showPasswordsNew1 ? 'text' : 'password'"
                      v-model="model.password"
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="flex-100 px-4">
                <validation-provider tag="div" ref="passwordNew2" name="Neues Passwort Überprüfung" vid="passwordNew2"
                                     rules="min:1|required|password:passwordNew1"
                                     v-slot="{ errors }">
                  <v-text-field
                      tabindex="3"
                      :dark="compColor.isInDarkMode"
                      :append-icon="showPasswordsNew2 ? 'visibility_off' : 'visibility'"
                      clearable
                      @click:append="showPasswordsNew2 = !showPasswordsNew2"
                      :color="compColor.primeColor"
                      :dense="denseFields"
                      :label="'Neues Passwort wiederholen'"
                      outlined
                      height="50"
                      prepend-inner-icon="fingerprint"
                      :type="showPasswordsNew2 ? 'text' : 'password'"
                      :error-messages="errors[0]"
                      v-model="model.newPassword"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </div>
          </ValidationObserver>
        </template>
        <template #actions>
          <div class="layout-row layout-align-center-center pa-1">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    tabindex="4"
                    iconSize="20"
                    width="auto"
                    :disabled="!showSaveBtn"
                    classes="text-none mr-1 flex"
                    @click.stop="processButtonResult('yes')"
                    icon="save"
                    tooltip="Passwort übernehmen"
                    :label="'Passwort übernehmen'"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click.stop="processButtonResult('no')"
                    icon="cancel"
                    tooltip="Passwort ändern abbrechen"
                    :label="'Abbrechen'"></lx-btn>
          </div>
        </template>
      </lx-dialog>
  </div>
</template>

<script>

export default {
  name: 'PasswordEditComponent',
  data () {
    return {
      showPasswordsOld: false,
      showPasswordsNew1: false,
      showPasswordsNew2: false,
      modal: false,
      model: {},
      isLoading: false
    }
  },
  computed: {
    showSaveBtn() {
      if (this.model && this.model.oldPassword) {
        let test = this.$refs['setPasswordPopupForm']
        return !test.flags.pristine && test.flags.valid
      }
      return false
    }
  },
  methods: {
    processButtonResult(btnResult) {
      this.btnResult = btnResult
      if (btnResult === 'no') {
        this.showPasswordsOld = false
        this.showPasswordsNew1 = false
        this.showPasswordsNew2 = false
        this.model = {}
        this.modal = false
      } else {
        this.changePassword ()
      }
    },
    changePassword () {
      if (this.isLoading) return
      this.isLoading = true

      let mod = {
        password: this.model.password,
        oldPassword: this.model.oldPassword,
      }

      this.$http.patch('/auth/user/changePassword', mod)
        .then(() => {
          this.modal = false
          this.NotifySystem(this.$t('AUTH.EDIT_PASSWORD.SUCCESS'))
        })
        .catch(() => {
          delete this.model.oldPassword
          delete this.model.password
          delete this.model.newPassword
          this.NotifyError(this.$t('AUTH.EDIT_PASSWORD.ERROR'))
        })
        .finally(() => {
          this.model = {}
          this.isLoading = false
        })
    },

    close () {
      this.modal = false
      this.model = {}
    }
  }
}
</script>
