<template>
  <lx-dialog v-model="visible" :allow-drag="true" :overlay="true" :size="{x: '45', y: 'auto'}">
    <template #header>
      <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
           :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
        Verfügbare Standorte
      </div>
    </template>
    <template #default>
      <div class="ma-1 pa-4 px-2 flex layout-column elevation-3"
           style="border-radius: 5px;position: relative;overflow-y: auto;"
           :style="{'background': compColor.lxExpansion.background}">
        <v-text-field
            :dark="isDarkMode"
            :color="compColor.primeColor"
            :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
            :dense="denseFields"
            v-if="searchEnabled"
            clearable
            flat
            solo
            class="ml-2 mr-2"
            label="Filter"
            hide-details="auto"
            prepend-inner-icon="search"
            @click:clear="clearSearch"
            v-model.lazy="searchText"
        ></v-text-field>
        <v-list-item-group
          color="primary"
        >

          <v-list-item two-line v-for="item in filteredItems"
                       :key="item._id"
                       style="border-radius:4px;"
                       class="mb-1"
          :class="{'grey lighten-4': !isDarkMode, 'blue-grey darken-3': isDarkMode}"
          :style="{'cursor': item._id === currentId ? 'default':'pointer' }">
              <v-icon
                size="35"
                :color="(item._id !== currentId) ? 'red lighten-3' : 'green lighten-1'"
                dark
                class="mr-3"
              >
                  mdi-map-marker-radius
              </v-icon>

            <v-divider vertical class="mr-4 ml-1 my-3" style="opacity: 0.5;"></v-divider>

            <v-list-item-content @click="select(item)">
              <v-list-item-title :class="{'blue-grey--text text--darken-1': !isDarkMode, 'grey--text text--lighten-2': isDarkMode}">{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle :class="{'blue-grey--text text--lighten-1': !isDarkMode, 'grey--text': isDarkMode}">{{ item.address || '' }}</v-list-item-subtitle>
              <div class="layout-row">
                <v-chip color="green lighten-4" style="line-height: 18px !important;height: 18px !important;" class="green--text text--darken-2 px-2" label small v-if="item._id === currentId">Derzeitiger Standort</v-chip>
              </div>
            </v-list-item-content>

            <v-divider vertical class="mx-1 my-3" style="opacity: 0.5;"></v-divider>

            <v-list-item-action>
              <v-tooltip bottom open-delay="550">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="setFavorit(item)">
                    <v-icon
                      size="30"
                      :color="(item.is_default === true) ? 'amber darken-1' : 'grey lighten-2'"
                    >mdi-star</v-icon>
                  </v-btn>
                </template>
                <span>Legen Sie diesen Standort als Ihren Favoriten fest</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>

        </v-list-item-group>
      </div>
    </template>
    <template #actions>
      <div class="layout-row layout-align-center-center pa-1">
        <div class="flex mr-1">
        </div>
        <lx-btn :color="'#353a47'"
                :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                small
                block
                class="flex"
                iconSize="20"
                width="auto"
                classes="text-none"
                @click="close"
                icon="cancel"
                tooltip="Fenster schließen"
                :label="'Schließen'"></lx-btn>
      </div>
    </template>
  </lx-dialog>

</template>

<script>
function SortByName(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA > nameB) {
    return 1
  } else if (nameA < nameB) {
    return -1
  }

  return 0
}

export default {
  name: 'LxSelectLocation',
  mounted() {
    this.searchText = null
  },
  data: () => ({
    visible: false,
    resolve: null,
    reject: null,
    showing: false,
    isBusy: false,
    items: [],
    currentId: undefined,
    searchEnabled: true,
    searchText: null
  }),
  computed: {
    filteredItems() {
      if (!this.searchEnabled || !this.searchText) {
        return this.items
      }

      const searchRegExp = new RegExp(this.searchText, "i")
      return this.items.filter((item) => {
        return searchRegExp.test(item.name) || searchRegExp.test(item.address)
      })
    }
  },
  methods: {
    open() {
      this.items = []
      this.selectedItem = null
      this.searchText = null
      this.currentId = this.authStore.getters.getCurrentLocationId
      this.getData()

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    select(location) {
      if (location && location._id !== this.currentId) {
        this.resolve(location)
        this.visible = false
        this.searchText = null
      }
    },
    ok() {
      this.resolve(this.items[this.selectedItem])
      this.visible = false
      this.searchText = null
    },
    close() {
      this.reject()
      this.visible = false
      this.searchText = null
    },
    getData() {
      this.items = []
      this.selectedItem = null
      this.searchEnabled = false

      this.$http.get('/account/user/locations')
          .then((res) => {
            this.items = res.data || []
            this.items.sort(SortByName)
            this.searchEnabled = this.items.length > 5
            this.visible = true
          })
          .catch(this.NotifyError)
    },
    setFavorit (location) {
      this.$http.put('/account/user/location', location)
        .then(this.getData)
        .catch(this.NotifyError)
    },
    clearSearch() {
      this.searchText = null
    }
  }
}
</script>

<style scoped>

</style>
