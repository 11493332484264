<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="PharmacyDetailForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">

      <lx-content-container class="mb-3">
        <template v-slot:toolbar>
          <v-btn :dark="compColor.isInDarkMode" :color="compColor.primeColor"
                 class="white--text text-none mr-1" @click="back()">
            <v-icon left dark>chevron_left</v-icon>
            <span>Zurück zur Apothekenliste</span>
          </v-btn>

          <v-btn :dark="compColor.isInDarkMode" @click="save" :color="compColor.primeColor"
                 class="white--text text-none mr-1" :disabled="!valid || pristine">
            <v-icon left dark>save</v-icon>
            <span v-if="!loading">Speichern</span>
            <v-progress-circular indeterminate v-if="loading" :width="3"
                                 color="white"></v-progress-circular>
          </v-btn>

          <v-btn :dark="compColor.isInDarkMode" :color="compColor.primeColor"
                 class="white--text text-none mr-1" @click="clearForm(reset)" :disabled="pristine">
            <v-icon left dark>cancel</v-icon>
            <span>Zurücksetzen</span>
          </v-btn>

          <v-btn :dark="compColor.isInDarkMode" :color="compColor.primeColor" v-if="pharmacyid != null"
                 class="white--text text-none mr-1" @click="openEditor">
            <v-icon left dark>business</v-icon>
            <span>Erweitere Einstellungen</span>
          </v-btn>
        </template>

        <div class="pa-2" v-bind:class="[compColor.contentFrameBack]">
          <form class="flex" ref="form" v-bind:class="[compColor.contentFrameBack]">
            <v-expansion-panels accordion multiple v-model="panel" :readonly="true">
              <v-expansion-panel class="mb-1">
                <v-expansion-panel-header
                  v-bind:class="[compColor.expansionHeader,compColor.textInput+ '--text']">
                  Apothekendaten
                  <template v-slot:actions>
                    <v-icon @click="openCloseExpansionPanel(0)">expand_more</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-bind:class="[compColor.contentFrameBack]">
                  <div class="mx-auto">
                    <v-layout row wrap class="pa-1">
                      <v-flex xs12 sm12 md12 class="pa-2">
                        <validation-provider tag="div" ref="Apothekenname" name="Apothekenname"
                                             rules="required" v-slot="{ errors }">
                          <v-text-field
                            label="Apothekenname *"
                            :color="compColor.primeColor"
                            prepend-inner-icon="house"
                            v-model="model.name"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-flex>
                      <v-flex xs12 sm8 md8 class="pa-2">
                        <validation-provider tag="div" ref="Strasse" name="Strasse"
                                             v-slot="{ errors }">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="Straße"
                            prepend-inner-icon="email"
                            v-model="model.address.street"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          ></v-text-field>
                        </validation-provider>
                      </v-flex>
                      <v-flex xs12 sm4 md4 class="pa-2">
                        <validation-provider tag="div" ref="Hausnummer" name="Hausnummer"
                                             v-slot="{ errors }">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="Hausnummer"
                            prepend-inner-icon="email"
                            v-model="model.address.number"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          ></v-text-field>
                        </validation-provider>
                      </v-flex>
                      <v-flex xs12 sm4 md4 class="pa-2">
                        <validation-provider tag="div" ref="Postleitzahl" name="Postleitzahl"
                                             v-slot="{ errors }">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="Postleitzahl"
                            prepend-inner-icon="email"
                            v-model="model.address.zipcode"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          ></v-text-field>
                        </validation-provider>
                      </v-flex>
                      <v-flex xs12 sm8 md8 class="pa-2">
                        <validation-provider tag="div" ref="Stadt" name="Stadt"
                                             v-slot="{ errors }">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="Stadt"
                            prepend-inner-icon="mdi-city-variant"
                            v-model="model.address.city"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          ></v-text-field>
                        </validation-provider>
                      </v-flex>
                      <v-flex xs12 sm12 md12 class="pa-2">
                        <v-select
                          :color="compColor.primeColor"
                          prepend-inner-icon="location_on"
                          label="Bundesland *"
                          v-model="model.state"
                          :rules="[rules.required]"
                          :items="states"
                          item-text="name"
                          item-value="statecode"
                          :menu-props="{ bottom: true, offsetY: true }"
                          hide-no-data
                          required
                          outlined
                          :dark="compColor.isInDarkMode"
                          clearable
                          :dense="denseFields"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex xs12 sm12 md12 class="pa-2">
                          <v-select
                              disabled
                              outlined
                              hide-no-data
                              hide-details
                              :color="compColor.primeColor"
                              :menu-props="{ bottom: true, offsetY: true }"
                              :dark="compColor.isInDarkMode"
                              :dense="denseFields"
                              :items="pharmacy_types"
                              label="Apotheken Type"
                              prepend-inner-icon="mdi-city-variant"
                              v-model="model.sub_type"
                              item-text="caption"
                              item-value="type"
                          ></v-select>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-1" v-if="!pharmacyid">
                <v-expansion-panel-header
                  v-bind:class="[compColor.expansionHeader,compColor.textInput+ '--text']">
                  Administratoren Konto <i class="ml-3"><small>(einzuladende Person)</small></i>
                  <template v-slot:actions>
                    <v-icon @click="openCloseExpansionPanel(1)">expand_more</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-bind:class="[compColor.contentFrameBack]">
                  <div class="mx-auto">
                    <v-layout row wrap class="pa-1">
                      <v-flex xs12 sm12 md12 class="pa-2">
                        <validation-provider tag="div" ref="E-Mail" name="E-Mail"
                                             rules="required|email" v-slot="{ errors }">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="E-Mail *"
                            prepend-inner-icon="email"
                            v-model="model.invites_email"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          ></v-text-field>
                        </validation-provider>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-1">
                <v-expansion-panel-header
                  v-bind:class="[compColor.expansionHeader,compColor.textInput+ '--text']">
                  Supportdaten
                  <template v-slot:actions>
                    <v-icon @click="openCloseExpansionPanel(2)">expand_more</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-bind:class="[compColor.contentFrameBack]">
                  <div class="mx-auto">
                    <v-layout row wrap class="pa-1">
                      <v-flex xs12 sm12 md12 class="pa-2">
                        <validation-provider tag="div" ref="Support-Telefon"
                                             name="Support-Telefon" v-slot="{ errors }">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="Support-Telefon"
                            prepend-inner-icon="phone"
                            v-model="model.supportphone"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          ></v-text-field>
                        </validation-provider>
                      </v-flex>

                      <v-flex xs12 sm12 md12 class="pa-2">
                        <validation-provider tag="div" ref="Support-E-Mail"
                                             name="Support-E-Mail" rules="email"
                                             v-slot="{ errors }">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="Support E-Mail"
                            prepend-inner-icon="email"
                            v-model="model.supportemail"
                            outlined
                            :dark="compColor.isInDarkMode"
                            :error-messages="errors[0]"
                            :dense="denseFields"
                          ></v-text-field>
                        </validation-provider>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-1" v-show="pharmacyid">
                <v-expansion-panel-header
                  v-bind:class="[compColor.expansionHeader,compColor.textInput+ '--text']">
                  Module
                  <template v-slot:actions>
                    <v-icon @click="openCloseExpansionPanel(3)">expand_more</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-bind:class="[compColor.contentFrameBack]">
                  <div class="mx-auto">
                    <v-layout row wrap class="pa-1">
                      <v-flex xs12 sm12 md12 class="pa-2">
                        <v-row
                          align="center"
                          justify="start"
                        >
                          <v-col cols="12">
                            <validation-provider tag="div" ref="Modules" name="Modules"
                                                 v-slot="{ errors }">
                              <v-select
                                :items="modules"
                                :color="compColor.primeColor"
                                :dark="compColor.isInDarkMode"
                                :error-messages="errors[0]"
                                :dense="denseFields"
                                :disabled="!pharmacyid"
                                v-model="model.modules"
                                label="Aktivierte Module"
                                :menu-props="{ bottom: true, offsetY: true }"
                                item-value="value"
                                item-text="text"
                                outlined
                                multiple
                                chips
                              ></v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-1" v-show="pharmacyid">
                <v-expansion-panel-header
                  v-bind:class="[compColor.expansionHeader,compColor.textInput+ '--text']">
                  Kontingente
                  <template v-slot:actions>
                    <v-icon @click="openCloseExpansionPanel(4)">expand_more</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-bind:class="[compColor.contentFrameBack]">
                  <div class="mx-auto">
                    <v-layout row wrap class="pa-1">
                      <v-flex xs12 sm12 md12 class="pa-2">
                        <v-row
                          align="center"
                          justify="start"
                        >
                          <v-col cols="12">
                            <validation-provider rules="numeric|min_value:4" tag="div" ref="max_medicalunit" name="max_medicalunit" v-slot="{ errors }">
                              <v-text-field
                                  :color="compColor.primeColor"
                                  :dark="compColor.isInDarkMode"
                                  :error-messages="errors[0]"
                                  :dense="denseFields"
                                  :disabled="!pharmacyid"
                                  label="Medizinische Einrichtungen"
                                  type="number"
                                  prepend-inner-icon="mdi-account-cash"
                                  outlined
                                  v-model.number="model.licenses.max_medicalunit"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row
                          align="center"
                          justify="start"
                        >
                          <v-col cols="12">
                            <validation-provider rules="numeric|min_value:2" tag="div" ref="max_specialarticle_lists" name="max_specialarticle_lists" v-slot="{ errors }">
                              <v-text-field
                                  :color="compColor.primeColor"
                                  :dark="compColor.isInDarkMode"
                                  :error-messages="errors[0]"
                                  :dense="denseFields"
                                  :disabled="!pharmacyid"
                                  label="Maximale Sonderartikellisten"
                                  type="number"
                                  prepend-inner-icon="mdi-format-list-numbered"
                                  outlined
                                  v-model.number="model.licenses.max_specialarticle_lists"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </form>
        </div>

      </lx-content-container>
    </ValidationObserver>

    <v-snackbar top color="success" :timeout="3000" v-model="snackbar" :multi-line="true" class="mt-12">
      <v-icon class="mr-3">done</v-icon>
      Datensatz wurde erfolgreich gespeichert
      <v-btn color="green darken-3" text @click="snackbar = false">Ok</v-btn>
    </v-snackbar>

    <v-dialog v-model="editorAdditionallyShow" width="700" persistent class="red pa-4">>
      <v-card>
        <v-card-title>Erweiterte Einstellungen</v-card-title>
        <v-card-subtitle>Achtung: Änderungen am Objekt</v-card-subtitle>
        <v-card-text>
          <v-textarea
            style="font-family: 'Lucida Console'"
            :color="compColor.primeColor"
            :dark="compColor.isInDarkMode"
            :dense="denseFields"
            height="160px"
            v-model="editorAdditionallyText"
            :rules="[ValidateJSON]"
            validate-on-blur
            outlined
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="layout-row">
          <v-btn @click="saveAdditionallyDetails()" :disabled="false">Speichern</v-btn>
          <v-btn @click="getTemplate()">Median Template</v-btn>
          <v-btn @click="editorAdditionallyShow = false">Abbruch</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" width="700" persistent class="red pa-4">
      <v-card>
        <v-card-text class="pa-4">
          <div class="layout-row">
            <div class="layout-column layout-align-center-center">
              <img src="../../../assets/bot_03.png" style="width:250px;height:250px;"/>
            </div>
            <v-divider vertical></v-divider>
            <div class="flex layout-column pa-5">
              <div class="display-3 font-weight-black pink--text">Oops!</div>
              <div class="headline font-weight-light mb-3 mt-1">Da ist wohl etwas schief gelaufen.</div>
              <div class="flex">Wir entschuldigen uns für die Unanehmlichkeiten, doch leider hat der
                letzte Vorgang zu einem Fehler geführt. Bitte versuchen Sie es noch einmal und wenden
                Sie sich bei wiederholten Auftreten des Fehlers an den Support.
              </div>
              <div class="mt-3 text-right">
                <v-btn color="pink" text @click.stop="errorDialog=false">Ok</v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import router from '../../../router'

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export default {
  name: 'PharmacyDetails',
  props: {
    pharmacyid: {
      type: [String, null],
      default: () => {
        return null;
      }
    },
    pharmacy_type: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      editorAdditionallyText: null,
      editorAdditionallyShow: false,
      panel: [0, 1, 2, 3, 4],
      search: null,
      e1: true,
      loading: false,
      model: {
        sub_type: this.pharmacy_type,
        address: {},
        licenses: {}
      },
      breadcrumbs: null,
      oldModel: null,
      tabAction: null,
      // addedRoles: [],
      // allRoles: [],
      error: {},
      errorDialog: false,
      snackbar: false,
      isDirty: false,
      states: [],
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
      modules: [],
      selected: [],
      pharmacy_types: [
          {caption: 'Öffentliche Apotheke', type: 'public' },
          {caption: 'Krankenhausversorgung', type: 'hospital' }
      ]
    }
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length
    },

    selections() {
      const selections = []

      for (const selection of this.selected) {
        selections.push(selection)
      }

      return selections
    },
  },
  created() {
    this.getCountryStates()
    this.getAvailableModules()

    if (this.pharmacyid != null) {
      this.getData()
    }
  },
  methods: {
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * Get all users, all rights, all rights of selected role and all users with selected role
     */
    getData() {
      if (this.loading) {
        return
      }

      this.loading = true

      // get all pharmacies
      this.$http.get('/pharmacy/' + this.pharmacyid)
        .then(res => {
          if (res.data) {

            this.model = res.data || {}
            this.model.address = this.model.address || {}
            this.model.licenses = this.model.licenses || {}

            this.oldModel = Object.assign({}, this.model)
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false
        })
    },

    getCountryStates() {
      this.$http.get('/calendar/states/ger')
        .then((result) => {
          let stateResult = result.data || []
          this.states = stateResult.sort(compare)
        })
        .catch(this.NotifyError)
    },

    getAvailableModules() {
      this.$http.get('/settings/modules/all')
        .then(res => {
          this.modules = res.data || []
        })
        .catch(this.NotifyError)
    },

    save() {
      let data = structuredClone(this.model)

      if (this.pharmacyid != null) {
        delete data['_id']

        this.$http.put('/pharmacy/' + this.pharmacyid, data)
          .then(() => {
            this.snackbar = true
            if (this.$refs.PharmacyDetailForm) {
              this.$refs.PharmacyDetailForm.reset()
            }
          })
          .catch(this.NotifyError)
      } else {
        delete data["licenses"]

        this.$http.post('/pharmacy', data)
          .then((result) => {
            if (result && result.data && this.$refs.PharmacyDetailForm) {
              this.$refs.PharmacyDetailForm.reset()
              this.panel = [0, 1, 2, 3, 4]

              router.replace('/administration/pharmacy/' + result.data + '/details')
            } else {
              this.back()
            }
          })
          .catch(this.NotifyError)
      }
    },

    saveAdditionallyDetails() {
      try {
        const payload = JSON.parse(this.editorAdditionallyText)

        this.$http.post('/pharmacy/additionally/' + this.pharmacyid, payload)
          .then(() => {
            this.editorAdditionallyShow = false
            this.model.additionally = payload
            this.oldModel.additionally = payload
          })
          .catch(this.NotifyError)
      } catch (e) {
        this.NotifyError(e.toString())
      }
    },

    getTemplate() {
      let payload = {}
      try {
        payload = JSON.parse(this.editorAdditionallyText)
      } catch (e) {
        this.NotifyError(e.toString())
      }

      payload['median'] = {
        "name": "Median Kliniken",
        "vendor_number": "NUMMER"
      }

      this.editorAdditionallyText = JSON.stringify(
          payload, null, 2
      )
    },

    /**
     * resetet das Formular, also alles prestine, keine Fehler etc. anschließend wird das model wieder mit dem Ursprungsmodel gefüllt
     *
     * **/
    clearForm(reset) {
      reset()
      this.model = Object.assign({}, this.oldModel)
      if (!this.model.address) {
        this.model.address = {}
      }
    },

    openEditor() {
      this.editorAdditionallyText = JSON.stringify(this.model.additionally || {}, null, 1);
      this.editorAdditionallyShow = true
    },

    ValidateJSON() {
      try {
        const X = JSON.parse(this.editorAdditionallyText)
        return X !== null && X !== undefined
      } catch (e) {
        return e.toString()
      }
    }

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dark_login {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 50px;
  min-width: 500px;
  min-height: 700px;
  background-image: linear-gradient(#3b4b65, #212c3e);
}

h1, h2 {
  font-weight: normal;
}

.subheader {
  height: 20px;
  font-size: 16px;
  padding: 0 16px 0 2px;
  font-weight: 300;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.tabs__div {
  text-transform: none;
  color: #575757;
}
</style>
