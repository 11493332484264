<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="mdi-chevron-left"
                  :hide-label="true"
                  tooltip="zurück zur Übersicht"
                  :label="'Zurück'"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  :disabled="isLoadingData > 0"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  tooltip="Ansicht aktualisieren"
                  :label="'Aktualisieren'"></lx-btn>
        </div>
        <div class="flex layout-row"></div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1); overflow-y: auto"
               class="flex layout-column">
            <doughnut-chart
              v-if="loaded"
              :chartdata="chartdata"
              :options="options"/>

            <v-divider class="mt-5"></v-divider>

            <div class="pa-2">
              <h4>Elemente in Warteschlange</h4>

              <v-data-table
                caption=""
                :headers="headers"
                :loading="!loaded"
                :items="queued"
              >
                <template v-slot:item.timestamp="{ item }">
                  {{ item.timestamp | formatDate('DD.MM.YYYY HH:MM:ss') }}
                </template>

                <template v-slot:item.locked="{ item }">
                  {{ item.locked | formatDate('DD.MM.YYYY HH:MM:ss') }}
                </template>

              </v-data-table>
            </div>

            <v-divider class="mt-5"></v-divider>

            <div class="pa-2">
              <h4>Nicht gesendete Elemente</h4>

              <v-data-table
                :headers="headers"
                :loading="!loaded"
                :items="errored"
              >
                <template v-slot:item.timestamp="{ item }">
                  {{ item.timestamp | formatDate('DD.MM.YYYY HH:MM:ss') }}
                </template>

                <template v-slot:item.locked="{ item }">
                  {{ item.locked | formatDate('DD.MM.YYYY HH:MM:ss') }}
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PieChart from '@/components/charts/PieChart'
import DoughnutChart from "@/components/charts/DoughnutChart";

function toPieChartData(input) {
  const states = [
    { label: "Unbekannt", color: "#777" },// 0
    { label: "Warteschlange", color: "#00A0A0" }, // 1
    { label: "Gesendet", color: "#00A000" },   // 2
    { label: "Fehler", color: "#A00000" }   // 3
  ]

  let xxx = new Array(states.length)

  if (Array.isArray(input)) {
    input.forEach((v) => {
      xxx[v.n] = v.c
    })
  }

  const data = {
    label: 'Dataset 1',
    data: xxx,
    backgroundColor: states.map(v => v.color)
  }

  return {
    labels: states.map(v => v.label),
    datasets: [data]
  }
}

export default {
  name: 'StatisticsMailQueueDetails',
  components: { DoughnutChart },
  data() {
    return {
      isLoadingData: 0,
      loaded: false,
      chartdata: null,
      errored: [],
      queued: [],
      headers: [
        { text: 'Zeitstempel', value: 'timestamp', sortable: true, align: 'start' },
        { text: 'Betreff', value: 'subject', sortable: false },
        { text: 'Empfänger', value: 'receivers', sortable: false },
        { text: 'Zurückgehalten bis', value: 'locked', sortable: false },
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        title: {
          display: true,
          text: 'Warteschlange der letzten 24 Stunden'
        }
      },
    }
  },
  async mounted() {
    this.getData()
  },
  methods: {
    back() {
      window.history.back()
    },
    getData() {
      this.loaded = false
      this.errored = []
      this.queued = []

      this.$http.get('statistics/data/mailqueue')
        .then((res) => {
          this.chartdata = toPieChartData(res.data.v1);

          if (Array.isArray(res.data.v1)) {
            res.data.v1.forEach((x) => {
              if (x.n === 1) {
                this.queued = x.v || [];
              }

              if (x.n === 3) {
                this.errored = x.v || [];
              }
            })
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loaded = true
        })
    }
  }
}
</script>

<style scoped>

</style>
