<template>
  <div :class="{'pa-0': !this.showHTMLByRight({act: 'GET', obj: '/deliveryplan/nextdates'})}">

    <v-list two-line class="pa-0 lx-dash-list" color="transparent"
            v-if="!this.showHTMLByRight({act: 'GET', obj: '/deliveryplan/nextdates'})">

      <v-list-item class="pa-0 ma-1 mb-2 elevation-3" v-bind:class="[compColor.contentFrameBack]"
                   style="border-radius:3px;">
        <v-list-item-content v-bind:class="[compColor.widget.text]" class="pa-0">
          <v-alert
            border="left"
            prominent
            style="font-size:15px;"
            class="pa-3 ma-0"
            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            <div class="layout-column layout-align-center-start subtitle-2 font-weight-regular">
              Sie verfügen
              nicht über die Rechte um die Liefertermine anschauen zu können.
            </div>
          </v-alert>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div class="lx-timeline pa-1 pt-2" :style="{color: isDarkMode ? '#363a47' : '#aaa'}" v-if="deliveryDates.length > 0">
      <div class="layout-row" :class="index < 1 ? 'mb-5' : 'my-5'" v-for="(date, index) in deliveryDates" :key="index">
        <div class="layout-column layout-align-center-center">
          <div class="px-2 ml-1 elevation-2 layout-row layout-align-end-center"
               style="padding-top:6px;padding-bottom:6px;border-radius: 6px;width:140px;"
               :style="{'background': compColor.context.background}"
               v-bind:class="[compColor.widget.text, {'opaque-date': !date.orders || date.orders.length <= 0}]">
            <div class="display-1 font-weight-black" v-bind:class="[compColor.textInput + '--text']">
              {{ date.deliver_date.format('DD') }}
            </div>
            <v-divider vertical class="mr-1"></v-divider>
            <div class="layout-column layout-align-center-start">
              <div class="font-weight-bold body-2" style="line-height: 14px;">{{ date.deliver_date.format('dddd') }}
              </div>
              <div class="font-weight-light body-2" style="line-height: 14px;">
                {{ date.deliver_date.format('MMM YYYY') }}
              </div>
            </div>
          </div>
        </div>
        <div class="lx-timeline-points layout-column layout-align-center-center px-5">
          <div v-if="date.orders && date.orders.length > 0" :class="compColor.prime" class="elevation-1"
               :style="{'border': '3px solid' + compColor.context.background + ' !important'}"
               style="border-radius:50%;width:23px;min-width:23px;max-width:23px;height:23px;min-height:23px;max-height:23px;">
            &nbsp;
          </div>
        </div>
        <div class="flex pr-1 layout-column layout-align-center">
          <v-card class="elevation-2 pa-0 arrow-left" style="border-radius:6px;"
                  :style="{color: compColor.context.background, 'background': compColor.context.background}"
                  v-if="date.orders && date.orders.length > 0">
            <v-card-text class="py-0 px-3">
              <v-list two-line class="pa-0 transparent" v-for="(order, indexOrder) in date.orders" :key="indexOrder">
                <v-list-item style="min-height: auto;" class="px-2">
                  <v-list-item-content v-bind:class="[compColor.widget.text]" class="py-2">
                    <v-list-item-title class="font-weight-bold">{{ order.department_name }}</v-list-item-title>
                    <v-list-item-subtitle class="blue-grey--text">{{ order.medical_name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="date.orders.length > 1 && indexOrder < date.orders.length - 1"></v-divider>
              </v-list>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Deliveries',
  data() {
    return {
      duration: null,
      intervalCounterTimer: null,
      deliverDateMessage: null,
      deliveryDates: []
    }
  },
  created() {
    //die showHTMLByRight Methode kommt aus dem lx_auth plugin als mixinund prüft den user ob recht vorhanden ist
    if (this.showHTMLByRight({ act: 'GET', obj: '/deliveryplan/nextdates' })) {
      this.$http.get('/deliveryplan/nextdates')
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            this.deliveryDates = res.data

            this.deliveryDates.forEach(item => {
              item.deliver_date = moment(item.deliver_date)
            })
          }
        })
        .catch(() => {
          this.NotifyError()
        })
    }
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.v-timeline:after {
  content: '';
  border-top: 12px solid #8998a0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -9px;
}

.opaque-date {
  .v-timeline-item {
    opacity: 0.5;
  }
}

.opaque-date {
  opacity: 0.3;
}

.arrow-left {
  position: relative;
  color: white;

  &:after {
    content: "";
    position: absolute;
    background: transparent;
    border-right: 8px solid currentColor;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    content: "";
    position: absolute;
    background: transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.22);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    left: -8px;
    top: calc(50% - 8px + 2px);
  }
}

.arrow-right {
  position: relative;
  color: white;

  &:after {
    content: "";
    position: absolute;
    background: transparent;
    border-left: 8px solid currentColor;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    content: "";
    position: absolute;
    background: transparent;
    border-left: 8px solid rgba(0, 0, 0, 0.22);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    right: -8px;
    top: calc(50% - 8px + 2px);
  }
}

.lx-timeline {
  position: relative;

  .lx-timeline-points {
    z-index: 3;
  }

  &:before {
    z-index: 1;
    content: "";
    width: 10px;
    background: currentColor;
    position: absolute;
    top: 0;
    bottom: -10px;
    left: 174px;
  }

  &:after {
    content: "";
    position: absolute;
    background: transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid currentColor;
    border-left: 14px solid transparent;
    left: 165px;
    bottom: -20px;
    //transform: translateX(-10px);
  }
}
</style>
