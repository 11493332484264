<template>
  <div class="flex white" style="overflow-y: auto;">
    <!-- Impressum -->
    <v-container>
      <div class="pa-5 grey--text text--darken-1">
        <h1 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Impressum</h1>
        <h4 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Verantwortlicher</h4>
        <div class="layout-column grey lighten-5 pa-2">
          <div class="layout-row">
            <div style="width:140px;">Name:</div>
            <div class="flex font-weight-bold">Litixsoft GmbH</div>
          </div>
          <div class="layout-row">
            <div style="width:140px;">Anschrift:</div>
            <div class="flex layout-column">
              <span>Karl-Tauchnitz-Straße 8</span>
              <span>04107 Leipzig</span>
            </div>
          </div>
          <div class="layout-row">
            <div style="width:140px;">Handelsregister:</div>
            <div class="flex">HRB 27482</div>
          </div>
          <div class="layout-row">
            <div style="width:140px;">Registergericht:</div>
            <div class="flex">Amtsgericht Leipzig</div>
          </div>
        </div>
        <h4 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Vertreten durch:</h4>
        <div class="layout-column grey lighten-5 pa-2">
          <div>Dr. Uwe Krasselt</div>
        </div>
        <h4 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Kontakt:</h4>
        <div class="layout-column grey lighten-5 pa-2">
          <div class="layout-row">
            <div style="width:140px;">E-Mail:</div>
            <div class="flex">support@litixsoft.de</div>
          </div>
        </div>
        <h4 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Umsatzsteuer-ID:</h4>
        <div class="layout-column grey lighten-5 pa-2">
          <div>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE 279 27 86 23</div>
          <div>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </div>
        </div>
        <h4 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Erstellt und betrieben
          von:</h4>
        <div class="layout-column grey lighten-5 pa-2">
          <div class="layout-row">
            <div style="width:140px;">Anschrift:</div>
            <div class="flex layout-column">
              <span>Litixsoft GmbH</span>
              <span>Karl-Tauchnitz-Str. 08</span>
              <span>04107 Leipzig</span>
            </div>
          </div>
          <div class="layout-row">
            <div style="width:140px;">E-Mail:</div>
            <div class="flex">info@litixsoft.de</div>
          </div>
        </div>
      </div>
    </v-container>

    <v-divider class="my-7"></v-divider>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h1 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Datenschutzhinweise</h1>
        <h3 id="preamble" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Präambel</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
            personenbezogenen Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem
            Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.</p>
          <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
          <p>Stand: 30.November 2023</p>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Inhaltsübersicht</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <ul>
            <li><a href="#preamble">Präambel </a></li>
            <li><a href="#responsibility">Verantwortlicher</a></li>
            <li><a href="#dataSecurityOfficer">Kontakt Datenschutzbeauftragter</a></li>
            <li><a href="#dataHandling">Übersicht der Verarbeitungen</a></li>
            <li><a href="#legalBasis">Maßgebliche Rechtsgrundlagen</a></li>
            <li><a href="#securityMeasures">Sicherheitsmaßnahmen</a></li>
            <li><a href="#transmissionPersonalData">Übermittlung von personenbezogenen Daten</a></li>
            <li><a href="#deletionData">Löschung von Daten</a></li>
            <li><a href="#rights">Rechte der betroffenen Personen</a></li>
            <li><a href="#cookies">Einsatz von Cookies</a></li>
            <li><a href="#business">Geschäftliche Leistungen </a></li>
            <li><a href="#webhosting">Bereitstellung des Onlineangebots und Webhosting</a></li>
            <li><a href="#apps">Besondere Hinweise zu Applikationen (Apps)</a></li>
            <li><a href="#registration">Registrierung, Anmeldung und Nutzerkonto</a></li>
            <li><a href="#singleSignOn">Single-Sign-On-Anmeldung</a></li>
            <li><a href="#requestManagment">Kontakt- und Anfragenverwaltung</a></li>
            <li><a href="#newsletter">Newsletter und elektronische Benachrichtigungen</a></li>
            <li><a href="#changePrivacyPolicy">Änderung und Aktualisierung der Datenschutzerklärung</a></li>
            <li><a href="#definitions">Begriffsdefinitionen</a></li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="responsibility" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">
          Verantwortlicher</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <div class="layout-row">
            <div style="width:260px;">Name:</div>
            <div class="flex font-weight-bold">Litixsoft GmbH</div>
          </div>

          <div class="layout-row">
            <div style="width:260px;">Anschrift:</div>
            <div class="flex layout-column">
              <span>Karl-Tauchnitz-Str. 8</span>
              <span>04107 Leipzig</span>
            </div>
          </div>

          <div class="layout-row">
            <div style="width:260px;">Vertretungsberechtigte Personen:</div>
            <div class="flex font-weight-bold">Geschäftsführer Dr. Uwe Krasselt</div>
          </div>

          <div class="layout-row">
            <div style="width:260px;">E-Mail-Adresse:</div>
            <div class="flex">support@litixsoft.de</div>
          </div>

          <div class="layout-row">
            <div style="width:260px;">Kontakt Datenschutzbeauftragter:</div>
            <div class="flex">dsb@litixsoft.de</div>
          </div>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="dataHandling" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Übersicht der
          Verarbeitungen</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung
            zusammen und verweist auf die betroffenen Personen.</p>
          <div class="layout-column grey lighten-5 pa-2">
            <strong class="blue-grey--text text--darken-2">Arten der verarbeiteten Daten</strong>
            <ul>
              <li>Bestandsdaten</li>
              <li>Zahlungsdaten</li>
              <li>Kontaktdaten</li>
              <li>Inhaltsdaten</li>
              <li>Vertragsdaten</li>
              <li>Nutzdaten</li>
              <li>Meta-, Kommunikations- und Verfahrensdaten</li>
            </ul>
            <br/>
            <strong class="blue-grey--text text--darken-2">Kategorien betroffener Personen</strong>
            <ul>
              <li>Kunden</li>
              <li>Interessenten</li>
              <li>Kommunikationspartner</li>
              <li>Nutzer</li>
              <li>Geschäfts- und Vertragspartner</li>
            </ul>
            <br/>
            <strong class="blue-grey--text text--darken-2">Zwecke der Verarbeitung</strong>
            <ul>
              <li>Erbringung vertraglicher Leistungen und Erfüllung vertraglicher Pflichten</li>
              <li>Kontaktanfragen und Kommunikation</li>
              <li>Sicherheitsmaßnahmen</li>
              <li>Büro- und Organisationsverfahren</li>
              <li>Verwaltung und Beantwortung von Anfragen</li>
              <li>Feedback</li>
              <li>Anmeldeverfahren</li>
              <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit</li>
              <li>Informationstechnische Infrastruktur</li>
            </ul>
          </div>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="legalBasis" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Maßgebliche
          Rechtsgrundlagen</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p><b>Maßgebliche Rechtsgrundlagen nach der DSGVO:</b> Im Folgenden erhalten Sie eine Übersicht der
            Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten.
            Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem
            bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im
            Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
            mit.</p>

          <ul class="mt-3">
            <li class="mb-5"><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person
              hat ihre Einwilligung in die Verarbeitung der sie
              betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
            </li>
            <li class="mb-5"><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
              DSGVO)</strong> - Die Verarbeitung ist für die
              Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung
              vorvertraglicher Maßnahmen erforderlich,
              die auf Anfrage der betroffenen Person erfolgen.
            </li>
            <li class="mb-5"><strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)</strong> - Die
              Verarbeitung ist zur Erfüllung einer
              rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.
            </li>
            <li class="mb-5"><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - Die
              Verarbeitung ist zur Wahrung der berechtigten
              Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder
              Grundrechte und Grundfreiheiten der
              betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
            </li>
          </ul>

          <p><b>Nationale Datenschutzregelungen in Deutschland:</b> Zusätzlich zu den Datenschutzregelungen der DSGVO
            gelten nationale Regelungen zum Datenschutz in
            Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
            Datenverarbeitung (Bundesdatenschutzgesetz – BDSG).
            Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
            Widerspruchsrecht, zur Verarbeitung besonderer
            Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie
            automatisierten Entscheidungsfindung im Einzelfall
            einschließlich Profiling. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
            gelangen.</p>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="securityMeasures" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">
          Sicherheitsmaßnahmen</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
            Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
            unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten
            natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.</p>

          <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
            Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden
            Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren
            haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
            Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
            personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren
            entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche
            Voreinstellungen.</p>

          <p>TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen wir
            eine TLS-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
            Adresszeile Ihres Browsers. Secure Sockets Layer (SSL) ist die Standardtechnologie zur Sicherung von
            Internetverbindungen durch Verschlüsselung der zwischen einer Website oder App und einem Browser (oder
            zwischen zwei Servern) übertragenen Daten. Transport Layer Security (TLS) ist eine aktualisierte und
            sicherere Version von SSL. Hyper Text Transfer Protocol Secure (HTTPS) wird in der URL angezeigt, wenn eine
            Website durch ein SSL/TLS-Zertifikat gesichert ist</p>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="transmissionPersonalData" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">
          Übermittlung von personenbezogenen Daten</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen,
            Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen
            gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte
            Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In
            solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw.
            Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.</p>

          <p>Datenaustausch an die von Ihnen gewählten Geschäftspartner innerhalb der Anwendung: Wir können
            personenbezogene Daten an andere Stellen (von Ihnen gewählte Geschäftspartner) innerhalb der Anwendung
            übermitteln oder ihnen den Zugriff auf diese Daten gewähren. Die Weitergabe der Daten beruht auf Ihrem
            Einverständnis und ist in diesem Fall zur Erfüllung unserer vertragsbezogenen Verpflichtungen
            erforderlich.</p>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="deletionData" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Löschung von
          Daten</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur
            Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z. B. wenn der
            Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind). Sofern
            die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird
            deren Verarbeitung auf diese Zwecke beschränkt. D. h., die Daten werden gesperrt und nicht für andere Zwecke
            verarbeitet. Das gilt z. B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden
            müssen oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum
            Schutz der Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.</p>

          <p>Im Rahmen unserer Datenschutzhinweise können wir den Nutzern weitere Informationen zu der Löschung sowie zu
            der Aufbewahrung von Daten mitteilen, die speziell für die jeweiligen Verarbeitungsprozesses gelten.</p>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="rights" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Rechte der betroffenen
          Personen</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:</p>

          <ul class="mb-6">
            <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
              ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von
              Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
              Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie
              betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong></li>

            <li class="mt-3"><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte
              Einwilligungen jederzeit zu widerrufen.
            </li>
            <li class="mt-3"><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu
              verlangen, ob betreffende Daten verarbeitet werden und
              auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den
              gesetzlichen Vorgaben.
            </li>
            <li class="mt-3"><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben
              das Recht, die Vervollständigung der Sie
              betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
            </li>
            <li class="mt-3"><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach
              Maßgabe der gesetzlichen Vorgaben das Recht,
              zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der
              gesetzlichen Vorgaben eine Einschränkung der
              Verarbeitung der Daten zu verlangen.
            </li>
            <li class="mt-3"><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende
              Daten, die Sie uns bereitgestellt haben, nach
              Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu
              erhalten oder deren Übermittlung an einen anderen
              Verantwortlichen zu fordern.
            </li>
            <li class="mt-3"><strong>Beschwerde bei Aufsichtsbehörde:</strong> Entsprechend den gesetzlichen Vorgaben
              und unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie
              ferner das Recht, bei einer Datenschutzaufsichtsbehörde, insbesondere einer Aufsichtsbehörde im
              Mitgliedstaat, in dem Sie sich gewöhnlich aufhalten, der Aufsichtsbehörde Ihres Arbeitsplatzes oder des
              Ortes des mutmaßlichen Verstoßes, eine Beschwerde einzulegen, wenn Sie der Ansicht sei sollten, dass die
              Verarbeitung der Ihre Person betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="cookies" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Einsatz von Cookies und ähnlicher Technologien</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die Informationen auf Endgeräten speichern
            und Informationen aus den Endgeräten auslesen. Z. B. um den Login-Status in einem Nutzerkonto, einen
            Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder verwendete Funktionen eines Onlineangebotes
            speichern. Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden, z. B. zu Zwecken der
            Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie der Erstellung von Analysen der
            Besucherströme.</p>

          <p><strong>Hinweise zur Einwilligung:</strong> Wir setzen Cookies und ähnliche Technologien im Einklang mit
            den gesetzlichen Vorschriften ein. Daher holen wir von den Nutzern eine vorhergehende Einwilligung ein,
            außer wenn diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig, wenn
            das Speichern und das Auslesen der Informationen, also auch von Cookies, unbedingt erforderlich sind, um dem
            den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur
            Verfügung zu stellen. Zu den unbedingt erforderlichen Cookies und ähnlichen Technologien gehören in der
            Regel Cookies und ähnliche Technologien mit Funktionen, die der Anzeige und Lauffähigkeit des
            Onlineangebotes, dem Lastausgleich, der Sicherheit, der Speicherung der Präferenzen und Auswahlmöglichkeiten
            der Nutzer oder ähnlichen mit der Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern
            angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die widerrufliche Einwilligung wird
            gegenüber den Nutzern deutlich kommuniziert und enthält die Informationen zu der jeweiligen Cookie-Nutzung
            bzw. der Nutzung ähnlicher Technologien.</p>

          <p><strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:</strong> Auf welcher datenschutzrechtlichen
            Rechtsgrundlage wir die personenbezogenen Daten der Nutzer mit Hilfe von Cookies und ähnlicher Technologien
            verarbeiten, hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist
            die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe
            von Cookies und ähnlicher Technologien verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.
            B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung seiner Nutzbarkeit)
            verarbeitet oder, wenn dies im Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der
            Einsatz von Cookies und ähnlicher Technologien erforderlich ist, um unsere vertraglichen Verpflichtungen zu
            erfüllen. Zu welchen Zwecken die Cookies und ähnlichen Technologien von uns verarbeitet werden, darüber
            klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und
            Verarbeitungsprozessen auf.</p>

          <p><strong>Speicherdauer:</strong> Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies
            unterschieden:</p>
          <ul class="mx-3 mb-5">
            <li><strong>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</strong> Temporäre Cookies werden
              spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot
              verlassen und sein Endgerät (z. B. Browser oder mobile Applikation) geschlossen hat.
            </li>
            <li class="mt-3"><strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch nach dem Schließen des
              Endgerätes gespeichert.
              So können beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden,
              wenn der Nutzer eine Website erneut besucht.
              Ebenso können die mit Hilfe von Cookies erhobenen Daten der Nutzer zur Reichweitenmessung verwendet
              werden. Sofern wir Nutzern keine expliziten
              Angaben zur Art und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung der Einwilligung),
              sollten Nutzer davon ausgehen,
              dass Cookies permanent sind und die Speicherdauer bis zu zwei Jahre betragen kann.
            </li>
            <li class="mt-3"><p><strong>Protokollierung</strong> Bei jedem Zugriff auf unsere Anwendung/Webseite werden
              zu
              statistischen Zwecken, die nötig für die Bereitstellung und die Sicherheit des Webangebots sowie dessen
              Verbesserung sind, folgende Daten in Protokolldateien gespeichert:</p>

              <ul class="mx-3 mb-5">
                <li class="mt-3">Zeitpunkt des Zugriffs</li>
                <li class="mt-3">User Agent (verwendeter Browser und genutztes Betriebssystem)</li>
                <li class="mt-3">Bildschirmauflösung</li>
                <li class="mt-3">Version der Webanwendung</li>
              </ul>

              <p>Eine Zusammenführung der IP-Adresse mit anderen Datenbeständen erfolgt nicht. Diese Daten werden mit
                jeder neuen Session/Sitzung (d. h. jedem neuen Login) überschrieben.</p>
            </li>
          </ul>

          <p><strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):</strong>
            Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen und der Verarbeitung
            entsprechend den gesetzlichen Vorgaben widersprechen. Hierzu können Nutzer unter anderem die Verwendung von
            Cookies in den Einstellungen ihres Browsers einschränken (wobei dadurch auch die Funktionalität unseres
            Onlineangebotes eingeschränkt sein kann). Ein Widerspruch gegen die Verwendung von Cookies zu
            Online-Marketing-Zwecken kann auch über die Websites <a href="https://optout.aboutads.info" target="_blank">https://optout.aboutads.info</a> und
            <a href="https://www.youronlinechoices.com/" target="_blank">https://www.youronlinechoices.com/</a> erklärt werden.

            <br /><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)
          </p>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
          <ul class="mx-3 mb-5">
            <li><strong>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:</strong> Wir setzen ein
              Verfahren zum Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der Nutzer in den
              Einsatz von Cookies, bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten
              Verarbeitungen und Anbieter eingeholt sowie von den Nutzern verwaltet und gespeichert, um deren Abfrage
              nicht erneut wiederholen zu müssen und die Einwilligung entsprechend der gesetzlichen Verpflichtung
              nachweisen zu können. Die Speicherung kann serverseitig und/oder in einem
              Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um die
              Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben zu
              den Anbietern von Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der Speicherung der
              Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet
              und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien
              von Cookies und/oder Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät gespeichert;

              <br /><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="business" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Geschäftliche
          Leistungen</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z. B. Kunden und Interessenten
            (zusammenfassend bezeichnet als "Vertragspartner")
            im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im
            Rahmen der Kommunikation mit den
            Vertragspartnern (oder vorvertraglich), z. B., um Anfragen zu beantworten.</p>

          <p>Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu gehören insbesondere
            die Verpflichtungen zur Erbringung der vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe
            bei Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus verarbeiten wir die Daten zur Wahrung
            unserer Rechte und zum Zwecke der mit diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
            Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf Grundlage unserer berechtigten
            Interessen an einer ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung sowie an
            Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor Missbrauch,
            Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte (z. B. zur Beteiligung von
            Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und
            Rechtsberatern, Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts geben wir die
            Daten von Vertragspartnern nur insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder zur
            Erfüllung gesetzlicher Pflichten erforderlich ist. Über weitere Formen der Verarbeitung, z. B. zu
            Marketingzwecken, werden die Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.</p>

          <p>Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder im
            Rahmen der Datenerhebung, z. B. in Onlineformularen, durch besondere Kennzeichnung (z. B. Farben) bzw.
            Symbole (z. B. Sternchen o.ä.), oder persönlich mit.</p>

          <p>Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d. h.,
            grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert werden,
            z. B. solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen. Die gesetzliche
            Aufbewahrungsfrist beträgt bei steuerrechtlich relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
            Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen erforderlichen
            Arbeitsanweisungen und sonstigen Organisationsunterlagen und Buchungsbelegen zehn Jahre sowie bei
            empfangenen Handels- und Geschäftsbriefen und Wiedergaben der abgesandten Handels- und Geschäftsbriefe sechs
            Jahre. Die Frist beginnt mit Ablauf des Kalenderjahres, in dem die letzte Eintragung in das Buch gemacht,
            das Inventar, die Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht aufgestellt, der Handels- oder
            Geschäftsbrief empfangen oder abgesandt worden oder der Buchungsbeleg entstanden ist, ferner die
            Aufzeichnung vorgenommen worden ist oder die sonstigen Unterlagen entstanden sind.</p>

          <p>Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im Verhältnis
            zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen
            Drittanbieter oder Plattformen.</p>

          <ul class="mt-1 mb-6">
            <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen, Adressen); Zahlungsdaten (z. B.
              Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z. B. E-Mail, Telefonnummern);
              Vertragsdaten (z. B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z. B. Inhalte der
              Nutzung der Web-Applikation, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten
              (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
            </li>

            <li class="mt-3"><strong>Betroffene Personen:</strong> Kunden; Kunden unserer Kunden; Interessenten;
              Geschäfts- und Vertragspartner
            </li>

            <li class="mt-3"><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
              vertraglicher Pflichten; Sicherheitsmaßnahmen; Kontaktanfragen und Kommunikation; Büro- und
              Organisationsverfahren; Verwaltung und Beantwortung von Anfragen.
            </li>

            <br /><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6
            Abs. 1 S. 1 lit. b) DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO); Berechtigte
            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
          </ul>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>

          <ul class="mb-5">
            <li><p><strong>Kundenkonto:</strong> Vertragspartner können innerhalb unseres Onlineangebotes ein Konto anlegen
              (z. B. Kunden- bzw. Nutzerkonto, kurz "Kundenkonto"). Falls die Registrierung eines Kundenkontos
              erforderlich ist, werden Vertragspartner hierauf ebenso hingewiesen wie auf die für die Registrierung
              erforderlichen Angaben. Die Kundenkonten sind nicht öffentlich und können von Suchmaschinen nicht
              indexiert werden. Im Rahmen der Registrierung sowie anschließender Anmeldungen und Nutzungen des
              Kundenkontos speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die Registrierung
              nachweisen und etwaigem Missbrauch des Kundenkontos vorbeugen zu können.
            </p>

              <p>
                Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das Kundenkonto betreffenden Daten gelöscht,
                vorbehaltlich, deren Aufbewahrung ist aus gesetzlichen Gründen erforderlich. Es obliegt den Kunden, ihre
                Daten bei erfolgter Kündigung des Kundenkontos zu sichern;

                <br /><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
              </p>
            </li>
            <li class="mt-3"><strong>Shop und E-Commerce:</strong> Wir verarbeiten die Daten unserer Kunden und deren
              Kunden, um ihnen die Auswahl, den Erwerb, bzw. die Bestellung der gewählten Produkte, Waren sowie
              verbundener Leistungen, als auch deren Bezahlung und Zustellung, bzw. Ausführung zu ermöglichen. Für die
              Ausführung einer Bestellung sind unsere Kunden selbst verantwortlich, ebenso für die Abwicklung der
              Zahlungsvorgänge, die im Rahmen dieser Bestellungen vorzunehmen sind. Die erforderlichen Angaben sind als
              solche im Rahmen des Bestell- bzw. vergleichbaren Erwerbsvorgangs gekennzeichnet und umfassen die zur
              Auslieferung, bzw. Zurverfügungstellung und Abrechnung benötigten Angaben sowie Kontaktinformationen und
              ggf. Freigeber der Bestellung, damit unsere Kunden bei ihren Kunden etwaige Rücksprache halten können.

              <br /><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art.
              6 Abs. 1 S. 1 lit. b) DSGVO).
            </li>
            <li class="mt-3"><strong>Angebot von Software- und Plattformleistungen (Cloud, SaaS):</strong>
              Wir verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich
              als "Nutzer" bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf
              Grundlage berechtigter Interessen, um die Sicherheit unseres Angebotes gewährleisten und es
              weiterentwickeln zu können. Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell-
              bzw. vergleichbaren Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und
              Abrechnung benötigten Angaben sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können.

              <br /><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
              1 lit. b) DSGVO).
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="webhosting" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Bereitstellung des
          Onlineangebotes und Webhosting</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu
            diesem Zweck verarbeiten wir die IP-Adresse
            des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser oder das
            Endgerät der Nutzer zu übermitteln.</p>

          <ul class="mb-6">
            <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. besuchte Webseiten, Interesse an
              Inhalten, Zugriffszeiten); Meta-,
              Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
              Einwilligungsstatus).
            </li>
            <li class="mt-3"><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li class="mt-3"><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
              Nutzerfreundlichkeit; Informationstechnische Infrastruktur (Betrieb und Bereitstellung von
              Informationssystemen und technischen Geräten, bspw. Computer, Server etc.); Sicherheitsmaßnahmen.
            </li>
            <br /><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </ul>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>

          <ul class="mb-5">
            <li><strong>Bereitstellung Onlineangebot auf gemietetem Speicherplatz:</strong> Für die Bereitstellung
              unseres Onlineangebotes nutzen wir Speicherplatz, Rechenkapazität und Software, die wir von einem
              entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig beziehen.

              <br /><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </li>
            <li class="mt-3"><strong>Erhebung von Zugriffsdaten und Logfiles:</strong>
              Der Zugriff auf unser Onlineangebot wird in Form von so genannten "Server-Logfiles" protokolliert. Zu den
              Serverlogfiles können die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des
              Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das
              Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall IP-Adressen und der
              anfragende Provider gehören. Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt
              werden, z. B. um eine Überlastung der Server zu vermeiden (insbesondere im Fall von missbräuchlichen
              Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre Stabilität
              sicherzustellen; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
              DSGVO). <strong>Löschung von Daten:</strong> Logfile-Informationen werden für die Dauer von maximal 30
              Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere Aufbewahrung zu
              Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung
              ausgenommen.
            </li>
            <li class="mt-3"><strong>Google Cloud Plattform: </strong>Leistungen auf dem Gebiet der Bereitstellung von
              informationstechnischer Infrastruktur und
              verbundenen Dienstleistungen (Betrieb der Infrastruktur im Google-Rechenzentrum in Frankfurt/Main, Betrieb
              und Weiterentwicklung der Services für Google
              Workspace und Google Cloud Platform)<br/>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)<br/>
              <strong>Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
                Drittländern):</strong> Einbeziehung im Auftragsverarbeitungsvertrag<br/>
              <strong>Auftragsverarbeitungsvertrag: </strong><a
                  href="https://cloud.google.com/terms/data-processing-addendum" target="_blank">https://cloud.google.com/terms/data-processing-addendum</a><br/>
              <strong>Dienstanbieter: </strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland,
              Dachorganisation: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043. USA<br/>
              <strong>Website: </strong><a href="https://cloud.google.com/?hl=de" target="_blank">https://cloud.google.com/?hl=de</a><br/>
              <strong>Datenschutzerklärung: </strong><a href="https://cloud.google.com/terms/cloud-privacy-notice?hl=de"
                                                        target="_blank">https://cloud.google.com/terms/cloud-privacy-notice?hl=de</a>
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="apps" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Besondere Hinweise zu
          Applikationen (Apps)</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Wir verarbeiten die Daten der Nutzer unserer Applikation, soweit diese erforderlich sind, um den Nutzern
            die Applikation sowie deren Funktionalitäten bereitstellen, deren Sicherheit überwachen und sie
            weiterentwickeln zu können. Wir können ferner Nutzer unter Beachtung der gesetzlichen Vorgaben kontaktieren,
            sofern die Kommunikation zu Zwecken der Administration oder Nutzung der Applikation erforderlich ist. Im
            Übrigen verweisen wir im Hinblick auf die Verarbeitung der Daten der Nutzer auf die Datenschutzhinweise in
            dieser Datenschutzerklärung.</p>

          <p><strong>Rechtsgrundlagen:</strong> Die Verarbeitung von Daten, die für die Bereitstellung der
            Funktionalitäten der Applikation erforderlich ist, dient der Erfüllung von vertraglichen Pflichten. Dies
            gilt auch, wenn die Bereitstellung der Funktionen eine Berechtigung der Nutzer (z. B. Freigaben von
            Gerätefunktionen) voraussetzt. Sofern die Verarbeitung von Daten für die Bereitstellung der Funktionalitäten
            der Applikation nicht erforderlich ist, aber der Sicherheit der Applikation oder unseren
            betriebswirtschaftlichen Interessen dient (z. B. Erhebung von Daten zu Zwecken der Optimierung der
            Applikation oder Sicherheitszwecken), erfolgt sie auf Grundlage unserer berechtigten Interessen. Sofern
            Nutzer ausdrücklich deren Einwilligung in die Verarbeitung ihrer Daten gebeten werden, erfolgt die
            Verarbeitung der von der Einwilligung umfassten Daten auf Grundlage der Einwilligung.</p>

          <ul class="mb-6">
            <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen, Adressen); Meta-, Kommunikations-
              und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus);
              Zahlungsdaten (z. B. Bankverbindungen, Rechnungen, Zahlungshistorie); Vertragsdaten (z. B.
              Vertragsgegenstand, Laufzeit, Kundenkategorie).
            </li>
            <li class="mt-3"><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
              Onlinediensten); Kunden; Kunden von Kunden; Geschäfts- und Vertragspartner.
            </li>
            <li class="mt-3"><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
              vertraglicher Pflichten; Kundenservice.
            </li>
            <br /><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO);
            Vertragserfüllung und vorvertragliche Anfragen
            (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </ul>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>

          <ul class="mb-5">
            <li><strong>Kommerzielle Nutzung:</strong> Wir verarbeiten die Daten der Nutzer unserer Applikation,
              angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als "Nutzer" bezeichnet), um ihnen gegenüber
              unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen, um die
              Sicherheit unserer Applikation gewährleisten und sie weiterzuentwickeln zu können. Die erforderlichen
              Angaben sind als solche im Rahmen des Nutzungs-, Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses
              gekennzeichnet und können die zur Leistungserbringung und zur etwaigen Abrechnung benötigten Angaben sowie
              Kontaktinformationen, um etwaige Rücksprachen halten zu können, umfassen.

              <br/><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b) DSGVO).
            </li>

            <li><strong>Geräteberechtigungen für den Zugriff auf Funktionen und Daten (nur bei Buchung des Zusatzmoduls
              Artikel-Scan):</strong> Die Nutzung unserer Applikation oder ihrer Funktionalitäten kann Berechtigungen der
              Nutzer für Zugriff auf bestimmten Funktionen der verwendeten Geräte oder auf die, auf den Geräten
              gespeicherten oder mit Hilfe der Geräte zugänglichen Daten voraussetzen. Dies ist beispielsweise der Fall,
              wenn Sie den Warenkorb in der Anwendung mittels Barcodescanner befüllen. Standardmäßig müssen diese
              Berechtigungen von den Nutzern erteilt werden und können jederzeit in den Einstellungen der jeweiligen
              Geräte widerrufen werden. Das genaue Verfahren zur Kontrolle der App-Berechtigungen kann vom Gerät und der
              Software der Nutzer abhängig sein. Bei Erläuterungsbedarf können sich Nutzer an uns wenden. Wir weisen
              darauf hin, dass die Versagung oder Widerruf der jeweiligen Berechtigungen die Funktionsfähigkeit unsere
              Applikation beeinflussen kann.
            </li>

            <li><strong>MedishopWeasel (nur bei Buchung des Zusatzmoduls "Automatischer Import"):</strong> Im Rahmen der
              Nutzung unserer Applikation werden ABDA-Daten des Kunden durch den Zugriff auf den entsprechenden
              überwachten Ordner mit Hilfe einer lokal vom Kunden/Nutzer selbst zu installierenden Software
              ("MedishopWeasel") verarbeitet. Der Zugriff auf diese Daten erfolgt aufgrund vertraglicher Verpflichtungen
              nach Buchung des Zusatzmdolus "Automatischer Import" und kann vom Kunden einfach über Deinstallation der
              Software beendet werden. Die Verarbeitung der Daten dient jeweils nur der Bereitstellung der jeweiligen
              Funktionalität unserer Applikation, entsprechend ihrer Beschreibung gegenüber den Kunden und Nutzern bzw.
              ihrer typischen und erwartbaren Funktionsweise. Das Programm "MedishopWeasel" überwacht den Zugriff auf
              den vom Kunden/Nutzer angegebenen Ordner. Vom Kunden/Nutzer ist entsprechend nur das Verzeichnis
              anzugeben, in das ausschließlich jene Dateien abgelegt werden, welche zur Applikation übertragen werden
              sollen.

              <br/><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Vertragserfüllung
              und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="registration" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Registrierung,
          Anmeldung und Nutzerkonto</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern die erforderlichen
            Pflichtangaben mitgeteilt und zu Zwecken
            der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher Pflichterfüllung verarbeitet. Zu den
            verarbeiteten Daten gehören insbesondere die
            Login-Informationen (Nutzername, Passwort sowie eine E-Mail-Adresse).</p>

          <p>Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des
            Nutzerkontos speichern wir die IP-Adresse und den
            Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten
            Interessen als auch jener der Nutzer an einem
            Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
            grundsätzlich nicht, es sei denn, sie ist zur
            Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung hierzu.</p>

          <p>Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z. B. technische Änderungen,
            per E-Mail informiert werden.</p>

          <ul class="mb-6">
            <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen, Adressen); Kontaktdaten (z. B.
              E-Mail, Telefonnummern);
              Inhaltsdaten (z. B. Eingaben in Onlineformularen); Meta-, Kommunikations- und Verfahrensdaten (z. B.
              IP-Adressen, Zeitangaben, Identifikationsnummern,
              Einwilligungsstatus).
            </li>
            <li class="mt-3"><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li class="mt-3"><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
              vertraglicher Pflichten; Sicherheitsmaßnahmen; Verwaltung und Beantwortung von Anfragen; Bereitstellung
              unseres Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <br/><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
            lit. b) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </ul>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>

          <ul class="mb-6">
            <li><strong>Registrierung mit Pseudonymen:</strong> Nutzer dürfen statt Klarnamen Pseudonyme als Nutzernamen
              verwenden<br/>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
              b) DSGVO).
            </li>
            <li class="mt-3"><strong>Zwei-Faktor-Authentifizierung (optional):</strong> Die
              Zwei-Faktor-Authentifizierung bietet eine zusätzliche Sicherheitsebene für Ihr Benutzerkonto und stellt
              sicher, dass nur Sie auf Ihr Konto zugreifen können, auch, wenn jemand anderes Ihr Passwort kennt. Zu
              diesem Zweck müssen Sie zusätzlich zu Ihrem Passwort eine weitere Authentifizierungsmaßnahme durchführen
              (z. B. einen an ein mobiles Gerät gesandten Code eingeben). Wir werden Sie über das von uns eingesetzte
              Verfahren informieren.<br/>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
              b) DSGVO).
            </li>
            <li class="mt-3"><strong>Löschung von Daten nach Kündigung:</strong> Wenn Nutzer ihr Nutzerkonto gekündigt
              haben, werden deren Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer gesetzlichen Erlaubnis,
              Pflicht oder Einwilligung der Nutzer, binnen 4 Wochen gelöscht<br/>

              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
              b) DSGVO).
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="singleSignOn" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">
          Single-Sign-On-Anmeldung</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Als "Single-Sign-On" oder "Single-Sign-On-Anmeldung bzw. "-Authentifizierung" werden Verfahren bezeichnet,
            die es Nutzern erlauben, sich mit Hilfe eines Nutzerkontos bei einem Anbieter von Single-Sign-On-Verfahren
            (z. B. einem sozialen Netzwerk), auch bei unserem Onlineangebot, anzumelden. Voraussetzung der
            Single-Sign-On-Authentifizierung ist, dass die Nutzer bei dem jeweiligen Single-Sign-On-Anbieter registriert
            sind und die erforderlichen Zugangsdaten in dem dafür vorgesehenen Onlineformular eingeben, bzw. schon bei
            dem Single-Sign-On-Anbieter angemeldet sind und die Single-Sign-On-Anmeldung via Schaltfläche
            bestätigen.</p>

          <p>Die Authentifizierung erfolgt direkt bei dem jeweiligen Single-Sign-On-Anbieter. Im Rahmen einer solchen
            Authentifizierung erhalten wir eine Nutzer-ID mit der Information, dass der Nutzer unter dieser Nutzer-ID
            beim jeweiligen Single-Sign-On-Anbieter eingeloggt ist und eine für uns für andere Zwecke nicht weiter
            nutzbare ID (sog. "User Handle"). Ob uns zusätzliche Daten übermittelt werden, hängt allein von dem genutzten
            Single-Sign-On-Verfahren ab, von den gewählten Datenfreigaben im Rahmen der Authentifizierung und zudem
            davon, welche Daten Nutzer in den Privatsphäre- oder sonstigen Einstellungen des Nutzerkontos beim
            Single-Sign-On-Anbieter freigegeben haben. Es können je nach Single-Sign-On-Anbieter und der Wahl der Nutzer
            verschiedene Daten sein, in der Regel sind es die E-Mail-Adresse und der Benutzername. Das im Rahmen des
            Single-Sign-On-Verfahrens eingegebene Passwort bei dem Single-Sign-On-Anbieter ist für uns weder einsehbar,
            noch wird es von uns gespeichert.</p>

          <p>Die Nutzer werden gebeten, zu beachten, dass deren bei uns gespeicherte Angaben automatisch mit ihrem
            Nutzerkonto beim Single-Sign-On-Anbieter abgeglichen werden können, dies jedoch nicht immer möglich ist oder
            tatsächlich erfolgt. Ändern sich z. B. die E-Mail-Adressen der Nutzer, müssen sie diese manuell in ihrem
            Nutzerkonto bei uns ändern.</p>

          <p>Die Single-Sign-On-Anmeldung können wir, sofern mit den Nutzern vereinbart, im Rahmen der oder vor der
            Vertragserfüllung einsetzen, soweit die Nutzer darum gebeten wurden, im Rahmen einer Einwilligung
            verarbeiten und setzen sie ansonsten auf Grundlage der berechtigten Interessen unsererseits und der
            Interessen der Nutzer an einem effektiven und sicheren Anmeldesystem ein.</p>

          <p>Sollten Nutzer sich einmal entscheiden, die Verknüpfung ihres Nutzerkontos beim Single-Sign-On-Anbieter
            nicht mehr für das Single-Sign-On-Verfahren nutzen zu wollen, müssen sie diese Verbindung innerhalb ihres
            Nutzerkontos beim Single-Sign-On-Anbieter aufheben. Möchten Nutzer deren Daten bei uns löschen, müssen sie
            ihre Registrierung bei uns kündigen.</p>

          <ul class="mb-6">
            <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen, Adressen); Kontaktdaten (z. B.
              E-Mail, Telefonnummern); Nutzungsdaten
              (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
              Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, Einwilligungsstatus).
            </li>
            <li class="mt-3"><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von
              Onlinediensten).
            </li>
            <li class="mt-3"><strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Erfüllung
              vertraglicher Pflichten; Sicherheitsmaßnahmen; Anmeldeverfahren.
            </li>
            <li class="mt-3"><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
              DSGVO).
            </li>
          </ul>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>

          <ul class="mb-6">
            <li><strong>LxAuth:</strong> Authentifizierungsdienst von Litixsoft GmbH (Kontaktdaten siehe oben)<br/>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="requestManagment" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Kontakt- und
          Anfragenverwaltung</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular, E-Mail oder Telefon) sowie im Rahmen
            bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet, soweit
            dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>

          <ul class="mb-6">
            <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten (z.
              B. Eingaben in Onlineformularen); Nutzungsdaten
              (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-, Kommunikations- und
              Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, Einwilligungsstatus).
            </li>
            <li class="mt-3"><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
            <li class="mt-3"><strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und Kommunikation; Verwaltung und
              Beantwortung von Anfragen; Feedback
              (z. B. Sammeln von Feedback via Online-Formular); Bereitstellung unseres Onlineangebotes und
              Nutzerfreundlichkeit.
            </li>
            <li class="mt-3"><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
              DSGVO); Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </li>
          </ul>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>

          <ul class="mb-6">
            <li><strong>Kontaktformular:</strong> Wenn Nutzer über unser Kontaktformular, E-Mail oder andere
              Kommunikationswege mit uns in Kontakt treten,
              verarbeiten wir die uns in diesem Zusammenhang mitgeteilten Daten zur Bearbeitung des mitgeteilten
              Anliegens<br/>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
              b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </li>
            <li class="mt-3"><strong>Freshdesk:</strong> Management von Kontaktanfragen und Kommunikation mit
              Unterauftragnehmer Litixsoft GmbH<br/>
              <strong>Dienstanbieter:</strong> Freshworks, Inc., 2950 S.Delaware Street, Suite 201, San Mateo, CA 94403,
              USA<br/>
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
              b) DSGVO),
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)<br/>
              <strong>Website: </strong><a href="https://www.freshworks.com"
                                           target="_blank">https://www.freshworks.com</a><br/>
              <strong>Datenschutzerklärung: </strong><a href="https://www.freshworks.com/privacy" target="_blank">https://www.freshworks.com/privacy</a>
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="newsletter" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Newsletter und
          elektronische Benachrichtigungen</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend "Newsletter")
            nur mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung
            zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die Einwilligung der Nutzer
            maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.</p>

          <p>Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse
            angeben. Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder
            weitere Angaben, sofern diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.</p>

          <p><strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich in
            einem sogenannten Double-Opt-In-Verfahren. D. h., Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um
            die Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit
            fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den
            Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die
            Speicherung des Anmelde- und des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die
            Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.</p>

          <p><strong>Löschung und Einschränkung der Verarbeitung:</strong> Wir können die ausgetragenen E-Mail-Adressen
            bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen, um eine
            ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer
            möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern
            zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften
            Beachtung von Widersprüchen behalten wir uns die Speicherung der E-Mail-Adresse allein zu diesem Zweck in
            einer Sperrliste (sogenannte "Blocklist") vor.</p>

          <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu Zwecken
            des Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
            beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an einem effizienten und sicheren
            Versandsystem.</p>

          <p><strong>Inhalte:</strong></p>

          <p>Informationen zu neuen/geänderten Funktionalitäten bei Updates oder ggf. geplanter Nichtverfügbarkeit des
            Systems.</p>

          <ul class="mb-6">
            <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen, Adressen); Kontaktdaten (z. B.
              E-Mail, Telefonnummern);
              Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
              Einwilligungsstatus);
              Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
            </li>
            <li class="mt-3"><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
            <li class="mt-3"><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z. B. per E-Mail oder
              postalisch).
            </li>
            <li class="mt-3"><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO);
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
            </li>
            <li class="mt-3"><strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den Empfang unseres
              Newsletters jederzeit kündigen, d. h. Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang
              widersprechen. Einen Link zur Kündigung des Newsletters finden Sie entweder am Ende eines jeden
              Newsletters oder können sonst eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu
              nutzen.
            </li>
          </ul>

          <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten bei Unterauftragnehmer Litixsoft
            GmbH:</strong></p>

          <ul class="mb-6">
            <li><strong>Rapidmail:</strong> E-Mail-Marketing-Plattform<br/>
              <strong>Dienstanbieter:</strong> rapidmail GmbH, Augustinerplatz 2, 79098 Freiburg i.Br., Deutschland<br/>
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)<br/>
              <strong>Website: </strong><a href="https://www.rapidmail.de"
                                           target="_blank">https://www.rapidmail.de</a><br/>
              <strong>Datenschutzerklärung: </strong><a href="https://www.rapidmail.de/datenschutz" target="_blank">https://www.rapidmail.de/datenschutz</a><br/>
              <strong>Auftragsverarbeitungsvertrag: </strong><a
                  href="https://www.rapidmail.de/hilfe/datenschutzvertrag-nach-eu-dsgvo-abschliessen" target="_blank">https://www.rapidmail.de/hilfe/datenschutzvertrag-nach-eu-dsgvo-abschliessen</a>
            </li>
          </ul>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="changePrivacyPolicy" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">Änderung
          und Aktualisierung der Datenschutzerklärung</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
            Datenschutzerklärung an, sobald die Änderungen
            der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch
            die Änderungen eine Mitwirkungshandlung Ihrerseits
            (z. B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>

          <p>Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und
            Organisationen angeben, bitten wir zu beachten, dass die
            Adressen sich über die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.</p>

          <div class="flex mt-4">Für uns zuständige Aufsichtsbehörde: <br/>
            <div class="flex mt-2"> Sächsische Datenschutz- und Transparenzbeauftragte <br/>
              Postfach 11 01 32 <br/>
              01330 Dresden <br/>
              E-Mail: post@sdtb.sachsen.de <br/>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <v-container>
      <div class="px-5 grey--text text--darken-1">
        <h3 id="definitions" class="blue-grey lighten-5 pa-2 blue-grey--text text--darken-2 mb-1 mt-7">
          Begriffsdefinitionen</h3>
        <div class="layout-column grey lighten-5 py-4 px-6">
          <p>In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
            Begrifflichkeiten. Soweit die Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
            Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem Verständnis dienen..</p>

          <ul class="mb-6">
            <li><strong>Personenbezogene Daten:</strong> "Personenbezogene Daten" sind alle Informationen, die sich auf
              eine identifizierte oder identifizierbare natürliche Person (im Folgenden "betroffene Person") beziehen;
              als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels
              Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
              (z. B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck
              der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
              Identität dieser natürlichen Person sind.
            </li>
            <li class="mt-3"><strong>Verantwortlicher:</strong> Als "Verantwortlicher" wird die natürliche oder
              juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über
              die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
            </li>
            <li class="mt-3"><strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter
              Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
              Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten,
              das Speichern, das Übermitteln oder das Löschen.
            </li>
          </ul>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

import Vue from 'vue'

export default Vue.component('imprintContent', {})
</script>

<style scoped lang="scss">
p, li {
  text-align: justify;
}
</style>
