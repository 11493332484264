import axios from 'axios'
import lodash from 'lodash'
import router from '../../../router'
import moment from 'moment'

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SETACTIVE = 'SETACTIVE';
const SETIDLE = 'SETIDLE';
const UPDATE_USER = 'UPDATE_USER';
const LOCAL_STORE = process.env.VUE_APP_BASE_NAME + '-frontend';

let millisecondsBeginnIdle = 0;
let millisecondsAtRefreshtime = 0;
let repeatTime = 840000;
let intervalCounterTimer = null;

const state = {
  isLoggedIn: false,
  user: null,
  idleStatus: 'idle',
  showLoginPopup: false,
  remainingTime: 0,
  timeCounter: '0'       //gibt die ablaufende zeit an also z.b: 12min:44sec
};

const mutations = {
  [LOGIN](state, user) {
    state.isLoggedIn = true;
    state.user = user;
    state.showLoginPopup = false
  },
  [LOGOUT](state) {
    state.isLoggedIn = false;
    state.user = null;
    state.showLoginPopup = false
  },
  [SETACTIVE](state) {
    millisecondsBeginnIdle = 0;
    state.idleStatus = 'active'
  },
  [SETIDLE](state) {
    millisecondsBeginnIdle = (new Date()).getTime();
    state.idleStatus = 'idle'
  },
  [UPDATE_USER](state, user) {
    if (state.user && state.user.user) {
      if (user.first_name) {
        state.user.user.first_name = user.first_name
      }
      if (user.last_name) {
        state.user.user.last_name = user.last_name
      }
      if (user.email) {
        state.user.user.email = user.email
      }
      if (typeof user.is_active_2fa !== 'undefined') {
        state.user.is_active_2fa = user.is_active_2fa
      }

      // TODO O'Rly??????????????? Sven?

      let currentData = JSON.parse(window.sessionStorage.getItem(LOCAL_STORE))
      currentData.user = user
      window.sessionStorage.setItem(LOCAL_STORE, JSON.stringify(currentData));
    }
  }
};

/**
 * berechnet die Zeit vom abholen eines Tokens bis zum Refresh für ein neues token
 *
 * (darauf wird in "function checkRefresh()" geprüft ob man innerhalb dieser zeit noch aktiv war und ein neues
 * token bekommen darf oder ob man zu lange inaktiv war und sich neu anmelden muss)
 *
 * **/
function calculateRepeatTime(expireDate) {

  let begin = moment(new Date());
  let end = moment(expireDate);
  let duration = moment.duration(end.diff(begin));
  let calcRepeatTime = duration.asMilliseconds();

  return calcRepeatTime
}

/**
 * wird zum login oder initauth aufgerufen und startet ein intervall "intervalCounterTimer" das sich dauerhaft alle 1000ms wiederholt
 * jede sekunde wird dann gerpüft ob man noch innerhalb der repeattime ist (normal 15min) oder ob der sessionkey noch da ist
 * falls nein kommt sofort das login popup
 *
 * wenn man 30sekunde vor dem ablaufen ist und sonst alles stimmt wird intervall "intervalCounterTimer" gekillt und checkinterval() aufgerufen
 * **/
function repeatCounter() {
  clearInterval(intervalCounterTimer);

  /**
   * setIntervale erzeugt ein ewig laufenden Intervall ähnlich einer while schleife
   * **/
  intervalCounterTimer = setInterval(() => {
    // let remaining = Math.floor(calculateRepeatTime(state.user.tokenExpire) * 0.0365)
    let remaining = calculateRepeatTime(state.user.tokenExpire)
    // state.timeCounter = moment(new Date()).format('HH:mm') + ' Uhr | ' + moment.utc(remaining).format('mm:ss') + ' min:sek'

    state.timeCounter = {
      now:  moment(new Date()).format('HH:mm'),
      later:  moment(state.user.tokenExpire).format('HH:mm'),
      timer: {
        min: moment.utc(remaining).format('mm'),
        sec: moment.utc(remaining).format('ss')
      }
    }

    const user = JSON.parse(window.sessionStorage.getItem(LOCAL_STORE))

    if (remaining < 30000 && remaining > 0){  //30000ms als Puffer vor expire
      clearInterval(intervalCounterTimer)
      checkRefresh()
    } else if(!user || remaining <= 0) {
      clearInterval(intervalCounterTimer)
      state.showLoginPopup = true
    }
  }, 1000)
}

/**
 *
 * prüft ob man angemeldet ist und ob man noch refreshen darf, wenn nein dann sofort zum lopgin popup
 * wenn ja dann axios aufruf zum refresh, neue repeatTime berechnen und repeatCounter() wieder aufrufen
 * damit intervall wieder jede sekunde läuft
 *
 * **/
function checkRefresh() {

    millisecondsAtRefreshtime = (new Date()).getTime()

    if (state.user) {
      if (millisecondsAtRefreshtime - millisecondsBeginnIdle < repeatTime || millisecondsBeginnIdle === 0) {
        // axios.get(process.env.VUE_APP_BASE_AUTH + '/refresh')
        axios.get(state.user.refreshRoute)
          .then(result => {
            // let tempUser = state.user
            state.user.token = result.data.token
            state.user.tokenExpire = result.data.token_expire
            window.sessionStorage.setItem(LOCAL_STORE, JSON.stringify(state.user))

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token

            repeatTime = calculateRepeatTime(state.user.tokenExpire)

            repeatCounter()

          }).catch(() => {
          state.showLoginPopup = true
        })
      } else {
        state.showLoginPopup = true
      }
    }
}

function logout(auth_system, reloadPage) {
  if (state.user) {
    // axios.get(process.env.VUE_APP_BASE_AUTH + '/logout')
    axios.get(state.user.logoutRoute)
      .then(result => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.data.token
        // TODO SET LOCATION
        //axios.defaults.headers.common['LocationId'] = '5e96d56957587e0bd73ee89c'
      }).catch(error => {
      console.error('Error logout: ', error)
    })
  }

  // remove user from local session storage
  window.sessionStorage.removeItem(LOCAL_STORE);
  // remove token from local session storage
  // window.sessionStorage.removeItem(axios.defaults.headers.common['client-id'].toString())

  // Set header
  axios.defaults.headers.common['Authorization'] = 'null';
  delete axios.defaults.headers.common['Auth-Domain']

  clearInterval(intervalCounterTimer);

  if (!window.location.pathname.startsWith('/auth/')) {
    if(auth_system && auth_system === 'median'){
      router.push('/auth/autologout')
    } else if(reloadPage) {
      setTimeout(()=>{
        location.reload()
      },5000)
    } else {
      router.push('/auth/login')
    }
  }
}

const actions = {
  /**
   * setzt den Idle status auf true, also wenn man 5sec. lang nix mehr gemacht hat
   */
  setIdle({commit}) {
    commit(SETIDLE)
  },
  /**
   * setzt den active status auf true, also wenn man nach einer idle phase wieder etwas macht in der app
   */
  setActive({commit}) {
    commit(SETACTIVE)
  },
  /**
   * initAuth wird in der index.js des auth plugins im created aufgerufen und damit einmal zur Erstelllung der App
   * bei strg + r oder RefreshButton des Browser wird es somit ebenfalls aufgerufen
   */
  initAuth({commit}) {
    /**
     * hole ganzes user objekt aus dem Session Store
     */
    const user = JSON.parse(window.sessionStorage.getItem(LOCAL_STORE));
    /**
     * hole token objekt aus dem Session Store
     */
    // const token = JSON.parse(window.localStorage.getItem(axios.defaults.headers.common['client-id'].toString()))

    /**
     * falls user und token noch existieren kann geprüft werden ob sie noch gültig sind
     * falls ja wird man automatisch angemedelt, falls nein zum Login geleitet
     */
    if (user) {
      /**
       * setze den token als header für die axios aufrufe
       */
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
      // TODO SET LOCATION
      axios.defaults.headers.common['Auth-Domain'] = (user.user ||{}).location_id

      /**
       * falls es einen user und tokenExpire date gibt, dann berechne die repeatTime, diese entspricht der Laufzeit des Repeat Zyklus
       * anschließend wird der Counter aufgerufen der die Zeit runter zählt
       */
      if (repeatTime > 0 && !intervalCounterTimer && user.tokenExpire) {
        repeatTime = calculateRepeatTime(user.tokenExpire);
        repeatCounter();
        commit(LOGIN, user)
      } else {
        logout();
        router.push('/auth/login');
        commit(LOGOUT)
        // commit(LOGIN, user)
      }
    } else {
      logout();
      commit(LOGOUT)
    }
  },
  login({commit}, obj) {
    // Vue.Messaging.Init();
    let user = obj.data; // gesamtes Userobjekt (token, acl, user, routes)
    let type = obj.type; // login oder relogin

    if (user) {
      // TODO: remove
      if (user.user.location_id === '000000000000000000000000') {
        user.user.location_id = '*'
      }

      // let localToken = {
      //   token: user.token,
      //   tokenExpire: user.tokenExpire
      // }
      /**
       * speichere token und tokenexpirer im lokalen session store mit client id als key name
       * dadurch wird user für alle anwendungen mit der gleichen client id freigeschaltet so lange er aktiv ist
       * **/
      // window.localStorage.setItem(axios.defaults.headers.common['client-id'].toString(), JSON.stringify(localToken))

      // let policies = [];
      //
      // if (user.acl.hasOwnProperty('perm') && Array.isArray(user.acl.perm)) {
      //   user.acl.perm.forEach((v) => {
      //     if (v && typeof v.obj === 'string') {
      //       policies.push({
      //         action: v.obj.replace('*', '.*')
      //       })
      //     }
      //   });
      //   user.policies = policies
      // }

      /**
       * speichere ganzes user objekt (user, acl, routes, token) im lokalen session store
       * **/
      window.sessionStorage.setItem(LOCAL_STORE, JSON.stringify(user));

      /**
       * setze den token als header für die axios aufrufe
       */
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
      axios.defaults.headers.common['Auth-Domain'] = (user.user ||{}).location_id

      /**
       * falls es einen user und tokenExpire date gibt, dann berechne die repeatTime, diese entspricht der Laufzeit des Repeat Zyklus
       * anschließend wird der Counter aufgerufen der die Zeit runter zählt
       */
      if (user && user.tokenExpire) {
        // state.expireDate = user.tokenExpire
        repeatTime = calculateRepeatTime(user.tokenExpire);
        repeatCounter()
      }

      //
      // /**
      //  * starte den Repeat Zyklus
      //  */
      // repeat()

      /**
       * ändere noch die notwendigen Stati über die Mutation Login Siehe Zeile 24
       * state.isLoggedIn = true
       * state.user = user
       * state.showLoginPopup = false
       */
      commit(LOGIN, user);

      /**
       * abschließend rufe die Standard Route auf, allerdings nur wenn es sich um einen Initial Login handelt
       * und nicht beim Relogin über das Popup
       */
      if (type === 'login') {
        if(user.user.is_system){
          router.push('/administration/overview')
        } else {
          router.push('/')
        }
      } else if(type === 'medianLogin') {
        router.push('/shopping/' + user.departmentID + '/details')
      }
    }
  },
  updateLocation({commit}, obj) {
    let user = obj; // gesamtes Userobjekt (token, acl, user, routes)

    if (user) {
      // TODO: remove
      if (user.user.location_id === '000000000000000000000000') {
        user.user.location_id = '*'
      }

      /**
       * speichere ganzes user objekt (user, acl, routes, token) im lokalen session store
       * **/
      const currentUser = JSON.parse(window.sessionStorage.getItem(LOCAL_STORE))

      let newUser = {
        ...currentUser,
        ...user
      }

      window.sessionStorage.setItem(LOCAL_STORE, JSON.stringify(newUser))

      /**
       * ändere noch die notwendigen Stati über die Mutation Login Siehe Zeile 24
       * state.isLoggedIn = true
       * state.user = user
       * state.showLoginPopup = false
       */
      commit(LOGIN, user);

      /**
       * abschließend rufe die Standard Route auf, allerdings nur wenn es sich um einen Initial Login handelt
       * und nicht beim Relogin über das Popup
       */
    }
  },
  logout({commit}, reloadPage) {
    logout(state.user.auth_system_name, reloadPage);

    // Change state
    commit(LOGOUT)
  },
  updateUser({commit}, user) {
    // Change state
    commit(UPDATE_USER, user)
  }
};

const getters = {
  idleStatus: state => {
    return state.idleStatus
  },
  repeatCounterTime: state => {
    return state.timeCounter
  },
  showLoginPopup: state => {
    return state.showLoginPopup
  },
  isLoggedIn: state => {
    return state.isLoggedIn
  },
  // getAuthUserPolicies: state => {
  //   // return state.user.policies || []
  //   return state.user.acl.perm || []
  // },
  getAuthUserData: state => {
    return state.user
  },
  getAuthUserName: state => {
    let userstring = '';
    if (state.user && state.user.user && state.user.user.first_name) {
      userstring += state.user.user.first_name
    }
    if (state.user && state.user.user && state.user.user.last_name) {
      userstring = userstring + ' ' + state.user.user.last_name
    }
    if (state.user && !state.user.user.first_name && !state.user.user.last_name && state.user.user.email) {
      userstring += state.user.user.email
    }
    return userstring
  },
  getAuthUserTitle: state => {
    if (state.user && state.user.user) {
      return state.user.user.title
    }
  },
  getCurrentLocationId: state => {
    if (state.user && state.user.user) {
      return state.user.user.location_id
    }

    return null
  },
  userIsAdmin: state => {
    if (state.user && state.user.user) {
      return state.user.user.is_system
    }
  },
  userIsSystemadmin: state => {
    const userData = state.user || { acl: {}, user: {} }
    const locationId = userData.user?.location_id
    const domains = userData.acl?.domains_roles_acl || {}
    const roles = Object.keys(domains[locationId] || {})

    return lodash.includes(roles, 'root')
  },
  getUserHasMultiLocations: state => {
    return state.user?.user?.has_multi_location === true
  },
  getLocationSubType: state => {
    return state.user?.user.location_sub_type
  }
  // userIsPhysicianOrEmployee: state => {
  //   const userData = state.user || { acl: {} }
  //   return lodash.includes(userData.acl.roles, 'physician') || lodash.includes(userData.acl.roles, 'employee')
  // },
  // userIsPhysician: state => {
  //   const userData = state.user || { acl: {} }
  //   return lodash.includes(userData.acl.roles, 'physician')
  // }
};
export default {
  state,
  mutations,
  actions,
  getters
}
