<template>
  <v-navigation-drawer
    v-model="getMessagingSidebarVisibility"
    fixed
    temporary
    right
    width="500"
    class="elevation-12 lx-options layout-column"
    style="background-color: #546e7a7a !important;border-color: #546e7a !important;"
  >
    <v-card class="pa-3 elevation-0 transparent layout-column"
            style="height:100%;min-height:100%;max-height:100%;">

      <!--            <v-alert type="info" prominent class="white&#45;&#45;text ma-0 body-2" border="left" dismissible-->
      <!--                     v-show="BrowserNotificationPermission === 'default'">-->
      <!--                Sie möchten Benachrichtigungen direkt auf Ihrem Desktop erhalten, dann klicken Sie <strong-->
      <!--                    style="text-decoration: underline;cursor:pointer;" @click="claimPermission">hier</strong> um diese-->
      <!--                zu aktivieren!-->
      <!--            </v-alert>-->

      <!--            <v-alert type="warning" border="left" prominent dismissible class="ma-0"-->
      <!--                     v-show="BrowserNotificationPermission === 'denied'">-->
      <!--                Die Push-Mitteilungen in Ihrem Browser sind für diese Applikation deaktiviert wurden.-->
      <!--            </v-alert>-->

      <div class="layout-column flex" style="border-radius:5px;"
           :style="{background: compColor.lxExpansion.background}">
        <div class="layout-row pa-2">
          <div class="layout-align-start-center layout-column">
            <div style="border-radius:2px;width:40px;height:40px;"
                 :style="{'background': compColor.isInDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'}"
                 class="mr-2 layout-column layout-align-center-center">
              <v-icon :color="compColor.isInDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                      style="margin:0;">mdi-message-processing
              </v-icon>
            </div>
          </div>
          <div class="flex layout-column layout-align-center-start blue-grey--text text--darken-2">
            <div class="subtitle-1 font-weight-light"
                 v-bind:class="compColor.isInDarkMode ? compColor.primeHighlightClass : compColor.primeClass"
                 style="lineHeight:15px">
              <div class="layout-row layout-align-start-center">
                <span class="mr-3">Mitteilungen</span>
              </div>
            </div>
            <div class="caption font-weight-light" v-bind:class="compColor.widget.subHeadline">
              Systemnachrichten, Bestellinfos und mehr...
            </div>
          </div>
<!--          <div class="layout-column layout-align-center-center">-->
<!--            <v-btn text icon color="blue-grey" @click="getMessagingSettingsVisibility = true">-->
<!--              <v-icon size="18">mdi-cog</v-icon>-->
<!--            </v-btn>-->
<!--          </div>-->
        </div>
        <v-divider :style="{'border-color': compColor.lightDivider}"
                   v-show="BrowserNotificationPermission === 'denied'"></v-divider>
        <div class="pa-2" v-show="BrowserNotificationPermission === 'denied'">
          <v-alert type="warning" border="left" prominent dismissible class="ma-0">
            Die Push-Mitteilungen in Ihrem Browser sind für diese Applikation deaktiviert.
          </v-alert>
        </div>
        <v-divider :style="{'border-color': compColor.lightDivider}"></v-divider>
        <div class="px-2 py-1 layout-row">
          <v-btn small @click="removeAllMessages" :color="compColor.prime" elevation="0" class="text-none white--text">
            Alle Nachrichten löschen
          </v-btn>
          <div class="flex"></div>
          <span class="mr-2 blue-grey--text  body-2"><span class="font-weight-black">{{ getMessages.length }}</span> Nachrichten</span>
        </div>
        <v-divider :style="{'border-color': compColor.lightDivider}"></v-divider>
        <div class="flex pa-2 layout-column" style="overflow-y: auto;">
          <div v-for="(message, index) in getMessages" :key="index"
               v-bind:class="{'orange': message.type === 'system', 'blue-grey': message.type !== 'system', 'darken-3': isDarkMode && message.type !== 'system' }"
               class="lighten-4 elevation-0 layout-row mb-2"
               style="border-radius: 5px;">
            <div class="layout-column layout-align-center-center">
              <v-avatar tile height="40" width="40"
                        style="max-width: 40px !important;width:40px;">
                <v-icon color="blue-grey lighten-3" v-show="message.type !== 'system'">mdi-message-processing</v-icon>
                <v-icon color="orange lighten-2" v-show="message.type === 'system'">mdi-message-cog</v-icon>
              </v-avatar>
            </div>
            <v-divider vertical style="opacity: 0.5;"></v-divider>
            <div class="flex layout-column">
              <div class="flex layout-row">
                <div class="flex px-3 pt-2 pb-0" style="overflow-y: hidden">
                  <h4 class="orange--text text--darken-2" v-show="message.type === 'system'">Systemnachricht</h4>

                  <!-- Location info -->
                  <div class="layout-row layout-align-start-center mb-2 red--text text--lighten-2" v-show="showLocationName && message.location && message.location.name">
                    <div><small class="caption font-weight-bold">Standort "{{ message.location.name }}"</small></div>
                  </div>

                  <p style="white-space: pre-wrap;" class="body-2 ma-0"
                     :class="{'orange--text text--darken-3': message.type === 'system','blue-grey--text text--lighten-4': message.type !== 'system' && isDarkMode, 'blue-grey--text text--darken-2': message.type !== 'system' && !isDarkMode}"
                     v-text="getMessageText(message)">
                  </p>
                </div>
              </div>
              <div class="layout-row layout-align-start-center px-3"
                   v-bind:class="{'orange--text text--darken-1': message.type === 'system', 'blue-grey--text text--lighten-1': message.type !== 'system'}">
                <v-tooltip bottom open-delay="550">
                  <template v-slot:activator="{ on }">

                    <div v-on="on">
                      <div v-if="!message.expiry"><small
                        class="caption">{{ getTimespan(message.timestamp) }}</small></div>
                      <div v-else><small class="caption">läuft ab {{ getTimespan(message.expiry) }}</small>
                      </div>
                    </div>
                  </template>
                  <span>{{ message.timestamp | formatDate('DD.MM.YYYY hh:mm') }}Uhr</span>
                </v-tooltip>
              </div>
            </div>
            <v-divider vertical style="opacity: 0.5;"></v-divider>
            <div class="layout-column layout-align-center-center" style="min-width:40px;">
              <v-tooltip bottom open-delay="550">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text icon color="blue-grey" @click="removeMessage(message._id)"
                         v-show="message.type !== 'system'">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                  <v-btn v-on="on" text icon color="blue-grey" @click="confirmMessage(message)"
                         v-show="message.type === 'system' && (message.confirmed !== true)">
                    <v-icon>visibility_off</v-icon>
                  </v-btn>
                </template>
                <span>Nachricht löschen</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <v-divider :style="{'border-color': compColor.lightDivider}"
                   v-show="BrowserNotificationPermission === 'default'"></v-divider>
        <div class="pa-2" v-show="BrowserNotificationPermission === 'default'">
          <v-alert type="info" prominent class="white--text ma-0 body-2" border="left" dismissible>
            Sie möchten Benachrichtigungen direkt auf Ihrem Desktop erhalten, dann klicken Sie <strong
            style="text-decoration: underline;cursor:pointer;" @click="claimPermission">hier</strong> um diese
            zu aktivieren!
          </v-alert>
        </div>
      </div>
<!--      <div v-else class="layout-column flex" style="border-radius:5px;" :style="{background: compColor.lxExpansion.background}">-->
<!--        <div class="layout-row pa-2">-->
<!--          <div class="layout-align-start-center layout-column">-->
<!--            <div style="border-radius:2px;width:40px;height:40px;"-->
<!--                 :style="{'background': compColor.isInDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'}"-->
<!--                 class="mr-2 layout-column layout-align-center-center">-->
<!--              <v-icon :color="compColor.isInDarkMode ? compColor.primeHighlightColor : compColor.primeColor"-->
<!--                      style="margin:0;">mdi-message-processing-->
<!--              </v-icon>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="flex layout-column layout-align-center-start blue-grey&#45;&#45;text text&#45;&#45;darken-2">-->
<!--            <div class="subtitle-1 font-weight-light"-->
<!--                 v-bind:class="compColor.isInDarkMode ? compColor.primeHighlightClass : compColor.primeClass"-->
<!--                 style="lineHeight:15px">-->
<!--              <div class="layout-row layout-align-start-center">-->
<!--                <span class="mr-3">Systemnachricht erstellen</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="caption font-weight-light" v-bind:class="compColor.widget.subHeadline">-->
<!--              Wartungsarbeiten, Veröffentlichungen etc.-->
<!--            </div>-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="layout-column layout-align-center-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <v-btn text icon color="blue-grey" @click="getMessagingSettingsVisibility = true">&ndash;&gt;-->
<!--&lt;!&ndash;              <v-icon size="18">mdi-cog</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;            </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <v-divider :style="{'border-color': compColor.lightDivider}"></v-divider>-->
<!--        <div class="flex pa-2 layout-column" style="overflow-y: auto;">-->

<!--            <div>-->
<!--              <v-textarea-->
<!--                :dark="compColor.isInDarkMode"-->
<!--                :color="compColor.primeColor"-->
<!--                outlined-->
<!--                :dense="denseFields"-->
<!--                hide-details-->
<!--                label="Mitteilung"-->
<!--                placeholder=" "-->
<!--                v-model="messageData.text"-->
<!--                class="mt-2"-->
<!--              ></v-textarea>-->
<!--            </div>-->
<!--            <div class="pt-3 px-0 ma-0">-->
<!--              <lx-datepicker-->
<!--                :color="compColor.textInput"-->
<!--                name="date"-->
<!--                label="Ablaufdatum"-->
<!--                hide-details-->
<!--                placeholder=" "-->
<!--                class="mr-1"-->
<!--                v-model="messageData.expiry"-->
<!--              ></lx-datepicker>-->
<!--            </div>-->
<!--            <div class="pt-3 px-0 ma-0">-->
<!--              <v-dialog-->
<!--                ref="dialog"-->
<!--                v-model="modalTimepicker"-->
<!--                :return-value.sync="time"-->
<!--                persistent-->
<!--                width="290px"-->
<!--              >-->
<!--                <template v-slot:activator="{ on }">-->
<!--                  <v-text-field-->
<!--                    v-model="time"-->
<!--                    label="Ablaufzeit"-->
<!--                    placeholder=" "-->
<!--                    outlined-->
<!--                    append-icon="access_time"-->
<!--                    :disabled="!messageData.expiry"-->
<!--                    v-on="on"-->
<!--                  ></v-text-field>-->
<!--                </template>-->
<!--                <v-time-picker-->
<!--                  v-if="modalTimepicker"-->
<!--                  v-model="time"-->
<!--                  full-width-->
<!--                  format="24hr"-->
<!--                >-->
<!--                  <v-spacer></v-spacer>-->
<!--                  <v-btn text color="primary" @click="modalTimepicker = false">Cancel</v-btn>-->
<!--                  <v-btn text color="primary" @click="$refs.dialog.save(time)">OK</v-btn>-->
<!--                </v-time-picker>-->
<!--              </v-dialog>-->
<!--            </div>-->
<!--            <div>-->
<!--              <v-btn-->
<!--                block-->
<!--                :dark="compColor.isInDarkMode"-->
<!--                :color="compColor.primeColor"-->
<!--                :disabled="isLoading || !messageData.text"-->
<!--                class="white&#45;&#45;text text-none"-->
<!--                @click="postSystemMessage">-->
<!--                Nachricht absenden-->
<!--              </v-btn>-->
<!--            </div>-->

<!--        </div>-->
<!--      </div>-->
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import moment from 'moment'
import 'moment/locale/de'

export default {
  name: 'LxMessagingSidebar',
  data() {
    return {
      panel: [0],
      sticky: true,
      // adminPanelVisible: false,
      disableMessaging: false,
      messageData: {},
      isLoading: false,
      modalTimepicker: false,
      time: null,
      showLocationName: false,
    }
  },
  beforeMount() {
    // this.adminPanelVisible = (this.getUser() || {}).is_system === true;
    this.disableMessaging = (this.getUser() || {}).is_system === true
  },
  mounted() {
    this.showLocationName = this.authStore.getters.getUserHasMultiLocations
  },
  methods: {
    openMessagesSettings() {
      this.Messaging.dispatch('setSideBarVisibility', false);
      this.Messaging.dispatch('setSettingsVisibility', true);
    },
    postSystemMessage() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.$http.post('/messaging/system', { message: this.messageData, expiry: this.messageData.expiry})
        .then(() => {
          this.messageData = {};
          this.time = null
        })
        .finally(() => {

          this.isLoading = false;
        })
    },
    removeMessage(e) {
      this.DeleteMessage(e);
    },
    removeAllMessages() {
      this.DeleteAllMessages()
    },
    getTimespan(timestamp) {
      return moment(timestamp).fromNow();
    },
    confirmMessage(msg) {
      msg.confirmed = true
      this.ConfirmMessage(msg._id)
    },
    claimPermission() {
      let self = this;
      Notification.requestPermission(function (permission) {
        self.BrowserNotificationPermission = permission; // Update Store

        if (permission === 'granted') {
          return self.NotifySystem('Pushmitteilungen erfolgreich eingerichtet.')
        }

        self.NotifySystem({
          text: 'Pushmitteilungen Berechtigung abgelehnt',
          type: 'error'
        });
      });
    },
    getMessageText(message) {
      if (message.message.translatable) {
        return this.$t('MESSAGING.' + message.message.text, message.message.data || {})
      }

      return message.message.text
    }
  },
  watch: {
    time(newValue) {
      if (newValue) {
        let timeSplit = newValue.split(':');
        if (typeof this.messageData.expiry === 'object') {
          this.messageData.expiry.set('hour', timeSplit[0]);
          this.messageData.expiry.set('minute', timeSplit[1]);
        }
      }
    }
  },
  computed: {
    getMessages() {
      return this.Messaging.getters.getMessages;
    }
  }
}
</script>

<style scoped>

</style>
