<template>

  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="AdvertisementDetailsForm" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="back()"
                    icon="mdi-chevron-left"
                    :hide-label="true"
                    :tooltip="$t('BUTTONS.BACK.TOOLTIP')"
                    :label="$t('BUTTONS.BACK.TITLE')"></lx-btn>

            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!hasChanged && pristine || !valid "
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('BUTTONS.SAVE.TOOLTIP')"
                    :label="$t('BUTTONS.SAVE.TITLE')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!hasChanged && pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="resetModel(reset)"
                    icon="cancel"
                    :tooltip="$t('BUTTONS.REVERT.TOOLTIP')"
                    :label="$t('BUTTONS.REVERT.TITLE')"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-email-newsletter"
                                         :main-headline="$t('PANELS.ADVERTISEMENT.PREVIEW.HEADLINE')"
                                         :sub-headline="$t('PANELS.ADVERTISEMENT.PREVIEW.SUBHEADING')">
              </lx-expansion-panel-header>

              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">

                  <div class="flex-100">
                    <div class="layout-column flex">
                      <div class="layout-row layout-wrap flex px-3 pb-2">
                        <div class="layout-column flex">
                          <advertisement-item-widget
                              :headline="model.headline"
                              :color="model.color"
                              :content="model.content"
                              :creation_date="model.creation_date"
                              :offer_price="model.offer_price"
                              :regular_price="model.regular_price"
                              :file="model.file_info"
                          ></advertisement-item-widget>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel disabled>
              <lx-expansion-panel-header icon="mdi-email-newsletter"
                                         :main-headline="$t('PANELS.ADVERTISEMENT.EDITOR.HEADLINE')"
                                         :sub-headline="$t('PANELS.ADVERTISEMENT.EDITOR.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>

                <div class="layout-row layout-wrap mt-3 pa-2">

                  <div class="flex-100 layout-row">
                    <div class="flex pa-2">
                      <ValidationProvider ref="Nachrichtenüberschrift" name="headline"
                                          rules="required|max:80" v-slot="{ errors }">
                        <v-text-field
                            :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                            :dense="denseFields"
                            :label="$t('INPUTS.TITLES.ADVERTISEMENT.HEADLINE') + ' *'"
                            placeholder=" "
                            outlined
                            clearable
                            :error-messages="errors[0]"
                            v-model="model.headline"
                            class="mb-0"
                        >
                          <template #append v-if="!isDarkMode">
                            <v-icon color="grey lighten-2">assignment</v-icon>
                          </template>
                        </v-text-field>
                      </ValidationProvider>
                    </div>

                    <div class="pa-2" style="width: 310px; max-width: 310px">
                      <v-menu offset-y max-width="300">
                        <template v-slot:activator="{ on }">
                          <v-btn
                              :color="'#353a47'"
                              :height="denseFields ? '36' : '40'"
                              v-on="on"
                              block
                              elevation="0"
                              class="white--text text-none mt-1"
                          >
                            <template #default>
                              <div class="layout-row py-4" style="width:100%;">
                                <div
                                    class="flex layout-row layout-align-start-center pl-2"
                                    :style="getStyle"
                                >{{ $t('INPUTS.TITLES.ACCENT_COLOR') }}
                                </div>
                              </div>
                            </template>
                          </v-btn>
                        </template>

                        <v-color-picker
                            :dark="compColor.isInDarkMode"
                            v-model="colorValue"
                            hide-canvas
                            hide-mode-switch
                            hide-sliders
                            hide-inputs
                            mode="hexa"
                            show-swatches
                            swatches-max-height="200"

                        ></v-color-picker>
                      </v-menu>
                    </div>
                  </div>

                  <div class="flex-100 layout-row">
                    <div class="flex pa-2">
                      <ValidationProvider ref="offer_price" name="offer_price" vid="offer_price"
                                          rules="decimal" v-slot="{ errors }">
                        <lx-currency-input
                            :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                            :dense="denseFields"
                            :label="$t('INPUTS.TITLES.ADVERTISEMENT.OFFER_PRICE')"
                            :error-messages="errors[0]"
                            prefix="€"
                            placeholder=" "
                            outlined
                            clearable
                            v-model="model.offer_price"
                            class="mb-0"
                        >
                          <template #append v-if="!isDarkMode">
                            <v-icon color="grey lighten-2">money</v-icon>
                          </template>
                        </lx-currency-input>
                      </ValidationProvider>
                    </div>

                    <div class="flex pa-2">
                      <ValidationProvider ref="regular_price" name="regular_price" vid="regular_price"
                                          rules="decimal|lower_than:offer_price" v-slot="{ errors }">
                        <lx-currency-input
                            :dark="compColor.isInDarkMode"
                            :color="compColor.primeColor"
                            :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                            :dense="denseFields"
                            :label="$t('INPUTS.TITLES.ADVERTISEMENT.REGULAR_PRICE')"
                            :error-messages="errors[0]"
                            prefix="€"
                            placeholder=" "
                            clearable
                            outlined
                            v-model="model.regular_price"
                            class="mb-0"
                        >
                          <template #append v-if="!isDarkMode">
                            <v-icon color="grey lighten-2">money</v-icon>
                          </template>
                        </lx-currency-input>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="flex-100 pa-2">
                    <ValidationProvider id="content" name="Nachrichtentext" rules="required|max:1024"
                                        v-slot="{ errors }">
                      <v-textarea
                          outlined
                          clearable
                          no-resize
                          height="auto"
                          rows="4"
                          row-height="30"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          :label="$t('INPUTS.TITLES.ADVERTISEMENT.CONTENT') + ' *'"
                          name="input-7-4"
                          :error-messages="errors[0]"
                          v-model="model.content"
                          :dark="compColor.isInDarkMode"
                          :color="compColor.primeColor"
                          class="mb-0"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">comment</v-icon>
                        </template>
                      </v-textarea>
                    </ValidationProvider>
                  </div>

                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('INPUTS.TITLES.ADVERTISEMENT.DURATION')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                      <div class="layout-column flex pa-2 pt-5">

                        <div class="layout-row layout-wrap flex px-3 pb-2">
                          <div class="layout-column flex mx-4">
                            <validation-provider tag="div"
                                                 ref="af_from"
                                                 name="af_from"
                                                 rules="date_must_be_lesser_than:@af_to"
                                                 v-slot="{ errors }">
                              <lx-date-time-picker
                                  v-model="model.creation_date"
                                  :dark="compColor.isInDarkMode"
                                  :color="compColor.primeColor"
                                  :label="$t('INPUTS.TITLES.RELEASE_DATE')"
                                  :errors="errors[0]"
                                  :disabled="!!advertisement_id"
                                  :outlined="true"
                                  clearable
                              ></lx-date-time-picker>
                            </validation-provider>
                          </div>

                          <div class="layout-column flex mx-4">
                            <validation-provider tag="div"
                                                 ref="af_to"
                                                 name="af_to"
                                                 rules="date_must_in_future|date_must_be_greater_than:@af_from"
                                                 v-slot="{ errors }">
                              <lx-date-time-picker
                                  v-model="model.expiry_date"
                                  :dark="compColor.isInDarkMode"
                                  :color="compColor.primeColor"
                                  :label="$t('INPUTS.TITLES.EXPIRY_DATE')"
                                  :errors="errors[0]"
                                  :outlined="true"
                                  clearable
                              ></lx-date-time-picker>
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>

                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('INPUTS.TITLES.ADVERTISEMENT.DOWNLOADS')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                      <!--                          <ValidationProvider ref="Dateianhang" name="file_upload" v-slot="{ errors }">-->
                      <!--                            <v-file-input-->
                      <!--                                v-model="fileToken"-->
                      <!--                                :label="$t('INPUTS.TITLES.ADVERTISEMENT.PDF_FILE_INPUT')"-->
                      <!--                                accept="application/pdf"-->
                      <!--                                show-size-->
                      <!--                                outlined-->
                      <!--                                dense-->
                      <!--                            ></v-file-input>-->
                      <!--                          </ValidationProvider>-->

                      <div class="layout-column flex pa-2 pt-5">

                        <div class="layout-row layout-wrap flex px-3 pb-2">
                          <div class="layout-column flex mx-4">
                            <ValidationProvider ref="Dateianhang" name="file_upload" v-slot="{ errors }">
                              <v-file-input
                                  ref="FileInput"
                                  v-model="fileToken"
                                  :label="$t('INPUTS.TITLES.ADVERTISEMENT.PDF_FILE_INPUT')"
                                  accept="application/pdf"
                                  show-size
                                  outlined
                                  dense
                              ></v-file-input>
                            </ValidationProvider>
                          </div>

                          <div class="layout-column mx-4">
                            <v-btn @click="openHiddenFileInput()" depressed
                                   :color="compColor.primeColor" dark
                                   class="text-none">
                              Wählen Sie ihre Dateien aus
                            </v-btn>

<!--                            <input type="file" hidden ref="hiddenFileInput" accept="application/pdf" @change="addFilesByDialog"/>-->
                          </div>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>

                  <div class="flex-100 pa-2">
                    <fake-input-frame :label="$t('INPUTS.TITLES.ADVERTISEMENT.VISIBILITY')"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'">
                      <div class="layout-column flex pa-2">
                        <div class="pb-1">
                          <v-tooltip bottom open-delay="550">
                            <template v-slot:activator="{ on }">
                              <v-btn :color="'#353a47'"
                                     v-on="on"
                                     block
                                     :height="denseFields ? '36' : '40'"
                                     elevation="0"
                                     class="white--text text-none"
                                     @click="selectAllEntries">
                                <template #default>
                                  <div class="layout-row py-4" style="width:100%;">
                                    <div class="layout-column layout-align-start-center px-1">
                                      <v-icon
                                          :color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor">
                                        {{ hospitalListAreSelected ? 'mdi-toggle-switch-off' : 'mdi-toggle-switch' }}
                                      </v-icon>
                                    </div>
                                    <div class="flex layout-row layout-align-start-center pl-5">
                                      {{
                                        hospitalListAreSelected ? $t('BUTTONS.DESELECT_ALL.TITLE') : $t('BUTTONS.SELECT_ALL.TITLE')
                                      }}
                                    </div>
                                  </div>
                                </template>
                              </v-btn>
                            </template>
                            <span>{{
                                hospitalListAreSelected ? $t('BUTTONS.DESELECT_ALL.TOOLTIP') : $t('BUTTONS.SELECT_ALL.TOOLTIP')
                              }}</span>
                          </v-tooltip>
                        </div>

                        <v-divider class="mb-2 mt-1"></v-divider>

                        <div class="layout-row layout-wrap flex px-3 pb-2">
                          <div v-for="(item, index) in hospitalList" :key="index" class="mx-3 flex-30">
                            <ValidationProvider :ref="'Location' + index" :name="'Location' + index"
                                                v-slot="{ errors }">

                              <div class="layout-row flex">
                                <div class="">
                                  <v-switch inset dense :dark="compColor.isInDarkMode" :color="compColor.primeColor"
                                            hide-details class="ma-0 mb-0"
                                            v-model="item.is_selected">
                                  </v-switch>
                                </div>
                                <div class="flex">
                                  <div class="blue-grey--text text--darken-2"
                                       style="line-height:44px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                    {{ item.name }}
                                  </div>
                                </div>
                              </div>

                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </fake-input-frame>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>
          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import lodash from 'lodash'
import axios from 'axios'
import router from '../../../router'
import lxDateTimePicker from '@/components/lx_datetimepicker/LxDateTimePicker.vue'
import AdvertisementItemWidget from '@/components/advertisementItem/AdvertisementItem.vue'
import LxCurrencyInput from "@/components/lx_currency_input/LxCurrencyInput.vue";

const DefaultAccentColor = '#E91E63'
const DefaultMinutesInterval = 5

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

function GetWellFormattedDateTime() {
  let now = new Date()
  let min = Math.trunc(now.getMinutes() / DefaultMinutesInterval) * DefaultMinutesInterval
  now.setMinutes(min, 0, 0)
  return now.toISOString()
}

export default {
  name: 'AdvertisementAndAnnouncement',
  props: {
    advertisement_id: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  components: {
    lxDateTimePicker,
    LxCurrencyInput,
    AdvertisementItemWidget
  },
  data() {
    return {
      hoverHeader: false,
      hospitalList: null,
      oldModel: null,
      oldList: null,
      panel: [0, 1],
      loading: 0,
      model: {
        creation_date: GetWellFormattedDateTime(),
        color: DefaultAccentColor.substring(0, 7)
      },
      fileToken: null,
      fileTokenBackup: null,
      error: {},
      errorDialog: false,
      isDirty: false,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
    }
  },
  created() {
    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })

    this.getData()
  },
  computed: {
    /**
     * Color Picker can't handle Alpha Values
     */
    colorValue: {
      // getter
      get() {
        return (this.model?.color || DefaultAccentColor).substring(0, 7)
      },
      // setter
      set(newValue) {
        this.$set(this.model, 'color', newValue)
      }
    },
    hospitalListAreSelected() {
      if (this.hospitalList) {
        let selected = this.hospitalList.filter(item => {
          if (item.is_selected) return item
        })
        return selected.length === this.hospitalList.length
      } else {
        return false
      }
    },
    hasChanged() {
      return !lodash.isEqual(this.oldModel, this.model) || !lodash.isEqual(this.oldList, this.hospitalList)
    },
    getStyle() {
      const color = this.model.color || DefaultAccentColor

      return {
        "border-left": "8px solid " + color,
        "border-right": "8px solid " + color,
        "border-radius": "4px"
      }
    },
    isBusy() {
      return this.loading > 0
    }
  },
  methods: {
    selectAllEntries() {
      let booleanValue = this.hospitalListAreSelected

      this.hospitalList.forEach((item, index) => {
        this.$set(this.hospitalList[index], 'is_selected', !booleanValue)
      })
    },

    resetModel(reset) {
      this.model = lodash.cloneDeep(this.oldModel)
      this.hospitalList = lodash.cloneDeep(this.oldList)
      this.fileToken = this.fileTokenBackup
      reset()
    },

    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },

    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    /**
     * populateModel set all model data
     */

    populateModel(res) {
      if (res.data) {
        /**
         * Convert int to float
         */

        if (res.data.hasOwnProperty('offer_price')) {
          res.data.offer_price *= 0.01
          res.data.offer_price = res.data.offer_price.toFixed(2)
        }

        if (res.data.hasOwnProperty('regular_price')) {
          res.data.regular_price *= 0.01
          res.data.regular_price = res.data.regular_price.toFixed(2)
        }

        this.fileToken = null

        if (res.data?.file_info) {
          this.fileToken = {
            name: res.data.file_info.file_name,
            size: res.data.file_info.file_size,
            uploaded: true
          }
        }

        this.model = res.data ||{}
        this.fileTokenBackup = this.fileToken

        if (Array.isArray(this.model.location_ids)) {
          for (let i = 0; i < this.hospitalList.length; i++) {
            for (let j = 0; j < this.model.location_ids.length; j++) {
              if (this.hospitalList[i]._id === this.model.location_ids[j]) {
                this.hospitalList[i].is_selected = true
                break
              }
            }
          }
        }

        // this.hospitalList = this.hospitalList.sort(compare)
        this.oldModel = lodash.cloneDeep(this.model)
        this.oldList = lodash.cloneDeep(this.hospitalList)
      }
    },

    /**
     * Get all users, all rights, all rights of selected role and all users with selected role
     */
    getData() {
      if (this.isBusy) {
        return
      }

      this.loading++

      this.$http.get('/hospital')
          .then(res => {
            if (res.data && Array.isArray(res.data.data)) {
              let hospitalList = res.data.data.map((v) => {
                return {is_selected: false, ...v}
              })

              this.hospitalList = hospitalList.sort(compare)

              if (!this.advertisement_id) {
                this.oldModel = lodash.cloneDeep(this.model)
                this.oldList = lodash.cloneDeep(this.hospitalList)
                return
              }

              // get all pharmacies
              this.$http.get('/advertisement/' + this.advertisement_id)
                  .then(this.populateModel)
                  .catch(this.NotifyError)
            }
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loading--
          })
    },

    save(reset) {
      let locationIds = []

      if (this.hospitalList && this.hospitalList.length > 0) {
        for (let i = 0; i < this.hospitalList.length; i++) {
          if (this.hospitalList[i].is_selected) {
            locationIds.push(this.hospitalList[i]._id)
          }
        }
      }

      let data = structuredClone(this.model)

      /**
       * Convert float to int
       */

      if (data.hasOwnProperty('offer_price')) {
        if (!data.offer_price) {
          data.offer_price = null
          data.regular_price = null
        } else {
          data.offer_price = Math.ceil(data.offer_price * 100)
        }
      }

      if (data.hasOwnProperty('regular_price')) {
        if (!data.regular_price) {
          data.regular_price = null
        } else {
          data.regular_price = Math.ceil(data.regular_price * 100)
        }
      }

      data.location_ids = locationIds

      if (!this.model._id) {
        /**
         * Create advertisement
         */
        let formData = new FormData()
        formData.append("model", JSON.stringify(data))

        if (this.fileToken && this.fileToken.uploaded !== false) {
          formData.append("files", this.fileToken)
        }

        axios.post('/advertisement', formData, {headers: {'content-type': 'multipart/form-data'}})
            .then((res) => {
              reset()
              this.NotifySystem('Änderungen gespeichert')

              if (this.fileToken) {
                // Mark as uploaded
                this.fileToken.uploaded = true
              }

              if (res.data && res.data._id && this.$refs.AdvertisementDetailsForm) {
                this.$refs.AdvertisementDetailsForm.reset()
                this.model._id = res.data._id
                this.oldModel = lodash.cloneDeep(this.model)
                this.oldList = lodash.cloneDeep(this.hospitalList)
                this.fileTokenBackup = this.fileToken

                router.replace('/administration/advertisement/' + res.data._id + '/details')
              } else {
                this.oldModel = lodash.cloneDeep(this.model)
                this.oldList = lodash.cloneDeep(this.hospitalList)
              }
            })
            .catch((error) => {
              this.HandleFormError(this.$refs.AdvertisementDetailsForm, error, 'VALIDATION.SERVER.')
                  .catch(this.NotifyError)
            })
      } else {
        let formData = new FormData()

        if (this.fileToken && this.fileToken.uploaded !== false) {
          formData.append("files", this.fileToken)
        }

        let charlie = {}

        // diff
        Object.keys(data).forEach((k) => {
          if (data[k] !== this.oldModel[k]) {
            charlie[k] = data[k]
          }
        })

        delete charlie["_id"]
        delete charlie["pharmacy_id"]
        delete charlie["file_info"]
        delete charlie["creation_date"]

        // set delete flag
        if (this.fileToken !== this.fileTokenBackup && this.fileTokenBackup) {
          charlie.remove_file_id = this.model.file_info.file_id
        }

        // Append model to form
        formData.append("model", JSON.stringify(charlie))

        /**
         * Update advertisement
         */
        axios.patch('/advertisement/' + this.advertisement_id, formData, {headers: {'content-type': 'multipart/form-data'}})
            .then((res) => {
              this.populateModel(res)
              this.NotifySystem('Änderungen gespeichert')
              reset()
            })
            .catch((error) => {
              this.HandleFormError(this.$refs.AdvertisementDetailsForm, error, 'VALIDATION.SERVER.')
                  .catch(this.NotifyError)
            })

      }
    },

    openHiddenFileInput() {
      this.$refs.FileInput.$refs.input.click()
    },
    /**
     * wird bei onChange aufgerufen wenn im Dateidialog Dateien ausgewählt werden
     * diese werden an Methode addFiles übergeben wo sie zerlegt und aufbereitet werden
     * **/
    // addFilesByDialog() {
    //   if (this.$refs.hiddenFileInput.files.length > 0) {
    //     this.fileToken = this.$refs.hiddenFileInput.files[0]
    //   } else {
    //     this.fileToken = null
    //   }
    // }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

h1, h2 {
  font-weight: normal;
}

.subheader {
  height: 20px;
  font-size: 16px;
  padding: 0 16px 0 2px;
  font-weight: 300;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.tabs__div {
  text-transform: none;
  color: #575757;
}

.schraffiert {
  /*position: relative;*/
  /*overflow: hidden;*/

  //background-image: url("../../../assets/doodle_medishop3_opac.png") !important;
  background-repeat: repeat;
  background-size: 300px 300px;

  /*> div {*/
  /*    z-index: 2;*/
  /*}*/

}

.lx-dark-app {
  .schraffiert {
    /*position: relative;*/
    /*overflow: hidden;*/

    /*> div {*/
    /*    z-index: 2;*/
    /*}*/

    &:before {

    }
  }
}

.border-anima {

  .box {

    box-sizing: border-box;
    /*padding: 15px;*/
    position: relative;
    overflow: hidden;
  }

  .box::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 150%;
    background: repeating-linear-gradient(
            white 0%,
            white 7.5px,
            hotpink 7.5px,
            hotpink 15px,
            white 15px,
            white 22.5px,
            hotpink 22.5px,
            hotpink 30px);
    transform: translateX(-20%) translateY(-20%) rotate(-45deg);
    animation: animate 15s linear infinite;
  }

  .box .content {
    position: relative;
    /*background-color: white;*/
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
    text-align: center;
    font-family: sans-serif;
    z-index: 2;
  }

  .box,
  .box .content {
    box-shadow: 0 0 2px deeppink,
    0 0 5px rgba(0, 0, 0, 1),
    inset 0 0 5px rgba(0, 0, 0, 1);
    border-radius: 10px;
  }

  .box .content h2 {
    color: deeppink;
  }

  .box .content p {
    color: dimgray;
  }

  @keyframes animate {
    from {
      background-position: 0;
    }

    to {
      background-position: 0 450px;
    }
  }
}


</style>
