<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.REFRESH.TOOLTIP')"
                  :label="$t('BUTTONS.REFRESH.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="createKey()"
                  icon="mdi-key-variant"
                  :tooltip="$t('BUTTONS.NEW_API_KEY.TOOLTIP')"
                  :label="$t('BUTTONS.NEW_API_KEY.TITLE')"></lx-btn>

        </div>
        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="ApiManagement"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="doRowClick">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'icon'" style="padding:1px;height:35px;">
                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>
                </div>

                <span v-if="value.f.renderer === 'datetime'">
                  {{ value.i[value.f.value] | formatDate('DD.MM.YYYY HH:mm:ss') }}
                </span>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'ApiManagement'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>

    <api-management-dialog
      ref="apimanagmentdialog"
      :locationid="locationid"
      v-on:success="getData"
    />

    <v-dialog v-model="showDetails" persistent scrollable width="90%" max-width="900" overlay-opacity="0.9">
      <v-card class="flex" :dark="compColor.isInDarkMode" v-bind:class="[compColor.contentFrameBack]">
        <v-card-title class="title elevation-4 layout-row pa-0">
          <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
               style="z-index: 2;border-radius:4px 4px 0 0;">
            <div class="py-1 px-1 layout-row layout-align-start-center"
                 v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
              <div class="layout-column layout-align-center-center pr-3">
                <v-avatar :color="compColor.avatarTile" tile height="40" width="40"
                          style="min-width:40px;">
                  <v-icon :color="compColor.primeColor">pets</v-icon>
                </v-avatar>
              </div>
              Datenansicht
            </div>
          </div>
        </v-card-title>
        <v-divider v-bind:class="compColor.textInput" v-if="compColor.isInDarkMode"></v-divider>
        <v-card-text class="pa-5" style="height: 80%;">
          <pre>{{ detailData | pretty }}</pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="layout-row" style="width:100%;">
            <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                   :color="compColor.primeColor" @click="showDetails = null">
              Schließen
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment';
import agGridComponentIconHeader from "@/components/lx_ag-grid/components/headerRenderer/iconHeader";
import agGridComponentButtonCellEditor from "@/components/lx_ag-grid/components/cellRenderer/buttonActionCell";
import CheckIconCellRenderer from '../../../components/lx_ag-grid/components/cellRenderer/booleanCheckIconCell'
import ApiManagementDialog from "@/views/Administration/Data/ApiManagementDialog";

const timestampCellRenderer = function (params) {
  const myDateM = moment(params.value);
  return myDateM.format('DD.MM.YYYY HH:mm:ss')
};

const dataCellRender = function (params) {
  if (typeof params.value === 'object') {
    if (Array.isArray(params.value)) {
      return params.value.join('\n')
    }

    return JSON.stringify(params.value, null, 5)
  }

  return params.value
};

export default {
  name: 'ApiManagement',
  data() {
    return {
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['ordernr'],
          sortDesc: [false],
          // getClassForItem: (row) => {
          //   if (row.isExpanded) {
          //     return 'row-bold'
          //   }
          //   return ''
          // }
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            text: 'Letzte Verwendung',
            value: 'last_access',
            width: '300px',
            sortable: true,
            renderer: 'datetime'
          },
          {
            text: 'Einrichtung',
            value: 'location_name',
            width: '300px',
            sortable: true
          },
          {
            text: 'APIKey',
            value: 'apikey',
            sortable: true,
          },
          {
            text: 'Aktiv',
            value: 'enabled',
            sortable: true,
            renderer: 'icon'
          }
        ]
      },
      items: [],

      locationid: '',
      isLoadingData: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
      frameworkComponents: null,
      columnDefs: null,
      pharmacyIdList: [],
      FilterAreFloating: false,
      allowBlurOnLoading: true,
      showDetails: false,
      detailData: null,
      defaultSortOpts: [
        {
          colId: 'last_access',
          sort: 'desc'
        }],
    }
  },
  components: {
    ApiManagementDialog
  },
  created() {
    this.createColumnDefs();
    // this.getData();
  },
  beforeMount() {
    this.frameworkComponents = {
      agGridComponentIconHeader: agGridComponentIconHeader,
      agGridComponentButtonCellEditor: agGridComponentButtonCellEditor,
      checkIconCellRenderer: CheckIconCellRenderer
    }

  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },
    /**
     * hier werden wie gewohnt die Spalten des Grids definiert,
     * Besonderheit hier ist nur:
     *
     *     filterParams: {
     *       newRowsAction: 'keep'
     *    }
     *
     * dies ist Wichtig damit nach "getData" ein eventueller Eintrag im Filterinput erhalten bleibt
     * **/

    createColumnDefs() {
      this.columnDefs = [
        {
          headerName: 'Aktiv',
          field: 'enabled',
          width: 75,
          // filter: true,
          // checkboxSelection: true,
          // sort: 'desc',
          filterParams: {
            newRowsAction: 'keep'
          },
          cellRenderer: 'checkIconCellRenderer',
        },
        {
          headerName: 'Letzte Verwendung',
          width: 180,
          // sort: 'desc',
          field: 'last_access',
          filter: true,
          filterParams: {
            newRowsAction: 'keep'
          },
          // headerComponentParams: {sortChangedAction: this.sortChanged},
          cellRenderer: timestampCellRenderer
        },
        {
          headerName: 'Einrichtung',
          field: 'location_name',
          filter: true,
          filterParams: {
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'APIKey',
          field: 'apikey',
          sortable: false,
          filter: false,
          width: 400,
          checkboxSelection: false,
          filterParams: {
            newRowsAction: 'keep'
          },
          cellRenderer: dataCellRender
          // cellRendererSelector: function (params) {
          //   let check = {
          //     component: 'checkIconCellRenderer'
          //   }
          //   if (params.data.is_admin) {
          //     return check
          //   }
          // }
        },
        {
          headerName: '',
          field: "$button_delete", width: 50, pinned: 'left', filter: false, sortable: false,
          cellRendererSelector: function () {
            return {
              component: 'agGridComponentButtonCellEditor'
            }
          },
          cellRendererParams: {
            icon: 'delete_forever',
            action: this.deleteKey,
            btntype: true
            // alreadySet: this.listModel
          }
        }

      ]
    },

    doRowClick(params) {
      // if (params?._id) {
      //   router.push({path: `/administration/pharmacy/${params._id.toString()}/details`});
      // }
      this.detailData = params;
      this.showDetails = true;
    },

    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neuladen der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      this.isLoadingData = true;
      this.pharmacyList = [];


      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
      })

      this.pharmacyIdList = [];
      this.$http.get('/apimanagement', params)
        .then(res => {
          if (res.data) {
            this.pharmacyIdList = (res.data.result || []).map((a) => {
              return a._id
            });

            this.items = res.data.result || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },
    back() {
      window.history.back()
    },
    createKey() {
      // Optain *all* pharmacies
      this.$http.get('/pharmacy')
        .then(res => {
          const pharmacies = ((res.data || {}).data) || [];

          const pharmacyList = pharmacies.filter((x) => {
            return this.pharmacyIdList.indexOf(x._id) === -1
          });
          this.$refs.apimanagmentdialog.pharmacies = pharmacyList;
          if (pharmacyList.length > 0) this.$refs.apimanagmentdialog.Show();
        })
        .catch(this.NotifyError)
    },
    deleteKey(model) {
      this.DialogConfirm(
        'Löschen des API Schlüssel',
        'Sind Sie sicher den Schlüssel der zugewiesenen Organisation löschen zu wollen. Eine Wiederherstellung ist nicht möglich.',
        { cancel: this.$t('BUTTONS.DIALOGS.CANCEL') }
      )
        .then(() => {
          this.$http.delete('/apimanagement/' + model._id)
            .then(this.NotifySuccess)
            .catch(this.NotifyError)
            .finally(this.getData)
        })
        .catch(() => {
        })
    }
  }
}
</script>

<style scoped>

</style>
