<template>
  <v-form @submit="register" id="reset_fields">

    <div class="flex layout-column" style="max-width: 700px;">
      <!-- New User -->
      <ValidationObserver ref="RegisterFromTokenForm" v-slot="{ valid, pristine, reset }"
                          class="layout-column flex">
        <div class="flex px-10 py-6" style="overflow-y: auto;">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                  v-model="email"
                  color="white"
                  prepend-inner-icon="email"
                  label="E-Mail"
                  dark
                  placeholder=" "
                  outlined
                  disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-0">
            <v-col cols="6">
              <validation-provider vid="form.first_name" name="Vorname" rules="required" v-slot="{ errors }">
                <v-text-field
                    v-model="model.first_name"
                    :error-messages="errors[0]"
                    color="white"
                    prepend-inner-icon="person"
                    label="Vorname*"
                    placeholder=" "
                    outlined
                    dark
                    class="mr-4"
                    tabindex="1"
                ></v-text-field>
                <!--              autofocus-->
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider vid="form.last_name" name="Nachname" rules="required" v-slot="{ errors }">
                <v-text-field
                    v-model="model.last_name"
                    :error-messages="errors[0]"
                    color="white"
                    prepend-inner-icon="person"
                    label="Nachname*"
                    placeholder=" "
                    outlined
                    dark
                    class="ml-4"
                    tabindex="2"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-0">
            <v-col cols="12">
              <validation-provider vid="form.login_name" name="Benutzername" rules="required|invalid_chars"
                                   v-slot="{ errors }">
                <v-text-field
                    v-model="model.login_name"
                    :error-messages="errors[0]"
                    color="white"
                    prepend-inner-icon="supervised_user_circle"
                    label="Benutzername*"
                    placeholder=" "
                    outlined
                    dark
                    tabindex="3"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-0">
            <v-col cols="6">
              <validation-provider name="Password" rules="required" v-slot="{ errors }">
                <v-text-field
                    color="white"
                    dark
                    placeholder=" "
                    outlined
                    :error-messages="errors[0]"
                    label="Passwort*"
                    prepend-inner-icon="fingerprint"
                    v-model="model.password"
                    :clearable="false"
                    :append-icon="showPW ? 'visibility' : 'visibility_off'"
                    @click:append="() => (showPW = !showPW)"
                    :type="showPW ? 'password' : 'text'"
                    autocomplete="off"
                    class="mr-4"
                    tabindex="4"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="6">
              <validation-provider name="Confirmation" rules="required|password:Password"
                                   v-slot="{ errors }">
                <v-text-field
                    color="white"
                    dark
                    placeholder=" "
                    outlined
                    :error-messages="errors[0]"
                    label="Passwort (Wiederholung)*"
                    prepend-inner-icon="fingerprint"
                    v-model="model.password2"
                    :clearable="false"
                    :append-icon="showPW2 ? 'visibility' : 'visibility_off'"
                    @click:append="() => (showPW2 = !showPW2)"
                    :type="showPW2 ? 'password' : 'text'"
                    autocomplete="off"
                    class="ml-4"
                    tabindex="5"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-0">
            <v-col cols="12">
              <v-alert
                  border="left"
                  prominent
                  style="font-size:15px;"
                  class="pa-3 mb-3"
                  v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
                <template slot="prepend">
                  <v-avatar>
                    <v-icon large>mdi-information-variant</v-icon>
                  </v-avatar>
                </template>
                Mit Abschluss der Registrierung werden Sie der Organisation <strong class="text-decoration-underline"
                                                                                    v-if="company">"{{ company }}"</strong>
                zugewiesen.
              </v-alert>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mx-5"></v-divider>

        <div class="pa-2 d-flex flex-row">
          <div class="grow mr-2">
            <v-btn block class="green text-none white--text" type="submit"
                   :disabled="!valid || pristine"
                   :loading="loading">Registrierung abschließen
            </v-btn>
          </div>
          <v-btn class="text-none" @click="clearForm(reset)" :disabled="pristine"
                 title="Formularfelder leeren">
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </ValidationObserver>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'RegistrationNewUserComponent',
  props: {
    company: String,
    email: String,
    token: String,
    redirect: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },
  data () {
    return {
      loading: false,
      model: {},
      showPW: true,
      showPW2: true,
    }
  },
  methods: {
    clearForm(reset) {
      reset();
      this.model = {}
      this.loginError = false
    },
    register(ev) {
      if (this.loading) {
        return
      }

      ev.preventDefault() // wichtig damit Formular mit Enter abgeschickt werden kann

      let registerData = Object.assign({}, this.model);

      delete registerData.password2

      const regForm = {
        form: registerData,
        tokenid: this.token
      };

      this.loading = true

      this.$http.post('/register', regForm)
          .then(this.redirect)
          .catch((error) => {
            this.HandleFormError(this.$refs.RegisterFromTokenForm, error, 'VALIDATION.SERVER.')
                .then(() => {})
                .catch(this.NotifyError)
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>