<template>
  <div class="pa-0">
    <v-list two-line class="pa-0 lx-dash-list" color="transparent">


      <v-list-item class="pa-0 ma-2 elevation-3" v-bind:class="[compColor.contentFrameBack]"
                   v-if="!this.showHTMLByRight({act: 'GET', obj: '/deliveryplan/dates/*'})"
                   style="border-radius:3px;">
        <v-list-item-content v-bind:class="[compColor.widget.text]" class="pa-0">
          <v-alert
            border="left"
            prominent
            style="font-size:15px;"
            class="pa-3 ma-0"
            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            <div class="layout-column layout-align-center-start subtitle-2 font-weight-regular">
              Sie verfügen nicht über die Rechte um die Warenkörbe einsehen zu können.
            </div>
          </v-alert>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pa-0 ma-2 elevation-3" v-bind:class="[compColor.contentFrameBack]" v-if="duration"
                   style="border-radius:3px;">
        <v-list-item-content v-bind:class="[compColor.widget.text]" class="pa-0">
          <v-alert
            border="left"
            prominent
            style="font-size:15px;"
            class="pa-3 ma-0"
            v-bind:class="{'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode, 'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode}">
            <!--                    <v-alert type="info" text  class="ma-0 text-center px-3 pb-2 pt-3"  :icon="false">-->
            <!--                        <span class="subtitle-1 font-weight-bold">Bestellschluss:</span>-->
            <template slot="prepend">
              <v-avatar>
                <v-icon large>mdi-information-variant</v-icon>
              </v-avatar>
            </template>
            <div class="layout-column layout-align-center-start subtitle-2 font-weight-regular">
              <!--                            <div class="layout-row">-->
              <span v-if="deliverDateMessage"
                    style="line-height: 14px;">Die nächste Lieferung erfolgt zum {{ deliverDateMessage }}, wenn sie in den nächsten <span
                class="subtitle-1 font-weight-black">
                                    {{ duration.days * 24 + duration.hours }}h</span> und <span
                class="subtitle-1 font-weight-black">{{ duration.minutes }}min</span> bestellen</span>
              <!--                            </div>-->
            </div>
          </v-alert>
        </v-list-item-content>
      </v-list-item>
      <!--            <v-divider v-bind:class="[compColor.divider]"></v-divider>-->
      <v-list-item class="py-1 px-6" v-bind:class="[compColor.contentFrameBack]" v-if="!orders">
        <v-list-item-content v-bind:class="[compColor.widget.text]">
          <div class="flex layout-column px-3"
               v-bind:style="{'border-left': '4px solid ' + compColor.primeColor,'border-radius': '5px'}">
            <div class="font-weight-bold subtitle-2 my-1" style="line-height: 1.0rem;">es sind keine
              Warenkörbe zur Anzeige vorhanden
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>

      <div v-for="(cart, index) in orders" :key="index" class="ma-2 elevation-3">
        <v-list-item :to="'/shopping/' + cart._id + '/details'" class="pa-1"
                     :style="{'background':compColor.context.background}" style="border-radius:6px;">
          <v-list-item-avatar tile height="42" width="48" class="ma-0">
            <v-icon
              v-bind:class="{'blue-grey--text text--lighten-4': !compColor.isInDarkMode, 'blue-grey--text text--darken-2': compColor.isInDarkMode}">
              shopping_cart
            </v-icon>
          </v-list-item-avatar>
          <v-divider vertical v-bind:class="{'grey lighten-3': !compColor.isInDarkMode}"></v-divider>
          <v-list-item-content v-bind:class="['pl-2',compColor.widget.text]">
            <div class="layout-row">
              <div class="flex layout-column ml-1 layout-align-center-start">
                <div class="font-weight-bold subtitle-1 mb-1" style="line-height: 1.0rem;">
                  {{ cart.name }}
                </div>
                <div class="layout-row subtitle-2 font-weight-light" style="line-height: 1.0rem;"><span
                  style="width:110px;">Produktanzahl:</span>{{ cart.articles }}
                </div>
                <div class="layout-row subtitle-2 font-weight-light" style="line-height: 1.0rem;"><span
                  style="width:110px;">Status:</span>
                  <strong v-if="cart.flag === 1 && cart.articles > 0" class="green--text">zum
                    bestellen markiert</strong>
                  <strong v-if="cart.flag === 0 && cart.articles > 0" class="orange--text">in
                    Bearbeitung</strong>
                  <strong v-if="cart.flag === 0 && cart.articles <= 0">leer</strong>
                </div>
              </div>
              <div class="layout-column layout-align-center-center pr-2">
                <div class="grey lighten-4 layout-row">
                  <div></div>
                </div>
              </div>
            </div>
          </v-list-item-content>
          <v-divider vertical v-bind:class="{'grey lighten-3': !compColor.isInDarkMode}"></v-divider>
          <v-list-item-avatar tile height="42" width="48" class="ma-0">
            <v-icon size="18"
                    v-bind:class="{'orange--text': cart.flag === 0 && cart.articles > 0, 'green--text': cart.flag === 1, 'grey--text text--lighten-1': cart.flag === 0 && cart.articles <= 0}">
              fiber_manual_record
            </v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import moment from 'moment'

function SortValues(input) {
  input.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return input
}

export default {
  name: 'ShoppingCart',
  data() {
    return {
      orders: [],
      duration: null,
      intervalCounterTimer: null,
      deliverDateMessage: null,
    }
  },
  created() {
    let today = new Date()
    let todayString = moment(today).format('YYYYMMDD')

    //die showHTMLByRight Methode kommt aus dem lx_auth plugin als mixinund prüft den user ob recht vorhanden ist
    if (this.showHTMLByRight({ act: 'GET', obj: '/deliveryplan/dates/*' })) {
      this.$http.get('/deliveryplan/dates/' + todayString)
        .then((res) => {
          if (res && res.data && res.data.order_date) {
            let orderDate = moment(res.data.order_date);
            this.duration = moment.duration(orderDate.diff(today))._data
            this.deliverDateMessage = moment(res.data.deliver_date).format('dddd DD.MM.YYYY')
          }
        })
        .catch(this.NotifyError)

      this.getData()
    }
  },
  methods: {
    getData() {
      const params = {
        excludeAdmin: 1
      }

      this.$http.get('/department', params)
        .then(res => {
          if (res.data && res.data.data) {
            this.orders = SortValues(res.data.data) //res.data.data
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
