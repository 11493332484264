import moment from "moment";
import {NumberFloat, NumberInt} from "./LxDataTypes";

function LxQueryBuilder(fields) {
    // exclude invalid item fields, like null, undefined, empty
    fields = (fields || []).filter(item => (item && (typeof item === "string" || typeof item === "object")))


    // const reSplitGroup = /[^\s"']+|"([^"]*)"|'([^']*)'/gm
    const reSplitGroup = /\s+(?=(?:(?:[^"']*["']){2})*[^"']*$)/g // Better solution to avoid mishandling in key="value"
    const reOperators = /(?:[!=<>]|$)/
    const rules = [
        {char: '!', operator: '$not', hint: 'Nicht', build: (k, v) => {return {[k]: {$not: v}}}},
        {char: '>', operator: '$gt', hint: 'Größer als', build: (k, v) => {return {[k]: {$gt: v}}}},
        {char: '<', operator: '$lt', hint: 'Kleiner als', build: (k, v) => {return {[k]: {$lt: v}}}},
        {char: '=', operator: '$eq', hint: 'Entspricht', build: (k, v) => {return {[k]: {$eq: v}}}},
    ]

    function GetRegexOverFields(operator, pattern) {
        const f = fields.filter((item) => typeof item === 'string' || item.type === String || !item.type)

        return {[operator]: f.map((item) => {
                if (typeof item === 'string') {
                    return {[item]: pattern}
                }

                return null
            }).filter(e => e)}
    }

    function trimAny(str, chars) {
        let start = 0,
            end = str.length;

        while(start < end && chars.indexOf(str[start]) >= 0)
            ++start;

        while(end > start && chars.indexOf(str[end - 1]) >= 0)
            --end;

        return (start > 0 || end < str.length) ? str.substring(start, end) : str;
    }

    // let self = {}
    this.Build = function Build(searchstring) {
        if (typeof searchstring !== 'string') {
            return []
        }

        searchstring =  searchstring.replace(/[*()/\\:+\[\]]+/g, '')

        if (!searchstring) {
            return []
        }

        return searchstring.split(reSplitGroup)
            .map((element) => {
                const elements = element.split(reOperators)

                if (!elements || elements.length === 1) return GetRegexOverFields('$or', {$regex: trimAny(element, [' ', '\'', '"']), $options: 'i'})

                const operatorChar = element[element.search(reOperators)]
                const matchedRule = rules.find(item => operatorChar === item.char)
                let specifiedField = null

                // No Key
                if (!elements[0]) {
                    if (operatorChar === '!') return GetRegexOverFields('$and', {$not: {$regex: elements[1], $options: 'i'}})

                    specifiedField = fields.find((item) => item && item.default === true)
                } else {
                    const lcInput = elements[0].toLowerCase()
                    specifiedField = fields.find((item) => item && item.keyword === lcInput)
                }

                if (!specifiedField || !specifiedField.key || !elements[1]) {
                    return null
                }

                let value = trimAny(elements[1], [' ', '\'', '"'])

                // Convert
                if (specifiedField.type) {
                    switch (specifiedField.type) {
                        case Number:
                            value = parseFloat(value.replace(',', '.'))

                            if (isNaN(value)) return null
                            break

                        case Date:
                            if (value && value.length === 8) {
                                const dateValue = moment(value, 'DDMMYYYY').add(1, 'day')

                                if (!dateValue || !dateValue.isValid()) return null; // Invalid Date

                                if (matchedRule && matchedRule.operator === '$eq') {
                                    const fromValue = dateValue.clone().utc().startOf('day')
                                    const toValue = dateValue.clone().utc().endOf('day')

                                    let obj = {};
                                    obj[specifiedField.key] = {
                                        '$gte': {'$date': fromValue.toISOString()},
                                        '$lte': {'$date': toValue.toISOString()}
                                    }

                                    return obj
                                } else {
                                    if (matchedRule && matchedRule.operator === '$not') return null; // unsupported

                                    value = {$date: dateValue.utc().startOf('day').toISOString()}
                                }
                            } else {
                                return null
                            }
                            break

                        default:
                            // No Type
                            return null
                    }
                }

                if (!value) {
                    return null
                }

                return matchedRule.build(specifiedField.key, value)
            })
            .reduce((prev, curr) => {
                // Remove null value
                if (!curr) return prev

                if (Object.keys(curr)[0] === '$or') {
                    if (!Array.isArray(prev['$and'])) prev['$and'] = []
                    prev['$and'].push(curr)
                    return prev
                }

                if (Object.keys(curr)[0] === '$and') {
                    if (!Array.isArray(prev['$and'])) prev['$and'] = []

                    curr['$and'].forEach(e => prev['$and'].push(e))

                    return prev
                }

                const key = Object.keys(curr)[0]

                prev[key] = {
                    ...prev[key],
                    ...curr[key]
                }

                return prev
            }, {})
    }
}

export const LxQueryHelper = {
    GetQueryParam: function (options) {
        options = options || {}
        let query = options.query || {}
        let opts = {}

        // Paging
        if (!isNaN(options.limit)) opts['limit'] = options.limit
        if (!isNaN(options.skip)) opts['skip'] = options.skip

        // Sort
        if (Array.isArray(options.sortBy) && Array.isArray(options.sortDesc) && options.sortBy.length > 0 && options.sortBy.length === options.sortDesc.length) {
            if (options.sortBy.length === 1) {
                // Build single object
                opts['sort'] = (options.sortBy || []).reduce((result, key, index) => {
                    result[key] = (options.sortDesc[index] ? -1 : 1)
                    return result
                }, {})
            } else {
                // Build array of object's
                opts['sort'] = (options.sortBy || []).reduce((result, key, index) => {
                    result.push({[key]: (options.sortDesc[index] ? -1 : 1)});
                    return result
                }, [])
            }
        }

        // if searchString defined and > length 0 then build
        if (options.searchString && options.queryBuilder instanceof LxQueryBuilder) {
            const filter = options.queryBuilder.Build(options.searchString)

            // Merge given query with query from Builder
            query = {
                ...query,
                ...filter
            }
        } else {
            const engine = new LxQueryBuilder(options.searchFields)
            const filter = engine.Build(options.searchString)

            // Merge given query with query from Builder
            query = {
                ...query,
                ...filter
            }
        }

        // returns our config parameter
        return {
            config: {
                opts: opts,
                query: query,
                count: options.count !== false
            }
        }
    },
    FilterFieldsForTooltip: function (fields) {
        if (!Array.isArray(fields)) return fields
        fields = fields.filter(e => e && typeof e !== 'string')

        let help = []

        fields.forEach((element) => {
            const info = function (element) {
                switch (element.type) {
                    case NumberInt:
                        return[
                            {
                                example: element.keyword + '>22',
                                hint: 'Suchergebnis zeigt <b>größere</b> Einträge als 22'
                            },
                            {
                                example: element.keyword + '<17',
                                hint: 'Suchergebnis zeigt <b>kleinere</b> Einträge als 17'
                            },
                            {
                                example: element.keyword + '=6',
                                hint: 'Suchergebnis zeigt <b>gleiche</b> Einträge wie 6'
                            }
                        ]
                    case Number || NumberFloat:
                        return [
                            {
                                example: element.keyword + '>100,50',
                                hint: 'Suchergebnis zeigt <b>größere</b> Einträge als 100,50'
                            },
                            {
                                example: element.keyword + '<100',
                                hint: 'Suchergebnis zeigt <b>kleinere</b> Einträge als 100'
                            },
                            {
                                example: element.keyword + '=99.50',
                                hint: 'Suchergebnis zeigt <b>gleiche</b> Einträge wie 99.50'
                            },
                        ]
                    case Boolean:
                        return ''
                    case Date:
                        return [
                            {
                            example: element.keyword + '>02082021',
                            hint: 'Suchergebnis zeigt Einträge <b>nach</b> dem 02. August 2021'
                            },
                            {
                            example: element.keyword + '<02082021',
                            hint: 'Suchergebnis zeigt Einträge <b>vor</b> dem 02. August 2021'
                            },
                            {
                            example: element.keyword + '=02082021',
                            hint: 'Suchergebnis zeigt Einträge <b>vom</b> 02. August 2021'
                            }
                        ]
                    default:
                        return 'Keine Info'
                }
            }(element)

            help.push({
                hints: info,
                keyword: element.keyword,
                title: element.title
            })
        })

        return help
    }
}
