<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.REFRESH.TOOLTIP')"
                  :label="$t('BUTTONS.REFRESH.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="$router.push({path: '/administration/advertisement/new'})"
                  icon="add_box"
                  :tooltip="$t('BUTTONS.ADD_ADVERTISEMENT.TOOLTIP')"
                  :label="$t('BUTTONS.ADD_ADVERTISEMENT.TITLE')"></lx-btn>
        </div>

        <div class="flex layout-row">

        </div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
          :dark="isDarkMode"
          :color="compColor.primeColor"
          :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
          class="mt-2 mb-0"
          :label="$t('INPUTS.TITLES.SEARCH_TERM')"
          hide-details
          outlined
          :dense="denseFields"
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="searchString"
        ></v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="AdvertisementList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openAdvertisementDetails">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'delete'" style="padding:1px;height:35px;line-height:35px;">
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="deleteAdvertisement(value.i)"
                          icon="mdi-delete-forever"
                          :hide-label="true"
                          :tooltip="$t('BUTTONS.ADVERTISEMENT_DELETE.TOOLTIP')"
                          :label="$t('BUTTONS.ADVERTISEMENT_DELETE.TITLE')"></lx-btn>
                </div>
                <div v-if="value.f.renderer === 'detail_hover'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                    <v-tooltip right class="pa-0 ma-0 elevation-16" content-class="detailInfo" open-delay="350"
                               max-width="50%" min-width="300">
                      <template v-slot:activator="{ on }">
                        <v-icon :dark="compColor.isInDarkMode" v-on="on" color="blue-grey" style="cursor: pointer;">
                          mdi-magnify-plus-outline
                        </v-icon>
                      </template>

                      <div class="elevation-10 layout-column" style="border-radius: 6px;max-width:500px;"
                           v-bind:style="{background: compColor.contentFrame}">
                        <!-- HEADER -->
                        <div class="body-1 font-weight-light pa-2 layout-row layout-align-center-center"
                             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
                          Welche Medizinische Einrichtung sieht diese Nachricht?
                        </div>

                        <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

                        <!-- CONTENT (default slot) -->
                        <div class="ma-1 layout-column elevation-3" style="border-radius: 5px;position: relative;"
                             :style="{'background': compColor.lxExpansion.background}">
                          <div v-if="evaluateHospital(value.i) && evaluateHospital(value.i).length > 0"
                               class="pa-2 layout-row layout-wrap">
                            <div v-for="(item, index) in evaluateHospital(value.i)" :key="index" class="ma-1">
                              <v-chip v-if="item.name" :dark="isDarkMode" small class="blue-grey--text text--darken-2"
                                      :color="compColor.prime + ' lighten-4'">
                                <v-icon left small>
                                  mdi-eye-check
                                </v-icon>
                                {{ item.name }}
                              </v-chip>
                            </div>
                          </div>
                          <div v-else class="pa-3">
                            für alle Einrichtungen sichtbar
                          </div>
                        </div>
                      </div>
                    </v-tooltip>
                  </div>
                </div>
                <div v-if="value.f.renderer === 'dateTime'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                    {{ value.i[value.f.value] | formatDate('DD.MM.YYYY HH:mm') }}
                  </div>
                </div>
                <div v-if="value.f.renderer === 'cent_value'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                    {{ (value.i[value.f.value] * 0.01).toFixed(2) }} €
                  </div>
                </div>
                <div v-else-if="value.f.renderer === 'dateTime_alert'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                    <v-tooltip bottom v-if="value.i['deleted_forever']">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="orange--text mr-1">mdi-information-outline</v-icon>
                      </template>
                      <span>Diese Eintrag ist abgelaufen und wird nach dem <b>{{ value.i['deleted_forever'] | formatDate('DD.MM.YYYY') }}</b> endgültig gelöscht. </span>
                    </v-tooltip>

                    {{ value.i[value.f.value] | formatDate('DD.MM.YYYY HH:mm') }}
                  </div>
                </div>
              </template>
            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>

    <v-dialog v-model="deletionDialog" persistent width="20%" overlay-opacity="0.9">
      <v-card class="flex" :dark="compColor.isInDarkMode" v-bind:class="[compColor.contentFrameBack]">
        <v-card-title class="title elevation-4 layout-row pa-0">
          <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
               style="z-index: 2;border-radius:4px 4px 0 0;">
            <div class="py-1 px-1 layout-row layout-align-start-center"
                 v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
              <div class="layout-column layout-align-center-center pr-3">
                <v-avatar :color="compColor.avatarTile" tile height="40" width="40"
                          style="min-width:40px;">
                  <v-icon :color="compColor.primeColor">delete_forever</v-icon>
                </v-avatar>
              </div>
              Löschen bestätigen
            </div>
          </div>
        </v-card-title>
        <v-divider v-bind:class="compColor.textInput" v-if="compColor.isInDarkMode"></v-divider>
        <v-card-text class="pa-5">
          Möchten Sie die ausgewählte Nachricht wirklich löschen? Eine Wiederherstellung ist dann nicht möglich.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <div class="layout-row" style="width:100%;">
            <!--                    <v-spacer></v-spacer>-->
            <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                   :color="compColor.primeColor" @click="deleteAdvertisement">
              ja
            </v-btn>
            <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                   :color="compColor.primeColor" @click="deletionDialog = null">
              nein
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import router from '../../../router'

export default {
  name: 'AdvertisementList',
  data() {
    return {
      searchString: null,
      searchDelayTimer: null,
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['creation_date'],
          sortDesc: [true],
          getClassForItem: (row) => {
            if (row.item.deleted_forever) {
              return (this.isDarkMode) ? 'font-weight-light font-italic orange--text lighten-4' : 'font-weight-light font-italic orange lighten-5'
            }

            return ''
          }
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            width: '30px',
            sortable: true,
            sticky: 'left',
            renderer: 'delete',
            noResize: true,
            alternateText: 'Löschen'
          },
          {
            width: '30px',
            headerIcon: 'mdi-eye',
            value: 'hospitalsToInform',
            sticky: 'left',
            renderer: 'detail_hover',
            noResize: true,
            alternateText: 'Krankenhausvorschau'
          },
          {
            text: 'Veröffentlichungsdatum',
            value: 'creation_date',
            width: '150px',
            sortable: true,
            renderer: 'dateTime'
          },
          {
            text: 'Überschrift',
            value: 'headline',
            // width: '200px',
            sortable: false,
          },
          {
            text: 'Preis',
            value: 'offer_price',
            width: '80px',
            sortable: false,
            renderer: 'cent_value'
          },
          {
            text: 'Ablaufdatum',
            value: 'expiry_date',
            width: '180px',
            sortable: true,
            renderer: 'dateTime_alert'
          },
        ]
      },
      items: [],
      deletionDialog: false,
      deleteItem: null,
      allHospitals: []
    }
  },
  components: {},
  created() {
    this.$http.get('/hospital')
      .then(res => {
        if (res.data) {
          this.allHospitals = res.data.data
        }
      })
  },
  watch: {
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 500)
    }
  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }

      this.getData()
    },
    /**
     * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openAdvertisementDetails(params) {
      if (params?._id) {
        router.push({ path: `/administration/advertisement/${params._id}/details` })
      }
    },
    deleteDialog(data) {
      this.deletionDialog = true;
      this.deleteItem = data

    },
    deleteAdvertisement(value) {
      this.DialogConfirm(
        'Löschen der Produktinformation',
        'Möchten Sie die ausgewählte Produktinformation "' + value.headline + '" wirklich löschen? Eine Wiederherstellung ist nicht möglich.',
        { ok: this.$t('BUTTONS.DIALOGS.DELETE'), cancel: this.$t('BUTTONS.DIALOGS.CANCEL') }
      )
        .then(() => {
          if (value && value._id) {
            this.$http.delete('/advertisement/' + value._id)
              .then(this.NotifySuccess)
              .catch(this.NotifyError)
              .finally(this.getData)
          }
        })
        .catch(() => {
        })
    },

    evaluateHospital(item) {
      if (Array.isArray(item.location_ids) && item.location_ids.length > 0) {
        let hospitals = this.allHospitals.filter(value => item.location_ids.includes(value._id))

        this.$set(this.items, 'hospitals', hospitals)

        return hospitals
      }
    },

    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
        searchString: this.searchString,
        searchFields: ['headline', 'content']
      })

      // Show All
      params.showall = 1

      this.$http.get('/advertisement', params)
        .then(res => {
          if (res.data) {
              const now = new Date()

              this.items = (res.data.result || []).map((item) => {
                // mark item as expired if set; inform user that's Advertisement will be deleted after 28 Days
                if (item.expiry_date) {
                  let n = new Date(item.expiry_date)

                  if (n < now) {
                    n.setDate(n.getDate() + 27)
                    item.deleted_forever = n
                  }
                }

                return item
              })
              this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(this.tableObj.loading = false)
    }
  },
  computed: {}
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
