<template>

  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">
    <ValidationObserver ref="exportSettings" v-slot="{ valid, pristine, reset }" class="layout-column flex">
      <div class="layout-column flex ma-2 elevation-5"
           style="border-radius:4px;position:relative;"
           v-bind:style="{background: compColor.contentFrame}">

<!--        :disabled="valid && pristine && !showBtns || !valid && !pristine && !showBtns || valid && !pristine && !showBtns"-->
        <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1" :class="hoverHeader">
          <div class="layout-row">
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="!valid || pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="save(reset)"
                    icon="save"
                    :tooltip="$t('BUTTONS.SAVE.TOOLTIP')"
                    :label="$t('BUTTONS.SAVE.TITLE')"></lx-btn>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    :disabled="pristine"
                    iconSize="20"
                    width="auto"
                    classes="text-none mr-1"
                    @click="resetFormular(reset)"
                    icon="cancel"
                    :tooltip="$t('BUTTONS.REVERT.TOOLTIP')"
                    :label="$t('BUTTONS.REVERT.TITLE')"></lx-btn>
          </div>
          <div class="flex layout-row"></div>
        </div>

        <v-divider v-if="!hoverHeader" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div ref="scrollContainer" style="overflow-y: auto;" class="flex pa-1 layout-column">
          <lx-expansion-panels :single-open="false" v-model="panel">
            <lx-expansion-panel>
              <lx-expansion-panel-header icon="mdi-truck-delivery"
                                         :main-headline="$t('PANELS.DATA_EXPORT.SETTINGS.HEADLINE')"
                                         :sub-headline="$t('PANELS.DATA_EXPORT.SETTINGS.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="type" name="Export-Type" v-slot="{ errors }">
                      <v-select
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :dark="compColor.isInDarkMode"
                        label="Export-Methode"
                        v-model="model.type"
                        :items="exportTypes"
                        :error-messages="errors[0]"
                        hide-no-data
                        :menu-props="{ bottom: true, offsetY: true }"
                        required
                        outlined
                        class="mb-1"
                        @change="changeFormat"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-export</v-icon>
                        </template>
                      </v-select>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="format" name="Export-Format"
                                         rules="required" v-slot="{ errors }">
                      <v-select
                        :color="compColor.primeColor"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        :dark="compColor.isInDarkMode"
                        label="Export-Format"
                        v-model="model.format"
                        :items="exportFormat"
                        :error-messages="errors[0]"
                        :disabled="!model.type"
                        :menu-props="{ bottom: true, offsetY: true }"
                        @change="clear"
                        required
                        class="mb-1"
                        outlined
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-file-export</v-icon>
                        </template>
                      </v-select>
                    </validation-provider>

                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel v-if="isPanelValidFor === 'mail'">
              <lx-expansion-panel-header icon="mdi-email"
                                         :main-headline="$t('PANELS.DATA_EXPORT.E_MAIL_TRANSPORT.HEADLINE')"
                                         :sub-headline="$t('PANELS.DATA_EXPORT.E_MAIL_TRANSPORT.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">

                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="content" name="exportContent"
                                         rules="required" v-slot="{ errors }">
                      <v-select
                        :color="compColor.primeColor"
                        :dark="compColor.isInDarkMode"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        label="Übertragunsart"
                        v-model="model.content"
                        :items="exportContent"
                        :error-messages="errors[0]"
                        hide-no-data
                        :menu-props="{ bottom: true, offsetY: true }"
                        required
                        outlined
                        class="mb-0"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-location-enter</v-icon>
                        </template>
                      </v-select>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="content" name="exportCharmap"
                                         rules="required" v-slot="{ errors }">
                      <v-select
                        :color="compColor.primeColor"
                        :dark="compColor.isInDarkMode"
                        :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                        label="Dateiformat"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="model.charmap"
                        :items="exportCharmap"
                        :error-messages="errors[0]"
                        hide-no-data
                        required
                        outlined
                        class="mb-0"
                      >
                        <template #append v-if="!isDarkMode">
                          <v-icon color="grey lighten-2">mdi-file-cog</v-icon>
                        </template>
                      </v-select>
                    </validation-provider>
                  </div>
                  <div class="flex-100 pa-2">
                    <validation-provider tag="div" ref="Bestell E-Mail Eingabe"
                                         rules="email"
                                         name="Bestell E-Mail Eingabe"
                                         v-slot="{ errors }">
                      <div class="layout-row">
                        <div class="flex">
                          <v-text-field
                            :color="compColor.primeColor"
                            label="Fügen Sie eine neue E-Mail Adresse der Liste hinzu"
                            prepend-inner-icon="email"
                            :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                            v-model="receiver"
                            outlined
                            clearable
                            class="mb-0"
                            :dark="compColor.isInDarkMode"
                            :dense="denseFields"
                            @keyup="addReceiver"
                            :error-messages="errors[0]"
                          >
                            <template #prepend-inner v-if="!isDarkMode">
                              <v-icon color="grey lighten-2">mdi-mail</v-icon>
                            </template>
                            <template #append>
                              <div style="height:100%;line-height: 34px;margin-top: -7px;">

                                <lx-btn :color="'#353a47'"
                                        :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                                        small
                                        :disabled="typeof errors[0] === 'string' || !receiver"
                                        iconSize="20"
                                        width="auto"
                                        height="34"
                                        classes="text-none mr-n1 ml-3"
                                        @click="addReceiver"
                                        icon="add_circle"
                                        :tooltip="$t('BUTTONS.ADD_EMAIL.TOOLTIP')"
                                        :label="$t('BUTTONS.ADD_EMAIL.TITLE')"></lx-btn>
                              </div>
                            </template>
                          </v-text-field>
                        </div>
                      </div>
                    </validation-provider>
                  </div>

                  <div class="flex-100 pa-2" v-if="model.receivers && model.receivers.length > 0">
                    <fake-input-frame label="Bestell E-Mail Adressen" :data-fake-height="52"
                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                      class="mb-6">
                      <div class="layout-row layout-wrap px-2 pt-0 pb-2">
                        <v-chip v-for="(mail, index) in (model.receivers || [])"
                                v-bind:key="index"
                                :class="compColor.prime"
                                class="mr-2 mt-1 blue-grey--text text--darken-2 lighten-4" style="border-radius: 3px">
                          <v-avatar :color="compColor.prime + ' lighten-1'" left class="ml-n2"
                                    style="border-radius: 2px">
                            <v-icon color="white" size="12">email</v-icon>
                          </v-avatar>
                          <strong>{{ mail }}</strong>
                          <v-icon class="ml-2 mr-n1" size="20" style="opacity: 0.5;" @click="removeReceiver(index)">
                            mdi-delete-forever
                          </v-icon>
                        </v-chip>
                      </div>
                    </fake-input-frame>
                  </div>
                  <div class="flex-100 pa-2" v-if="model.format ==='adg'">
                    <validation-provider tag="div" ref="adg_commit_price"
                                         name="adg_commit_price">
                      <fake-input-frame label="Zusätzliche Einstellungen"
                                        :input-background="isDarkMode ? 'transparent':'#f5f5f5'"
                                        class="mb-6">
                        <div class="layout-row layout-align-start-center px-4 mt-n2"
                             :style="{height: denseFields ? '40px':'50px'}">
                          <v-switch
                            :dark="compColor.isInDarkMode"
                            :dense="denseFields"
                            :color="compColor.primeColor"
                            v-model="model.enable_price_export"
                            inset
                            class="ma-0"
                            hide-details
                            name="is_active">
                            <template v-slot:label>
                              Artikelpreise übermitteln
                            </template>
                          </v-switch>
                        </div>
                      </fake-input-frame>
                    </validation-provider>
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel v-if="isPanelValidFor === 'soap'">
              <lx-expansion-panel-header icon="mdi-share-variant"
                                         :main-headline="$t('PANELS.DATA_EXPORT.SOAP_TRANSPORT.HEADLINE')"
                                         :sub-headline="$t('PANELS.DATA_EXPORT.SOAP_TRANSPORT.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2" v-show="!isSoapEnabled">
                    <v-alert border="left"
                             prominent
                             style="font-size:15px;"
                             class="px-3 py-2"
                             v-bind:class="{
                           'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode,
                           'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode
                         }">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      Die Schnittstelle muss für Sie gesondert freigeschaltet werden
                    </v-alert>
                  </div>

                  <div class="flex-100 pa-2" v-show="isSoapEnabled">
                    <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Benutzername</div>
                    <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                      {{ model.username || '** wird automatisch generiert **' }}
                    </div>
                    <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
                  </div>

                  <div class="flex-100 pa-2" v-show="isSoapEnabled">
                    <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Passwort</div>
                    <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                      {{ model.password || '** wird automatisch generiert **' }}
                    </div>
                    <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
                  </div>

                  <div class="flex-100 pa-2" v-show="isSoapEnabled">
                    <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Backend Adresse</div>
                    <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                      {{ backendURL }}
                    </div>
                    <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
                  </div>
                  <!--                  <div class="flex-100 pa-2">-->
                  <!--                    <fake-input-frame label="Bestell E-Mail Adressen" :data-fake-height="52"-->
                  <!--                                      :input-background="isDarkMode ? 'transparent':'#f5f5f5'"-->
                  <!--                                      class="mb-2">-->
                  <!--                      <div class="layout-row layout-wrap px-2 pt-0 pb-2">-->
                  <!--                          <v-avatar :color="compColor.prime + ' lighten-1'" left class="ml-n2"-->
                  <!--                                    style="border-radius: 2px">-->
                  <!--                            <v-icon color="white" size="12">email</v-icon>-->
                  <!--                          </v-avatar>-->
                  <!--                          <strong>Fehler</strong>-->
                  <!--                      </div>-->
                  <!--                    </fake-input-frame>-->
                  <!--                  </div>-->
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

            <lx-expansion-panel v-if="isPanelValidFor === 'httpapi'">
              <lx-expansion-panel-header icon="mdi-share-variant"
                                         :main-headline="$t('PANELS.DATA_EXPORT.HTTP_TRANSPORT.HEADLINE')"
                                         :sub-headline="$t('PANELS.DATA_EXPORT.HTTP_TRANSPORT.SUBHEADING')">
              </lx-expansion-panel-header>
              <lx-expansion-panel-content>
                <div class="layout-row layout-wrap mt-3 pa-2">
                  <div class="flex-100 pa-2" v-show="!isHTTPAPIEnabled">
                    <v-alert border="left"
                             prominent
                             style="font-size:15px;"
                             class="px-3 py-2"
                             v-bind:class="{
                           'light-blue lighten-4 blue-grey--text text--darken-2': compColor.isInDarkMode,
                           'blue-grey--text text--darken-1 blue lighten-5': !compColor.isInDarkMode
                         }">
                      <template slot="prepend">
                        <v-avatar>
                          <v-icon large>mdi-information-variant</v-icon>
                        </v-avatar>
                      </template>
                      Die Schnittstelle muss für Sie gesondert freigeschaltet werden
                    </v-alert>
                  </div>

                  <div class="flex-100 pa-2" v-show="isHTTPAPIEnabled">
                    <validation-provider tag="div" ref="httpapi_url"
                                         rules="required|url"
                                         name="URL"
                                         v-slot="{ errors }">
                      <v-text-field
                          label="URL"
                          prepend-inner-icon="mdi-account-tie"
                          v-model="model.url"
                          class="mb-0"
                          :color="compColor.primeColor"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          :dark="compColor.isInDarkMode"
                          :dense="denseFields"
                          :error-messages="errors[0]"
                          outlined
                      ></v-text-field>
                    </validation-provider>
<!--                    <div class="caption blue-grey&#45;&#45;text" :class="{'text&#45;&#45;lighten-3' : !isDarkMode}">Benutzername</div>-->
<!--                    <div class="body-1 font-weight-bold blue-grey&#45;&#45;text mt-n1 mb-1" :class="{'text&#45;&#45;lighten-3' : isDarkMode}">-->
<!--                      {{ model.username || '** wird automatisch generiert **' }}-->
<!--                    </div>-->
<!--                    <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>-->
                  </div>

                  <div class="flex-100 pa-2" v-show="isHTTPAPIEnabled">
                    <validation-provider tag="div" ref="httpapi_password"
                                         rules="required"
                                         name="Passwort"
                                         v-slot="{ errors }">
                      <v-text-field
                          label="Passwort"
                          prepend-inner-icon="fingerprint"
                          v-model="model.password"
                          class="mb-0"
                          :color="compColor.primeColor"
                          :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                          :dark="compColor.isInDarkMode"
                          :dense="denseFields"
                          :error-messages="errors[0]"
                          :append-icon="displayPassword ? 'visibility_off' : 'visibility'"
                          :type="displayPassword ? 'text' : 'password'"
                          @click:append="displayPassword = !displayPassword"
                          outlined

                      ></v-text-field>
                    </validation-provider>
<!--                    <div class="caption blue-grey&#45;&#45;text" :class="{'text&#45;&#45;lighten-3' : !isDarkMode}">Passwort</div>-->
<!--                    <div class="body-1 font-weight-bold blue-grey&#45;&#45;text mt-n1 mb-1" :class="{'text&#45;&#45;lighten-3' : isDarkMode}">-->
<!--                      {{ model.password || '** wird automatisch generiert **' }}-->
<!--                    </div>-->
<!--                    <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>-->
                  </div>

<!--                  <div class="flex-100 pa-2" v-show="isHTTPAPIEnabled">-->
<!--                    <div class="caption blue-grey&#45;&#45;text" :class="{'text&#45;&#45;lighten-3' : !isDarkMode}">Backend Adresse</div>-->
<!--                    <div class="body-1 font-weight-bold blue-grey&#45;&#45;text mt-n1 mb-1" :class="{'text&#45;&#45;lighten-3' : isDarkMode}">-->
<!--                      {{ backendURL }}-->
<!--                    </div>-->
<!--                    <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>-->
<!--                  </div>-->

                  <lx-btn :color="'#353a47'"
                          block
                          :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                          small
                          class="flex"
                          iconSize="20"
                          width="auto"
                          classes="text-none"
                          @click.stop="showJobLog = true"
                          icon="history"
                          :tooltip="$t('BUTTONS.TRANSFER_HISTORY.TOOLTIP')"
                          :label="$t('BUTTONS.TRANSFER_HISTORY.TITLE')"></lx-btn>
                  <div class="flex-100 pa-2">
                  </div>
                </div>
              </lx-expansion-panel-content>
            </lx-expansion-panel>

          </lx-expansion-panels>
        </div>
      </div>
    </ValidationObserver>

    <lx-dialog v-model="showJobLog" :allow-drag="true" :overlay="true" :size="{x: '80', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          Übertragungsverlauf
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <lx-simple-table url="/logging/jobs/job_httpapi_pthttp"></lx-simple-table>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  block
                  classes="text-none mr-1 flex"
                  @click.stop="showJobLog = false"
                  icon="cancel"
                  :tooltip="$t('BUTTONS.DIALOG.CLOSE.TOOLTIP')"
                  :label="$t('BUTTONS.DIALOG.CLOSE.TITLE')"></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>

<script>
import lodash from 'lodash'
import LxSimpleTable from "@/components/lx_simple_table/LxSimpleTable.vue";

const EMailAddressRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: "DataExport.vue",
  components: {LxSimpleTable},
  data() {
    return {
      hoverHeader: false,

      dialog: false,
      panel: [0, 1, 2, 3],
      loading: false,
      errors: [],

      model: {},
      oldModel: null,
      modules: [],

      backendURL: process.env.VUE_APP_BASE_API + '/api/wsdl.svc',
      isSoapEnabled: false,
      isHTTPAPIEnabled: false,
      exportTypes: [],
      exportFormat: [],
      exportContent: [],
      exportCharmap: [],
      exportSettings: {},
      receiver: '',
      dummyPending: false,
      showJobLog: false,
      joblogdata: [],
      displayPassword: false
    }
  },
  created() {
    this.pull()

    this.$nextTick(() => {
      let scroller = this.$refs.scrollContainer
      scroller.addEventListener('scroll', () => {
        if (scroller.scrollTop > 0) {
          let number = scroller.scrollTop
          number = number > 4 ? 4 : number
          this.hoverHeader = 'elevation-' + number
        } else {
          this.hoverHeader = ''
        }
      })
    })
  },
  computed: {
    showBtns() {
      return lodash.isEqual(this.oldModel, this.model)
    },
    isPanelValidFor() {
      if (this.model.type !== null && this.model.format !== null) {
        return this.model.type
      }

      return 'null'
    }
  },
  methods: {
    addReceiver(e) {
      if (this.dummyPending || e instanceof KeyboardEvent && e.keyCode !== 13) return
      if (!EMailAddressRegEx.test(this.receiver)) return

      this.dummyPending = true

      this.$nextTick()
        .then(() => {
          const value = this.receiver.trim().toLowerCase()

          if (Array.isArray(this.model.receivers)) {
            const index = this.model.receivers.findIndex((a) => {
              return a.toLowerCase() === value;
            });

            if (index !== -1) return
          } else {
            this.model.receivers = []
          }

          this.model.receivers.push(value)
          this.receiver = null

        })
        .finally(() => {
          this.dummyPending = false
        })
    },
    removeReceiver(index) {
      this.dummyPending = true

      this.$nextTick()
        .then(() => {
          this.$delete(this.model.receivers, index)
        })
        .finally(() => {
          this.dummyPending = false
        })
    },
    openCloseExpansionPanel(index) {
      if (this.panel.indexOf(index) < 0) {
        this.panel.push(index)
      } else {
        this.panel.splice(this.panel.indexOf(index), 1)
      }
    },
    save() {
      this.$http.post('/settings/export', this.model)
        .then(() => {
          if (this.$refs.exportSettings) {
            this.$refs.exportSettings.reset()
          }
          this.NotifySystem('Änderungen gespeichert')
          this.getData()
        })
        .catch(this.NotifyError)
    },
    populatesFormats() {
      this.exportFormat = (!this.model.type) ? null : this.exportSettings[this.model.type].formats
      this.exportContent = (!this.model.type) ? null : this.exportSettings[this.model.type].contents
      this.exportCharmap = (!this.model.type) ? null : this.exportSettings[this.model.type].charmap
    },
    changeFormat () {
      this.loading = true

      this.populatesFormats()

      this.$nextTick()
          .then(() => {
            if (this.oldModel && this.oldModel.type === this.model.type) {
              return this.resetFormular()
            }

            this.model = {
              type: this.model.type,
              format: null
            }
          })
          .finally(() => {
            this.loading = false
          })
    },
    clear() {
      this.loading = true;
      this.$nextTick()
        .then(() => {
          this.model = {
            type: this.model.type,
            format: (!this.model.type) ? null : this.model.format
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetFormular(reset) {
      if (reset) reset()

      this.populatesFormats()

      this.$nextTick()
        .then(() => {
          this.model = lodash.cloneDeep(this.oldModel)
          this.populatesFormats()
        })
    },
    getData() {
      if (this.loading) return

      this.loading = true

      this.$http.get('/settings/export')
        .then((res) => {
          this.model = res.data || {}
          this.oldModel = lodash.cloneDeep(this.model)
          this.populatesFormats()
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false
        })
    },
    pull() {
      if (this.loading) return

      this.loading = true

      this.$http.get('/settings/modules')
          .then((res) => {
            const data = res.data || {}

            this.modules = data.modules || []
            this.isSoapEnabled = this.modules.indexOf('soap') !== -1
            this.isHTTPAPIEnabled= this.modules.indexOf('httpapi') !== -1
          })

      this.$http.get('/settings/options/export')
        .then((res) => {
          if (res && res.data) {
            this.exportTypes = res.data.types
            this.exportFormat = res.data.formats
            this.exportSettings = res.data.settings

            this.exportContent = res.data.contents
            this.exportCharmap = res.data.charmap
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.loading = false
          this.getData()
        })
    }
  }
}
</script>

<style scoped>

</style>
