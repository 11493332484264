<template>
  <div ref="scrollContainer" class="flex layout-column" style="overflow-y: auto;">
    <v-progress-linear :indeterminate="isBusy" v-if="isBusy" color="red accent-2"
                       background-color="red lighten-4" class="ma-0"
                       style="border-radius:0;height:4px;position: absolute;top:56px;left:0; width:100%;"></v-progress-linear>

    <lx-expansion-panels :single-open="false" v-model="panel" class="pa-1">
      <lx-expansion-panel>
        <lx-expansion-panel-header icon="mdi-account-circle"
                                   :main-headline="$t('PANELS.REPORTS.SPECIAL_FEE.HEADLINE')"
                                   :sub-headline="$t('PANELS.REPORTS.SPECIAL_FEE.SUBHEADING')">
        </lx-expansion-panel-header>
        <lx-expansion-panel-content class="pa-3 pr-2">
          <ValidationObserver ref="AdditionalFeeReport" v-slot="{ valid, pristine, reset }" class="layout-column flex">
            <lx-obfuscation-panel
              class="layout-row layout-wrap"
              :enabled="disabled || !ifModule('ze_item')"
            >
              <template v-slot:obfuscation-text>
                <v-alert
                  icon="mdi-shield-lock-outline"
                  outlined
                  text
                  dense
                  border="left"
                  type="info"
                >Dieses Modul ist nicht aktiviert
                </v-alert>
              </template>
              <!-- Von -->
              <div class="flex-gt-sm-20 flex-100 pl-1 pr-3">
                <validation-provider tag="div"
                                     ref="af_from"
                                     name="af_from"
                                     rules="required|german_date|date_not_in_future"
                                     v-slot="{ errors }">
                  <lx-datepicker
                    :color="compColor.textInput"
                    :dark="isDarkMode"
                    :dense="compColor.denseFields"
                    :error="errors[0]"
                    name="fromDate"
                    label="Von"
                    placeholder=" "
                    class="mr-1"
                    v-model="model.from"
                  ></lx-datepicker>
                </validation-provider>
              </div>

              <!-- Bis -->
              <div class="flex-gt-sm-20 flex-100 pl-1 pr-3">
                <validation-provider tag="div"
                                     ref="af_from"
                                     name="af_from"
                                     rules="required|german_date|date_not_in_future"
                                     v-slot="{ errors }">
                  <lx-datepicker
                    :color="compColor.textInput"
                    :dark="isDarkMode"
                    :dense="compColor.denseFields"
                    :error="errors[0]"
                    :disabled="disabled"
                    name="toDate"
                    label="Bis"
                    placeholder=" "
                    class="mr-1"
                    v-model="model.to"
                  ></lx-datepicker>
                </validation-provider>
              </div>

              <!-- Listtyp -->
              <div class="flex-gt-sm-10 flex-100 pl-1 pr-3">
                <validation-provider tag="div"
                                     ref="af_report_type"
                                     name="af_report_type"
                                     rules="required"
                                     v-slot="{ errors }">
                  <v-select
                    :dark="isDarkMode"
                    :color="compColor.primeColor"
                    :dense="denseFields"
                    :background-color="isDarkMode ? 'transparent':'#f5f5f5'"
                    :items="reportTypes"
                    :errors="errors[0]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="Sonderentgelt Artikel"
                    placeholder="Nichts"
                    item-text="caption"
                    item-value="value"
                    hide-no-data
                    hide-details
                    class="mb-0"
                    v-model="model.exporttype"
                    outlined
                  ></v-select>
                </validation-provider>
              </div>

              <!-- Krankenhaus -->
              <div class="flex-gt-sm-35 flex-100 pl-1 pr-3">
                <validation-provider tag="div"
                                     ref="af_location"
                                     name="af_location"
                                     rules="required"
                                     v-slot="{ errors }">
                  <v-select
                    :dark="isDarkMode"
                    :color="compColor.primeColor"
                    :dense="denseFields"
                    :background-color="isDarkMode ? 'transparent':'#f5f5f5'"
                    :items="locations"
                    :errors="errors[0]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="Medizinische Einrichtung"
                    placeholder="Keine"
                    item-text="name"
                    item-value="_id"
                    hide-no-data
                    hide-details
                    class="mb-0"
                    v-model="model.location"
                    outlined
                  ></v-select>
                </validation-provider>
              </div>

              <div class="flex-gt-sm-10 flex-100 pl-1 pr-3">
                <lx-report
                  :disabled="disabled || isBusy || !valid"
                  label="Generieren"
                  :url="'/reports/additionalfee/' + model.location.toString()"
                  :options="{filename: 'report', type: model.exporttype, from_date: model.from, to_date: model.to}"
                ></lx-report>

              </div>
            </lx-obfuscation-panel>
          </ValidationObserver>
        </lx-expansion-panel-content>
      </lx-expansion-panel>
    </lx-expansion-panels>
  </div>
</template>

<script>
import lxDatepicker from '@/components/lx_datepicker/LxDatepicker'
import LxObfuscationPanel from '@/components/lx_obfuscation_panel/LxObfuscationPanel'
import LxReport from '@/components/lx_report/LxReport';
import moment from 'moment'

export default {
  name: 'ReportPharmacy',
  components: {
    lxDatepicker,
    LxObfuscationPanel,
    LxReport
  },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    modules: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: 0,
      panel: [0, 1],
      model: {
        from: moment().subtract(3, 'month').hours(0).minutes(0).seconds(0).toDate(),
        to: moment().hours(23).minutes(59).seconds(59).toDate(),
        location: ''
      },
      reportTypes: [
        { caption: 'ZE Artikel', value: 'ze_item' },
        { caption: 'Nub Artikel', value: 'nub_item' },
      ],
      locations: []
    }
  },
  computed: {
    isBusy() {
      return this.loading > 0
    }
  },
  mounted() {
    const filter = { config: { opts: { sort: { name: 1 } } } }

    this.loading++
    this.$http.get('/hospital', filter)
      .then(res => {
        if (res.data && res.data.data) {
          this.locations = res.data.data
        }
      })
      .finally(() => {
        this.loading--
      })
  },
  methods: {
    ifModule(name) {
      return Array.isArray(this.modules) && this.modules.indexOf(name) !== -1
    },
  }
}
</script>

<style scoped>

</style>
