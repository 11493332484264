<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-row">
    <div class="py-2 pl-2 lx-dark-drawer layout-column"
         :style="{width: setNavMini ? '85px':'320px'}">

      <div class="flex layout-column" v-bind:style="{background:  compColor.adminNav}"
           style="position:relative;overflow:visible;border-radius:6px;">
        <div class="text-center"
             style="padding-top: 6px !important;padding-bottom: 6px !important;background:rgba(255,255,255,0.05);border-radius: 6px 6px 0 0;">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text icon @click="setNavMini = !setNavMini">
                <v-icon color="white">mdi-format-list-checkbox</v-icon>
              </v-btn>
            </template>
            <span>Menu ein-/ausklappen</span>
          </v-tooltip>
        </div>

        <div class="flex layout-column elevation-3 white--text pa-2 pb-9" style="overflow-y:auto;">

          <v-list subheader dark class="transparent" v-bind:class="{'mini-list': setNavMini}">

            <div v-for="(category, index) in navObj" :key="index" class="">
              <v-divider class="my-2" v-bind:class="compColor.textInput" v-show="setNavMini"></v-divider>
              <v-subheader style="padding-left: 6px !important;opacity:0.85;text-align: right;" v-show="!setNavMini"
                           v-bind:class="[compColor.primeHighlightClass]" class="mt-5 mb-1 ml-1">{{ category.title }}
              </v-subheader>
              <div v-for="(navItem, navIndex) in category.items" :key="navIndex" class="pl-1">

                <v-list-item two-line :to="navItem.route" :name="navItem.title">
                  <v-list-item-content>
                    <div class="layout-row" style="position:relative;">
                      <div class="flex">
                        <v-list-item-title class="ma-0">
                          <v-tooltip right v-if="setNavMini">
                            <template v-slot:activator="{ on }">
                              <strong v-on="on">{{ navItem.short }}</strong>
                            </template>
                            <div class="text-center">{{ navItem.title }}</div>
                          </v-tooltip>

                          <strong v-if="!setNavMini">{{ navItem.title }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="!setNavMini" class="font-weight-light caption">
                          {{ navItem.subtitle }}
                        </v-list-item-subtitle>
                      </div>
                      <div class="layout-column layout-align-center-center" style="position:absolute;right:0px;top:25%;"
                           v-if="$route.path.indexOf(navItem.route) === 0">
                        <v-icon size="10" :color="compColor.primeHighlightColor">mdi-checkbox-blank-circle</v-icon>
                      </div>
                    </div>
                  </v-list-item-content>

                </v-list-item>
              </div>
            </div>
          </v-list>
        </div>

        <div class="mr-4"
             :style="{'background-image': 'linear-gradient(' + compColor.adminNav +' 5%, transparent 60%)'}"
             style="position:absolute;top:48px;left:0;right:0;height:50px;"></div>

        <div class="mr-4" :style="{'background-image': 'linear-gradient(transparent 0,' + compColor.adminNav + ' 60%)'}"
             style="position:absolute;bottom:0;left:0;right:0;height:40px;border-radius: 0 0 6px 6px;"></div>
      </div>

    </div>
    <div class="flex" style="position:relative;">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>

import router from '../../router'

export default {
  data() {
    return {
      navObj: null,
      drawer: null,
      setNavMini: false
    }
  },
  /**

   * die Admin Navigationspunkte wurden in die router.js in den meta Bereich der Router Options ausgelagert
   * NavObj bekommt all diese Route
   * danach werden alle anhand der Nutzerrechte gefilter und die erlaubten Links in this.navObj übergeben
   *
   * **/
  created() {
    let NavObj = router.options.meta.adminNavigation || [];
    // const location_sub_type = this.getUser().location_sub_type
    // const location_type = this.getUser().location_type
    //
    // console.log(location_type, location_sub_type)

    let tempNavObj = [];

    NavObj.forEach((category) => {
      let categoryCopy = Object.assign({}, category)

      categoryCopy.items = categoryCopy.items.filter((item) => {
        return ((item.visibility.role && this.showHTMLByRole(item.visibility.role)) || this.showHTMLByRight(item.visibility))
      })

      if (categoryCopy.items.length > 0) {
        tempNavObj.push(categoryCopy)
      }
      // let filteredNavItems = [];
      //
      // let kk = categoryCopy.items.filter((item) => {
      //   return ((item.visibility.role && this.showHTMLByRole(item.visibility.role)) || this.showHTMLByRight(item.visibility))
      // })
      //
      // categoryCopy.items.forEach(item => {
      //   if ((item.visibility.role && this.showHTMLByRole(item.visibility.role)) || this.showHTMLByRight(item.visibility)) {
      //     filteredNavItems.push(item)
      //   }
      // });
      //
      // console.log(kk, filteredNavItems)
      //
      // categoryCopy.items = filteredNavItems;
      // if (filteredNavItems.length > 0) {
      //   tempNavObj.push(categoryCopy)
      // }
    });

    this.navObj = tempNavObj
  },
  mounted() {

  },
  destroyed() {

  },
  computed: {}
}
</script>
<style lang="scss">

.lx-dark-drawer {
  .v-subheader {
    //border-bottom: 1px solid currentColor;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 25px;
    margin: 19px 0 12px 0;
    padding: 0px !important;
  }

  /*.v-list.theme--dark{*/
  /*    background: transparent;*/
  /*}*/
  .mini-list {
    .v-list-item--link:before {
      background: transparent;
    }

    .v-list-item--active {
      background: transparent;
    }
  }

  .v-list--two-line .v-list-item .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list--two-line .v-list-item .v-list-item__icon, .v-list--three-line .v-list-item .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list--three-line .v-list-item .v-list-item__icon, .v-list-item--two-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list-item--two-line .v-list-item__icon, .v-list-item--three-line .v-list-item__avatar:not(.v-list-item__avatar--horizontal), .v-list-item--three-line .v-list-item__icon {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .v-list-item__content {
    padding: 0px 0;
  }

  .v-list--two-line .v-list-item, .v-list-item--two-line {
    min-height: 44px;
  }

  .v-list-item__title {
    font-size: 1em;
  }

  .v-list-item__subtitle {
    font-size: 0.875em;
  }

  .v-list-item {
    padding: 0 6px;
  }

  .v-application--is-ltr .v-list-item__avatar:first-child {
    margin-right: 12px;
  }

  .v-list-item--link:before {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

}
</style>
