<template>
  <div class="pa-0 ma-2 pdf-popup">
    <v-treeview
        :items="items"
        :dark="compColor.isInDarkMode"
        :color="compColor.primeColor"
        :open.sync="open"
        item-key="id"
        open-on-click
        dense
        hoverable
        return-object
        style="border-radius:6px;"
        :style="{background: compColor.context.background}"
        v-bind:class="{'elevation-3': !showEditor}"
    >
      <template v-slot:label="{item}">
        <div class="layout-column mb-1" v-bind:class="{'line-background': showEditor}">
          <!--          <div style="border-bottom: 1px solid rgba(0,0,0,0.03)">-->
          <div v-bind:class="{'editor-border':showEditor }">
            <v-btn block text @click="download(item)" :disabled="!item.isFile"
                   v-if="item.isFile"
                   class="text-none blue-grey--text justify-start"
                   :title="item.contenttype" style="">
              <div class="layout-row ml-n2">
                <div style="">
                  <v-icon size="20" v-if="!item.isFile && item.isRoot" :color="compColor.primeColor">
                    {{ 'mdi-file-tree' }}
                  </v-icon>
                  <v-icon size="20" v-else-if="!item.isFile && !item.isRoot"
                          v-bind:class="[{'orange--text': item.isPending}, compColor.isInDarkMode ?  'amber--text text--lighten-4' : 'amber--text text--darken-1']">
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                  <div v-else>
                    <v-icon size="20" v-if="item.contenttype === 'application/pdf'"
                            v-bind:class="[compColor.isInDarkMode ? 'red--text text--lighten-3' : 'red--text text--lighten-2']">
                      {{ 'mdi-file-pdf' }}
                    </v-icon>
                    <v-icon size="20" v-else-if="item.contenttype === 'image/jpeg' || item.contenttype === 'image/png'"
                            v-bind:class="[compColor.isInDarkMode ? 'green--text text--lighten-2' : 'green--text text--lighten-1']">
                      {{ 'mdi-file-image' }}
                    </v-icon>
                    <v-icon size="20" v-else
                            v-bind:class="[compColor.isInDarkMode ? 'blue-grey--text' : 'blue-grey--text text--lighten-1']">
                      {{ 'mdi-file' }}
                    </v-icon>
                  </div>
                </div>
              </div>
              <div v-bind:class="{'pending font-italic': item.isPending}" class="layout-row flex text-left ml-1"
                   style="padding-top:2px;">
                <span>{{ item.name }}</span>
              </div>

              <div class="align-right">
                  <span class="text--lighten-2 font-weight-regular caption"
                        v-bind:class="[compColor.textInput + '--text']"
                        style="line-height: 1.0rem"
                        v-if="!showEditor">{{ item.updated | formatDate('DD.MM.YYYY') }}</span>
              </div>
            </v-btn>

            <div v-else>

              <div class="layout-row ml-2">
                <div class="pt-1">
                  <v-icon size="20" v-if="!item.isFile && item.isRoot" :color="compColor.primeColor">
                    {{ 'mdi-file-tree' }}
                  </v-icon>
                  <v-icon size="20" v-else-if="!item.isFile && !item.isRoot"
                          v-bind:class="[{'orange--text': item.isPending}, compColor.isInDarkMode ?  'amber--text text--lighten-4' : 'amber--text text--darken-1']">
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                </div>

                <div v-bind:class="{'pending font-italic': item.isPending}"
                     class="flex blue-grey--text text-left text-body-2 ml-1" style="padding-top:5px;">
                  <span>{{ item.name }}</span>
                </div>

                <div v-if="showEditor" class="align-right layout-row">
                  <div class="" style="width: 55px;"
                       v-bind:class="[compColor.textInput + '--text','text--lighten-2']">
                        <span class="text-center body-2 blue-grey--text text--lighten-3"
                              v-if="!item.isFile && item.isRoot">Root</span>
                    <span class="text-center body-2 blue-grey--text text--lighten-3"
                          v-else-if="!item.isFile && !item.isRoot">Ordner</span>
                    <span class="text-center body-2 blue-grey--text text--lighten-3" v-else>Datei</span>
                  </div>

                  <div class="text-center" style="width: 30px;">
                    <v-btn small text icon :color="compColor.primeColor" @click="showDialog($event, 'create', item)"
                           title="Ordner erstellen" v-show="!item.isFile" :disabled="item.isPending"
                           class="text-none mr-1" depressed dark>
                      <v-icon class="">create_new_folder</v-icon>
                      <!--          Ordner erstellen-->
                    </v-btn>
                  </div>

                  <div class="text-center" style="width: 30px;">
                    <v-btn small text icon :color="compColor.primeColor" @click="showDialog($event, 'upload', item)"
                           title="Dokument hochladen" v-show="!item.isFile" class="text-none mr-1" depressed dark>
                      <v-icon class="">mdi-file-upload</v-icon>
                      <!--          Datei hochladen-->
                    </v-btn>
                  </div>

                  <div class="text-center" style="width: 30px;">
                    <v-btn small text icon :color="compColor.primeColor" @click="showDialog($event, 'edit', item)"
                           title="Bearbeiten"
                           v-show="!item.isRoot" :disabled="!item.isFile"
                           class="text-none mr-1" depressed dark>
                      <v-icon>settings</v-icon>
                      <!--          Löschen-->
                    </v-btn>
                  </div>

                  <div class="text-center" style="width:30px;">
                    <v-btn small text icon :color="compColor.primeColor" @click="preDelete($event, item)"
                           title="Entfernen"
                           v-show="!item.isRoot" :disabled="item.children && item.children.length > 0"
                           class="text-none mr-1" depressed dark>
                      <v-icon>delete_forever</v-icon>
                      <!--          Löschen-->
                    </v-btn>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="flex layout-row layout-align-end" v-if="showEditor && item.isFile" style="">

            <div class="blue-grey--text text--lighten-3 px-2" v-if="showEditor && item.isFile">
              <small v-if="item.owner" class="red--text text--lighten-2">Eingeschränk</small>
              <small class="lime--text lighten-2" v-else>Für alle sichtbar</small>
            </div>

            <div class="flex"></div>

            <div class="mr-1 blue-grey--text text--lighten-3 d-none d-md-block" style="width: 120px;">
              <small v-if="item.updated">{{ item.updated | formatDate('DD.MM.YYYY HH:mm') }} Uhr</small>
            </div>

            <div class="mr-3 text-right blue-grey--text text--lighten-3 d-none d-md-block" style="width: 70px;">
              <small v-if="typeof item.size === 'number' && item.size > 0">{{ Math.round(item.size / 1000) }} kb</small>
            </div>

            <div class="" style="width: 55px;"
                 v-bind:class="[compColor.textInput + '--text','text--lighten-2']">
                        <span class="text-center body-2 blue-grey--text text--lighten-3"
                              v-if="!item.isFile && item.isRoot">Root</span>
              <span class="text-center body-2 blue-grey--text text--lighten-3"
                    v-else-if="!item.isFile && !item.isRoot">Ordner</span>
              <span class="text-center body-2 blue-grey--text text--lighten-3" v-else>Datei</span>
            </div>

            <div class="text-center" style="width: 30px;">
              <v-btn small text icon :color="compColor.primeColor" @click="showDialog($event, 'create', item)"
                     title="Ordner erstellen" v-show="!item.isFile" :disabled="item.isPending"
                     class="text-none mr-1" depressed dark>
                <v-icon class="">create_new_folder</v-icon>
                <!--          Ordner erstellen-->
              </v-btn>
            </div>

            <div class="text-center" style="width: 30px;">
              <v-btn small text icon :color="compColor.primeColor" @click="showDialog($event, 'upload', item)"
                     title="Dokument hochladen" v-show="!item.isFile" class="text-none mr-1" depressed dark>
                <v-icon class="">mdi-file-upload</v-icon>
                <!--          Datei hochladen-->
              </v-btn>
            </div>

            <div class="text-center" style="width: 30px;">
              <v-btn small text icon :color="compColor.primeColor" @click="showDialog($event, 'edit', item)"
                     title="Bearbeiten"
                     v-show="!item.isRoot" :disabled="!item.isFile"
                     class="text-none mr-1" depressed dark>
                <v-icon>settings</v-icon>
                <!--          Löschen-->
              </v-btn>
            </div>

            <div class="text-center" style="width:30px;">
              <v-btn small text icon :color="compColor.primeColor" @click="preDelete($event, item)" title="Entfernen"
                     v-show="!item.isRoot" :disabled="item.children && item.children.length > 0"
                     class="text-none mr-1" depressed dark>
                <v-icon>delete_forever</v-icon>
                <!--          Löschen-->
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </v-treeview>

    <!-- PRINT DIALOG -->
    <v-dialog
        persistent
        v-model="pdfDialog"
        overlay-opacity="0.9"
        width="90%"
        content-class="pdf-popup"
        transition="dialog-scale-transition"
        :dark="compColor.isInDarkMode"
        :color="compColor.primeColor"
    >
      <div class="elevation-10 layout-column" style="border-radius: 6px;height:100%;min-height:100%;max-height:100%;"
           v-bind:style="{background: compColor.contentFrame}">

        <!-- CONTENT  -->
        <div class="ma-1 pa-1 flex layout-column lx-delivery-background" :class="{'elevation-3': !loadingPDF}"
             style="border-radius: 5px;position: relative;"
             :style="{'background': loadingPDF ? 'transparent' : compColor.lxExpansion.background}">

          <lx-pdf-viewer :value="pdfObject" :loading="loadingPDF"></lx-pdf-viewer>

          <lx-loading-animation :loading="loadingPDF">
            <span class="mb-n3 pa-0 font-weight-light" style="font-size:1em">lade</span>
            <span class="mb-n3 pa-0 font-weight-bold" style="font-size:3.5em;">Ansicht</span>
          </lx-loading-animation>
        </div>

        <!-- ACTIONS -->

        <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

        <div class="layout-row layout-align-end-center pa-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  block
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click="pdfDialog = false"
                  icon="mdi-close"
                  :tooltip="$t('BUTTONS.PDF_PREVIEW_DIALOG_CLOSE.TOOLTIP')"
                  :label="$t('BUTTONS.PDF_PREVIEW_DIALOG_CLOSE.TITLE')"></lx-btn>
        </div>
      </div>
    </v-dialog>

  </div>
</template>

<script>
import lxPdfViewer from "@/components/lx_pdf_viewer/LxPdfViewer.vue";
import LxLoadingAnimation from "@/components/lx_loading_animation/LxLoadingAnimation.vue";

function SortChildrenName(left, right) {
  const a = left.name.toLowerCase()
  const b = right.name.toLowerCase()

  if (a === b) return 0

  return a < b ? -1 : 1
}

function SortChildrenType(a, b) {
  if (a.isFile === b.isFile) return SortChildrenName(a, b)

  return Number(a.isFile) - (Number(b.isFile) * 2)
}

function ConvertFileListToTreeView(input, showRoot) {
  if (!Array.isArray(input)) return {}

  // Move out or template into a creator function.
  function createPath(info, name) {
    if (info && info.name.endsWith(name)) {
      return {...info, children: []}
    } else {
      return {size: 0, children: []}
    }
  }

  // Resolves the path into objects iteratively (but looks eerily like recursion).
  function resolvePath(root, path) {
    path.name.split('/').reduce(function (pathObject, pathName) {
      // For each path name we come across, use the existing or create a sub path
      pathObject.children[pathName] = pathObject.children[pathName] || createPath(path, pathName)
      // Then return that sub path for the next operation
      return pathObject.children[pathName]
      // Use the passed in base object to attach our resolutions
    }, root)
  }

  // Convert To TreeView
  const tree = input.reduce(function (carry, pathEntry) {
    // On every path entry, resolve using the base object
    resolvePath(carry, pathEntry)
    // Return the base object for succeeding paths, or for our final value
    return carry
    // Create our base object
  }, createPath())
  let id = 0

  function Build(a, o) {
    if (!a.children) return;

    Object.keys(a.children).forEach(function (element) {
      if (element === "") return
      const isFile = Object.keys(a.children[element].children).length === 0

      let sub = {
        ...a.children[element],
        name: element,
        isFile: isFile,
        id: ++id,
        prefix: [o.prefix, element].join('/')
      };
      // Recursive add if not a file
      if (!isFile) {
        sub.children = []
        sub = Build(a.children[element], sub)
        // sub.isFile = false;
      }
      o.children.push(sub)
    });
    // Sort children; bring FOLDERS UP
    if (Array.isArray(o.children)) {
      o.children.sort(SortChildrenType)
    }
    // Out out, the object is out
    return o
  }

  let output = Build(tree, {
    name: 'Ordnerelemente',
    isRoot: true,
    children: []
  })

  return {
    lastid: id,
    items: (showRoot) ? [output] : output.children
  };
}

export default {
  name: 'Downloads',
  components: {
    LxLoadingAnimation,
    lxPdfViewer
  },
  props: [
    'showEditor',
    'showDialog'
  ],
  data() {
    return {
      lastid: 0,
      open: [],
      items: [],
      pdfDialog: false,
      pdfObject: null,
      loadingPDF: false
    }
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    download(item) {
      if (!item.isFile) return

      let filename = item.prefix
      const isPdfPreview = (item.contenttype === 'application/pdf');

      if (isPdfPreview) {
        this.pdfDialog = true
        this.loadingPDF = true
      }

      this.$http.getAsBlob('/document/' + encodeURIComponent(filename))
          .then((res) => {
            if (isPdfPreview && res.data instanceof Blob) {
              this.pdfObject = res.data
            } else {
              let objectURL = URL.createObjectURL(res.data)
              const hiddenElement = document.createElement('a')
              hiddenElement.style.display = 'none'
              hiddenElement.href = objectURL
              hiddenElement.setAttribute('download', item.name)
              document.body.appendChild(hiddenElement)
              hiddenElement.click()
              document.body.removeChild(hiddenElement)

              setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(objectURL)
              }, 100);

            }
          })
          .catch(this.NotifyError)
          .finally(() => {
            this.loadingPDF = false
          })
    },

    createFolder(res, item) {
      item.children = item.children || []
      item.children.push({
        name: res.name,
        id: ++this.lastid,
        isPending: true,
        isFile: false,
        prefix: [item.prefix, res.name].join('/')
      });

      item.children.sort(SortChildrens)
      this.open.push(item)
    },

    preDelete($event, item) {
      if (item.isPending === true) {
        return this.getDocuments()
      }

      this.showDialog($event, 'delete', item)
    },

    deleteItem(item) {
      let filename = item.prefix

      if (!item.isFile && !filename.endsWith('/')) {
        // Folder
        filename += '/'
      }

      this.$http.delete('/document/' + encodeURIComponent(filename))
          .then(() => {
            this.getDocuments()
          })
          .catch(this.NotifyError)
    },

    getDocuments() {
      this.$http.get('/documents')
          .then((res) => {
            let data = ConvertFileListToTreeView(res.data, this.showEditor)

            this.items = data.items
            this.lastid = data.lastid

            if (this.showEditor) this.open = [0]
          })
          .catch(this.NotifyError)
    }
  }
}
</script>

<style>

.line-background {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 5px;
}

.editor-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03)
}

.pdf-preview {
  display: block;
  height: 100%;
  border-width: 0;
  position: relative;
  margin: 0 auto;
  top: 0;
  left: 0;
}

.pdf-popup {
  min-height: 90%;
  height: 90%;
}

</style>