<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-start-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="mdi-chevron-left"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.BACK.TOOLTIP')"
                  :label="$t('BUTTONS.BACK.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="showDialog('approve')"
                  icon="mdi-check"
                  :disabled="!isApprovable"
                  :tooltip="$t('BUTTONS.ORDER_APPROVE.TOOLTIP')"
                  style="border-left:5px solid red;"
                  :label="$t('BUTTONS.ORDER_APPROVE.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="showDialog('reject')"
                  icon="mdi-close"
                  :disabled="!isRejectable"
                  :tooltip="$t('BUTTONS.ORDER_REJECT.TOOLTIP')"
                  :label="$t('BUTTONS.ORDER_REJECT.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="mdi-restore"
                  :tooltip="$t('BUTTONS.ORDER_RESET.TOOLTIP')"
                  :label="$t('BUTTONS.ORDER_RESET.TITLE')"></lx-btn>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-row lx-delivery-background pa-1" style="overflow-y: hidden">

        <div class="elevation-3 flex mr-1 layout-column" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div class="flex layout-column pa-1">
            <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
              <lx-data-table
                v-model="tableObj.selection"
                tableName="OrderDetails"
                :items="items"
                :headers="tableObj.headers"
                :loading="tableObj.loading"
                :tableOptions="tableObj.options"
                :hideContextFilter="true"
                :stayAtScrollPosition="true"
                @changeOptions="changeTableOptions">

                <template v-slot:cellrenderer="{ value }">
<!--                  changes-->
                  <div v-if="value.f.renderer === 'changes'" style="padding:1px;height:35px;" class="layout-row layout-align-center-center" v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">

<!--                      <div class="flex pa-1 layout-row yellow">-->
                        <v-icon color="green darken-3" small v-if="value.i.count === value.i.initial_count">mdi-checkbox-marked-circle-outline</v-icon>
                        <v-icon color="orange darken-1" small v-else>mdi-checkbox-blank-circle</v-icon>
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <div v-if="value.f.renderer === 'ze_item'" style="padding:1px;height:35px;">

                    <v-tooltip bottom open-delay="750">
                      <template v-slot:activator="{ on }">

                        <div v-on="on" class="layout-row layout-align-center-center"
                             style="border-radius:3px;height:33px;">

                          <div class="blue-grey lighten-4 layout-row" v-if="value.i.ze_item"
                               @click="openZeItemDialog(value.i)" style="border-radius: 4px;">
                            <div class="px-1 layout-column layout-align-center-center">
                              <v-icon small color="blue-grey darken-2">mdi-account</v-icon>
                            </div>
                            <span v-if="getValueByObjectPath(value.i, 'ze_item')"
                                  class="px-1 white--text font-weight-bold layout-row layout-align-center-center blue-grey"
                                  style="border-radius:0 4px 4px 0;max-width:23px;min-width:23px;height:25px;">
                        {{ getValueByObjectPath(value.i, 'ze_item').length }}
                      </span>
                          </div>

                        </div>
                      </template>
                      <span>ZE/NUB Dateneingabe</span>
                    </v-tooltip>
                  </div>

                  <div v-if="value.f.renderer === 'currency_ger'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                      {{ getValueByObjectPath(value.i, value.f.value) | currency_ger }} €
                    </div>
                  </div>
                  <div v-if="value.f.renderer === 'changeNumber'" style="padding:1px;height:35px;">
                    <v-tooltip bottom open-delay="750" v-if="!value.i.ze_item">
                      <template v-slot:activator="{ on }">
                        <div v-on="on" class="layout-row layout-align-center-center px-1"
                             style="border-radius:3px;height:33px;">
                          <agGridComponentInputCellEditor
                              v-model="value.i"
                              :field="value.f.value"
                              :min="0"
                              :max="value.i.initial_count"
                              @changeEvent="changeArticleCount"
                          ></agGridComponentInputCellEditor>
                        </div>
                      </template>
                      <span>Artikelanzahl ändern</span>
                    </v-tooltip>

                    <v-tooltip bottom open-delay="750" v-if="value.i.ze_item">
                      <template v-slot:activator="{ on }">
                        <div v-on="on" class="layout-row layout-align-center-center px-1"
                             style="border-radius:3px;height:33px;">
                          {{ getZeNubValues(value.i.ze_item) }}
                        </div>
                      </template>
                      <span>Artikelanzahl wird durch die ZE/NUB Eingabe bestimmt und  ist nicht direkt änderbar</span>
                    </v-tooltip>
                  </div>
                  <div v-if="value.f.renderer === 'detail_hover'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                      <v-tooltip right class="pa-0 ma-0 elevation-16" content-class="detailInfo" open-delay="350"
                                 max-width="50%" min-width="300">
                        <template v-slot:activator="{ on }">
                          <v-icon :dark="compColor.isInDarkMode" v-on="on" color="blue-grey" style="cursor: pointer;">
                            mdi-magnify-plus-outline
                          </v-icon>
                        </template>

                        <div class="elevation-10 layout-column" style="border-radius: 6px;"
                             v-bind:style="{background: compColor.contentFrame}">
                          <!-- HEADER -->
                          <div class="body-1 font-weight-light pa-2 layout-row layout-align-center-center"
                               :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
                            Detailinformationen
                          </div>

                          <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

                          <!-- CONTENT (default slot) -->
                          <div class="ma-1 layout-column elevation-3" style="border-radius: 5px;position: relative;"
                               :style="{'background': compColor.lxExpansion.background}">
                            <div v-for="(item, index) in getHoverDetailValues(value.i)" :key="index">
                              <div class="layout-colum py-0 px-3" v-if="item.value">
                                <div class="caption blue-grey--text text--lighten-3 pt-1" style="line-height:16px;">
                                  {{ item.label }}
                                </div>

                                <div v-if="item.renderer === 'apovk'"
                                     class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1">
                                  {{ item.value | currency_ger }} €
                                </div>
                                <div v-else-if="item.renderer === 'autidem'"
                                     class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1">
                                  <!--                                  <div style="width:25px;height:25px;border:1px solid #cfd8db;border-radius: 3px;" class="layout-column layout-align-center-center">-->
                                  <v-icon color="blue-grey" v-if="item.value">mdi-check</v-icon>
                                  <!--                                    <div v-else class="text-center" style="opacity: 0.3;line-height: 7px;font-size:10px;"><small>aut<br>idem</small></div>-->
                                  <!--                                  </div>-->
                                </div>
                                <div v-else class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1">
                                  {{ item.value ? item.value : '-' }}
                                </div>
                                <v-divider></v-divider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                  <div v-if="value.f.renderer === 'position'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                      {{ value.i.internalIndex + 1 }}
                    </div>
                  </div>
                  <div v-if="value.f.renderer === 'sum_up'" style="padding:1px;height:35px;">
                    <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                      {{ (value.i.amount * value.i.count) | currency_ger }} €
                    </div>
                  </div>
                  <div v-if="value.f.renderer === 'list-type'" style="padding:1px;height:35px;">
                    <div class="layout-row" style="margin:2px;"
                         v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">
                      <div class="flex pa-1 layout-row layout-align-center-center"
                           v-bind:class="getListTypeValues(value.i).color">
                        <span
                          class="caption blue-grey--text text--darken-2">{{ getListTypeValues(value.i).text }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="value.f.renderer === 'autidem'" style="padding:1px;height:35px;"
                       class="layout-column flex layout-align-center-center">
                    <div style="width:25px;height:25px;border:1px solid #cfd8db;border-radius: 3px;"
                         class="layout-column layout-align-center-center">
                      <v-icon color="blue-grey" v-if="getValueByObjectPath(value.i, value.f.value)">mdi-close</v-icon>
                      <div v-else class="text-center" style="opacity: 0.3;line-height: 7px;font-size:10px;">
                        <small>aut<br>idem</small></div>
                    </div>

                  </div>
                </template>

              </lx-data-table>
            </div>
          </div>

        </div>

        <div class="elevation-3 flex-30 layout-column" style="border-radius: 6px;max-width: 350px;min-width: 250px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div class="pa-4 pt-6 flex" style="overflow-y: auto;">
            <div class="layout-colum py-1 px-3 mb-2" v-if="orderData.ordernr">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Bestellnummer</div>
              <div class="text-h4 font-weight-bold blue-grey--text mt-n1 mb-1"
                   :class="{'text--lighten-3' : isDarkMode}">{{ orderData.ordernr }}
              </div>
              <v-divider class="mb-2" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.order_ref_number">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Referenznummer</div>
              <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                {{ orderData.order_ref_number }}
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.state">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Status</div>
              <div class="layout-row pa-2 mb-1" style="border-radius:3px;"
                   :style="{'background': isDarkMode ? getStatusForCode(orderData.state).colorDark : getStatusForCode(orderData.state).color}">

                <v-tooltip right class="pa-0 ma-0 elevation-16" content-class="detailInfo" open-delay="350"
                           max-width="50%" min-width="300" v-if="Array.isArray(orderData.states) && orderData.states.length > 0">
                  <template v-slot:activator="{ on }">
                    <v-icon :dark="compColor.isInDarkMode" v-on="on" color="blue-grey" style="cursor: pointer;">
                      mdi-history
                    </v-icon>
                  </template>

                  <div class="elevation-10 layout-column" style="border-radius: 6px;"
                       v-bind:style="{background: compColor.contentFrame}">
                    <!-- HEADER -->
                    <div class="body-1 font-weight-light pa-2 layout-row layout-align-center-center"
                         :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
                      Statusverlauf
                    </div>

                    <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

                    <!-- CONTENT (default slot) -->
                    <div class="ma-1 layout-column elevation-3" style="border-radius: 5px;position: relative;" :style="{'background': compColor.lxExpansion.background}">
                      <div v-for="(item, index) in orderData.states" :key="index">
                        <div class="layout-row pa-2 ma-1" style="border-radius:3px;"
                             :style="{'background': isDarkMode ? getStatusForCode(item.State).colorDark : getStatusForCode(item.State).color}">
                          <div class="flex body-1 font-weight-bold blue-grey--text" :class="{'text--darken-3': isDarkMode}">
                            {{ getStatusForCode(item.State).name }}
                          </div>
                          <div class="caption layout-row layout-align-end-end" style="opacity: 0.3; line-height: 12px; color: #000000">
                            <small class="mr-1">Zeitstempel</small>{{ item.Timestamp | formatDate('DD.MM.YYYY HH:mm:SS') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tooltip>
                <div class="flex body-1 font-weight-bold blue-grey--text" :class="{'text--darken-3': isDarkMode}">
                  {{ getStatusForCode(orderData.state).name }}
                </div>
                <div class="caption layout-row layout-align-end-end" style="opacity: 0.3;line-height: 12px;">
                  <small class="mr-1">code</small>{{ orderData.state }}
                </div>
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.medical_name">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Auftraggeber</div>
              <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                {{ orderData.medical_name }}
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.department_name">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Abteilung</div>
              <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                {{ orderData.department_name }}
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.username">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Freigegeben durch</div>
              <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                {{ orderData.username }}
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-row" v-if="orderData.timestamp">
              <div class="flex layout-colum py-1 px-3">
                <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Bestelldatum</div>
                <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1"
                     :class="{'text--lighten-3' : isDarkMode}">{{ orderData.timestamp | formatDate }}
                </div>
                <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
              </div>
              <div class="flex layout-colum py-1 px-3">
                <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Bestellzeit</div>
                <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1"
                     :class="{'text--lighten-3' : isDarkMode}">
                  {{ orderData.timestamp | formatDate('HH:mm') }} Uhr
                </div>
                <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
              </div>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.deliverydate">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Lieferdatum
                (voraussichtlich)
              </div>
              <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                {{ orderData.deliverydate | formatDate }}
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.comment">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Bemerkungen</div>
              <div style="max-height: 150px;overflow-y:auto;">
                <p class="body-1 font-weight-bold blue-grey--text my-1" :class="{'text--lighten-3' : isDarkMode}"
                   style="overflow-wrap: break-word; ">
                  {{ orderData.comment }}</p>
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3" v-if="orderData.total_count && items.length">
              <div class="caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Gesamtartikelanzahl /
                Positionen
              </div>
              <div class="body-1 font-weight-bold blue-grey--text mt-n1 mb-1" :class="{'text--lighten-3' : isDarkMode}">
                {{ orderData.total_count }} /
                {{ items.length }}
              </div>
              <v-divider class="mb-0" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>
            <div class="layout-colum py-1 px-3 mt-4" v-if="orderData.total_amount">
              <div class="text-right caption blue-grey--text" :class="{'text--lighten-3' : !isDarkMode}">Gesamtpreis
              </div>
              <div class="text-h4 text-right font-weight-bold blue-grey--text mt-n1 mb-1"
                   :class="{'text--lighten-3' : isDarkMode}">
                <span :class="isDarkMode ? compColor.primeHighlightClass : compColor.primeClass"
                      class="mr-2">{{ orderData.total_amount | currency_ger }}</span><small>EUR</small></div>
              <v-divider class="mb-2" v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>
            </div>

          </div>

        </div>

      </div>
    </div>

    <ValidationObserver slim ref="dialogComment" v-slot="{ valid, pristine }">
      <lx-dialog v-model="dialogShowing" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
        <template #header>
          <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
               :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
            Begründung
          </div>
        </template>
        <template #default>
          <div class="ma-1 pa-4 flex layout-column elevation-3" style="border-radius: 5px;position: relative;overflow-y: auto;" :style="{'background': compColor.lxExpansion.background}">

              <validation-provider tag="div" ref="comment" name="Kommentar" rules="required|min:4|max:512" v-slot="{ errors }">
                <v-textarea
                    :dark="compColor.isInDarkMode"
                    :color="compColor.primeColor"
                    :dense="denseFields"
                    :error-messages="errors[0]"
                    v-model="dialogComment"
                    outlined
                    autofocus
                    height="155"
                    label="Begründung"
                    rows="4"
                    placeholder="..."
                    counter="512"
                    maxlength="512"
                ></v-textarea>
              </validation-provider>
          </div>
        </template>
        <template #actions>
          <div class="layout-row layout-align-center-center pa-1">
            <div class="flex mr-1">
              <lx-btn :color="'#353a47'"
                      block
                      :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                      :disabled="!valid"
                      small
                      class="flex"
                      iconSize="20"
                      width="auto"
                      classes="text-none"
                      @click.stop="closeDialog"
                      icon="mdi-check-circle-outline"
                      :tooltip="$t('BUTTONS.DIALOG.SAVE.TOOLTIP')"
                      :label="$t('BUTTONS.DIALOG.SAVE.TITLE')"></lx-btn>
            </div>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    class="flex"
                    iconSize="20"
                    width="auto"
                    classes="text-none"
                    @click.stop="dialogShowing = false"
                    icon="cancel"
                    :tooltip="$t('BUTTONS.DIALOG.CLOSE.TOOLTIP')"
                    :label="$t('BUTTONS.DIALOG.CLOSE.TITLE')"></lx-btn>
          </div>
        </template>
      </lx-dialog>
    </ValidationObserver>

    <lx-dialog v-model="zeDialog" :allow-drag="true" :overlay="true" :size="{x: '70', y: 'auto'}">
      <template #header>
        <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
             :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
          ZE- / NUB-Artikel
        </div>
      </template>
      <template #default>
        <div class="ma-1 pa-4 flex layout-column elevation-3"
             style="border-radius: 5px;position: relative;overflow-y: auto;"
             :style="{'background': compColor.lxExpansion.background}">
          <div class="flex layout-column pa-1">
            <div class="flex layout-column pa-1">
              <fake-input-frame label="ZE-Artikel / Patienten"
                                class="flex layout-column"
                                :input-background="isDarkMode ? 'transparent':'#f0f0f0'">
                <div class="layout-column pa-2">
                  <div v-for="(items, index) in zeData.ze_item"
                       :key="index"
                       class="px-2 py-1"
                       :class="index < zeData.ze_item.length - 1 ? 'mb-1' : ''"
                       :style="{'background': items.selected ? (isDarkMode ? '#8edad0' : '#b2dfdb') : (isDarkMode ? '#192330' : '#cfd8dc')}"
                       style="border-radius: 4px;">
                    <div class="layout-row">
                      <div class="layout-column layout-align-center-center mr-2">
                        <v-icon
                            :color="items.selected ? compColor.prime + ' lighten-3': (isDarkMode ? 'blue-grey darken-3' : 'blue-grey lighten-3')"
                            size="25">mdi-account-circle
                        </v-icon>
                      </div>
                      <v-divider vertical class="mr-2"></v-divider>
                      <div class="flex blue-grey--text text--darken-1 layout-column layout-align-center-start pt-1">
                        <div style="line-height: 16px;"><span
                            style="width:100px;display: inline-block;">Fallnummer:</span><strong>{{
                            items.case_number
                          }}</strong></div>
                        <div><small style="line-height: 16px;"><span style="width:100px;display: inline-block;">Artikelanzahl:</span>{{
                            items.article_count
                          }}</small></div>
                      </div>
                    </div>
                  </div>
                </div>
              </fake-input-frame>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="layout-row layout-align-center-center pa-1">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  block
                  iconSize="20"
                  width="auto"
                  classes="text-none"
                  @click.stop="zeDialog = null"
                  icon="cancel"
                  :tooltip="$t('BUTTONS.DIALOG.CLOSE.TOOLTIP')"
                  :label="$t('BUTTONS.DIALOG.CLOSE.TITLE')"></lx-btn>
        </div>
      </template>
    </lx-dialog>

  </div>
</template>

<script>

import lodash from "lodash";
import agGridComponentInputCellEditor from "@/components/lx_ag-grid/components/cellRenderer/numberUpDownCell";
import OrderStates from "@/components/common/order_states";

function SortValues(input) {
  input.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  return input
}

export default {
  $validator: 'new',
  name: 'OrderDetailsController',
  mixins: [OrderStates],
  components: {
    agGridComponentInputCellEditor,
  },
  props: {
    orderid: {
      type: String,
    }
  },
  data() {
    return {
      isApprovable: false,
      isRejectable: false,
      zeData: null,
      zeDialog: false,
      loading: false,
      dialogShowing: false,
      dialogComment: null,
      dialogType: null,
      tableObj: {
        loading: false, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        options: {
          sortBy: ['name'],
          sortDesc: [false],
          getClassForItem: (row) => {
            if (row.item.count !== row.item.initial_count) {
              return (row.item.count === 0) ? 'red lighten-4' : 'yellow lighten-4'
            }

            return 'green lighten-5'
          }
        },
        filterValue: null,
        selection: null,
        headers: [
          // {
          //   text: '',
          //   value: 'data-table-select',
          //   sticky: 'left',
          //   width: '30px',
          //   hideRowByKeyValue: 'reported',
          //   noResize: true,
          //   alternateText: 'Zeilenauswahl'
          // },
          // {
          //   text: 'Pos.',
          //   value: '_id',
          //   width: '30px',
          //   sticky: 'left',
          //   class: 'text-right pr-2',
          //   renderer: 'position'
          // },
          {
            text: '',
            value: '_id',
            width: '30px',
            sticky: 'left',
            class: 'text-right pr-2',
            renderer: 'changes'
          },
          {
            text: '',
            width: '25px',
            sticky: 'left',
            class: 'text-right pr-2',
            renderer: 'detail_hover',
            noResize: true,
            alternateText: 'Detailansicht'
          },
          {
            text: 'Listentyp',
            value: 'list_Type',
            width: '70px',
            class: 'text-center',
            //sortable: true,
            renderer: 'list-type'
          },
          {
            text: 'PZN',
            value: 'pzn',
            width: '70px',
            //sortable: true
          },
          {
            text: 'Artikel',
            value: 'name',
            //sortable: true
          },
          {
            text: 'DF',
            value: 'dosage_form',
            width: '80px',
            //sortable: true
          },
          {
            text: 'Packungsgr.',
            value: 'unit',
            width: '160px',
            //sortable: true
          },
          {
            text: 'ZE / NUB',
            value: 'ze_item',
            preventPl3Header: true,
            //sticky: 'right',
            class: 'text-center',
            //sortable: true,
            renderer: 'ze_item',
            bodyClass: 'px-0',
            width: '40px',
          },
          {
            text: 'Artikelkommentar',
            value: 'comment',
            width: '200px',
            class: 'pinned',
            //sortable: true
          },
          {
            text: 'aut idem',
            value: 'autidem',
            width: '60px',
            class: 'text-center',
            //sortable: true,
            renderer: 'autidem'
          },
          // {
          //   text: 'Anzahl',
          //   value: 'count',
          //   width: '60px',
          //   class: 'text-right pr-2',
          //   bodyClass: 'text-right',
          //   sticky: 'right',
          //   renderer: 'changeNumber',
          //   sortable: false,
          //   noResize: true,
          // },
          {
            text: 'Anzahl',
            value: 'count',
            preventPl3Header: true,
            sticky: 'right',
            class: 'text-center',
            sortable: false,
            renderer: 'changeNumber',
            bodyClass: 'px-0',
            width: '60px',
            noResize: true,
          },
          {
            text: 'Einzelpreis',
            value: 'amount',
            width: '85px',
            class: 'text-right pr-2',
            sticky: 'right',
            renderer: 'currency_ger'
          },
          {
            text: 'Summe',
            value: 'Apo_Vk',
            width: '85px',
            class: 'text-right pr-2',
            sticky: 'right',
            renderer: 'sum_up'
          }
        ]
      },
      items: [],
      status: null,
      orderData: {},
      error: {},
    }
  },
  created() {
    this.getData()
  },
  methods: {
    openZeItemDialog(articleData) {
      this.zeDialog = true;
      this.zeData = articleData
    },

    getHoverDetailValues(itemOriginal) {
      let abdaItem = []

      let item = lodash.cloneDeep(itemOriginal)

      for (let i in item) {
        if (item['count'] && item['amount']) {
          item['Apo_Vk'] = item['count'] * item['amount']
        }
      }

      delete item.count

      for (let i in item) {
        if (Object.prototype.hasOwnProperty.call(item, i)) {
          switch (i) {
            case 'pzn':
              abdaItem.push({
                label: 'PZN',
                index: 1,
                value: item[i]
              });
              break;
            case 'name':
              abdaItem.push({
                label: 'Artikel',
                index: 2,
                value: item[i]
              });
              break;
            case 'agent':
              abdaItem.push({
                label: 'Wirkstoff',
                index: 3,
                value: item[i]
              });
              break;
            case 'dosage_form':
              abdaItem.push({
                label: 'Darreichungsform',
                index: 4,
                value: item[i]
              });
              break;
            case 'unit':
              abdaItem.push({
                label: 'Packungsgröße',
                index: 5,
                value: item[i]
              });
              break;
            case 'manufacturer_Langname':
              abdaItem.push({
                label: 'Hersteller',
                index: 6,
                value: item[i]
              });
              break;
            case 'Hinweis':
              abdaItem.push({
                label: 'Hinweis',
                index: 7,
                value: item[i]
              });
              break;
            case 'RoteListe':
              abdaItem.push({
                label: 'Indikation',
                index: 8,
                value: item[i]
              });
              break;
            case 'amount':
              abdaItem.push({
                label: 'Einzelpreis',
                index: 9,
                // value: item[i].toString().replace('.', ',') + ' €'
                value: item[i],
                renderer: 'apovk'
              });
              break;
            case 'count':
              abdaItem.push({
                label: 'Anzahl',
                index: 10,
                value: item[i]
              });
              break;
            case 'Apo_Vk':
              abdaItem.push({
                label: 'Summe',
                index: 11,
                value: item[i],
                renderer: 'apovk'
              });
              break;
            case 'comment':
              abdaItem.push({
                label: 'Artikelkommentar',
                index: 12,
                value: item[i]
              });
              break;
            case 'autidem':
              abdaItem.push({
                label: 'aut idem',
                index: 13,
                value: item[i],
                renderer: 'autidem'
              });
              break;
            case 'Apo_Bestand':
              if (item[i] > 0) {
                abdaItem.push({
                  label: 'Apothekenbestand',
                  index: 14,
                  color: 'green lighten-2',
                  icon: 'done',
                  value: 'lagernd'
                })
              } else if (item[i] <= 0) {
                abdaItem.push({
                  label: 'Apothekenbestand',
                  index: 15,
                  color: 'red lighten-2',
                  icon: 'clear',
                  value: 'nicht lagernd'
                })
              }
              break;
            case 'editor':
              abdaItem.push({
                label: 'Zuletzt bearbeitet von',
                index: 16,
                value: [item[i].last_name, item[i].first_name].join(', ')
              })

              abdaItem.push({
                label: 'Zuletzt bearbeitet am',
                index: 17,
                value: (new Date(item[i].modified_at)).toLocaleString(
                    'de-DE',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })
              })

              break;

            default:
              break;
          }
        }
      }

      // Sortieren nach Wert
      abdaItem.sort(function (a, b) {
        return a.index - b.index;
      });

      return abdaItem
    },
    getListTypeValues(value) {
      if (value.Abgabebestimmung === "3" || value.levy_regulation === "3") {
        return { text: 'BTM', color: this.compColor.isInDarkMode ? 'red lighten-2' : 'red lighten-4' }
      }
      if ((value.location_id && value.location_id !== value.owner_id) || value.ref_list === 10) {
        return { text: 'KH', color: this.compColor.isInDarkMode ? 'green lighten-2' : 'green lighten-4' }
      } else {
        return { text: 'SA', color: this.compColor.isInDarkMode ? 'amber lighten-2' : 'amber lighten-4' }
      }
    },
    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }

      const objectPath = path.split('.')
      for (let i = 0; i < objectPath.length; i++) {
        if (!obj[objectPath[i]]) {
          return;
        }
        obj = obj[objectPath[i]]
      }
      return obj
    },

    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },

    /**
     * wird aufgerufen, wenn man den zurück Button drückt
     *
     * */
    back() {
      window.history.back()
    },

    getData() {
      this.isApprovable = false

      if (this.tableObj.loading) return;

      this.tableObj.loading = true;
      this.$http.get('/orders/' + this.orderid)
        .then((res) => {
          this.orderData = res.data || {}
          this.items = SortValues(this.orderData.articles) || []
          this.items.forEach((item) => {
            item.initial_count = item.count
            return item
          })

          this.isApprovable = this.orderData?.state === 8 && this.orderData?.total_count > 0
          this.isRejectable = this.isApprovable

        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false;
        })
    },

    changeArticleCount (item) {
      const model = item.value || {}
      const pzn = model.pzn
      const index = this.items.findIndex((e) => e.pzn === pzn)

      if (index !== -1) {
        this.items[index].count = model.count

        let total = 0.0
        let counts = 0
        this.items.forEach((e) => {
          counts += e.count
          total += e.count * e.amount
        })

        this.orderData.total_count = counts
        this.orderData.total_amount = total
        this.isApprovable = this.orderData?.state === 8 && this.orderData?.total_count > 0
      }
    },

    showDialog(type) {
      const hasChanges = this.items.filter((e) => e.count !== e.initial_count).length !== 0

      if (type === 'approve' && !hasChanges ) {
        return this.approveOrder()
      }

      this.dialogComment = null
      this.dialogShowing = true
      this.dialogType = type
    },

    closeDialog() {
      this.dialogShowing = false

      switch (this.dialogType) {
        case 'approve': return this.approveOrder()
        case 'reject': return this.rejectOrder()
      }
    },

    approveOrder () {
      const articles = this.items.filter((e) => e.count !== e.initial_count).map((e) => {
        return {pzn: e.pzn, count: e.count}
      })

      let data = {
        order_id: this.orderid
      }

      if (articles.length > 0) {
        data['comment'] = this.dialogComment
        data['articles'] = articles
      }

      this.$http.post('/orders/approve', data)
        .then((res) => {
          this.NotifySuccess(res)
          window.history.back()
        })
        .catch(this.NotifyError)
    },

    rejectOrder () {
      this.$http.post('/orders/reject', {order_id: this.orderid, comment: this.dialogComment})
        .then((res) => {
          this.NotifySuccess(res)
          window.history.back()
        })
        .catch(this.NotifyError)
    },

    getZeNubValues(zeItem) {
      // const articles = zeItem.length
      let article_count = 0

      zeItem.forEach(item => {
        article_count += item.article_count
      })

      return article_count // + '(' + articles + ' Artikel)'
    },
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
