<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  height="40"
                  classes="text-none mr-1"
                  @click="back()"
                  icon="chevron_left"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.BACK.TOOLTIP')"
                  :label="$t('BUTTONS.BACK.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.REFRESH.TOOLTIP')"
                  :label="$t('BUTTONS.REFRESH.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="openEditor"
                  icon="done"
                  :tooltip="$t('BUTTONS.ADD_ARTICLE.TOOLTIP')"
                  :label="$t('BUTTONS.ADD_ARTICLE.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="!tableObj.selection || tableObj.selection.length <= 0"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="deleteArticles"
                  icon="mdi-delete-forever"
                  :tooltip="$t('BUTTONS.DELETE_SELECTED_ARTICLE.TOOLTIP')"
                  :label="$t('BUTTONS.DELETE_SELECTED_ARTICLE.TITLE')"></lx-btn>

        </div>
        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="px-0 layout-row pb-1">
        <div class="px-1 flex">
          <lx-abda-search-fields :data-layout="verticalCols"
                                 :data-method="getAbdaData"
          />
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="AbdaEditor"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @rowClicked="showEditorDialog"
              @changeOptions="changeTableOptions">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'currency_ger'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                    {{ getValueByObjectPath(value.i, value.f.value) | currency }}
                  </div>
                </div>
                <div v-else-if="value.f.renderer === 'list-type'" style="padding:1px;height:35px;">
                  <div class="layout-row" style="margin:2px;"
                       v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">
                    <div class="flex pa-1 layout-row layout-align-center-center"
                         v-bind:class="getListTypeValues(value.i).color">
                        <span
                          class="caption blue-grey--text text--darken-2">{{ getListTypeValues(value.i).text }}</span>
                    </div>
                  </div>
                </div>
                <div v-else-if="value.f.renderer === 'search'" style="padding:1px;height:35px;">
                  <div class="layout-row" style="margin:2px;"
                       v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">
                    <lx-btn :color="'#353a47'"
                            :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                            :disabled="tableObj.loading"
                            iconSize="22"
                            height="30"
                            width="auto"
                            as-text
                            as-icon
                            classes="text-none mr-1"
                            @click.stop="$refs.abdaSearchEditor.Show(value.i._id)"
                            icon="mdi-filter"
                            :hide-label="true"
                            :tooltip="$t('BUTTONS.CUSTOMIZE_SEARCH_FIELDS.TOOLTIP')"
                            :label="$t('BUTTONS.CUSTOMIZE_SEARCH_FIELDS.TITLE')"></lx-btn>
                  </div>
                </div>
                <div v-else-if="value.f.renderer === 'article_edit'" style="padding:1px;height:35px;">
                  <div class="layout-row" style="margin:2px;"
                       v-bind:class="{'blue-grey--text text--darken-3': compColor.isInDarkMode}">
                    <lx-btn :color="'#353a47'"
                            :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                            :disabled="tableObj.loading"
                            iconSize="22"
                            height="30"
                            width="auto"
                            as-text
                            as-icon
                            classes="text-none mr-1"
                            @click.stop="$refs.abdaEditor.Show(value.i._id)"
                            icon="mdi-table-edit"
                            :hide-label="true"
                            :tooltip="$t('BUTTONS.EDIT_ARTICLE.TOOLTIP')"
                            :label="$t('BUTTONS.EDIT_ARTICLE.TITLE')"></lx-btn>
                  </div>
                </div>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>
    </div>

    <catalog-form-edit
      ref="abdaEditor"
      :locationid="locationid"
      v-on:success="getAbdaData"
    />

    <catalog-search-editor-dialog
      ref="abdaSearchEditor"
    />

  </div>
</template>

<script>
// @ is an alias to /src
import lxPagination from '../../../components/lx_pagination/LxPagination'
import CatalogFormEdit from './CatalogFormEdit'
import CatalogSearchEditorDialog from "./CatalogSearchEditorDialog";
import router from "@/router";

export default {
  name: 'CatalogEditor',
  props: {
    listtype: {
      type: [String, null],
      default: () => {
        return null;
      }
    },
    locationid: {
      type: [String, null],
      default: () => {
        return null;
      }
    }
  },
  components: {
    CatalogSearchEditorDialog,
    lxPagination,
    CatalogFormEdit
  },
  data: () => ({

    tableObj: {
      loading: false,
      options: {
        sortBy: ['Produktname'],
        sortDesc: [true],
        // getClassForItem: (row) => {
        //   if (row.isExpanded) {
        //     return 'row-bold'
        //   }
        //   return ''
        // }
      },
      filterValue: null,
      selection: null,
      headers: [
        {
          text: '',
          value: 'data-table-select',
          sticky: 'left',
          width: '30px',
          hideRowByKeyValue: 'reported',
          noResize: true,
          alternateText: 'Zeilenauswahl'
        },
        {
          text: 'Listentyp',
          value: 'list_Type',
          width: '60px',
          sortable: true,
          class: 'text-center',
          renderer: 'list-type'
        },
        {
          text: 'PZN',
          value: 'pzn',
          width: '80px',
          sortable: true
        },
        {
          text: 'Artikel',
          value: 'Produktname',
          width: '250px',
          sortable: true
        },
        {
          text: 'DF',
          value: 'dosage_form',
          width: '50px',
          sortable: true
        },
        // {
        //   text: 'Status',
        //   value: 'flag',
        //
        //   width: '200px',
        //   // class: 'text-center',
        //   sortable: true,
        //   renderer: 'order_state'
        // },
        {
          text: 'Hersteller',
          value: 'manufacturer_Kurzname',
          sortable: true
        },
        {
          text: 'Wirkstoff',
          value: 'agent',
          sortable: true
        },
        {
          width: '30px',
          class: 'text-right pr-3',
          sticky: 'right',
          renderer: 'article_edit',
          noResize: true,
          alternateText: 'Artikel editieren'
        },
        {
          width: '30px',
          class: 'text-right pr-3',
          sticky: 'right',
          renderer: 'search',
          noResize: true,
          alternateText: 'Suchfelder anpassen'
        },
        {
          text: 'Preis',
          value: 'Apo_Vk',
          width: '85px',
          class: 'text-right pr-3',
          sortable: true,
          sticky: 'right',
          renderer: 'currency_ger'
        },
      ]
    },
    items: [],

    editorDialog: false,

    verticalCols: false, //variable ob taballen nebeneinander oder übereinander angezeigt werden
    searchModel: {},  //enthält model aller Suchbegriffe
  }),
  created() {
  },
  methods: {
    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }
    },
    getListTypeValues(value) {
      if (value.Abgabebestimmung === "3" || value.levy_regulation === "3") {
        return { text: 'BTM', color: this.compColor.isInDarkMode ? 'red lighten-2' : 'red lighten-4' }
      }
      if (value.ref_list === 10) {
        return { text: 'KH', color: this.compColor.isInDarkMode ? 'green lighten-2' : 'green lighten-4' }
      } else {
        return { text: 'SA', color: this.compColor.isInDarkMode ? 'amber lighten-2' : 'amber lighten-4' }
      }
    },
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },

    back() {
      if (typeof this.listtype === 'string') {
        return router.push({ path: `/administration/catalog/${this.listtype}`});
      }

      window.history.back()
    },

    /**
     * wird von der unter componenten lx-abda-search aufgerufen um nach eine 700ms verzögern den eingegebenen suchbegriff zu suchen
     * gleichzeitig in den stationslisten und abda listen schauen
     * **/
    getAbdaData(value) {
      this.searchModel = {};
      Object.assign(this.searchModel, {}, value);

      this.getData();
    },

    /**
     * getData Methode die über ABDA Liste sucht
     * wird beim sortieren oder suchbegriff schreiben ausgeführt, dann mit verzögerung
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      let opts = {
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit
      };

      if (this.tableObj.options && this.tableObj.options.sortBy && this.tableObj.options.sortBy.length > 0) {
        opts.sort = {}
        opts.sort[this.tableObj.options.sortBy[0]] = this.tableObj.options.sortDesc[0] ? 1 : -1
      }

      let route = (this.listtype === 'sal') ? 'global' : 'location';

      this.$http.get('/abda/search/' + route, {
        config: {
          opts: opts,
          query: this.searchModel,
          count: true
        },
        locationid: this.locationid,
      })
        .then(res => {
          if (res.data) {
            this.items = res.data.result || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },

    /**
     * Open ABDA Editor Dialog
     */
    openEditor() {
      this.$refs.abdaEditor.Show();
    },

    /**
     * Show ABDA Editor Dialog on row click
     * @param item
     */
    showEditorDialog (item) {
      this.$refs.abdaEditor.Show(item._id)
    },

    deleteArticles() {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      const data = this.tableObj.selection.filter(value => (value._id || '').length === 24)
        .map(value => value._id);

      this.$http.patch('/abda/delete', data)
        .then((res) => {
          if (res.data && res.data.success === true) {
            this.NotifySystem({
              title: 'Datensätze löschen',
              text: `Es wurde(n) ${res.data.deleted} von ${res.data.num} erfolgreich gelöscht`,
              type: 'success'
            });
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
          this.getData();
        })
    }
  }
}
</script>

<style scoped>

</style>
