<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">

          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  :disabled="tableObj.loading"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.REFRESH.TOOLTIP')"
                  :label="$t('BUTTONS.REFRESH.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="createNewClinicalUnit()"
                  icon="mdi-plus-circle"
                  :tooltip="$t('BUTTONS.CLINICAL_UNIT_ADD.TOOLTIP')"
                  :label="$t('BUTTONS.CLINICAL_UNIT_ADD.TITLE')"></lx-btn>

        </div>
        <div class="flex layout-row"></div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
          :dark="isDarkMode"
          :color="compColor.primeColor"
          :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
          class="mt-2 mb-0"
          :label="$t('INPUTS.TITLES.SEARCH_TERM')"
          hide-details
          outlined
          :dense="denseFields"
          prepend-inner-icon="mdi-magnify"
          clearable
          v-model="searchString"
        ></v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">

          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
              v-model="tableObj.selection"
              tableName="ClinicalUnitList"
              :items="items"
              :headers="tableObj.headers"
              :loading="tableObj.loading"
              :tableOptions="tableObj.options"
              :hideContextFilter="true"
              @changeOptions="changeTableOptions"
              @rowClicked="openClinicalUnitDetails">

              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'icon'" style="padding:1px;height:35px;">
                  <v-icon v-if="value.i[value.f.value]" :color="compColor.prime">mdi-check</v-icon>
                </div>
                <div v-if="value.f.renderer === 'delete'" style="padding:2px;height:35px;">
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="askForDeletion(value.i)"
                          icon="mdi-delete-forever"
                          :hide-label="true"
                          :tooltip="$t('BUTTONS.DEPARTMENT_DELETE_SELECTED.TOOLTIP')"
                          :label="$t('BUTTONS.DEPARTMENT_DELETE_SELECTED.TITLE')"></lx-btn>
                </div>
              </template>

            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'orderList'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

      <v-dialog v-model="deleteDialog" persistent width="90%" max-width="400" overlay-opacity="0.9">
        <v-card class="flex" :dark="compColor.isInDarkMode" v-bind:class="[compColor.contentFrameBack]">
          <v-card-title class="title elevation-4 layout-row pa-0">
            <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
                 style="z-index: 2;border-radius:4px 4px 0 0;">
              <div class="py-1 px-1 layout-row layout-align-start-center"
                   v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
                <div class="layout-column layout-align-center-center pr-3">
                  <v-avatar :color="compColor.avatarTile" tile height="40" width="40"
                            style="min-width:40px;">
                    <v-icon :color="compColor.primeColor">delete_forever</v-icon>
                  </v-avatar>
                </div>
                Löschen bestätigen
              </div>
            </div>
          </v-card-title>
          <v-divider v-bind:class="compColor.textInput" v-if="compColor.isInDarkMode"></v-divider>
          <v-card-text class="pa-5" v-if="departmentToDelete">
            Möchten Sie die ausgewählte Abteilung <strong>"{{ departmentToDelete.name }}"</strong> wirklich
            löschen? Eine Wiederherstellung ist nicht
            möglich.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <div class="layout-row" style="width:100%;">
              <!--                    <v-spacer></v-spacer>-->
              <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                     :color="compColor.primeColor" @click="deleteDepartment()">
                Ja
              </v-btn>
              <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                     :color="compColor.primeColor" @click="deleteDialog = null">
                Nein
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="noDeletionPossibleDialog" persistent width="90%" max-width="400" overlay-opacity="0.9">
        <v-card class="flex" :dark="compColor.isInDarkMode" v-bind:class="[compColor.contentFrameBack]">
          <v-card-title class="title elevation-4 layout-row pa-0">
            <div class="pa-1 flex" v-bind:class="[compColor.contentFrameBack]"
                 style="z-index: 2;border-radius:4px 4px 0 0;">
              <div class="py-1 px-1 layout-row layout-align-start-center"
                   v-bind:class="[compColor.contentFrameFront,compColor.widget.headline]">
                <div class="layout-column layout-align-center-center pr-3">
                  <v-avatar :color="compColor.avatarTile" tile height="40" width="40"
                            style="min-width:40px;">
                    <v-icon :color="compColor.primeColor">delete_forever</v-icon>
                  </v-avatar>
                </div>
                Löschen nicht möglich
              </div>
            </div>
          </v-card-title>
          <v-divider v-bind:class="compColor.textInput" v-if="compColor.isInDarkMode"></v-divider>
          <v-card-text class="pa-5" v-if="departmentToDelete">
            Die ausgewählte Abteilung <strong>"{{ departmentToDelete.name }}"</strong> kann nicht gelöscht werden,
            da Ihre beliefernde Apotheke diese mit einer Kostenstelle plant. Bitte wenden Sie sich für eine
            Löschung an Ihre Apotheke.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <div class="layout-row" style="width:100%;">
              <!--                    <v-spacer></v-spacer>-->
              <v-btn :dark="compColor.isInDarkMode" class="white--text text-none mx-1 flex"
                     :color="compColor.primeColor" @click="noDeletionPossibleDialog = null">
                Verstanden
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </div>

  </div>
</template>
<script>
import router from '../../../router'
import CheckIconCellRenderer from '../../../components/lx_ag-grid/components/cellRenderer/booleanCheckIconCell'
import agGridComponentButtonCellEditor from '../../../components/lx_ag-grid/components/cellRenderer/buttonActionCell'

export default {
  name: 'ClinicalUnitList',
  data() {
    return {
      searchString: null,
      searchDelayTimer: null,
      tableObj: {
        loading: false, // benutzt während des GetData, um eine Ladeanimation einzublenden
        options: {
          sortBy: ['name'],
          sortDesc: [false],
          getClassForItem: (row) => {
            if (row.item.hasOwnProperty('medicalunit_is_active') && !row.item.medicalunit_is_active) {
              return 'row-italic font-weight-light'
            }

            if (row.item.hasOwnProperty('is_active') && !row.item.is_active) {
              return 'row-italic'
            }

            return ''
          }
        },
        filterValue: null,
        selection: null,
        headers: [
          {
            width: '30px',
            sortable: true,
            sticky: 'left',
            renderer: 'delete',
            noResize: true,
            alternateText: 'Löschen'
          },
          {
            text: 'Abteilung',
            value: 'name',
            width: '200px',
            sortable: true
          },
          {
            text: 'Krankenhaus',
            value: 'medicalunit_name',
            width: '300px',
            sortable: true
          },
          {
            text: 'Kostenstellennummer',
            value: 'costcenter',
            sortable: true,
            width: '200px',
            // renderer: 'icon'
          },
          {
            text: 'E-Mail',
            value: 'email',
            width: '300px',
            sortable: true,
            // renderer: 'icon'
          },
          {
            text: 'Aktiv',
            value: 'is_active',
            sortable: true,
            renderer: 'icon'
          }
        ]
      },
      items: [],
      isLoadingData: false, // benutzt während des GetDatas, um eine Ladeanimation einzublenden
      frameworkComponents: null,
      columnDefs: null,
      rowData: null,
      FilterAreFloating: false,
      allowBlurOnLoading: true,
      isPharmacy: (this.getUserLocationType() === "pharmacy"),
      deleteDialog: false,
      noDeletionPossibleDialog: false,
      departmentToDelete: null,
      defaultSortOpts: {
        colId: 'name',
        sort: 'asc'
      }
    }
  },
  components: {
    // DeleteDialog
  },
  created() {
    if (!this.isPharmacy) {
      this.tableObj.headers = [
        {
          width: '30px',
          sortable: true,
          sticky: 'left',
          renderer: 'delete',
          noResize: true,
          alternateText: 'Löschen'
        },
        {
          text: 'Abteilung',
          value: 'name',
          width: '200px',
          sortable: true
        },
        {
          text: 'E-Mail',
          value: 'email',
          sortable: true,
          // renderer: 'icon'
        },
        {
          text: 'Aktiv',
          value: 'is_active',
          sortable: true,
          renderer: 'icon'
        }
      ]
    }
  },
  beforeMount() {
    this.frameworkComponents = {
      // agColumnHeader: agGridComponentHeader,
      agGridComponentButtonCellEditor: agGridComponentButtonCellEditor,
      checkIconCellRenderer: CheckIconCellRenderer
    }
  },
  methods: {
    /**
     * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
     */
    changeTableOptions(value) {
      if (value) {
        this.tableObj.options = value
      }
      this.getData()
    },
    createNewClinicalUnit() {
      router.push({ path: `/administration/clinical_units/new` });
    },
    /**
     * Methode wird vom Grid angesprungen, wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffnet.
     * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
     * **/
    openClinicalUnitDetails(params) {
      if (params?._id) {
        router.push({ path: `/administration/clinical_units/${params._id.toString()}/details` });
      }
    },

    /**
     * Wird verschiedenen Stellen aus aufgerufen und soll die Daten vom Backend abholen. Mittels axios....bla bla bla
     * Besonderheit:
     * "this.pagingOptions.count = res.data.count"
     * - Anzahl der Datensätze vom Server wird auch in den pagingOptions gespeichert
     *
     * "this.$store.dispatch('setPagingOptions', this.pagingOptions)"
     * - nach der Speicherung der Anzahl werden die pagingOptions wieder in den Store geschrieben
     *
     * "this.gridOptions.api.ensureIndexVisible(this.$store.getters.gridScrollIndex, 'top')"
     * - sorgt dafür das nach dem neu Laden der Daten das Grid wieder an die Stelle scrollt wo es war. notwendig z.B. bei der Rückkehr zum Grid
     * **/
    getData(options) {
      if (this.tableObj.loading) return
      this.tableObj.loading = true

      this.isLoadingData = true;

      if (options && typeof options.skip === 'number') {
        this.pagingOptions.skip = options.skip
      }
      if (options && options.limit) {
        this.pagingOptions.limit = options.limit
      }

      const params = this.$helper.GetQueryParam({
        ...this.tableObj.options,
        skip: this.pagingOptions.skip,
        limit: this.pagingOptions.limit,
        query: {},
        count: true,
        searchString: this.searchString,
        searchFields: ['medicalunit_name', 'name', 'costcenter', 'email']
      })

      this.$http.get('/department', params)
        .then(res => {
          if (res.data) {
            this.items = res.data.data || []
            this.pagingOptions.count = res.data.count || 0
          }
        })
        .catch(this.NotifyError)
        .finally(() => {
          this.tableObj.loading = false
        })
    },
    askForDeletion(val) {
      if (val && val._id) {
        this.departmentToDelete = val;

        if (!this.isPharmacy && val.is_active) {
          this.noDeletionPossibleDialog = true;
        } else {
          this.DialogConfirm(
            'Löschen der Abteilung',
            'Möchten Sie die ausgewählte Abteilung "' + this.departmentToDelete.name + '" wirklich löschen? Eine Wiederherstellung ist nicht möglich.',
            { ok: this.$t('BUTTONS.DIALOGS.DELETE'), cancel: this.$t('BUTTONS.DIALOGS.CANCEL') }
          )
            .then(() => {
              this.$http.delete('/department/' + this.departmentToDelete._id)
                .then((res) => {
                  if (res.data?.deleted === 0) {
                    return this.DialogConfirm(
                        'Löschen der Abteilung',
                        'Diese Abteilung verfügt bereits über Bestellungen und kann nicht gelöscht werden.',
                        { ok: this.$t('BUTTONS.DIALOGS.OK'), hideCancelButton: true }
                    ).then(() => {}).catch(() => {})
                  }

                  this.NotifySuccess(res)
                })
                .catch(this.NotifyError)
                .finally(this.getData)
            })
            .catch(() => {
            })
        }
      }
    },
  },
  watch: {
    searchString() {
      clearInterval(this.searchDelayTimer);

      this.searchDelayTimer = setInterval(() => {
        clearInterval(this.searchDelayTimer);
        this.getData()
      }, 500)
    }
  },
  computed: {}
}
</script>
<!-- Add 'scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.view-top-toolbar {
  .btn {
    min-width: 40px;
  }

  .btn__content {
    padding: 0 5px !important;
  }
}
</style>
