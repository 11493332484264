const SET_SINGLE_SEARCH_FIELD = 'SET_SINGLE_SEARCH_FIELD';
const SET_All_STORE_VALUES = 'SET_All_STORE_VALUES';

const LOCAL_STORE = process.env.VUE_APP_BASE_NAME + '-frontend';

import axios from 'axios'
import vue from 'vue'

const state = {
    abda: {
        singleSearchField: true
    }
};

function updateUser() {
    let data = null; //{settings: state.theme}

    /**
     * ändere Sessionkey und schreibe neue Theme Eigenschaften rein
     * **/
    let storage = JSON.parse(window.sessionStorage.getItem(LOCAL_STORE));
    if (storage && storage.user && storage.user) {
         data = storage.user.settings
    }


    if(!data){
        data = {
            abda: {}
        }
    }

    data.abda = state.abda
    storage.user.settings = data

    window.sessionStorage.setItem(LOCAL_STORE, JSON.stringify(storage));

    /**
     * update Settings des Users in Datenbank
     * **/
    if (axios) {
        axios.put('/account/user/settings', {settings: data})
            .then(() => {
                // console.log('RESULT: ', res)
            })
            .catch(vue.NotifyError)
    }
}

const mutations = {
    [SET_SINGLE_SEARCH_FIELD](state, payload) {
        state.abda.singleSearchField = payload;
        // updateUser()
    },
    [SET_All_STORE_VALUES](state, payload) {
        state.abda = payload
    }
};

const actions = {
    setSingleSearchField({commit}, payload) {
        commit(SET_SINGLE_SEARCH_FIELD, payload)
    },
    setAllStoreValues({commit}, payload) {
        commit(SET_All_STORE_VALUES, payload)
    }
};

const getters = {
    getSingleSearchFields: state => {
        return state.abda.singleSearchField
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}
