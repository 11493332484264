<script>

import {isNumber} from "lodash";

const MAX_TEXT_LENGTH = 120;

function shorten(text, max) {
  return text && text.length > max ? text.slice(0, max).split(' ').slice(0, -1).join(' ') : text
}

export default {
  name: 'AdvertisementItemWidget',
  props: {
    color: {
      type: [String],
      default: '#E0E0E0'
    },
    headline: String,
    content: String,
    creation_date: [String, Date],
    options: {
      type: Object,
      default: () => {
        return {
          counter: false
        }
      }
    },
    file: Object,
    offer_price: [String, Number],
    regular_price: [String, Number],
    dark: Boolean,
    download: {
      type: Function,
      default: null,
    }
  },
  components: {

  },
  data() {
    return {
      shorted: true
    }
  },
  computed: {
    offerPrice () {
      if (typeof this.offer_price === 'number') {
        return (this.offer_price * 0.01).toFixed(2)
      }

      return this.offer_price
    },
    regularPrice () {
      if (typeof this.regular_price === 'number') {
        return (this.regular_price * 0.01).toFixed(2)
      }

      return this.regular_price
    },
    discount () {
      let higher = parseInt(this.regularPrice)
      let lower = parseInt(this.offerPrice)

      if (higher <= lower) return '??'

      return Math.round((1.0 - (lower / higher)) * 100)
    },
    showPriceLabel() {
      let v = this.offer_price;

      if (typeof v === 'string') {
        v = parseInt(this.offer_price.toString().replace(/[^0-9]/g, ''))
      }

      return isNumber(v) && v > 0
    },
  },
  methods: {
    show_download () {
      if (typeof this.download === 'function') {
        return this.download(this.file)
      }

    }
  }
}
</script>

<template>
  <div class="layout-row mb-4 lx-advertisement-item" v-bind:style="{'color': color}" v-bind:class="[compColor.contentFrameBack]">
    <div class="flex pa-5 layout-column lx-advertisement-item-content" v-bind:class="[compColor.widget.text]">
      <div class="font-weight-bold lx-advertisement-item-headline mt-2">{{ headline }}</div>
      <v-divider class="my-1" v-bind:class="[compColor.divider]"></v-divider>
      <div class="flex body-2">
        <span>{{ this.content }}</span>
      </div>

      <div v-if="file" class="mt-3">
        <v-btn text block @click="show_download" class="lx-advertisement-item-document-link mt-3 font-weight-black"
               v-bind:style="{'color': color, 'caret-color': color}"
        >Produktinformation</v-btn>
      </div>
    </div>

    <div class="lx-advertisement-item-upper-box" v-if="options.counter || regular_price">
      <v-chip
          class="headline font-weight-black pa-5 elevation-4"
          :color="color"
          label
          text-color="white">
        <span v-if="options.counter" class="lx-advertisement-item-days-left font-weight-regular">Noch <strong>{{ options.days_left }}</strong> Tag(e) gültig</span>
        <div v-if="options.counter && regular_price" class="lx-advertisement-item-divider"></div>
        <span v-if="regular_price">-{{ discount }}%</span>
      </v-chip>
    </div>

    <!-- Preis unten rechts -->
    <div class="layout-column layout-align-end mr-2 mb-2" v-if="showPriceLabel">
      <div class="layout-row">
        <v-icon size="18" class="ml-1">euro_symbol</v-icon>
        <div class="layout-column lx-advertisement-item-prices">
          <div class="text-right font-weight-bold lx-advertisement-item-regular-price" v-bind:class="[compColor.widget.text]" v-if="regular_price">
            <small><del>{{ regularPrice }}</del></small>
          </div>
          <div class="layout-row" v-bind:style="{'color': color}">
            <span class="font-weight-black lx-advertisement-item-offer-price-big">{{ offerPrice.toString().split('.')[0] }}</span>
            <span class="font-weight-black lx-advertisement-item-offer-price-small">.{{ offerPrice.toString().split('.')[1] }}</span>
          </div>
          <small></small>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v-application {
  .lx-advertisement-item {
    position: relative;
    border: 1px solid currentColor !important;
    border-radius: 5px;

    .lx-advertisement-item-content{
      position:relative;
      .lx-advertisement-item-headline{
        line-height: 1.0rem;font-size:16px;
      }
      .lx-advertisement-item-document-link{
        cursor: pointer;
        text-decoration: none;
      }
    }

    .lx-advertisement-item-upper-box{
      position:absolute;
      top:-10px;
      right:10px;

      .lx-advertisement-item-days-left{
        font-size: 12px;
        strong{
          font-size: 16px;
        }
      }
      .lx-advertisement-item-divider{
        width:1px;
        background:white;
        height:20px;
        margin:0 15px;
      }
    }

    .lx-advertisement-item-prices{
      position: relative;
      .lx-advertisement-item-offer-price-big{
        font-size:50px;
        line-height: 40px;
      }
      .lx-advertisement-item-offer-price-small{
        font-size:30px;
        line-height: 30px;
        padding-top:12px;
      }
      .lx-advertisement-item-regular-price{
        position:absolute;
        right:0;
        top:0;
        line-height:10px;
      }
    }
  }
}
.lx-dark-app {
  .lx-advertisement-item {
    border: 1px solid currentColor !important;

    .lx-advertisement-item-upper-box{
      .lx-advertisement-item-divider{
        width:1px;
        background:white;
        height:20px;
        margin:0 15px;
      }
    }
  }
}
</style>
