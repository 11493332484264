<template>
  <div class="layout-row">
    <v-tooltip bottom open-delay="750">
      <template v-slot:activator="{ on }">

        <div v-on="on" class="layout-row layout-align-center-center" style="position:relative;height:100%;">

          <div class="blue-grey lighten-4 layout-row layout-align-center-center"
               style="border-radius: 4px;width:47px;height:25px;" v-if="!valueBool">
            <div class="px-3" style="border-radius:2px;">
              <v-icon color="blue-grey" small @click="$emit('clickBtn', value)">mdi-plus-thick</v-icon>
            </div>
          </div>

          <div class="blue-grey lighten-4 layout-row layout-align-center-center" style="border-radius: 4px;width:47px;"
               v-if="valueBool && !valueIsArray">
            <v-menu
              bottom
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="blue-grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-menu-open
                </v-icon>
              </template>

              <v-btn-toggle
                  dense
                  :dark="isDarkMode"
              >
                <v-btn @click="$emit('clickBtnEdit', value)">
                  <v-icon color="blue-grey">mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="$emit('clickBtnDelete', value)">
                  <v-icon color="blue-grey">delete_forever</v-icon>
                </v-btn>
              </v-btn-toggle>

            </v-menu>
          </div>

          <div class="blue-grey lighten-4 layout-row" @click="$emit('clickBtn', value)" style="border-radius: 4px;"
               v-if="valueBool && valueIsArray">
            <div class="px-1 layout-column layout-align-center-center">
              <v-icon small color="blue-grey darken-2">mdi-pencil</v-icon>
            </div>
            <span v-if="getValueByObjectPath(value, field)"
                  class="px-1 white--text font-weight-bold layout-row layout-align-center-center blue-grey"
                  style="border-radius:0 4px 4px 0;max-width:23px;min-width:23px;height:25px;">
                  {{ getValueByObjectPath(value, field).length }}
                </span>
          </div>

        </div>

      </template>
      <span v-if="!valueBool">Lagerort des Artikels auf der Station festlegen</span>
      <span v-if="valueBool">Lagerort des Artikels auf der Station anpassen</span>
    </v-tooltip>
  </div>

</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: [
    'value',
    'field',
    'clickBtn',
    'clickBtnEdit',
    'clickBtnDelete',
  ],
  data() {
    return {
      icon: '',
      // value: null,
      valueBool: false,
      valueIsArray: false,
      addAction: null,
      editAction: null,
      deleteAction: null,
      alreadySetList: null,
    }
  },
  created() {
    if (this.value && this.field && this.getValueByObjectPath(this.value, this.field)) {
      this.valueBool = true
      this.valueIsArray = Array.isArray(this.getValueByObjectPath(this.value, this.field))
    } else {
      this.valueBool = false
      this.valueIsArray = false
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal && this.field && this.getValueByObjectPath(newVal, this.field)) {
          this.valueBool = true
          this.valueIsArray = Array.isArray(this.getValueByObjectPath(newVal, this.field))
        } else {
          this.valueBool = false
          this.valueIsArray = false
        }
      },
      deep: true
    },
  },
  methods: {
    /**
     *
     * @param obj The complete object
     * @param path The path as dot notation to the field value (e.g. 'address.street.number')
     * @returns {*}
     */
    getValueByObjectPath(obj, path) {
      if (path.indexOf('.') <= 0) {
        return obj[path]
      }
    }
  }
})
</script>
