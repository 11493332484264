<template>
  <v-menu
      offset-y
      transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <lx-btn
            :color="color"
            :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
            small
            is-menu
            iconSize="20"
            width="auto"
            classes="text-none mr-1"
            :icon="icon"
            :tooltip="tooltip"
            :label="label"
            :loading="loading > 0"
            :disabled="disabled"
            v-bind="attrs"
            v-on="on"
        ></lx-btn>
      </div>
    </template>
    <v-list
        :color="compColor.primary"
        :dark="isDarkMode"
        :dense="denseFields"
    >
      <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :disabled="item.disabled === true"
          @click="clickSelect(item, index, $event)"
      >
        <v-list-item-icon v-if="!hideListIcons">
          <v-icon
              small
              v-text="item.icon"
          ></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
              style="cursor: pointer"
              v-text="item.caption"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LxButtonMenu",
  props: {
    options: Object,
    color: {
      type: String,
      default () {
        return '#353a47'
      }
    },
    label: String,
    tooltip: String,
    disabled: {
      type: Boolean,
      default () {
        return false
      },
    },
    icon: {
      type: String,
      default () {
        return 'mdi-file-chart-outline'
      }
    },
    items: {type: Array, default() {return []}},
    hideLabel: {
      type: Boolean,
      default () {
        return false
      }
    },
    hideListIcons: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      loading: 0
    }
  },
  methods: {
    clickSelect (item, index, event) {
      if (!item || isNaN(index)) {
        return
      }

      item = this.items[index]

      if (item.router) {
        return this.$router.push(item.router)
      }

      if (item && event) {
        this.$emit('click', event, item.value, index)
      }

    }
  }
}

</script>
