import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import i18n from './locales'
import axios from 'axios'
import moment from 'moment'
import vuetify from './plugins/vuetify';
import {extend, localize, ValidationObserver, ValidationProvider, configure} from 'vee-validate/dist/vee-validate.full';
import de from 'vee-validate/dist/locale/de.json';

import lxvue from 'lxvue'

import LxBtn from './components/lx_button/LxBtn'
import lx_auth from './components/lx_auth'
import lx_abda_search_field from './components/lx_abda_search_field'
import lx_messaging from './components/lx_messaging'
import lx_shopping_cart from './components/lx_shopping_cart'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// import '../node_modules/ag-grid-community/dist/styles/ag-grid.css'
// import '../node_modules/ag-grid-community/dist/styles/ag-theme-material.css'
import '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css'

import transport from './lib/transport'
import FormErrorHelper from './lib/formerrorhelper'
import QueryBuilder from './components/lx-query-builder'

import './style/lx-style.scss'
import {isNumber} from "lodash";

Vue.config.productionTip = false;

/**
 *
 * Axios Settings:
 *
 */
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.headers.common['client-id'] = process.env.VUE_APP_BASE_CLIENT_ID;
axios.defaults.headers.common['frontend-version'] = process.env.PACKAGE_VERSION || '0.0.0';

const isDevBuild = process.env['NODE_ENV'] === 'development';

/**
 *
 * Use Components:
 *
 */
Vue.use(transport, {propertyKey: '$http', forceBsonTransport: false, quiet: !isDevBuild, verbosity: isDevBuild});
Vue.use(lx_auth);

Vue.use(lxvue, {
  exclude: ['lxMessaging'],
  components: {
    vuetify
  }
})

Vue.use(lx_abda_search_field);
Vue.use(lx_messaging, {
  components: {
    i18n
  }
})

Vue.use(FormErrorHelper, {
  components: {
    i18n
  }
})

Vue.use(QueryBuilder);
Vue.use(lx_shopping_cart);

/**
 *
 * Vee-Validate Settings:
 * - Lokalisierung auf Deutsch (de)
 * - Erweiterung der Regeln mittels extend (Passwort und Passwortwiederholung vergleichen)
 * - Komponenten registrieren ValidationProvider und ValidationObserver
 *
 */
localize('de', de)

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    const i18n_field_name = `validations.fields.${field}`

    if (i18n.te(i18n_field_name)) {
      values._field_ = i18n.t(i18n_field_name)
    } else {
      if (isDevBuild) {
        // INFORM DEVELOPER ONLY IS DEV BUILD
        console.log('VALIDATION NOT EXISTS FIELD NAME', field)
      }
    }

    return i18n.t(`validations.messages.${values._rule_}`, values)
  }
})

extend('decimal', {
  message: (field, n) => {
    void n === 0 && (n = [])
    let t = n.decimals || 'keine'
    return void t === 0 && (t = '*'), 'Der Wert muss numerisch sein und darf ' + (t === '*' ? '' : t) + ' Nachkommastellen enthalten.'
  },
  validate: (value, params) => {
    function isNullOrUndefined() {
      let values = [], len = arguments.length
      while (len--) values[len] = arguments[len]

      return values.every(function (value) {
        return value === null || value === undefined
      })
    }

    if (params === void 0) params = {}

    let decimals = params.decimals
    if (decimals === void 0) decimals = '*'

    let separator = params.separator
    if (separator === void 0) separator = '.'

    if (isNullOrUndefined(value) || value === '') {
      return false
    }

    // if is 0.
    if (Number(decimals) === 0) return /^-?\d*$/.test(value)

    const regexPart = decimals === '*' ? '+' : ("{1," + decimals + "}")
    const regex = new RegExp(("^[-+]?\\d*(\\" + separator + "\\d" + regexPart + ")?([eE]{1}[-]?\\d+)?$"))

    return regex.test(value)
  },
  params: [{name: 'decimals'}, {name: 'separator'}]
});
extend('multi_email', {
  validate: (value) => {
    const reMails = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let valid = true;
    value.split(";").map((a) => {
      let address = a.trim();
      let check = (address.match(reMails) != null);
      if (!check && address.length > 0) {
        valid = check
      }
    });
    return valid
  },
  message: 'Eine der E-Mail Adressen erfüllt nicht das benötigte Format.'
  // params: [{ name: 'other', isTarget: true }]
});
extend('unique_email', {
  validate: async (value, locations) => {
    let config = {}

    if (Array.isArray(locations) && locations.length > 0) {
      config.params = {
        locations: locations.join(';')
      }
    }

    const response = await axios.get('/users/existing/' + value, config);

    if (response.status === 200 && typeof response.data === 'boolean') {
      return !response.data;
    }

    return true
  },
  message: 'Diese E-Mail Adresse wird bereits verwendet. Bitte nutzen Sie eine andere E-Mail.'
  // params: [{ name: 'other', isTarget: true }]un
});
extend('regex_validate', {
  validate: (value) => {
    try {
      new RegExp(value);
      return true
    } catch (e) {
      return false;
    }
  },
  message: 'Ungültiges Regex'
});
extend('password', {
  validate: (value, {other}) => value === other,
  message: 'Die Passwörter stimmen nicht überein.', //'The password confirmation does not match.',
  params: [{name: 'other', isTarget: true}]
});
extend('requireDependency', {
  params: ['other'],
  validate: (value, {other}) => {
    return !!other && !!value
  },
  message: 'Dieses Feld wird benötigt'
});
extend('lower_than', {
  params: [{name: 'other', isTarget: true}],
  validate: (value, {other}) => {
    // console.log('value:',Number(value), 'other',Number(other), value > other)
    return Number(value) > Number(other)
  },
  message: (field, values) => {
    values._field_ = i18n.t(`validations.fields.${field}`);
    values._other_ = i18n.t(`validations.fields.${values.other}`);
    // console.log(values)
    return i18n.t(`validations.messages.${values._rule_}`, values);
  }
})
extend('date_not_in_future', {
  message: () => `Das Datum darf nicht in der Zukunft liegen.`,
  validate: (value) => {
    return moment(value).isValid() && (moment().isAfter(value, 'day') || moment().isSame(value, 'day'))
  }
})
extend('date_must_in_future', {
  message: () => `Das Datum muss in der Zukunft liegen.`,
  validate: (value) => {
    const source = value instanceof Date ? value : new Date(value)
    const target = new Date()

    return source.getTime() > target.getTime()
  }
})
extend('german_date', {
  message: () => `Die Eingabe ist kein gültiges Datum oder liegt vor dem 01.01.1900.`,
  validate: (value) => {
    const current = moment(new Date(String(value)))
    return current.isValid() && current.isAfter('1899-12-31', 'day')
  }
})
extend('date_must_be_lesser_than', {
  message: () => 'Das Datum muss kleiner sein als Datum Ende.',
  validate: (value, {otherValue}) => {
    if (!value || !otherValue) return true

    const source = value instanceof Date ? value : new Date(value)
    const target = otherValue instanceof Date ? otherValue : new Date(otherValue)
    return source.getTime() <= target.getTime()
  },
  params: ['otherValue']
})
extend('date_must_be_greater_than', {
  message: () => 'Das Datum muss größer sein als Datum Beginn.',
  validate: (value, {otherValue}) => {
    if (!value || !otherValue) return true

    const source = value instanceof Date ? value : new Date(value)
    const target = otherValue instanceof Date ? otherValue : new Date(otherValue)
    return source.getTime() >= target.getTime()
  },
  params: ['otherValue']
})
extend('json_object', {
  message: () => 'Muss ein valides JSON Objekt sein',
  validate: (value) => {
    try {
      const X = JSON.parse(value)
      return X !== null && X !== undefined
    } catch {
      return false
    }
  }
})
extend('url', {
  validate(value) {
    if (value) {
      return /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(value);
    }

    return false;
  },
  message: 'This value must be a valid URL',
})
extend('invalid_chars', {
  message: () => 'Hat ungültige Zeichen',
  validate: (value) => {
    if (value) {
      return /^[^@\*\\]+$/.test(value)
    }

    return true
  }
})
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.filter('formatDate', function (value, _format) {
  let format = _format || 'DD.MM.YYYY';
  moment.locale('de');
  if (value) {
    return moment(new Date(String(value))).format(format)
  } else {
    return null
  }
});

Vue.filter('currency', function (amount) {
  let value = parseFloat(amount);
  let currency, ger_currency = ''
  if (value) {
    currency = value.toFixed(2) + ' €'
    ger_currency = currency.replace('.', ',')
  }

  return ger_currency
});
Vue.filter('currency_ger', function (amount) {
  if (isNaN(amount)) return '-,-- €';
  let value = parseFloat(amount);
  let curency = value.toFixed(2)
  let ger_currency = curency.replace('.', ',')
  return ger_currency
});

/**
 * eigener Filter damit man mit einer Pipe ein Datum im HTML in die form DD.MM.YYYY bringen kann
 * */
Vue.filter('leadingZero', function (value, size) {
  let s = value.toString();
  while (s.length < (size || 2)) {
    s = '0' + s
  }
  return s
});

Vue.filter('pretty', function (value, size) {
  if (value === null || value === undefined) {
    return 'no data'
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.join('\n')
    }

    return JSON.stringify(value, null, size || 4)
  }

  return value
});

Vue.filter('json', function (value, size) {
  if (value === null || value === undefined) {
    return 'no data'
  }

  if (typeof value === 'object') {
    return JSON.stringify(value, null, size || 4)
  }

  return value
});

Vue.filter('translate', (value, prefix) => {
  let n = value || ''

  if (Array.isArray(prefix)) {
    prefix.push(value)
    n = prefix.join('.')
  }

  return i18n.t(n.toUpperCase())
})
/**
 *
 * Vue Instance:
 *
 */
new Vue({
  i18n,
  router,
  store,
  vuetify,
  LxBtn,
  render: h => h(App)
}).$mount('#app');
