<template>
  <div style="position: absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;" class="layout-column">

    <div class="layout-column flex ma-2 elevation-5"
         style="border-radius:4px;position:relative;"
         v-bind:style="{background: compColor.contentFrame}">

      <div v-bind:style="{'z-index': '4'}" class="layout-row layout-align-center-center pa-1">
        <div class="layout-row">
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  iconSize="20"
                  width="auto"
                  :disabled="isBusy"
                  classes="text-none mr-1"
                  @click="getData()"
                  icon="refresh"
                  :hide-label="true"
                  :tooltip="$t('BUTTONS.REFRESH.TOOLTIP')"
                  :label="$t('BUTTONS.REFRESH.TITLE')"></lx-btn>
          <lx-btn :color="'#353a47'"
                  :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                  small
                  :disabled="isBusy || !isCreateable"
                  iconSize="20"
                  width="auto"
                  classes="text-none mr-1"
                  @click="showModal()"
                  icon="mdi-plus-circle"
                  :tooltip="$t('BUTTONS.SPECIAL_ARTICLE_LIST.NEW.TOOLTIP')"
                  :label="$t('BUTTONS.SPECIAL_ARTICLE_LIST.NEW.TITLE')"></lx-btn>

        </div>
        <div class="flex layout-row"></div>

      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="pt-0 pb-2 px-1 layout-row">
        <v-text-field
            :dark="isDarkMode"
            :color="compColor.primeColor"
            :background-color="isDarkMode ? 'transparent':'grey lighten-3'"
            class="mt-2 mb-0"
            label="Suchbegriff(e) eingeben"
            hide-details
            outlined
            :dense="denseFields"
            prepend-inner-icon="mdi-magnify"
            clearable
            v-model="searchString"
        ></v-text-field>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="flex layout-column lx-delivery-background pa-1" style="overflow-y: hidden">
        <div class="elevation-3 flex layout-column pa-1" style="border-radius: 6px;"
             :style="{'background': compColor.lxExpansion.background}">


          <div style="border-radius: 6px;border: 1px solid rgba(0,0,0,0.1);" class="flex layout-column">
            <lx-data-table
                v-model="tableObj.selection"
                tableName="SpecialArticleLists"
                :items="items"
                :headers="tableObj.headers"
                :loading="isBusy"
                :tableOptions="tableObj.options"
                :hideContextFilter="true"
                @rowClicked="openEditorDetails"
                @changeOptions="changeTableOptions"
            >
              <template v-slot:cellrenderer="{ value }">
                <div v-if="value.f.renderer === 'dateTime'" style="padding:1px;height:35px;">
                  <div class="layout-row layout-align-start-center" style="border-radius:3px;height:33px;">
                    {{ value.i[value.f.value] | formatDate('DD.MM.YYYY HH:mm:ss') }}
                  </div>
                </div>
                <div v-if="value.f.renderer === 'delete'" style="padding:1px;height:35px;line-height:35px;">
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading || value.i[value.f.value]"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="deleteList(value.i._id)"
                          icon="mdi-delete-forever"
                          :hide-label="true"
                          :tooltip="$t('BUTTONS.SPECIAL_ARTICLE_LIST.DELETE.TOOLTIP')"
                          :label="$t('BUTTONS.SPECIAL_ARTICLE_LIST.DELETE.TITLE')"></lx-btn>
                </div>
                <div v-if="value.f.renderer === 'edit'" style="padding:1px;height:35px;line-height:35px;">
                  <lx-btn :color="'#353a47'"
                          :icon-color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                          :disabled="tableObj.loading"
                          iconSize="22"
                          height="30"
                          width="auto"
                          as-text
                          as-icon
                          classes="text-none mr-1"
                          @click.stop="showModal(value.i)"
                          icon="mdi-playlist-edit"
                          :hide-label="true"
                          :tooltip="$t('BUTTONS.SPECIAL_ARTICLE_LIST.EDIT.TOOLTIP')"
                          :label="$t('BUTTONS.SPECIAL_ARTICLE_LIST.EDIT.TITLE')"></lx-btn>
                </div>
                <div v-if="value.f.renderer === 'clickToActivate'" style="padding:1px;height:35px;line-height:35px;">
                  <div class="layout-row layout-align-end-center" style="border-radius:3px;height:33px;">
                    <v-btn
                      v-if="!value.i[value.f.value]"
                      :color="isDarkMode ? compColor.primeHighlightColor : compColor.primeColor"
                      block
                      outlined
                      small
                      text
                      @click.stop="countArticles(value.i._id, value.i.internalIndex)"
                    >{{ $t('BUTTONS.COUNT_ARTICLES.TITLE') }}</v-btn>
                    <span v-else>{{ value.i[value.f.value] }}</span>
                  </div>
                </div>
              </template>
            </lx-data-table>
          </div>
        </div>
      </div>

      <v-divider v-bind:style="{'border-color':compColor.lightDivider}"></v-divider>

      <div class="layout-row pa-0">
        <lx-pagination :page-name="'SpecialArticleLists'"
                       :page-options="pagingOptions"
                       :page-change="getData">
        </lx-pagination>
      </div>

    </div>

    <ValidationObserver ref="CreateEditDialog" v-slot="{ valid, pristine }">
      <lx-dialog v-model="dialogOptions.show" :allow-drag="true" :overlay="true" :size="{x: '80', y: 'auto'}" >
        <template #header>
          <div class="text-h6 font-weight-light pa-3 layout-row layout-align-center-center"
               :class="isDarkMode? 'blue-grey--text text--lighten-2' : ' grey--text text--darken-2'">
            <span v-if="!dialogOptions._id">Neue Sonderartikelliste</span>
            <span v-else>Sonderartikelliste ändern</span>
          </div>
        </template>
        <template #default>
          <div class="ma-1 pa-0 flex layout-column elevation-3"
               style="border-radius: 5px;position: relative;overflow-y: auto;"
               :style="{'background': compColor.lxExpansion.background}">

            <div class="flex-100 pa-2">
              <validation-provider tag="div" ref="listname"
                                   name="Bezeichner" rules="required|min:1|max:80"
                                   v-slot="{ errors }">
                <v-text-field
                    v-model="dialogModel.name"
                    outlined
                    class="mb-1"
                    label="Bezeichnung"
                    :dark="isDarkMode"
                    :error-messages="errors[0]"
                    :dense="denseFields"
                    :color="compColor.primeColor"
                    :background-color="isDarkMode ? 'transparent':'grey lighten-4'"
                >
                  <template #prepend v-if="!isDarkMode">
                    <v-icon color="grey lighten-2">mdi-playlist-edit</v-icon>
                  </template>
                </v-text-field>
              </validation-provider>
            </div>

          </div>
        </template>
        <template #actions>
          <div class="layout-row layout-align-center-center pa-1">
            <div class="flex mr-1">
              <lx-btn :color="'#353a47'"
                      block
                      :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                      :disabled="!valid || pristine"
                      small
                      class="flex"
                      iconSize="20"
                      width="auto"
                      classes="text-none mr-1"
                      @click.stop="saveModal"
                      icon="save"
                      :tooltip="$t('BUTTONS.DIALOG.SAVE.TOOLTIP')"
                      :label="$t('BUTTONS.DIALOG.SAVE.TITLE')"></lx-btn>
            </div>
            <lx-btn :color="'#353a47'"
                    :icon-color="isDarkMode || isMediumMode ? compColor.primeHighlightColor : compColor.primeColor"
                    small
                    iconSize="20"
                    width="auto"
                    classes="text-none"
                    @click.stop="dialogOptions.show = false;"
                    icon="cancel"
                    :tooltip="$t('BUTTONS.DIALOG.CANCEL.TOOLTIP')"
                    :label="$t('BUTTONS.DIALOG.CANCEL.TITLE')"></lx-btn>
          </div>
        </template>
      </lx-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
  import router from "@/router";

  export default {
    name: "CatalogSpecialList",
    data() {
      return {
        isLoading: 0, // benutzt während des GetDatas um eine Ladeanimation einzublenden
        searchString: null,
        searchDelayTimer: null,
        dialogOptions: {
          show: false,
          _id: null,
          errors: null
        },
        dialogModel: {},
        licenses: {},
        tableObj: {
          options: {
            sortBy: [],
            sortDesc: [],
            // getClassForItem: (row) => {
            //   if (row.isExpanded) {
            //     return 'row-bold'
            //   }
            //   return ''
            // }
          },
          filterValue: null,
          selection: null,
          headers: [
            {
              width: '30px',
              value: 'import_default',
              sortable: true,
              sticky: 'left',
              renderer: 'delete',
              noResize: true,
              alternateText: 'Löschen'
            },
            {
              text: 'Bezeichnung',
              value: 'name',
              sortable: true
            },
            {
              text: 'Artikelanzahl',
              value: 'article_count',
              sticky: 'right',
              class: 'text-right pr-2',
              width: '120px',
              renderer: 'clickToActivate'
            },
            {
              width: '30px',
              sticky: 'right',
              renderer: 'edit',
              noResize: true,
              alternateText: 'Editieren'
            },
          ]
        },
        items: [],
        frameworkComponents: null,
        columnDefs: null,
        rowData: null,
        FilterAreFloating: false,
        allowBlurOnLoading: true,
        showDetails: false,
        detailData: null,
      }
    },
    watch: {
      searchString() {
        clearInterval(this.searchDelayTimer);

        this.searchDelayTimer = setInterval(() => {
          clearInterval(this.searchDelayTimer);
          this.getData()
        }, 500)
      }
    },
    computed: {
      isBusy() {
        return this.isLoading > 0
      },
      isCreateable () {
        return this.licenses.num_special_article_lists < this.licenses.max_special_article_lists
      }
    },
    methods: {
      created() {

      },
      /**
       * Methode wird vom Grid angesprungen wenn man doppelt auf eine Zeile klickt. Dann wird die Detailansicht geöffent.
       * Kann auch an einen Editbutton in einer Spalte gehangen werden aber Doppelklick ist komfortabler.
       * **/
      openEditorDetails(params) {
        if (params?._id) {
          router.push({ path: `/administration/catalog/details/sal/${params._id.toString()}` });
        }
      },
      /**
       * React on changes of options in lx-data-table, set table-options in component and call getDataForTable
       */
      changeTableOptions(value) {
        if (value) {
          this.tableObj.options = value
        }

        this.getData()
      },
      /**
       *
       *
       * @param options
       */
      getData(options) {
        if (this.isBusy) return
        this.isLoading++

        if (options && typeof options.skip === 'number') {
          this.pagingOptions.skip = options.skip
        }
        if (options && options.limit) {
          this.pagingOptions.limit = options.limit
        }

        const params = this.$helper.GetQueryParam({
          ...this.tableObj.options,
          skip: this.pagingOptions.skip,
          limit: this.pagingOptions.limit,
          query: {},
          searchString: this.searchString,
          searchFields: ['name'],
          count: true,
        })

        this.loading++
        this.$http.get('/pharmacy/licenses/quota')
            .then(res => {
              this.licenses = res.data || {}
            })
            .catch(this.NotifyError)
            .finally(() => {
              this.loading--
            })


        this.$http.get('/abda/settings/list', params)
            .then(res => {
              if (res.data) {
                this.items = res.data.data || []
                this.pagingOptions.count = res.data.count || 0
              }
            })
            .catch(this.NotifyError)
            .finally(() => {
              this.isLoading--
            })
      },

      deleteList(id) {
        this.isLoading++

        this.$http.get('/abda/settings/list/' + id)
            .then((res) => {
              if (Array.isArray(res.data.link_with) && res.data.link_with.length > 0) {
                // Refuse delete
                this.DialogConfirm(
                    "Hinweis",
                    "Zum löschen der Liste müssen folgende Zuweisungen entfernt werden " + res.data.link_with.join(', ') + ".",
                    {
                      ok: this.$t('BUTTONS.DIALOGS.CONFIRM'),
                      hideCancelButton: true
                    }
                )
                    .then(() => {})
                    .catch(() => {})

                return
              }

              // Ask
              this.DialogConfirm(
                  "Löschen?",
                  `Möchten Sie wirklich die Liste "${res.data.name}" löschen? Alle enthaltenen Artikel werden ebenfalls gelöscht.`,
              )
                  .then(() => {
                    // Delete
                    this.isLoading++
                    this.$http.delete('/abda/settings/list/' + id)
                        .then((res) => {
                          let text = 'Liste gelöscht.'

                          if (res.data && res.data.deleted > 0) {
                            text += ` Dadurch wurden ebenfalls ${res.data.deleted} Artikel entfernt.`
                          }

                          this.NotifySystem({
                            title: 'Erfolgreich',
                            text: text,
                            type: 'success'
                          })
                        })
                        .catch(this.NotifyError)
                        .finally(() => {
                          this.isLoading--
                          this.getData()
                        })
                  })
                  .catch(() => {})
            })
            .catch(this.NotifyError)
            .finally(() => {
              this.isLoading--
            })
      },

      showModal(data) {
        if (!data) {
          // Show Create Dialog
          this.dialogModel = {}
          this.dialogOptions._id = null
          this.dialogOptions.show = true
          return
        }

        // Show Edit Dialog
        this.dialogModel = {
          name: data.name
        }

        this.dialogOptions._id = data._id
        this.dialogOptions.show = true
      },

      saveModal() {
        // Type 1 - Create
        if (!this.dialogOptions._id) {
          this.isLoading++
          this.dialogOptions.show = false

          this.$http.post('/abda/settings/list', this.dialogModel)
              .then(this.NotifySuccess)
              .catch(this.NotifyError)
              .finally(() => {
                this.isLoading--
                this.getData()
              })

          return
        }

        // Type 2 - Edit
        this.isLoading++
        this.dialogOptions.show = false

        this.$http.patch('/abda/settings/list/' + this.dialogOptions._id, this.dialogModel)
            .then(this.NotifySuccess)
            .catch(this.NotifyError)
            .finally(() => {
              this.isLoading--
              this.getData()
            })

      },

      countArticles (_id, index) {
        this.isLoading++
        this.$http.get(`/abda/count/${_id}`)
          .then((res) => {
            let val = "0"

            if (!isNaN(res.data["count"])) {
              val = res.data["count"].toLocaleString( 'de-DE' )
            }

            let data = {
              ...this.items[index],
              article_count: val
            }
            this.$set(this.items, index, data)

          })
          .catch(this.NotifyError)
          .finally(() => {
            this.isLoading--
          })
      }
    }
  }
</script>

<style scoped lang="scss">

</style>