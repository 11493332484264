// Copied from https://github.com/juareznasato/vuetify-money
<template>
  <v-text-field
      v-model="cmpValue"
      v-bind:label="label"
      v-bind:placeholder="placeholder"
      v-bind:readonly="readonly"
      v-bind:disabled="disabled"
      v-bind:outlined="outlined"
      v-bind:dense="dense"
      v-bind:hide-details="hideDetails"
      v-bind:error="error"
      v-bind:error-messages="errorMessages"
      v-bind:rules="rules"
      v-bind:clearable="clearable"
      v-bind:backgroundColor="backgroundColor"
      v-bind:prefix="options.prefix"
      v-bind:suffix="options.suffix"
      v-bind="properties"
      v-on:blur="onBlur"
  ></v-text-field>
</template>

<script>
export default {
  model: {prop: "value", event: "input"},
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: false
    },
    errorMessages: {
      type: [Array, String],
      default: () => []
    },
    rules: {
      type: [Array, String],
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    valueWhenIsEmpty: {
      type: String,
      default: "" // "0" or "" or null
    },
    valueOptions: {
      type: Object,
      default: function () {
        return {
          min: 0,
          minEvent: "SetValueMin"
        }
      }
    },
    options: {
      type: Object,
      default: function () {
        return {
          locale: "de-DE",
          prefix: "€",
          suffix: "",
          length: 11,
          precision: 2
        }
      }
    },
    // Other v-text-field properties
    properties: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data: () => ({}),
  computed: {
    cmpValue: {
      get: function () {
        return !this.value !== null && this.value !== ""
            ? this.value.toString()
            : this.valueWhenIsEmpty;
      },
      set: function (newValue) {
        if (!newValue) return this.$emit("input", "")

        this.$emit("input", newValue.replace(',', '.'));
      }
    }
  },
  methods: {
    humanFormat: function (number) {
      if (isNaN(number)) {
        number = ""
      } else {
        number = Number(number).toLocaleString(this.options.locale, {
          maximumFractionDigits: this.options.precision,
          minimumFractionDigits: this.options.precision
        })
      }

      return number
    },
    machineFormat(number) {
      if (number) {
        number = this.cleanNumber(number);
        number = number.padStart(parseInt(this.options.precision) + 1, "0");
        number =
            number.substring(
                0,
                number.length - parseInt(this.options.precision)
            ) +
            "." +
            number.substring(
                number.length - parseInt(this.options.precision),
                number.length
            );
        if (isNaN(number)) {
          number = this.valueWhenIsEmpty;
        }
      } else {
        number = this.valueWhenIsEmpty;
      }

      if (this.options.precision === 0) {
        number = this.cleanNumber(number);
      }

      return number;
    },
    // Retira todos os caracteres não numéricos e zeros à esquerda
    cleanNumber: function (value) {
      let result = "";
      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");

        for (let i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              // Retirar zeros à esquerda
              if (arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }
      return result;
    },
    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    },
    targetLength() {
      return (Number(this.cleanNumber(this.value).length) >= Number(this.options.length))
    },
    onBlur() {
      if (
          this.value.length === 0 ||
          parseFloat(this.value) <= this.valueOptions.min
      )
        this.$emit(
            this.valueOptions.minEvent || "SetValueMin",
            this.valueOptions.min
        );

      if (
          this.valueOptions.max &&
          parseFloat(this.value) >= this.valueOptions.max
      )
        this.$emit(
            this.valueOptions.maxEvent || "SetValueMax",
            this.valueOptions.max
        );
    }
  }
};
</script>