<template>
    <div class="flex layout-column pa-2">
        <div class="layout-row" v-show="!useBackupCode">
            <div class="flex"></div>

            <input type="number" ref="factor1" min="0" max="9"
                   @keyup="checkfield($event, 2)"
                   class="mx-2 py-3 text-center twofactor"
                   v-bind:class="styles[styleVersion].classes"
                   v-bind:style="styles[styleVersion].styles"/>
            <input type="number" ref="factor2" min="0" max="9"
                   @keyup="checkfield($event, 3)"
                   class="mx-2 py-3 text-center twofactor"
                   v-bind:class="styles[styleVersion].classes"
                   v-bind:style="styles[styleVersion].styles"/>
            <input type="number" ref="factor3" min="0" max="9"
                   @keyup="checkfield($event, 4)"
                   class="mx-2 py-3 text-center twofactor"
                   v-bind:class="styles[styleVersion].classes"
                   v-bind:style="styles[styleVersion].styles"/>
            <input type="number" ref="factor4" min="0" max="9"
                   @keyup="checkfield($event, 5)"
                   class="mx-2 py-3 text-center twofactor"
                   v-bind:class="styles[styleVersion].classes"
                   v-bind:style="styles[styleVersion].styles"/>
            <input type="number" ref="factor5" min="0" max="9"
                   @keyup="checkfield($event, 6)"
                   class="mx-2 py-3 text-center twofactor"
                   v-bind:class="styles[styleVersion].classes"
                   v-bind:style="styles[styleVersion].styles"/>
            <input type="number" ref="factor6" min="0" max="9"
                   @keyup="checkfield($event, 7)"
                   class="mx-2 py-3 text-center twofactor"
                   v-bind:class="styles[styleVersion].classes"
                   v-bind:style="styles[styleVersion].styles"/>
            <div class="flex"></div>
        </div>
        <div class="layout-row" v-show="useBackupCode">
            <div class="flex px-4 py-2">
                <input autofocus
                       v-model="backupCode"
                       ref="backupInput"
                       @keyup="fillModel"
                       class="py-3 text-center twofactor backupcode"
                       v-bind:class="styles[styleVersion].classes"
                       v-bind:style="styles[styleVersion].styles"
                       placeholder="Backupcode"/>
            </div>
        </div>

        <div class="text-center  mt-4">
            <small v-bind:class="styles[styleVersion].text" v-show="!useBackupCode">Falls sie Ihre App
                nicht zur Verfügung
                haben klicken Sie
                bitte <a @click="useBackupCode = !useBackupCode" v-bind:class="styles[styleVersion].text"
                         style="text-decoration: underline"><strong>hier</strong></a>
                für die Eingabe
                eines Backup Codes.</small>
            <small v-bind:class="styles[styleVersion].text" v-show="useBackupCode">Wenn Sie ihre App zur
                Verfügung haben
                klicken Sie
                bitte <a @click="useBackupCode = !useBackupCode" v-bind:class="styles[styleVersion].text"
                         style="text-decoration: underline"><strong>hier</strong></a>
                für die Eingabe
                eines Zugriffscodes.</small>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('CodeInput', {
    name: 'CodeInput',
    props: {
        errorResetFunc: [Function],
        enterFunc: [Function],
        value: [String],
        styleVersion: {
            type: Number,
            default: 0
        },
        // passcode:
    },
    data() {
        return {
            useBackupCode: false,
            backupCode: null,
            styles: [
                {classes: 'blue-grey--text text--darken-2 grey lighten-3', text: 'blue-grey--text'},
                {
                    classes: 'white--text',
                    styles: 'background:rgba(255,255,255,0.25);border:1px solid white;border-radius:5px;',
                    text: 'white--text'
                },
            ]
        }
    },
    created() {

    },
    mounted() {
      if (this.$refs['factor1']) {
        this.$refs['factor1'].focus()
      }
    },
    computed: {},
    watch: {
        useBackupCode(newVal, oldVal) {
            this.$emit('input', '');
            if (newVal && !oldVal) {
                setTimeout(() => {
                  if (this.$refs['backupInput']) {
                    this.$refs['backupInput'].focus()
                  }
                }, 100)
            }
        }
    },
    methods: {
        fillModel() {

            //

            if (this.useBackupCode) {
                if (this.backupCode.length >= 0) {
                    this.$emit('input', this.backupCode)
                }
            } else {
                let code = '';

                for (let i = 1; i < 7; i++) {
                    if (this.$refs['factor' + i.toString()].value) {
                        code += this.$refs['factor' + i.toString()].value
                    }
                }

                if (code.length >= 0) {
                    this.$emit('input', code)
                }
            }


        },
        checkfield(event, index) {
            this.loginError = false;

            /* Backspace zum löschen gedrückt */
            if (event.keyCode === 8 && index > 1) {
                let id_prev = index - 2;
                let id_prev2 = index - 1;

                id_prev < 1 ? 1 : id_prev;

                if (this.$refs['factor' + id_prev.toString()]) {
                    this.$refs['factor' + id_prev.toString()].focus()
                }

                if (!this.$refs['factor' + id_prev2.toString()].value) {
                    this.$refs['factor' + id_prev2.toString()].style.background = 'rgba(255,255,255,0.25)'
                }

                this.fillModel()
            } else {
                if (typeof this.errorResetFunc !== 'undefined' && event.keyCode !== 13) { //notwendig weil erste if bedingung auf 13 und index >= 7 hört
                    this.errorResetFunc()
                }

                let id_prev = index - 1;

                // remove whitespaces from input field because when user paste the complete code with whitspaces included
              this.$refs['factor' + id_prev.toString()].value = this.$refs['factor' + id_prev.toString()].value.trim()

              if (this.$refs['factor' + id_prev.toString()].value.length === 6) {
                // fill pasted numbers into fields
                const input = this.$refs['factor' + id_prev.toString()].value

                for (let i = 1; i < 7; i++) {
                  this.$refs['factor' + i.toString()].value = input.slice(i - 1, i)

                  if (this.$refs['factor' + i.toString()].value) {
                    this.$refs['factor' + i.toString()].style.background = 'rgb(61, 162, 61)'
                  }
                }
                this.$refs['factor6'].focus()
              } else {
                // fill number typed by user into field
                this.$refs['factor' + id_prev.toString()].value = this.$refs['factor' + id_prev.toString()].value.slice(0, 1)

                if (this.$refs['factor' + id_prev.toString()].value) {
                  this.$refs['factor' + id_prev.toString()].style.background = 'rgb(61, 162, 61)'
                }

                if (index < 7 && this.$refs['factor' + id_prev.toString()].value) {
                  this.$refs['factor' + index.toString()].focus()
                }
              }

              this.fillModel()
            }
        },
        resetSingleFields() {
            for (let i = 1; i < 7; i++) {
                if (this.$refs['factor' + i.toString()].value) {
                    this.$refs['factor' + i.toString()].value = '';
                    this.$refs['factor' + i.toString()].style.background = 'rgba(255,255,255,0.25)'
                }
            }
            this.backupCode = null;

          if (this.$refs['factor1']) {
            this.$refs['factor1'].focus()
          }
        }
    }
})
</script>

<style scoped lang="scss">

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    input.twofactor {
        font-size: 40px;
        max-width: 60px;
        min-width: 60px;
        border-radius: 5px;
        outline: 0;
        box-shadow: none;
        &.backupcode {
            font-size:20px;
            max-width:100%;
            min-width:100%;
        }
    }
</style>
