<template>
  <v-text-field
      :label="label"
      :color="compColor.primeColor"
      :dark="compColor.isInDarkMode"
      :disabled="disabled"
      :dense="dense"
      v-model="model"
      :hide-details="hideDetails"
      :suffix="suffix"
      outlined
      :class="classes"
      :hint="hint"
      :persistent-hint="persistentHint"
      :maxlength="maxLength"
      @keyup="keyPressed"
      @blur="onBlur"
      :error-messages="errorMessages"
  ></v-text-field>
</template>

<script>
import Vue from 'vue'
import lodash from 'lodash'

export default Vue.component('lxNumberfield', {
  props: {
    value: [String, Number],
    errorMessages: {
      type: [String, Object],
      // required: true
    },
    maxLength: {
      type: Number
    },
    label: {
      type: String
    },
    classes: {
      type: String
    },
    suffix: {
      type: String
    },
    hint: {
      type: String
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    separator: {
      type: String,
      default: '.'
    },
    decimal: {
      type: Number,
      default: 100
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      model: null,
      oldModel: null,
    }
  },
  created () {
    if (this.value) {
      this.model = this.value.toString().replace('.', this.separator)
    }
  },
  methods: {
    keyPressed () {
      this.model = this.model || ''
      let str = lodash.cloneDeep(this.model)
      let strSplit = str.split('')

      let existingSeparator = false
      let counter = 0

      let strFilter = strSplit.filter((item, index) => {
        if (this.separator === item && !existingSeparator) {
          existingSeparator = true
          return true
        }
        if (index === 0 && item === '-') {
          return true
        }
        let it = item?.replace(/\D/g, '')
        if (it.length > 0 && !existingSeparator) {
          return true
        }
        if (it.length > 0 && existingSeparator && counter < this.decimal) {
          counter++
          return true
        }
        return false
      })

      let strJoin = strFilter.join('')
      this.model = strJoin

      if (this.oldModel !== strJoin) {
        this.oldModel = strJoin
        let strReplace = strJoin.replace(',', '.')
        if (strReplace.length > 0) {
          this.$emit('input', Number(strReplace))
        } else {
          this.$emit('input', null)
        }
      }
    },
    onBlur () {}
  },
  watch: {
    value (newVal, oldVal) {
      if (typeof newVal === 'number' && newVal !== oldVal) {
        let temp = newVal.toString().replace('.', this.separator)
        this.model = temp
        this.oldModel = temp
      } else if (!newVal) {
        this.model = null
        this.oldModel = null
      }
    }
  },
  computed: {}
})
</script>
